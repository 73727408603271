<template>

  <div class="actionButtons">
    <div
      class="actionButton"
      :class="[isScreenshotActive ? 'active' : 'inactive']"
      :title="$t('map.TooltipPlaceMarker')"
    >
      <a @click="clickScreenshotHandle">
        <span class="glyphicons glyphicons-screenshot"></span>
      </a>
    </div>
    <div
      class="actionButton"
      :class="[isMoveActive ? 'active' : 'inactive']"
      :title="$t('map.TooltipDrag')"
    >
      <a @click="clickMoveHandle">
        <span class="glyphicons glyphicons-move"></span>
      </a>
    </div>
    <div class="actionButton inactive" :title="$t('map.TooltipScreenshot')">
      <a @click="clickFloppyHandle">
        <span class="glyphicons glyphicons-floppy-disk"></span>
      </a>
    </div>
    <div class="actionButton inactive" :title="$t('map.OpenPrintToScale')">
      <a @click="clickSaveHandle">
        <span class="glyphicon glyphicon-save-file"></span>
      </a>
    </div>
    <div class="actionButton"
         :class="[isPencilActive ? 'active' : 'inactive']"
         :title="$t('map.DrawContour.icon')">
      <a @click="clickPencilHandle">
        <span :class="[isPencilActive ? 'glyphicons glyphicons-remove' : 'glyphicon glyphicon-pencil']"></span>
      </a>
    </div>
  </div>

</template>

<script>

export default {
  name: 'action-buttons',

  props: {
    map: {
      type: Object,
      required: true
    },
    isScreenshotActive: {
      type: Boolean,
      required: true
    },
    isMoveActive: {
      type: Boolean,
      required: true
    },
    isPencilActive: {
      type: Boolean,
      required: true
    }
  },

  methods: {
    clickScreenshotHandle () {
      this.$emit('clickScreenshotBtn')
    },

    clickMoveHandle () {
      this.$emit('clickMoveBtn')
    },

    clickFloppyHandle () {
      this.$emit('clickFloppyBtn')
    },

    clickSaveHandle () {
      this.$emit('clickSaveBtn')
    },

    clickPencilHandle () {
      this.$emit('clickPencilBtn')
    }
  }
}

</script>

<style lang="less">

.actionButtons {
  position: absolute;
  top: 15px;
  right: 15px;
  height: 40px;
  z-index: 1;
}

.actionButton {
  width: 40px;
  height: 40px;
  transition: 0.5s;
  margin: 5px;
  float: right;
  background-color: #67ac45;

  &:hover {
    transform: scale(0.9);
    cursor: pointer;
  }

  &.active {
    background-color: #67ac45;
  }
  &.inactive {
    background-color: #838383;
  }

  a {
    span {
      display: inline-block;
      width: 35px;
      height: 35px;
      color: #fff;
      font-size: 21px;
      margin: 3px 1px;
    }
  }
}

</style>
