<template>
  <div>
    <dx-data-grid-wrapper
      v-if="!loading && cadastralOvamCustomers && selected.frmCadastralOvam && cadastralOvam.length > 0"
      ref="tableBase"
      :template-name="templateName"
      :controls="controls"
      :data="cadastralOvamCustomers"
      :selected="selected"
      :projectId="projectId"
      :row-key="rowKeyName"
      @ti-table-row-removed="rowRemoved"
      @ti-table-row-updated="rowUpdated"
      @ti-table-row-inserted="rowInserted"
      @savingToDataSourceFinished="saveEditedRowsUsingDataService"
       @selectionChanged="onSelectionChanged"
    >
      <div slot="customButtonsRightStart">
        <square-button :width="34" :height="34" :title="$t('button.create')" @click.native="showAddModal">
          <span class="addIcon glyphicons glyphicons-plus"></span>
        </square-button>
      </div>
      <div slot="customButtonsRightEnd">
        <square-button :width="34" :height="34" :title="$t('button.delete')" @click.native="showDeleteModal">
          <span class="addIcon glyphicons glyphicons-bin"></span>
        </square-button>
      </div>
    </dx-data-grid-wrapper>
    <nv-loading :show="loading"></nv-loading>
    <add-cadastral-ovam-customer-modal :show="addModalShown" :PrID="projectId" :CaID="CaID"  @create-cadastral-ovam-customer="addRowToTable" @close-modal="addModalShown = false"></add-cadastral-ovam-customer-modal>
    <confirm-delete-modal :subHeader="deleteModalSubHeader" :show="deleteModalShown" @delete-confirmed="deleteTableRow" @close-modal="deleteModalShown = false">
      <p>{{ selectedRows > 1? $t("project.deleteCadastralOvamCustomersHelp") : $t("project.deleteCadastralOvamCustomerHelp")}}</p>
    </confirm-delete-modal>
    <table-base />
  </div>
</template>

<script>
import tableBase from '@/components/tables/tableBase'
import nvLoading from '@/components/_shared/loading'
import squareButton from '@/components/_shared/squareButton'
import addCadastralOvamCustomerModal from '@/components/modal/addCadastralOvamCustomerModal'
import confirmDeleteModal from '@/components/modal/confirmDeleteModal'
import DxDataGridWrapper from '@/components/tables/dxDataGridWrapper'

import { CadastralOvamService } from '@/services/DataWS/cadastralOvamService'

const cadastralOvamService = new CadastralOvamService()
export default {
  name: 'tableCadastralOvamCustomers',
  extends: tableBase,
  components: {
    DxDataGridWrapper, nvLoading, squareButton, confirmDeleteModal, addCadastralOvamCustomerModal, tableBase
  },
  data () {
    return {
      templateName: 'frmCadastralOvamCustomers',
      rowKeyName: 'CuGUID',
      deleteKey: 'CuDeleted',
      dateLastChangedKey: 'CuDateLastChanged',
      tableName: 'cadastralOvamCustomers',
      idKey: 'CuID',
      selectedRows: 0
    }
  },
  computed: {
    cadastralOvam: {
      get () {
        return this.$store.state.cadastralOvam
      },
      set (value) {
        this.$store.commit('setCadastralOvams', value)
      }
    },
    cadastralOvamCustomers: {
      get () {
        return this.$store.state.cadastralOvamCustomers
      },
      set (value) {
        this.$store.commit('setCadastralOvamCustomers', value)
      }
    },
    CaID: function () {
      if (this.selected.frmCadastralOvam) {
        return this.selected.frmCadastralOvam.CaID
      } else {
        return null
      }
    },
    CaGUID: function () {
      if (this.selected.frmCadastralOvam) {
        return this.selected.frmCadastralOvam.CaGUID
      } else {
        return null
      }
    }
  },
  watch: {
    'selected.frmCadastralOvam': {
      handler: function (to, from) {
        if (to && to.CaGUID) {
          this.$store.dispatch('fetchCadastralOvamCustomers', { PrID: this.projectId, CaGUID: to.CaGUID, CaID: to.CaID })
        }
      },
      deep: true
    },
    'cadastralOvamCustomers': {
      handler: function (to, from) {
        if (!this.loading && this.cadastralOvamCustomers && this.selected.frmCadastralOvam && this.cadastralOvam.length > 0) {
          this.reUpdateSelected()
        }
      }
    }
  },
  methods: {
    onSelectionChanged (e) {
      this.selectedRows = e.selectedRowKeys.length
    },
    saveRow (row, action) {
      switch (action) {
        case 'INSERT':
          return cadastralOvamService.createCadastralOvamCustomer(row, this.projectId, this.CaID)
        case 'DELETE':
          return cadastralOvamService.deleteCadastralOvamCustomer(row, this.projectId, this.CaID)
        default:
          return cadastralOvamService.updateCadastralOvamCustomer(row, this.projectId, this.CaID)
      }
    },
    reFetchData  () {
      this.cadastralOvamCustomers = []
      this.$store.dispatch('fetchCadastralOvamCustomers', { PrID: this.projectId, CaGUID: this.CaGUID, CaID: this.CaID })
    }
  }
}
</script>

<style lang="less" scoped>
.addIcon, .deleteIcon{
  font-size: 1.2rem;
  margin: 2px 1px;
}
</style>
