import { dxTableCellValidator } from '@/utils/terraIndexEditorValidator'
import sortUtils from '@/utils/sortUtils'

export default {
  buildColumnFromTemplate: (control) => {
    let column = {
      caption: control.labels[0][control.languageCode3Letter],
      dataField: control.fields[0].key,
      validationRules: [{
        type: 'custom',
        validationCallback: dxTableCellValidator(control),
        message: null
      }]
    }
    // Use natural sorting on the column
    column.sortingMethod = function (a, b) {
      return sortUtils.naturalSort(a, b)
    }

    return column
  }
}
