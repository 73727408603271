<template>
  <div class="multi-edit-content">
    <div v-for="control in controls" :key="control.controlId">
      <!-- Text Editor-->
      <div v-if="control.fields[0].type === '25' || control.fields[0].type === '8' || control.fields[0].type === '1'">
        <label>{{ control.labels[0][languageCode] }}</label>
        <div class="input-group">
          <span :id="control.fields[0].key + '-span'" class="input-group-addon">
            <input :id="control.fields[0].key + '-use'" type="checkbox" style="vertical-align: text-bottom" v-on:change="fieldDataChanged(control.fields[0].key, 'use')" />
          </span>
          <input
            v-model="changedData[control.fields[0].key].value"
            type="text"
            class="form-control"
            :maxlength="control.fields[0].maxLength"
            v-on:change="fieldDataChanged(control.fields[0].key, 'value')"
          />
        </div>
      </div>
      <!-- ListEditor -->
      <div v-else-if="control.fields[0].type === '11'">
        <label>{{ control.labels[0][languageCode] }}</label>
        <div class="input-group">
          <span :id="control.fields[0].key + '-span'" class="input-group-addon">
            <input :id="control.fields[0].key + '-use'" type="checkbox" class="checkbox-align" v-on:change="fieldDataChanged(control.fields[0].key, 'use')" />
          </span>
          <select v-model="changedData[control.fields[0].key].value" class="form-control" v-on:change="fieldDataChanged(control.fields[0].key, 'value')">
            <option value=""></option>
            <option v-for="(option, index) in listsOptions[control.fields[0].key]" :key="index" :value="option.value">
              {{ option.text[0][languageCode] }}
            </option>
          </select>
        </div>
      </div>
      <!-- Number editor -->
      <div v-else-if="control.fields[0].type === '6'">
        <label>{{ control.labels[0][languageCode] }}</label>
        <div class="input-group">
          <span :id="control.fields[0].key + '-span'" class="input-group-addon">
            <input :id="control.fields[0].key + '-use'" type="checkbox" class="checkbox-align" v-on:change="fieldDataChanged(control.fields[0].key, 'use')" />
          </span>
          <input v-model="changedData[control.fields[0].key].value" type="number" class="form-control" v-on:change="fieldDataChanged(control.fields[0].key, 'value')" />
        </div>
      </div>
      <!-- Date editor or Date time editor. Because we only use date -->
      <div v-else-if="control.fields[0].type === '2' || control.fields[0].type === '13'">
        <label>{{ control.labels[0][languageCode] }}</label>
        <div class="input-group">
          <span :id="control.fields[0].key + '-span'" class="input-group-addon">
            <input :id="control.fields[0].key + '-use'" type="checkbox" class="checkbox-align" v-on:change="fieldDataChanged(control.fields[0].key, 'use')" />
          </span>
          <input v-model="changedData[control.fields[0].key].value" type="date" class="form-control" v-on:change="fieldDataChanged(control.fields[0].key, 'value')" />
        </div>
      </div>
      <!-- Sample taker and drilling master editor -->
      <div v-else-if="control.fields[0].type === '3'">
        <label>{{ control.labels[0][languageCode] }}</label>
        <div class="input-group">
          <span :id="control.fields[0].key + '-span'" class="input-group-addon">
            <input :id="control.fields[0].key + '-use'" type="checkbox" class="checkbox-align" v-on:change="fieldDataChanged(control.fields[0].key, 'use')" />
          </span>
          <input
            v-model="changedData[control.fields[0].key].value"
            type="text"
            class="form-control col-sm-12 custom-select"
            :list="control.fields[0].key + '-list'"
            v-on:change="fieldDataChanged(control.fields[0].key, 'value')"
          />
          <datalist :id="control.fields[0].key + '-list'">
            <option v-for="(option, index) in listsOptions[control.fields[0].key]" :key="index" :value="option.text[0][languageCode]">
              {{ option.text[0][languageCode] }}
            </option>
          </datalist>

          <!-- <select v-model="changedData[control.fields[0].key].value" class="form-control" v-on:change="fieldDataChanged(control.fields[0].key, 'value')">
            <option value=""></option>
            <option v-for="(option, index) in listsOptions[control.fields[0].key]" :key="index" :value="option.text[0][languageCode]">
              {{ option.text[0][languageCode] }}
            </option>
          </select> -->
        </div>
      </div>
      <!-- Boolean editor that returns a string true false value -->
      <div v-else-if="control.fields[0].type === '4'">
        <label>{{ control.labels[0][languageCode] }}</label>
        <div class="input-group">
          <span :id="control.fields[0].key + '-span'" class="input-group-addon">
            <input :id="control.fields[0].key + '-use'" type="checkbox" class="checkbox-align" v-on:change="fieldDataChanged(control.fields[0].key, 'use')" />
          </span>
          <select v-model="changedData[control.fields[0].key].value" class="form-control" v-on:change="fieldDataChanged(control.fields[0].key, 'value')">
            <option value="false" :label="$t('toggle.no')">
              {{ $t('toggle.no') }}
            </option>
            <option value="true" :label="$t('toggle.yes')">
              {{ $t('toggle.yes') }}
            </option>
          </select>
        </div>
      </div>
      <!-- SubLocationEditor -->
      <div v-else-if="control.fields[0].type === '30'">
        <label>{{ control.labels[0][languageCode] }}</label>
        <div class="input-group">
          <span :id="control.fields[0].key + '-span'" class="input-group-addon">
            <input :id="control.fields[0].key + '-use'" type="checkbox" class="checkbox-align" v-on:change="fieldDataChanged(control.fields[0].key, 'use')" />
          </span>
          <select v-model="changedData[control.fields[0].key].value" class="form-control" v-on:change="fieldDataChanged(control.fields[0].key, 'value')">
            <option value=""></option>
            <option v-for="(sublocation, index) in sublocations" :key="index" :value="sublocation.SlID">
              {{ sublocation.SlName }}
            </option>
          </select>
        </div>
      </div>
      <div></div>
    </div>
  </div>
</template>
<script>
import stringUtils from '@/utils/stringUtils'
import sortUtils from '@/utils/sortUtils'
import cookie from '@/utils/cacheProviders/cookieCacheProvider'
import config from '@/configurations/config.js'
import typefields from '@/configurations/app/config-typefield'

let getEditor = config.configEditors

export default {
  name: 'multiEditDialog',
  components: {},

  inject: ['validate'],
  props: {
    form: String,
    projectId: Number,
    template: Object
  },
  computed: {
    languageCode() {
      return stringUtils.convert2To3LetterLanguageCode(cookie.get('language'))
    }
  },
  data() {
    return {
      controls: [],
      changedData: {},
      listsOptions: {},
      sublocations: []
    }
  },
  async created() {
    if (this.template.screenName === 'frmMeasurementPointsGrid') {
      this.$store.dispatch('getSubLocations', { projectId: this.projectId }).then((result) => {
        this.sublocations = result
      })
    }
    // fetch multi-edit fields from cache and parse
    if (this.template && this.template.screenName) {
      this.controls = await this.parseMultiEditFields(this.template)
    } else {
      // fetch multi-edit fields from GUITemplate
      this.controls = await this.$store.dispatch('fetchMultiEditFields', {
        formName: this.form,
        projectId: this.projectId
      })
    }

    if (typeof this.controls === 'undefined') {
      console.log('template controls not found in multiEditDialog.vue created()')
    } else {
      this.controls.forEach((control) => {
        // instantiate the changedData with fields and default values
        this.changedData[control.fields[0].key] = {}
        this.changedData[control.fields[0].key].use = false
        // A boolean editor needs to have a default value of false instead of empty string otherwise it crashes the sync
        if (control.fields[0].type === '4') {
          this.changedData[control.fields[0].key].value = 'false'
        } else {
          this.changedData[control.fields[0].key].value = ''
        }
        // A prepare list to prepare in the data object
        if (control.fields[0].type === '3' || control.fields[0].type === '11') {
          this.listsOptions[control.fields[0].key] = this.orderGUIListAlphabetically(control.fields[0]['options'])
        }
        this.changedData[control.fields[0].key].type = control.fields[0].type
        this.changedData[control.fields[0].key].dataField = control.fields[0].key
      })
    }
  },
  mounted() {
    this.validate(this.changedData)
  },
  methods: {
    async parseMultiEditFields(template) {
      if (typeof template === 'undefined') {
        console.log('template controls not found in actions.js fetchMultiEditFields()')
        return
      }

      let sortFunction = function (a, b) {
        return parseInt(a.order) - parseInt(b.order)
      }
      let controls
      let allowedControls = template.controls.filter((control) => {
        // return true
        return control.inputControlOptions.AllowMultiEdit && control.inputControlOptions.AllowMultiEdit === 'true'
      })
      controls = allowedControls
        .map((control) => {
          // leave one field
          if (control.fields.length > 1) {
            let tempfield = control.fields[0]
            let typeFields = typefields[tempfield.type]
            if (typeFields) {
              let generatedTypeField = typeFields.find((v) => {
                let check = tempfield.key.indexOf(v.name) !== -1
                if (tempfield.title) {
                  check = check && tempfield.title.indexOf(v.title) !== -1
                }
                return check
              })
              if (generatedTypeField) {
                let raws = []
                control.fields.forEach((field) => {
                  let raw = {}
                  raw.key = field.key
                  raw.maxLength = field.maxLength
                  raw.requiredBySystem = field.requiredBySystem
                  raw.requiredByUser = field.requiredByUser
                  raw.options = field.options
                  raws.push(raw)
                })
                tempfield.raws = raws
                control.fields = [tempfield]
              }
            }
          }
          control.fields.forEach((field) => {
            delete field.component
            let editor = getEditor(field.type || '*')
            field.component = editor.name
            field.editorOptions = editor.options
            field.inputControlOptions = control.inputControlOptions || {}
          })
          return control
        })
        .sort(sortFunction)

      return controls
    },
    // This method needs to be added to checkbox and value because of a reactivity isue
    fieldDataChanged(key, item) {
      // invert the checkbox value if this is changed otherwise set on true
      if (item === 'use') {
        this.changedData[key]['use'] = !this.changedData[key]['use']
      } else {
        this.changedData[key]['use'] = true
      }
      let checkbox = document.getElementById(key + '-use')
      let span = document.getElementById(key + '-span')
      if (this.changedData[key]['use']) {
        // Check checkbox and make background green
        checkbox.checked = true
        span.classList.add('checked-background-colour')
      } else {
        // uncheck checkbox and remove background
        checkbox.checked = false
        span.classList.remove('checked-background-colour')
      }
    },
    orderGUIListAlphabetically(list) {
      return list.sort((a, b) => sortUtils.naturalSort(a.text[0][this.languageCode], b.text[0][this.languageCode]))
    }
  }
}
</script>

<style scoped>
/deep/ .dx-toolbar {
  background-color: white !important;
}
.checkbox-align {
  vertical-align: text-bottom;
}

.checked-background-colour {
  background-color: #67ac45;
}
.multi-edit-content {
  max-height: 25em;
  overflow: auto;
}
</style>
