<template>
  <div class="create-water-samples-dialog-container">
    <div class="create-water-samples-dialog-header">
      <div class="create-water-samples-dialog-header-text">
        {{ $t('createWaterSamplesDialog.filterTubesWithinMeasurementPoints') }}
      </div>
      <div class="matrix-selection-field-container">
        <div class="matrix-selection-field-label">
          {{ $t('createWaterSamplesDialog.sampleMatrix') }}
        </div>
        <DxSelectBox
          v-model="waterSampleMatrixCode"
          :valueExpr="'value'"
          :displayExpr="'text'"
          :dataSource="waterSampleMatrixOptions">
        </DxSelectBox>
      </div>
    </div>
    <div class="filter-tube-table-container">
      <DxDataGrid
        v-if="filterTubeTableData.length > 0"
        ref="filterTubeTable"
        :dataSource="filterTubeTableData"
        height="100%"
        keyExpr="FtGuid"
        :showBorders="true"
        :rowAlternationEnabled="true"
        @selectionChanged="onSelectionChanged"
      >
        <DxHeaderFilter :visible="true" />
        <DxSelection showCheckBoxesMode="always" mode="multiple" selectAllMode="all-pages" />
        <DxPaging :enabled="false" />
        <DxScrolling mode="standard" rowRenderingMode="standard" />
        <DxColumn dataField="MpName" :caption="$t('project.MeasurementPointName')" />
        <DxColumn dataField="FtName" :caption="$t('project.GaugingTubeName')" />
        <DxColumn dataField="FtFilterFrom" :caption="$t('project.GaugingTubeFrom')" />
        <DxColumn dataField="FtFilterTo" :caption="$t('project.GaugingTubeTo')" />
      </DxDataGrid>
    </div>
    <BasePopup
      v-show="showConfirmationPopup"
      :popupOpen="showConfirmationPopup"
      :popupTitle="$t('createWaterSamplesDialog.createWaterSamples')"
      :popupSubTitle="projectCode"
      :rightFooterButtonText="$t('createWaterSamplesDialog.create')"
      rightFooterButtonIcon="fas fa-plus"
      :rightFooterButtonActive="!isCreatingWaterSamples"
      :leftFooterButtonText="$t('label.back')"
      leftFooterButtonIcon="fas fa-chevron-left"
      :leftFooterButtonActive="!isCreatingWaterSamples"
      :canClose="!isCreatingWaterSamples"
      @leftButtonClicked="showConfirmationPopup = !showConfirmationPopup"
      @rightButtonClicked="onCreateWaterSamplesConfirmed"
      @closeButtonClicked="showConfirmationPopup = !showConfirmationPopup">
      <template>
        <div v-if="!isCreatingWaterSamples" class="confirmation-box">
          <font-awesome-icon icon="fas fa-exclamation-triangle" class="warning-icon"/>
          <div class="confirmation-text">
            {{ $t('createWaterSamplesDialog.createConfirmation', { 0: selectedFilterTubeRows.length }) }}
          </div>
        </div>
        <div v-else class="confirmation-box">
          <font-awesome-icon icon="fas fa-spinner" class="spinner-icon"/>
          <div class="confirmation-text">
            {{ $t('createWaterSamplesDialog.creatingWaterSamples', { 0: amountOfWaterSamplesCreated, 1: amountOfWaterSamplesToCreate }) }}
          </div>
        </div>
      </template>
    </BasePopup>
  </div>
</template>

<script>
import moment from 'moment'
import DxSelectBox from 'devextreme-vue/select-box'
import DxDataGrid, { DxColumn, DxHeaderFilter, DxPaging, DxScrolling, DxSelection } from 'devextreme-vue/data-grid'

import arrayUtils from '@/utils/arrayUtils'
import stringUtils from '@/utils/stringUtils'

import { CodeListService } from '@/services/DataWS/codeListService'
import { WaterSampleService } from '@/services/DataWS/waterSampleService'

import BasePopup from '@/components/boxes/base-components/basePopup.vue'

export default {
  name: 'CreateWaterSamplesDialog',
  components: { BasePopup, DxSelectBox, DxDataGrid, DxColumn, DxHeaderFilter, DxSelection, DxScrolling, DxPaging },
  emits: ['numberOfSelectedRowsChanged'],
  async mounted() {
    this.emitNumberOfSelectedRows()
    await this.setMatrixCodeList()
  },
  data() {
    return {
      waterSampleMatrixOptions: [],
      selectedFilterTubeRows: [],
      showConfirmationPopup: false,
      waterSampleMatrixCode: 'GW',
      isCreatingWaterSamples: false,
      amountOfWaterSamplesToCreate: 0,
      amountOfWaterSamplesCreated: 0
    }
  },
  props: {
    projectId: {
      type: String,
      required: true
    },
    projectCode: {
      type: String,
      required: true
    },
    selectedMeasurementPointsWithAtLeastOneFilterTube: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  computed: {
    filterTubeTableInstance() {
      return this.$refs.filterTubeTable.instance
    },
    waterSampleMatrixCodes() {
      return ['AW', 'DW', 'GW', 'SW', 'ST', 'UW']
    },
    filterTubeTableData() {
      return this.selectedMeasurementPointsWithAtLeastOneFilterTube.map((measurementPoint) => {
        return measurementPoint.filterTubes.map((filterTube) => {
          return {
            MpName: measurementPoint.MpName,
            MpGuid: measurementPoint.MpGuid,
            FtGuid: filterTube.FtGuid,
            FtName: filterTube.FtName,
            FtFilterFrom: filterTube.FtFilterFrom,
            FtFilterTo: filterTube.FtFilterTo
          }
        })
      }).flat()
    },
    matrixCodeListCategoryId() {
      return 23
    },
    codeListService() {
      return new CodeListService()
    },
    waterSampleService() {
      return new WaterSampleService()
    }
  },
  methods: {
    async setMatrixCodeList() {
      const matrixCodeList = await this.codeListService.getCodeListByCategoryIDOrCode(this.matrixCodeListCategoryId)

      this.waterSampleMatrixOptions = matrixCodeList.filter((code) => {
        return this.waterSampleMatrixCodes.includes(code.GcCode)
      }).map((code) => {
        return {
          value: code.GcCode,
          text: stringUtils.capitalizeFirstLetter(code.GcDescription)
        }
      })
    },
    onSelectionChanged(event) {
      this.selectedFilterTubeRows = event.selectedRowsData
      this.emitNumberOfSelectedRows()
    },
    emitNumberOfSelectedRows() {
      this.$emit('numberOfSelectedRowsChanged', this.selectedFilterTubeRows.length)
    },
    createWaterSamplesForSelectedFilterTubes() {
      this.showConfirmationPopup = true
    },
    async onCreateWaterSamplesConfirmed() {
      this.amountOfWaterSamplesToCreate = this.selectedFilterTubeRows.length
      this.amountOfWaterSamplesCreated = 0
      this.isCreatingWaterSamples = true

      for (const filterTubeRow of this.selectedFilterTubeRows) {
        const suggestedName = await this.getSuggestedNameForNewWaterSample(filterTubeRow)

        const waterSampleRecord = {
          WsName: suggestedName,
          WsMatrixCode: this.waterSampleMatrixCode,
          WsDate: moment().format('YYYY-MM-DDTHH:mm:ss')
        }

        await this.waterSampleService.createWaterSample(this.projectId, filterTubeRow.MpGuid, filterTubeRow.FtGuid, [waterSampleRecord])
        this.amountOfWaterSamplesCreated = this.amountOfWaterSamplesCreated + 1
      }

      this.isCreatingWaterSamples = false
      this.showConfirmationPopup = false
      this.filterTubeTableInstance.clearSelection()
      this.$emit('waterSamplesCreated')
    },
    async getSuggestedNameForNewWaterSample(filterTubeRow) {
      const sampleData = await this.$store.dispatch('getSamples', {
        projectId: this.projectId,
        gaugingTubeGuid: filterTubeRow.FtGuid,
        measurementPointGuid: filterTubeRow.MpGuid
      })

      let suggestedName = arrayUtils.getDefaultName(sampleData.tblWaterSamples, 'WsName', 'WsID')

      if (!suggestedName) {
        if (filterTubeRow.FtName !== null) {
          suggestedName = filterTubeRow.MpName + '-' + filterTubeRow.FtName + '-1'
        } else {
          suggestedName = filterTubeRow.MpName + '-1'
        }
      }

      return suggestedName
    },
    clearSelection() {
      if (!this.filterTubeTableInstance) return
      this.filterTubeTableInstance.clearSelection()
    }
  }
}
</script>

<!-- need to override dev extreme styles so can't be scoped -->
<style lang="less">
.create-water-samples-dialog-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  gap: 16px;

  .create-water-samples-dialog-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;

    .create-water-samples-dialog-header-text {
      font-weight: bold;
      flex-grow: 1;
      color: @gray700;
      font-size: 18px;
    }

    .matrix-selection-field-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;

      .matrix-selection-field-label {
        color: @gray500;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  .filter-tube-table-container {
    .dx-gridbase-container > .dx-datagrid-rowsview.dx-scrollable > .dx-scrollable-wrapper {
      max-height: 512px;
    }
  }

  .confirmation-box {
    height: 128px;
    width: 512px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 16px;

    .warning-icon {
      color: @warning500;
      font-size: 68px;
    }

    .spinner-icon {
      color: @gray700;
      font-size: 68px;
      animation: gif 1.5s infinite linear;
      @keyframes gif {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }

    .confirmation-text {
      text-align: center;
      font-size: 16px;
      font-weight: bold;
      color: @gray700;
    }
  }
}
/deep/ .popupHeader {
  padding: 15px;
}
</style>
