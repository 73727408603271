<template>
  <div>
    <TerraIndexDynamicTable
      ref="TerraIndexDynamicTable"
      scrollingMode="infinite"
      :tableData="tableData"
      :tableTitle="$t('label.Measurementpoints')"
      :tableDataIdentifier="tableIdentifier"
      :tableCreateAction="createTableRowAction"
      :tableUpdateAction="updateTableRowAction"
      :tableDeleteAction="deleteTableRowAction"
      :tableColumns="tableColumns"
      :tableName="tableName"
      :template="template"
      :settingName="settingName"
      :language="language"
      :excelExportSettings="exportSettings"
      :enableColumnChooser="enableColumnChooser"
      :search-panel-enabled="searchPanelEnabled"
      :enableKeyboardNavigation="true"
      :focusedRowEnabled="true"
      :state-storing="{}"
      :defaultSelectedRowIndex="0"
      :selectedRowKeyFromParent="mpGuid"
      :hasSaveAndRevertButtons="false"
      :searchPanelWidth="100"
      :onRowClick="onRowClick"
      @updateSelectedRows="onUpdateRow"
    />
  </div>
</template>

<script>
import TerraIndexDynamicTable from '@/components/dynamicTables/TerraIndexDynamicTable'
import GuiTemplateParser from '@/components/dynamicTables/GuiTemplateParser'
import Cookie from '@/utils/cacheProviders/cookieCacheProvider'
import stringUtils from '@/utils/stringUtils'
import Popup from '@/utils/popup'
import Config from '@/configurations/config'

const TiPopup = Popup[Config.platform].default

export default {
  name: 'measurementObjectsSelectTable',
  components: {
    TerraIndexDynamicTable
  },
  data() {
    return {
      tableData: [],
      tableColumns: [],
      tableIdentifier: 'MpGuid',
      tableName: 'tblMeasurementPoints',
      templateName: 'frmMeasurementPointsGrid',
      template: {},
      settingName: 'MeasurementPointsSelect_Settings'
    }
  },
  async created() {
    await this.getTableColumns()
  },
  props: {
    projectId: {
      type: String,
      required: true
    },
    updateSelectedRows: {
      type: Function,
      required: false,
      default() {
        return () => {}
      }
    },
    enableColumnChooser: {
      required: false,
      default() {
        return true
      }
    },
    searchPanelEnabled: {
      required: false,
      default() {
        return true
      }
    }
  },
  watch: {
    projectId: {
      deep: true,
      immediate: true,
      handler: async function () {
        await this.refreshTableData()
      }
    }
  },
  computed: {
    createTableRowAction() {
      return { active: false }
    },
    updateTableRowAction() {
      return { active: false }
    },
    deleteTableRowAction() {
      return { active: false }
    },
    language() {
      return Cookie.get('language')
    },
    exportSettings() {
      return { enabled: false }
    },
    isValidRequest() {
      return this.isProjectIdSet
    },
    isProjectIdSet() {
      return stringUtils.isNullOrEmptyOrWhitespace(this.projectId) === false
    },
    mpGuid() {
      return this.$route.params.mpGuid
    }
  },
  methods: {
    async refreshTableData() {
      if (this.isValidRequest) {
        const response = await this.getTableData()
        this.tableData = response
      } else {
        await this.clearTableData()
      }
    },
    async clearTableData() {
      this.tableData = []
    },
    async getTableData() {
      try {
        return this.$store.dispatch('getMeasurementPoints', {
          projectId: this.projectId
        })
      } catch (error) {
        await this.handleErrorResponse(error)
      }
    },
    async getTableColumns() {
      try {
        this.tableColumns = await this.$store
          .dispatch('getDefaultFieldTemplate', {
            templateName: this.templateName
          })
          .then((templateData) => {
            this.template = templateData
            const parsedTemplate = GuiTemplateParser.parseTemplate(templateData)
            if (!parsedTemplate) {
              return
            }
            parsedTemplate.find((item) => item.dataFieldKey === 'MpDate').dataType = 'date'

            return parsedTemplate
          })
      } catch (error) {
        await this.handleErrorResponse(error)
      }
    },
    async handleErrorResponse(error, errorMessage = 'message.UnknownError') {
      console.log(error)
      TiPopup.popup(this.$t(errorMessage))
    },
    onUpdateRow(rows) {
      /* Legacy */
      // this.updateSelectedRows(rows)
      if (Array.isArray(rows)) {
        this.$emit('update:selected', rows[0])
      }
    },
    onRowClick(event) {
      if (this.$route.params.mpGuid === event.data.MpGuid) return
      if (this.$route.params.overviewId) {
        this.$router.push({
          name: 'overview',
          params: {
            mpGuid: event.data.MpGuid,
            projectId: this.projectId,
            overviewId: this.$route.params.overviewId
          }
        })
      }
    }
  }
}
</script>
