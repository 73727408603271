<template>
  <div class="data-table-overview project-table-overview">
    <div class="data-table-overview-row">
      <div class="data-table">
        <ProjectDetailsTable :project="project" />
      </div>
    </div>
    <div class="data-table-overview-row">
      <div class="data-table">
        <LabAssignmentsTable :project="project" />
      </div>
    </div>
    <div class="data-table-overview-row">
      <div class="data-table">
        <SubLocationsTable :project="project" />
      </div>
    </div>
    <div class="data-table-overview-row">
      <div class="data-table">
        <SummariesAndConclusionsTable :project="project" />
      </div>
    </div>
    <!-- <div class="data-table-overview-row">
        <div class="data-map">
          <MeasurementObjectsMap
            :project="project"
          />
        </div>
    </div> -->
  </div>
</template>

<script>
import {
  ProjectDetailsTable,
  SummariesAndConclusionsTable,
  SubLocationsTable,
  LabAssignmentsTable
} from '@/components/dynamicTables/tables'

import BaseTableOverview from '@/components/table-overviews/baseTableOverview'

export default {
  name: 'projectOverview',
  extends: BaseTableOverview,
  components: {
    ProjectDetailsTable,
    SummariesAndConclusionsTable,
    SubLocationsTable,
    LabAssignmentsTable
  }
}
</script>

<style scoped>
.project-table-overview {
  /* padding: 1em; */
  height: 100%;
  overflow-y: scroll;
}

.data-table-overview-row {
  margin: 1em;
}

</style>
