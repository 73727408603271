<template>
  <div>
    <TerraIndexDynamicTable
      ref="terraIndexDynamicTable"
      :tableData="tableData"
      :tableTitle="$t('label.Measurementpoints')"
      :tableDataIdentifier="tableIdentifier"
      :tableCreateAction="createTableRowAction"
      :tableUpdateAction="updateTableRowAction"
      :tableDeleteAction="deleteTableRowAction"
      :tableGroupingAction="groupTableAction"
      :tableColumns="tableColumns"
      :tableName="tableName"
      :template="template"
      :settingName="settingName"
      :language="language"
      :excelExportSettings="exportSettings"
      :enableColumnChooser="true"
      :onRowValidating="customRowValidation"
      :stateStoring="{}"
      :defaultSelectedRowIndex="0"
      :selectedRowKeyFromParent="mpGuid"
      :additionalHeaderButtons="additionalHeaderButtons"
      :tableMultiUpdateAction="multiUpdateTableRowAction"
      :useMultiSelect="true"
      :isLoading="isLoading"
      :onRowClick="onRowClick"
      :projectId="projectId"
      :isValidRequest="isValidRequest"
      :hasMapButton="true"
      @openMap="openMap"
      @updateSelectedRows="onUpdateSelectedRows"
      @refreshTableData="refreshTableData"
    />
    <TiDialog ref="copyMoveSettingsDialog">
      <MeasurementObjectsCopyMoveSettingsDialog />
    </TiDialog>
    <TiDialog ref="selectProjectDialog">
      <ProjectSelectDialog />
    </TiDialog>
    <BasePopup
      v-show="createWaterSamplesDialogOpen"
      :popupOpen="createWaterSamplesDialogOpen"
      :popupTitle="$t('createWaterSamplesDialog.createWaterSamples')"
      :popupSubTitle="projectCode"
      :rightFooterButtonText="$t('createWaterSamplesDialog.create')"
      rightFooterButtonIcon="fas fa-plus"
      :rightFooterButtonActive="createWaterSamplesFromSelectedFilterTubesButtonActive"
      :rightFooterText="createWaterSamplesDialogRightFooterText"
      @closeButtonClicked="onCreateWaterSamplesDialogCloseButtonClicked"
      @rightButtonClicked="createWaterSamplesDialog.createWaterSamplesForSelectedFilterTubes()"
    >
      <CreateWaterSamplesDialog
        ref="createWaterSamplesDialog"
        :projectId="projectId"
        :projectCode="projectCode"
        :selectedMeasurementPointsWithAtLeastOneFilterTube="selectedMeasurementPointsWithAtLeastOneFilterTube"
        @numberOfSelectedRowsChanged="onAmountOfSelectedFilterTubesChanged"
        @waterSamplesCreated="createWaterSamplesDialogOpen = false"
      />
    </BasePopup>
    <Transition>
      <div
        v-if="showMap"
        class="map-container"
      >
        <div class="mapBar">
          <div
            class="close-map"
            @click="showMap = false"
          >
            <span class="glyphicon glyphicon-chevron-right"></span>
          </div>
        </div>
        <open-layers
          :showActionButtons=false
          @selectRow="selectNewRow"
        ></open-layers>
      </div>
    </Transition>
  </div>
</template>

<script>
import TerraIndexDynamicTable from '@/components/dynamicTables/TerraIndexDynamicTable'
import GuiTemplateParser from '@/components/dynamicTables/GuiTemplateParser'
import Cookie from '@/utils/cacheProviders/cookieCacheProvider'
import ProjectionUtils from '@/utils/projectionUtil'
import MeasurementObjectsCopyMoveSettingsDialog from '@/components/modalSelects/copyMoveSettingsDialog'
import ProjectSelectDialog from '@/components/modalSelects/projectSelectDialog'
import CreateWaterSamplesDialog from '@/components/dynamicTables/tables/createWaterSamplesDialog'
import openLayers from '@/components/openLayers.vue'
import TiDialog from '@/components/_shared/tiDialog'
import Popup from '@/utils/popup'
import stringUtils from '@/utils/stringUtils'
import Config from '@/configurations/config'
import DataTableUtils from '@/utils/dataTableUtils'
import { formatDate } from 'devextreme/localization'
import { Promise } from 'bluebird'
import BasePopup from '@/components/boxes/base-components/basePopup.vue'

const TiPopup = Popup[Config.platform].default

export default {
  name: 'MeasurementObjectsTable',
  components: {
    TerraIndexDynamicTable,
    MeasurementObjectsCopyMoveSettingsDialog,
    ProjectSelectDialog,
    CreateWaterSamplesDialog,
    TiDialog,
    openLayers,
    BasePopup
  },
  data() {
    return {
      showMap: false,
      tableData: [],
      tableColumns: [],
      tableName: 'tblMeasurementPoints',
      tableNameForExport: 'tblMeasurementPoints',
      tableIdentifier: 'MpGuid',
      dateLastChangedField: 'MpDateLastChanged',
      templateName: 'frmMeasurementPointsGrid',
      template: {},
      settingName: 'MeasurementPoints_Settings',
      isLoading: false,
      additionalHeaderButtons: [
        {
          locateInMenu: 'auto',
          location: 'after',
          name: 'updateRowButton',
          options: {
            disabled: false,
            icon: 'glyphicons glyphicons-duplicate',
            onClick: this.onDuplicateButtonClick,
            onInitialized: this.onDuplicateButtonInitialized,
            hint: this.$t('label.duplicateMeasurementPoint'),
            text: this.$t('label.duplicateMeasurementPoint')
          },
          showText: 'inMenu',
          sortIndex: 22,
          widget: 'dxButton'
        },
        {
          locateInMenu: 'auto',
          location: 'after',
          name: 'updateRowButton',
          options: {
            disabled: false,
            icon: 'glyphicon glyphicon-tint',
            onClick: this.onCreateWaterSamplesButtonClick,
            onInitialized: this.onCreateWaterSamplesButtonInitialized,
            hint: this.$t('createWaterSamplesDialog.createWaterSamples'),
            text: this.$t('createWaterSamplesDialog.createWaterSamples')
          },
          showText: 'inMenu',
          sortIndex: 22,
          widget: 'dxButton'
        }
      ],
      selectedRows: [],
      duplicateButtonInstance: {},
      createWaterSamplesButtonInstance: {},
      createWaterSamplesDialogOpen: false,
      createWaterSamplesFromSelectedFilterTubesButtonActive: false,
      // this array gets lazily expanded as items get selected
      measurementPointsWithFilterTubes: [],
      createWaterSamplesDialogRightFooterText: ''
    }
  },
  async created() {
    await this.$store.dispatch('fetchMeasurementPoints', {
      PrID: this.$route.params.projectId
    })
    await this.getTableColumns()
  },
  props: {
    projectId: {
      type: String,
      required: true
    },
    projectCode: {
      type: String,
      required: true
    }
  },
  watch: {
    projectId: {
      deep: true,
      immediate: true,
      handler: async function () {
        await this.refreshTableData()
      }
    }
  },
  computed: {
    createTableRowAction() {
      return { active: true, method: this.createTableRow }
    },
    updateTableRowAction() {
      return { active: true, method: this.updateTableRow }
    },
    multiUpdateTableRowAction() {
      return {
        active: true,
        projectId: this.projectId,
        tableName: this.tableName,
        dateLastChangedField: this.dateLastChangedField
      }
    },
    deleteTableRowAction() {
      return { active: true, method: this.deleteTableRow }
    },
    groupTableAction() {
      return { active: true, method: this.evaluateGrouping }
    },
    language() {
      return Cookie.get('language')
    },
    exportSettings() {
      return {
        enabled: true,
        fileName: `${this.projectCode}_${this.tableNameForExport}`
      }
    },
    isValidRequest() {
      return this.isProjectIdSet
    },
    isProjectIdSet() {
      return stringUtils.isNullOrEmptyOrWhitespace(this.projectId) === false
    },
    copyMoveSettingsDialog() {
      return this.$refs.copyMoveSettingsDialog
    },
    selectProjectDialog() {
      return this.$refs.selectProjectDialog
    },
    createWaterSamplesDialog() {
      return this.$refs.createWaterSamplesDialog
    },
    coordinateSystemCode() {
      return this.$store.state.currentProject.PrCoordinateSystem
    },
    coordinateSystem() {
      const currentCoordinateSystemCode = this.coordinateSystemCode

      if (currentCoordinateSystemCode === false) {
        return
      }

      return ProjectionUtils.coordinateSystems.find((coordinateSystem) => {
        return coordinateSystem.GcCode === currentCoordinateSystemCode
      })
    },
    shouldEnableDuplicateButton() {
      if (this.isValidRequest === false) {
        return false
      }

      if (this.selectedRows.length < 1) {
        return false
      }

      return true
    },
    mpGuid() {
      return this.$route.params.mpGuid
    },
    filterTubeTypeCode() {
      return 'P'
    },
    measurementPointsWithAtLeastOneFilterTube() {
      return this.measurementPointsWithFilterTubes.filter((measurementPointWithFilterTube) => {
        return measurementPointWithFilterTube.filterTubes.length > 0
      })
    },
    selectedMeasurementPointsWithAtLeastOneFilterTube() {
      return this.measurementPointsWithAtLeastOneFilterTube.filter((measurementPointWithFilterTube) => {
        return this.selectedRows.some((selectedRow) => {
          return selectedRow.MpGuid === measurementPointWithFilterTube.MpGuid
        })
      })
    }
  },
  methods: {
    onRowClick(event) {
      if (this.$route.params.mpGuid === event.data.MpGuid) return
      this.$router.push({
        name: 'overview',
        params: {
          mpGuid: event.data.MpGuid,
          projectId: this.projectId,
          overviewId: this.$route.params.overviewId
        }
      })
    },
    openMap() {
      this.showMap = true
    },
    selectNewRow(clickedMarkerId) {
      this.$refs.terraIndexDynamicTable.selectRowWithId(clickedMarkerId)
      this.$router.push({
        name: 'overview',
        params: {
          mpGuid: clickedMarkerId,
          projectId: this.projectId,
          overviewId: this.$route.params.overviewId
        }
      })
    },
    onDuplicateButtonInitialized(event) {
      this.duplicateButtonInstance = event.component
    },
    onCreateWaterSamplesButtonInitialized(event) {
      this.createWaterSamplesButtonInstance = event.component
      this.evaluateCreateWaterSamplesButtonAvailability()
    },
    toggleDuplicateButtonVisibility() {
      if (this.duplicateButtonInstance && this.duplicateButtonInstance.option) {
        this.duplicateButtonInstance.option('disabled', this.shouldEnableDuplicateButton === false)
      }
    },
    evaluateCreateWaterSamplesButtonAvailability() {
      if (this.createWaterSamplesButtonInstance && this.createWaterSamplesButtonInstance.option) {
        this.createWaterSamplesButtonInstance.option('disabled', this.measurementPointsWithAtLeastOneFilterTube.filter((measurementPointWithFilterTube) => {
          return this.selectedRows.some((selectedRow) => {
            return selectedRow.MpGuid === measurementPointWithFilterTube.MpGuid
          })
        }).length === 0)
      }
    },
    async customRowValidation(event) {
      if (event.isValid === false) {
        return
      }

      if (event.newData.MpName) {
        event.isValid = this.validateNameIsUnique(event)

        if (event.isValid === false) {
          event.errorText = this.$t('message.nameShouldUnique')
          return
        }
      }

      event.promise = new Promise(async (resolve) => {
        if (event.newData.MpXCoord || event.newData.MpYCoord) {
          await this.validateCoordinates(event, resolve)
        }

        resolve({ isValid: true, errorText: '' })
      }).then((result) => {
        event.errorText = result.errorText
        event.isValid = result.isValid
      })
    },
    validateNameIsUnique(event) {
      const validationContext = {
        dataTable: this.tableData,
        propertyToValidate: 'MpName',
        guidToValidate: this.tableIdentifier,
        event: event
      }
      return DataTableUtils.ValidatePropertyIsUniqueInDataTable(validationContext)
    },
    async validateCoordinates(event, resolve) {
      if (this.coordinateSystem === false) {
        resolve({
          isValid: false,
          errorText: this.$t('message.coordinateSystemInvalid')
        })
      } else {
        if (event.newData.MpXCoord) {
          await this.validateXCoordinate(event, resolve)
        }

        if (event.newData.MpYCoord) {
          await this.validateYCoordinate(event, resolve)
        }

        await this.validateCombinedCoordinates(event, resolve)
      }
    },
    async validateCombinedCoordinates(event, resolve) {
      if ((!event.oldData || stringUtils.isNullOrEmptyOrWhitespace(event.oldData.MpYCoord)) && stringUtils.isNullOrEmptyOrWhitespace(event.newData.MpYCoord)) {
        resolve({ isValid: false, errorText: this.$t('message.invalidPoint') })
      }

      if ((!event.oldData || stringUtils.isNullOrEmptyOrWhitespace(event.oldData.MpXCoord)) && stringUtils.isNullOrEmptyOrWhitespace(event.newData.MpXCoord)) {
        resolve({ isValid: false, errorText: this.$t('message.invalidPoint') })
      }
    },
    async validateXCoordinate(event, resolve) {
      const projectedBounds = this.coordinateSystem.projectedBounds
      if (parseFloat(projectedBounds.XMin) > parseFloat(event.newData.MpXCoord)) {
        resolve({
          isValid: false,
          errorText: this.$t('message.CoordinateToLow') + `: ${projectedBounds.XMin}`
        })
      }

      if (parseFloat(projectedBounds.XMax) < parseFloat(event.newData.MpXCoord)) {
        resolve({
          isValid: false,
          errorText: this.$t('message.CoordinateToHigh') + `: ${projectedBounds.XMax}`
        })
      }
    },
    async validateYCoordinate(event, resolve) {
      const projectedBounds = this.coordinateSystem.projectedBounds
      if (parseFloat(projectedBounds.YMin) > parseFloat(event.newData.MpYCoord)) {
        resolve({
          isValid: false,
          errorText: this.$t('message.CoordinateToLow') + `: ${projectedBounds.YMin}`
        })
      }

      if (parseFloat(projectedBounds.YMax) < parseFloat(event.newData.MpYCoord)) {
        resolve({
          isValid: false,
          errorText: this.$t('message.CoordinateToHigh') + `: ${projectedBounds.YMax}`
        })
      }
    },
    async onDuplicateButtonClick(event) {
      if (this.selectedRows.length < 1) {
        TiPopup.popup(this.$t('message.Selection_is_required'))
        return
      }

      const response = await this.copyMoveSettingsDialog.open({
        title: this.$t('label.options'),
        buttonLabel: this.$t('label.duplicateMeasurementPoint'),
        numSelections: this.selectedRows.length,
        subHeader: this.$t('label.numberOfSelectedRows') + ': ' + this.selectedRows.length
      })
      if (response && response.action) {
        switch (response.action) {
          case 'move':
          case 'copy_move':
            const projectIdResponse = await this.getTargetProjectId()
            if (!stringUtils.isNullOrEmptyOrWhitespace(projectIdResponse)) {
              response.projectId = projectIdResponse
              await this.duplicateMeasurementObjects(response)
            }
            break
          case 'copy':
            await this.duplicateMeasurementObjects(response)
            break
        }
      }
    },
    async onCreateWaterSamplesButtonClick() {
      this.createWaterSamplesDialogOpen = true
    },
    async getTargetProjectId() {
      const options = await this.selectProjectDialog.open({
        title: this.$t('label.selectProject'),
        buttonLabel: this.$t('button.confirm')
      })
      if (options) {
        return options.PrID
      }
    },
    async duplicateMeasurementObjects(options) {
      try {
        this.isLoading = true

        await this.$store.dispatch('duplicateMeasurementPoint', {
          options: options,
          selection: this.selectedRows.map((row) => row[this.tableIdentifier]),
          data: this.selectedRows
        })
        await this.refreshTableData()
        this.isLoading = false
        const message = options.action === 'move' ? this.$t('message.move_mp_alert') : this.$t('message.duplicate_mp_alert')
        TiPopup.alertAsync(message, null, 'mp-alert')
      } catch (error) {
        await this.handleErrorResponse(error)
      }
      this.isLoading = false
    },
    async getTableDataRowsByRowKeys(keys) {
      return this.tableData.filter((row) => {
        return keys.indexOf(row[this.tableIdentifier]) > -1
      })
    },
    async onUpdateSelectedRows(data) {
      this.selectedRows = data
      this.toggleDuplicateButtonVisibility()
      await this.lazilyExpandMeasurementObjectsWithFilterTubes()
      this.evaluateCreateWaterSamplesButtonAvailability()
    },
    async lazilyExpandMeasurementObjectsWithFilterTubes() {
      const measurementPointsToRetrieveFilterTubeDataFor = this.selectedRows.filter((row) => {
        return !this.measurementPointsWithFilterTubes.some((measurementPointWithFilterTube) => {
          return measurementPointWithFilterTube.MpGuid === row.MpGuid
        })
      })

      const filterTubeRequestPromises = []

      measurementPointsToRetrieveFilterTubeDataFor.forEach((measurementPoint) => {
        filterTubeRequestPromises.push(this.getFilterTubeDataForMeasurementPoint(measurementPoint))
      })

      const measurementPointsWithFilterTubes = await Promise.all(filterTubeRequestPromises)

      measurementPointsWithFilterTubes.forEach((measurementPointWithFilterTubes) => {
        this.measurementPointsWithFilterTubes.push({
          MpGuid: measurementPointWithFilterTubes.measurementPoint.MpGuid,
          MpName: measurementPointWithFilterTubes.measurementPoint.MpName,
          filterTubes: measurementPointWithFilterTubes.filterTubes
        })
      })
    },
    async getFilterTubeDataForMeasurementPoint(measurementPoint) {
      return {
        measurementPoint: measurementPoint,
        filterTubes: await this.$store.dispatch('getGaugingTubes', {
          projectId: this.projectId,
          measurementPointGuid: measurementPoint.MpGuid
        })
      }
    },
    async createTableRow(row) {
      try {
        const newMeasurementPoint = {
          ...row.data,
          PrID: this.projectId
        }

        if (this.measurementPointHasCoordinates(newMeasurementPoint)) {
          newMeasurementPoint.MpPointGeometry = ProjectionUtils.convertCoordsToPointGeometry(newMeasurementPoint.MpXCoord, newMeasurementPoint.MpYCoord, this.coordinateSystem.epsg, 'EPSG:4326')
        }

        const measurementPoints = await this.$store.dispatch('createMeasurementPoint', {
          measurementPoint: newMeasurementPoint
        })

        this.tableData = measurementPoints
      } catch (error) {
        await this.handleErrorResponse(error)
      }
    },
    async updateTableRow(row) {
      try {
        const measurementPoint = {
          ...row.data,
          [this.dateLastChangedField]: await DataTableUtils.GenerateLastUpdatedAt()
        }
        if (this.measurementPointHasCoordinates(measurementPoint)) {
          measurementPoint.MpPointGeometry = ProjectionUtils.convertCoordsToPointGeometry(measurementPoint.MpXCoord, measurementPoint.MpYCoord, this.coordinateSystem.epsg, 'EPSG:4326')
        } else if (!stringUtils.isNullOrEmptyOrWhitespace(measurementPoint.MpPointGeometry)) {
          measurementPoint.MpPointGeometry = null
        }

        await this.$store.dispatch('updateMeasurementPoint', {
          measurementPoint: measurementPoint
        })
        await this.$store.dispatch('fetchMeasurementPoints', {
          PrID: this.$route.params.projectId
        })
      } catch (error) {
        await this.handleErrorResponse(error)
      }
    },
    async deleteTableRow(key) {
      try {
        await this.$store.dispatch('deleteMeasurementPoint', {
          projectId: this.projectId,
          measurementPointGuid: key
        })
      } catch (error) {
        await this.handleErrorResponse(error)
      }
    },
    async refreshTableData() {
      if (this.isValidRequest) {
        const response = await this.getTableData()
        this.tableData = response
      } else {
        await this.clearTableData()
      }
    },
    async clearTableData() {
      this.tableData = []
    },
    async getTableData() {
      try {
        return await this.$store.dispatch('getMeasurementPoints', {
          projectId: this.projectId
        })
      } catch (error) {
        await this.handleErrorResponse(error)
      }
    },
    async getTableColumns() {
      try {
        const [guiTemplateForm, sublocations] = await Promise.all([
          this.$store.dispatch('getDefaultFieldTemplate', {
            templateName: this.templateName
          }),
          this.$store.dispatch('getSubLocations', {
            projectId: this.projectId
          })
        ])

        this.template = guiTemplateForm
        this.tableColumns = GuiTemplateParser.parseTemplate(guiTemplateForm, {
          sublocations
        })
      } catch (error) {
        await this.handleErrorResponse(error)
      }
    },
    async handleErrorResponse(error, errorMessage = 'message.UnknownError') {
      console.log(error)
      TiPopup.popup(this.$t(errorMessage))
    },
    evaluateGrouping(rowData, tableColumn) {
      const value = rowData[tableColumn.dataFieldKey]
      if (tableColumn.dataFieldKey === 'MpDate') {
        if (stringUtils.isNullOrEmptyOrWhitespace(value)) {
          return this.$t('label.Empty')
        }
        const datetime = new Date(value)
        const dateTimeAsString = formatDate(datetime, 'yyyy-MM-dd')
        return dateTimeAsString
      }
      return value
    },
    measurementPointHasCoordinates(measurementPoint) {
      return stringUtils.isNullOrEmptyOrWhitespace(measurementPoint.MpXCoord) === false && stringUtils.isNullOrEmptyOrWhitespace(measurementPoint.MpYCoord) === false
    },
    onAmountOfSelectedFilterTubesChanged(amountOfSelectedFilterTubes) {
      this.createWaterSamplesFromSelectedFilterTubesButtonActive = amountOfSelectedFilterTubes > 0
      this.createWaterSamplesDialogRightFooterText = this.$t('createWaterSamplesDialog.amountOfWaterSamples', { 0: amountOfSelectedFilterTubes })
    },
    onCreateWaterSamplesDialogCloseButtonClicked() {
      this.createWaterSamplesDialogOpen = false
      this.createWaterSamplesDialog.clearSelection()
    }
  }
}
</script>

<style lang="less" scoped>
.glyphicons-duplicate:before {
  padding: 0;
}

#tidialogbg .modal-header {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

#tidialogbg .modal-content {
  border: 0;
}

.map-container {
  position: absolute;
  display: flex;
  z-index: 11;
  top: 0px;
  bottom: 60px;
  right: 0;
  height: calc(100vh - 120px) !important;
  background-color: #515151;
  width: 60vw;
}

.v-enter,
.v-leave-to {
  transform: translateX(60vw);
}

.v-leave-active,
.v-enter-active {
  transition: all 0.5s ease-in-out;
}

.mapBar {
  width: 50px;
  padding-left: 0px;
  padding-right: 0px;
  background-color: #515151;
  height: 100%;
}

.close-map {
  font-size: 30px;
  color: #eee;
  margin-left: 10px;
  margin-top: 10px;
  cursor: pointer;
}
/deep/ .popupHeader {
  padding: 15px;
}
</style>
