<template>
<div>
  <dx-tree-list
    :data-source="queries"
    key-expr="Name"
    height="300px"
    :focused-row-enabled="true"

    :focused-row-key.sync="focusedRow"
    @row-dbl-click="onRowDblClick"
  >
    <dx-search-panel
      :visible="true"
      :width="250"
    />
    <dx-scrolling
      mode="standard"
    />
    <dx-column data-field="Name" :caption="$t('columnTitles.MpName')"></dx-column>
    <dx-column data-field="Description" :caption="$t('gridColumn.localizedText')"></dx-column>
  </dx-tree-list>
</div>
</template>

<script>
import { DxTreeList, DxSearchPanel, DxColumn, DxScrolling } from 'devextreme-vue/tree-list'
import { mapGetters } from 'vuex'

export default {
  name: 'selectOverviewFromList',
  components: { DxTreeList, DxSearchPanel, DxScrolling, DxColumn },
  inject: ['validate'],
  data () {
    return {
      focusedRow: null
    }
  },
  created () {
    this.$store.dispatch('getOverviewQueries')
  },
  mounted () {
    if (this.currentOverview) {
      this.focusedRow = this.currentOverview.Name
    }
  },
  computed: {
    ...mapGetters(['currentOverview']),
    queries () {
      return this.$store.getters.getOverviewQueries
    }
  },
  watch: {
    'focusedRow' (name) {
      let record = this.getOverviewItem(name)
      this.validate(record)
    }
  },
  methods: {
    onRowDblClick (obj) {
      this.focusedRow = obj.key
    },
    getOverviewItem (name) {
      return this.queries.filter(item => item.Name === name)[0]
    }
  }
}
</script>

<style scoped>
/deep/ .dx-toolbar{
  background-color: white !important;
}
</style>
