<template>
  <div class="measurement-points-data-table-overview">
    <div class="overview-table-row">
      <div class="overview-table">
        <MeasurementObjectsTable
          :projectId="$route.params.projectId"
          :projectCode="projectCode"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { MeasurementObjectsTable } from '@/components/dynamicTables/tables'
import Popup from '@/utils/popup'
import Config from '@/configurations/config'
import stringUtils from '@/utils/stringUtils'

const TiPopup = Popup[Config.platform].default

export default {
  name: 'measurementPointsOverview',
  components: {
    MeasurementObjectsTable
  },
  data() {
    return {
      projectCode: ''
    }
  },
  async created() {
    await this.setCurrentProject()
  },
  methods: {
    async setCurrentProject() {
      if (stringUtils.isNullOrEmptyOrWhitespace(this.$route.params.projectId)) {
        TiPopup.popup(this.$t('message.projectNotFound'))
        return
      }

      await this.$store.dispatch('fetchProject', {
        PrID: this.$route.params.projectId
      })
      const project = this.$store.state.currentProject

      if (project && project.PrID) {
        this.projectCode = project.PrCode
      } else {
        TiPopup.popup(this.$t('message.projectNotFound'))
      }
    },
    async handleErrorResponse(error, errorMessage = 'message.UnknownError') {
      console.log(error)
      TiPopup.popup(this.$t(errorMessage))
    }
  }
}
</script>

<style>
.measurement-points-data-table-overview h2 {
  margin-top: 5px;
  font-size: 20px;
}
.measurement-points-data-table-overview {
  height: 100%;
}
.measurement-points-data-table-overview .overview-table-row {
  height: 100%;
}
.measurement-points-data-table-overview .overview-table-row:first-child {
  display: flex;
}
.measurement-points-data-table-overview .overview-table {
  overflow: hidden;
}
.measurement-points-data-table-overview
  .overview-table-row
  .overview-table
  > div {
  margin-left: 10px;
}
.measurement-points-data-table-overview .overview-table > div,
.measurement-points-data-table-overview .overview-table > div > div {
  height: 100%;
}
</style>
