<template>
  <div >
    <div v-if="ready" style="width:100%;">
      <div v-for="(param, index) in Object.keys(parameterObject)" :key="index">
        <div v-if="!isDefault(param)" style="padding-bottom:5px;">
          <input class="span2 paramStyling" type="text" :placeholder="param"/>
          <input v-model="parameterObject[param]" class="span2 paramInputStyling" type="text" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'insertParametersForOverview',
  inject: ['validate', 'agree'],
  data () {
    return {
      parameterObject: {},
      defaultParams: {
        PROJECTID: this.$route.params.projectId
      },
      hasParams: false,
      ready: false
    }
  },
  computed: {
    ...mapGetters(['currentOverview', 'currentOverviewParameters'])
  },
  mounted () {
    this.hasParams = false
    this.ready = false
    if (!this.currentOverviewParameters) {
      this.currentOverview.Parameters.forEach(this.prepareData)
      this.ready = true
    } else {
      Object.assign(this.parameterObject, this.currentOverviewParameters)
      for (var property in this.parameterObject) {
        if (!this.defaultParams.hasOwnProperty(property.toUpperCase())) {
          this.hasParams = true
        }
      }
      this.ready = true
    }

    if (!this.hasParams) {
      this.validate(this.parameterObject)
      this.agree()
    } else {
      this.validate(this.parameterObject)
    }
  },
  methods: {
    isDefault (param) {
      return param.toUpperCase() in this.defaultParams
    },
    prepareData (param) {
      if (param.toUpperCase() in this.defaultParams) {
        this.parameterObject[param] = this.defaultParams[param.toUpperCase()]
      } else {
        this.hasParams = true
        if (!this.parameterObject[param]) {
          this.parameterObject[param] = ''
        }
      }
    }
  }
}
</script>
<style scoped>
.paramStyling {
  pointer-events: none;
  border-radius: 0;
  height: 30px;
  width:50%;
  outline: 0;
  background-color: #eee;
  border: 0 black solid;
  text-align: left;
  padding-left: 5px;
  text-overflow: ellipsis;
}

.paramInputStyling {
  border-radius: 0;
  height: 30px;
  width: 50%;
}
</style>
