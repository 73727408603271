<template>
  <div class="openLayersContainer" :class="{ whileDrawing: clickToDrawContour }">
    <action-buttons
      v-if="!!map && showActionButtons"
      :map="map"
      :isScreenshotActive="clickToPlaceMarker"
      :isMoveActive="!!translateInteraction"
      :isPencilActive="clickToDrawContour"
      @clickScreenshotBtn="enableClickToPlaceMarker"
      @clickMoveBtn="toggleFeatureTranslateInteraction"
      @clickFloppyBtn="saveMapImage"
      @clickSaveBtn="openPrintToScaleModal"
      @clickPencilBtn="toggleDrawMode"
    ></action-buttons>
    <layer-handler
      v-if="!!map"
      :map="map"
      :editableMapLayers="!!editableMapLayers"
      :isHidden="isLayerHandlerHidden"
      :groupedEditableMapLayers="groupedEditableMapLayers"
      :expandedGroups="expandedGroups"
      @clickHandler="isLayerHandlerHidden = false"
      @clickGroupArrow="collapseGroup"
      @clickLayerArrow="collapseLayers"
      @clickLayerBox="setLayerVisible"
      @scrollOpacity="scrollOpacity"
      @clickOpacity="applyLayerConfiguration"
      @clickSpinner="changeOpacity"
      @clickSubLayerBox="setSubLayerVisible"
      @clickKMLVisible="changeVisibilityOfFeatures"
    ></layer-handler>
    <div v-if="showSearchBar" class="search-container">
      <search ref="search-component" style="width: 250px" :immediate="false" @search="search" />
    </div>
    <div id="ol-map" class="ol-map" @mousemove="moveCursor" @click="isLayerHandlerHidden = true"></div>
    <div
      v-if="clickToPlaceMarker"
      class="placeMarker"
      :style="{
        top: cursorYPixel - rectTop() - 18 + 'px',
        left: cursorXPixel - rectLeft() + 30 + 'px'
      }"
    >
      <span class="glyphicons glyphicons-screenshot"></span>
    </div>

    <start-draw-modal :drawSource="drawSource" @startConfirmedDraw="toggleClickToDrawContour"> </start-draw-modal>

    <context-menu
      v-if="!!selectedDrawFeature && showClickToContextMenu"
      :selectedDrawFeature="selectedDrawFeature"
      :clickToContextMenu="clickToContextMenu"
      :position="position"
      @clickOutside="clickToContextMenu = false"
      @clickDelete="deleteDrawFeature"
    ></context-menu>
  </div>
</template>

<script>
// disable eslint check for new-cap because every openlayer object is lowercase
/* eslint-disable new-cap */

import { bus } from '@/bus.js'
import Vue from 'vue'
import $ from 'jquery'
import moment from 'moment'
import olMap from 'ol/Map'
import olView from 'ol/View'
import olLayerVector from 'ol/layer/Vector'
import olSourceVector from 'ol/source/Vector'

import olFormatKml from 'ol/format/KML'
import olStyleStyle from 'ol/style/Style'
import olStyleIcon from 'ol/style/Icon'
import WMTS, { optionsFromCapabilities } from 'ol/source/WMTS'
import WMTSCapabilities from 'ol/format/WMTSCapabilities'
import { MediaService } from '@/services/MediaWS/mediaService'

import * as olProj4 from 'ol/proj/proj4'
import proj4 from 'proj4'
import TileLayer from 'ol/layer/Tile'

import * as olControl from 'ol/control'
import * as olInteraction from 'ol/interaction'
import olInteractionSelect from 'ol/interaction/Select'
import olInteractionTranslate from 'ol/interaction/Translate'
import olGeomPoint from 'ol/geom/Point'
import * as olCondition from 'ol/events/condition'
import actionButtons from '@/components/openLayers/actionButtons'
import layerHandler from '@/components/openLayers/layerHandler'
import contextMenu from '@/components/openLayers/contextMenu'

import cookie from 'vue-cookie'
import config from '@/configurations/config.js'
import search from '@/components/_shared/search.vue'
import renderHandlers from '@/components/openLayers/renderHandlers'
import mapHandlers from '@/components/openLayers/mapHandlers'
import drawHandlers from '@/components/openLayers/drawHandlers'
import startDrawModal from '@/components/modal/startDrawModal.vue'

import klicUtils from '@/utils/klicUtil'
import Popup from '@/utils/popup'
import { localStorageVersions, MAP_LAYERS_CONFIGS_KEY, LOCAL_STORAGE_VERSIONS_KEY } from '../configurations/app/config-constants'
import projectionUtils from '@/utils/projectionUtil'
import { MeasurementPointService } from '@/services/DataWS/measurementPointService'

// Origin URL = https://www.topotijdreis.nl/maps.json
import topoTijdReisJson from '@/configurations/app/config-map/topoTijdReis.json'
const mediaService = new MediaService()
const measurementPointService = new MeasurementPointService()

const parser = new WMTSCapabilities()

let popup = Popup[config.platform].default
let northArrowImg = new Image()
northArrowImg.src = window.location.protocol + '//' + window.location.host + config.root + 'assets/img/map_icons/north_arrow.png'

const defaultExpanded = ['ProjectData']

export default {
  name: 'openLayers',
  props: {
    MpIdFilter: {
      required: false
    },
    showSearchBar: {
      required: false,
      default: true
    },
    showActionButtons: {
      required: false,
      default: true
    },
    showClickToContextMenu: {
      required: false,
      default: true
    },
    enableAddFeatureSelectInteraction: {
      required: false,
      default: true
    }
  },
  data() {
    return {
      map: null,
      kmlLayers: [],
      mapLayers: [],
      drawLayer: null,
      mapLayersConfig: {},
      expandedGroups: {},
      isLayerHandlerHidden: true,
      measurementPointsReady: false,
      clickToPlaceMarker: false,
      clickToDrawContour: false,
      clickToContextMenu: false,
      selectedDrawFeature: null,
      position: { x: 50, y: 50 },
      drawFeature: null,
      drawSource: null,
      drawMode: { type: 'location', location: '' },
      cursorXPixel: 0,
      cursorYPixel: 0,
      hoverMarker: false,
      hoveredMeasurementPointName: '',
      invertMapTextColour: true,
      rememberedKMLStyles: {},
      defaultStyleSVG:
        'data:image/svg+xml;utf8,' +
        encodeURIComponent(
          '<svg xmlns="http://www.w3.org/2000/svg" width="100px" height="100px"><rect x="48" y="24.8" fill="#67ac45" width="4" height="50.3"/><path fill="#67ac45" d="M50,33.8c8.8,0,16,7.2,16,16s-7.2,16-16,16s-16-7.2-16-16S41.2,33.8,50,33.8 M50,29.8c-11,0-20,9-20,20 s9,20,20,20s20-9,20-20S61,29.8,50,29.8L50,29.8z"/><rect x="24.8" y="47.9" fill="#67ac45" width="50.3" height="4"/></svg>'
        ),
      gaugingTubeStyleSVG:
        'data:image/svg+xml;utf8,' +
        encodeURIComponent(
          '<svg xmlns="http://www.w3.org/2000/svg" width="100px" height="100px"> <path fill="#67ac45" d="M79.8,31.7c-0.2-0.7-0.7-1.4-1.4-2.1l-4.8-4.8L63.3,35.1l-2.8,2.8c1,0.9,1.8,1.8,2.6,2.9l5.2-5.2l1.5,1.4 c0.6,0.7,1.3,1.2,2,1.5c0.7,0.4,1.4,0.6,2.1,0.6c0.7,0.1,1.4-0.1,2.1-0.4c0.7-0.3,1.4-0.8,2.1-1.5c0.5-0.5,0.9-1.1,1.3-1.7 c0.4-0.6,0.6-1.3,0.7-1.9C80.1,33.1,80,32.4,79.8,31.7z M75.3,34.6c-0.7,0.7-1.4,1-2,1c-0.7,0-1.3-0.3-1.8-0.8l-1.2-1.2l4.1-4.1 l1.1,1.1c0.7,0.7,1,1.3,0.9,2C76.3,33.3,75.9,34,75.3,34.6z"/><path fill="#67ac45" d="M69.7,47.9c-0.4-3.7-1.7-7.1-3.8-10c-0.8-1-1.6-2-2.6-2.9c-3.1-2.8-7.1-4.7-11.5-5.2c-0.7-0.1-1.3-0.1-2-0.1 s-1.3,0-2,0.1c-9.5,0.9-17,8.5-17.9,18c-0.1,0.6-0.1,1.3-0.1,1.9c0,0.7,0,1.4,0.1,2.1c1,9.4,8.5,16.9,17.9,17.8 c0.7,0.1,1.3,0.1,2,0.1s1.3,0,2-0.1c9.4-0.9,16.9-8.4,17.9-17.8c0.1-0.7,0.1-1.4,0.1-2.1C69.8,49.2,69.8,48.5,69.7,47.9z M51.8,65.7c-0.7,0.1-1.3,0.1-2,0.1s-1.3,0-2-0.1c-7.2-0.9-12.9-6.6-13.9-13.8c-0.1-0.7-0.1-1.4-0.1-2.1c0-0.7,0-1.3,0.1-1.9 c0.9-7.3,6.6-13,13.9-14c0.7-0.1,1.3-0.1,2-0.1s1.3,0,2,0.1c3.3,0.4,6.3,1.8,8.7,4c1,0.9,1.8,1.8,2.6,2.9c1.4,2.1,2.3,4.5,2.7,7.1 c0.1,0.6,0.1,1.3,0.1,1.9c0,0.7,0,1.4-0.1,2.1C64.7,59.1,59,64.8,51.8,65.7z"/><rect x="24.6" y="47.9" fill="#67ac45" width="50.3" height="4"/><rect x="47.8" y="24.8" fill="#67ac45" width="4" height="50.3"/></svg>'
        ),
      otherStyleSVG:
        'data:image/svg+xml;utf8,' +
        encodeURIComponent(
          '<svg xmlns="http://www.w3.org/2000/svg" width="100px" height="100px"><circle fill="#67ac45" cx="50" cy="49.9" r="20"/><rect x="63" y="48" width="11.9" height="4"/><rect x="25.1" y="48" width="11.9" height="4"/><rect x="48" y="63" width="4" height="11.9"/><rect x="48" y="25.1" width="4" height="11.9"/></svg>'
        ),
      probingStyleSVG:
        'data:image/svg+xml;utf8,' +
        encodeURIComponent(
          '<svg xmlns="http://www.w3.org/2000/svg" width="100px" height="100px"><polygon fill="#67ac45" points="72.6,32.4 49.7,71.1 49.6,71.1 49.6,71.1 26.7,32.4 \t"/><rect x="47.9" y="25" width="4" height="12.2"/><rect x="47.9" y="62.9" width="4" height="12.2"/><rect x="62.7" y="48.1" width="12.2" height="4"/><rect x="25.1" y="48.1" width="12.2" height="4"/></svg>'
        ),
      holeStyleSVG:
        'data:image/svg+xml;utf8,' +
        encodeURIComponent(
          '<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="80" height="80"><style>tspan { white-space:pre }.shp0 { fill: none;stroke: #67ac45;stroke-width: 3.5 } .shp1 { fill: #67ac45 } </style><path class="shp0" d="M24 23L76 23L76 76L24 76L24 23Z" /><path class="shp1" d="M25.07 77.32L22.93 75.22L74.59 22.39L76.74 24.49L25.07 77.32ZM23.1 24.66L25.23 22.55L75.9 73.88L73.77 75.99L23.1 24.66Z" /></svg>'
        ),
      findingSiteStyleSVG:
        'data:image/svg+xml;utf8,' +
        encodeURIComponent(
          '<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="100" height="100"><style>tspan { white-space:pre }.shp0 { fill: #67ac45 } </style><path class="shp0" d="M49.5 62C42.59 62 37 56.41 37 49.5C37 42.59 42.59 37 49.5 37C56.41 37 62 42.59 62 49.5C62 56.41 56.41 62 49.5 62Z" /></svg>'
        ),
      monitoringWellStyleSVG:
        'data:image/svg+xml;utf8,' +
        encodeURIComponent(
          '<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="80" height="80"><style>tspan { white-space:pre }.shp0 { fill: #67ac45 } </style><path fill-rule="evenodd" class="shp0" d="M80.1 33.6C80 34.2 79.8 34.9 79.4 35.5C79 36.1 78.6 36.7 78.1 37.2C77.4 37.9 76.7 38.4 76 38.7C75.3 39 74.6 39.2 73.9 39.1C73.2 39.1 72.5 38.9 71.8 38.5C71.1 38.2 70.4 37.7 69.8 37L68.3 35.6L63.1 40.8C62.3 39.7 61.5 38.8 60.5 37.9L73.6 24.8L78.4 29.6C79.1 30.3 79.6 31 79.8 31.7C80 32.4 80.1 33.1 80.1 33.6ZM76.4 32.6C76.5 31.9 76.2 31.3 75.5 30.6L74.4 29.5L70.3 33.6L71.5 34.8C72 35.3 72.6 35.6 73.3 35.6C73.9 35.6 74.6 35.3 75.3 34.6C75.9 34 76.3 33.3 76.4 32.6Z" /><path fill-rule="evenodd" class="shp0" d="M69.8 49.7C69.8 50.4 69.8 51.1 69.7 51.8C68.7 61.2 61.2 68.7 51.8 69.6C51.1 69.7 50.5 69.7 49.8 69.7C49.1 69.7 48.5 69.7 47.8 69.6C38.4 68.7 30.9 61.2 29.9 51.8C29.8 51.1 29.8 50.4 29.8 49.7C29.8 49.1 29.8 48.4 29.9 47.8C30.8 38.3 38.3 30.7 47.8 29.8C48.5 29.7 49.1 29.7 49.8 29.7C50.5 29.7 51.1 29.7 51.8 29.8C56.2 30.3 60.2 32.2 63.3 35C64.3 35.9 65.1 36.9 65.9 37.9C68 40.8 69.3 44.2 69.7 47.9C69.8 48.5 69.8 49.2 69.8 49.7ZM65.8 51.9C65.9 51.2 65.9 50.5 65.9 49.8C65.9 49.2 65.9 48.5 65.8 47.9C65.4 45.3 64.5 42.9 63.1 40.8C62.3 39.7 61.5 38.8 60.5 37.9C58.1 35.7 55.1 34.3 51.8 33.9C51.1 33.8 50.5 33.8 49.8 33.8C49.1 33.8 48.5 33.8 47.8 33.9C40.5 34.9 34.8 40.6 33.9 47.9C33.8 48.5 33.8 49.1 33.8 49.8C33.8 50.5 33.8 51.2 33.9 51.9C34.9 59.1 40.6 64.8 47.8 65.7C48.5 65.8 49.1 65.8 49.8 65.8C50.5 65.8 51.1 65.8 51.8 65.7C59 64.8 64.7 59.1 65.8 51.9Z" /><path class="shp0" d="M24.6 47.9L74.9 47.9L74.9 51.9L24.6 51.9L24.6 47.9Z" /><path class="shp0" d="M47.8 24.8L51.8 24.8L51.8 75.1L47.8 75.1L47.8 24.8Z" /><path class="shp0" d="M70.8 20.92L70.8 24.92L22.28 24.92L22.28 20.92L70.8 20.92ZM22 21.33L26 21.33L26 77.47L22 77.47L22 21.33ZM22.6 77.53L22.6 73.53L77.92 73.53L77.92 77.53L22.6 77.53ZM78 77.53L74 77.53L74 41.46L78 41.46L78 77.53Z" /></svg>'
        ),
      holeBoreholeStyleSVG:
        'data:image/svg+xml;utf8,' +
        encodeURIComponent(
          '<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="80" height="80"><style>tspan { white-space:pre }.shp0 { fill: #67ac45 } .shp1 { fill: none;stroke: #67ac45;stroke-width: 3.5 } </style><path fill-rule="evenodd" class="shp0" d="M69.8 49.7C69.8 50.4 69.8 51.1 69.7 51.8C68.7 61.2 61.2 68.7 51.8 69.6C51.1 69.7 50.5 69.7 49.8 69.7C49.1 69.7 48.5 69.7 47.8 69.6C38.4 68.7 30.9 61.2 29.9 51.8C29.8 51.1 29.8 50.4 29.8 49.7C29.8 49.1 29.8 48.4 29.9 47.8C30.8 38.3 38.3 30.7 47.8 29.8C48.5 29.7 49.1 29.7 49.8 29.7C50.5 29.7 51.1 29.7 51.8 29.8C56.2 30.3 60.2 32.2 63.3 35C64.3 35.9 65.1 36.9 65.9 37.9C68 40.8 69.3 44.2 69.7 47.9C69.8 48.5 69.8 49.2 69.8 49.7ZM65.8 51.9C65.9 51.2 65.9 50.5 65.9 49.8C65.9 49.2 65.9 48.5 65.8 47.9C65.4 45.3 64.5 42.9 63.1 40.8C62.3 39.7 61.5 38.8 60.5 37.9C58.1 35.7 55.1 34.3 51.8 33.9C51.1 33.8 50.5 33.8 49.8 33.8C49.1 33.8 48.5 33.8 47.8 33.9C40.5 34.9 34.8 40.6 33.9 47.9C33.8 48.5 33.8 49.1 33.8 49.8C33.8 50.5 33.8 51.2 33.9 51.9C34.9 59.1 40.6 64.8 47.8 65.7C48.5 65.8 49.1 65.8 49.8 65.8C50.5 65.8 51.1 65.8 51.8 65.7C59 64.8 64.7 59.1 65.8 51.9Z" /><path class="shp0" d="M24.6 47.9L74.9 47.9L74.9 51.9L24.6 51.9L24.6 47.9Z" /><path class="shp0" d="M47.8 24.8L51.8 24.8L51.8 75.1L47.8 75.1L47.8 24.8Z" /><path class="shp1" d="M24 23L76 23L76 76L24 76L24 23Z" /></svg>'
        ),
      translateInteraction: null // If set allows drag and drop to move a measurementPoint. Set using toggleTranslateInteraction.
    }
  },
  components: {
    actionButtons,
    layerHandler,
    contextMenu,
    startDrawModal,
    search
  },
  computed: {
    PrGuid: {
      get() {
        return this.$store.state.projects.find((project) => {
          return project.PrID === this.$route.params.projectId
        }).PrGuid
      }
    },
    editableMapLayers: {
      get() {
        if (!this.map) {
          return null
        }
        let allLayers = this.map.getLayers().getArray()
        let val = allLayers.filter((layer) => layer.values_.editable === true)

        return val
      }
    },
    getSelectedMpId: {
      get() {
        if (this.$route.params.measurementPointId) return this.$route.params.measurementPointId
        if (this.$route.params.mpGuid) return this.$route.params.mpGuid
        return ''
      }
    },

    groupedEditableMapLayers: {
      get() {
        const arrayToObject = (array) =>
          array.reduce((obj, item) => {
            // if key already initialized, then add item to array, else initialize with array with item
            obj[item.values_.category] = obj[item.values_.category] ? [...obj[item.values_.category], item] : [item]
            return obj
          }, {})

        return arrayToObject(this.editableMapLayers)
      }
    },

    measurementPoints: {
      get() {
        return this.$store.state.measurementPoints
      },
      set(value) {
        this.$store.commit('setMeasurementPoints', value)
      }
    },
    topLayer: {
      get() {
        if (!this.map) {
          return null
        }
        // if layer is visible and it determines the text colour
        let layers = this.map
          .getLayers()
          .getArray()
          .filter((layer) => layer.getVisible() && typeof layer.values_['invertMapTextColour'] === 'boolean')

        if (layers.length === 0) {
          return null
        }

        // object in which we keep track of the layer indices per zindex
        let layerIndicesPerZIndex = {}

        for (let i = 0; i < layers.length; i++) {
          let layer = layers[i]
          let zindex = layer.getZIndex() || 0
          let layerIndicesAtZIndex = layerIndicesPerZIndex[zindex] || []
          // append index to key z index in layerIndicesPerZIndex
          layerIndicesPerZIndex[zindex] = [i, ...layerIndicesAtZIndex]
        }

        // get layer with max index of max z index
        return layers[Math.max(...layerIndicesPerZIndex[Math.max(...Object.keys(layerIndicesPerZIndex))])]
      }
    }
  },
  watch: {
    getSelectedMpId: {
      handler: function () {
        if (this.measurementPointsReady) {
          this.redrawMeasurementPoints()
          if (!this.$route.query.keepmapposition) {
            // Zoom in on selected measurementpoint
            mapHandlers.focusOnMP(this.getSelectedMpId, this.measurementPoints, this.map)
          }
        }
      },
      immediate: true
    },
    measurementPoints: {
      handler: function () {
        if (this.map) {
          this.redrawMeasurementPoints()

          // Zoom in on selected measurementpoint
          mapHandlers.focusOnMP(this.getSelectedMpId, this.measurementPoints, this.map)
        }
      },
      immediate: true
    },
    topLayer: {
      handler: function () {
        let topLayer = this.topLayer

        // if toplayer is not null, then use text colour setting of top layer and redraw mps
        if (topLayer) {
          this.invertMapTextColour = topLayer.values_.invertMapTextColour
          this.redrawMeasurementPoints()
        }
      }
    }
  },

  async created() {
    // Set project en Sublocation in Store so that it can be used to draw contours
    await Promise.all([this.$store.dispatch('fetchTblSubLocations', { PrID: this.$route.params.projectId }), this.$store.dispatch('fetchProject', { PrID: this.$route.params.projectId })])
  },

  async mounted() {
    this.loadLayersConfig()
    await this.createKmlLayers().then(
      // The current implementation of the map doesn't allow for adding layers after the map has been mounted. We need to wait with the mounting of the map untill all dynamic map layers are loaded.
      (kmlLayers) => {
        this.kmlLayers = kmlLayers
        this.mountMap()
      },
      (reason) => {
        // We want to load the map anyway if any dynamic map layers fail to load as all static map layers can still be usefull.
        this.mountMap()
      }
    )
  },
  methods: {
    async mountMap() {
      let baseLayers = config.map.BASELAYERS.map((layer) => mapHandlers.convertBaseLayerToOLLayerObject(layer, this.mapLayersConfig))
      let mapLayers = config.map.WMSLAYERS(cookie.get('licensenumber')).map((layer) => mapHandlers.convertToOLLayerObject(layer, this.mapLayersConfig[layer.name]))
      let companyLayers = config.map.companyLayers(cookie.get('licensenumber'), cookie.get('username')).map((layer) => mapHandlers.convertToOLLayerObject(layer, this.mapLayersConfig[layer.name]))

      const coordinateSystemRD = projectionUtils.coordinateSystems.find((item) => item.GcCode === 'RD')
      if (coordinateSystemRD) {
        proj4.defs(coordinateSystemRD.epsg, coordinateSystemRD.formula)
        olProj4.register(proj4)
      }

      let topoLayers = []

      const mapJson = topoTijdReisJson
      const promises = []
      mapJson.mapLayers.forEach((layer) => {
        promises.push(
          fetch(`${layer.url}/WMTS/1.0.0/WMTSCapabilities.xml`)
            .then(function (response) {
              return response.text()
            })
            .then((text) => {
              try {
                const result = parser.read(text)
                const options = optionsFromCapabilities(result, {
                  layer: layer.from === layer.to ? `Historische_tijdreis_${layer.to}` : `Historische_tijdreis_${layer.from}_${layer.to}`,
                  matrixSet: 'EPSG:28992'
                })
                let item = new TileLayer({
                  category: 'TijdreisNL',
                  opacity: 1,
                  className: 'ol-layer',
                  opacityPercentage: 100,
                  editable: true,
                  visible: false,
                  name: layer.from === layer.to ? `${layer.to.toString()}` : `${layer.from}-${layer.to}`,
                  source: new WMTS(options)
                })
                topoLayers.push(item)
              } catch (e) {
                console.error(`Topo tijdreis year ${layer.from}-${layer.to} could not be loaded`)
              }
            })
        )
      })
      // promises.push(
      //   fetch(`https://tile.informatievlaanderen.be/ws/raadpleegdiensten/wmts?request=GetCapabilities`)
      //     .then(function (response) {
      //       return response.text()
      //     })
      //     .then((text) => {
      //       try {
      //         const result = parser.read(text)
      //         console.log(result)
      //         result.Contents.Layer.forEach((layer) => {
      //           const options = optionsFromCapabilities(result, {
      //             layer: layer.Identifier,
      //             matrixSet: 'EPSG:4326'
      //           })

      //           let item = new TileLayer({
      //             category: 'TijdreisVlaanderern',
      //             opacity: 1,
      //             className: 'ol-layer',
      //             opacityPercentage: 100,
      //             editable: true,
      //             visible: false,
      //             name: layer.Title,
      //             source: new WMTS(options)
      //           })
      //           topoLayers.push(item)
      //         })
      //       } catch (e) {
      //         console.error(`Could not load Flanders Topop TimeTravel`)
      //       }
      //     })
      // )
      await Promise.all(promises)

      topoLayers = topoLayers.sort((a, b) => parseInt(b.values_.name) - parseInt(a.values_.name))

      this.mapLayers = [
        ...baseLayers,
        ...mapLayers,
        ...companyLayers,
        ...topoLayers,
        ...this.kmlLayers,

        new olLayerVector(
          Object.assign(
            {
              category: 'ProjectData',
              name: 'map.layer.projectData.measurementPointLayer',
              opacity: 1,
              opacityPercentage: 100,
              editable: true,
              preload: Infinity,
              zIndex: 100,
              source: new olSourceVector()
            },
            this.mapLayersConfig['map.layer.projectData.measurementPointLayer']
          )
        )
      ]

      // Adding expanded elements
      const categories = [...new Set(this.mapLayers.map((layer) => layer.values_.category))]
      categories.forEach((category) => {
        const isExpanded = defaultExpanded.find((value) => value === category)
        this.$set(this.expandedGroups, [category], {
          isGroupExpanded: isExpanded,
          expandedLayers: {}
        })
      })

      await drawHandlers.setupDrawLayer(this)

      // Note that below a default config is given, but merged (overwritten) by that gotten from the localstorage
      this.map = new olMap({
        target: 'ol-map',
        layers: [...this.mapLayers, this.drawLayer],
        controls: olControl.defaults({ attribution: false }),
        interactions: olInteraction.defaults({
          altShiftDragRotate: false,
          pinchRotate: false,
          doubleClickZoom: false
        }),
        view: new olView({
          projection: 'EPSG:3857',
          center: [609215.06, 6699231.07],
          zoom: 6
        })
      })

      // Use the set methods to actually set the 'default' config
      this.mapLayers.forEach((layer) => {
        this.applyLayerConfiguration(layer)
      })
      if (this.enableAddFeatureSelectInteraction) {
        this.addFeatureSelectInteraction()
      }
      this.addClickInteraction()
      this.addPointerMoveInteraction()
      mapHandlers.addOverlayFeatures(this.map)
      drawHandlers.addDrawInteraction(this)
      mapHandlers.addContextMenuInteraction(this)
      this.redrawMeasurementPoints()

      mapHandlers.focusOnMP(this.getSelectedMpId, this.measurementPoints, this.map)
    },

    deleteDrawFeature() {
      drawHandlers.deleteDrawFeature(this)
    },

    /**
     * Start Draw mode.
     */
    toggleDrawMode: function () {
      if (this.clickToDrawContour) this.toggleClickToDrawContour(false, null)
      else bus.$emit('startDrawEvent')
    },

    /**
     * Toggle click to draw. If draw layer is not visible, enables it
     * @param clickToDrawContour sets value.
     * @param drawMode sets .
     * @obs draw mode is set by adding OL Draw and Modify interactions.
     */
    toggleClickToDrawContour: function (clickToDrawContour, drawMode) {
      this.drawMode = drawMode
      this.clickToDrawContour = clickToDrawContour

      if (this.clickToDrawContour) {
        this.map.addInteraction(this.drawFeature)
        this.drawLayer.values_.visible = true
      } else {
        this.map.removeInteraction(this.drawFeature)
      }
    },

    scrollOpacity(event) {
      event.preventDefault()
      let targetValue = parseInt(event.target.value) - parseInt(event.target.step) * Math.sign(event.deltaY)
      targetValue = Math.min(Math.max(targetValue, event.target.min), event.target.max)
      event.target.value = isNaN(targetValue) ? 0 : targetValue

      // Trigger an input event because calling applyLayerConfiguration directly is slower for some reason...
      let inputEvent = new Event('input', {
        bubbles: true,
        cancelable: true
      })
      event.target.dispatchEvent(inputEvent)
    },

    changeOpacity(mapLayer, increment, min, max) {
      // Don't have access to the input element here so we can't modify value and trigger input event. Will have to to the "slower" way.
      let targetValue = parseInt(mapLayer.values_.opacityPercentage) + increment
      targetValue = Math.min(Math.max(targetValue, min), max)
      mapLayer.values_.opacityPercentage = isNaN(targetValue) ? 0 : targetValue

      this.applyLayerConfiguration(mapLayer)
    },

    async createKmlLayers() {
      let kmlLayers = []
      let getLinkChain = []
      let fileNames = await this.getKmlLayers()

      fileNames.forEach((fileName) => {
        getLinkChain.push(mediaService.getProjectMediaLocationLink(this.PrGuid, 'MapLayers', fileName))
      })

      let getLinkResponses = await Promise.all(getLinkChain)
      getLinkResponses.forEach((getLinkResponse) => {
        if (getLinkResponse.status === 200 && getLinkResponse.data.ResultCode === 'GetMediaLink_Success') {
          let downloadUrl = new URL(getLinkResponse.data.Link) // The share link from dropbox isn't a direct link, but a preview link so we need to modify it.
          downloadUrl.host = 'dl.dropboxusercontent.com' // First we change the host, the default dropbox url doesn't have CORS enabled
          downloadUrl.searchParams.set('dl', '1') // Then we change the dl parameter to 1 which turns it into a direct download link instead of a preview.
          let kmlName = fileNames.shift().replace(/\.[^/.]+$/, '') // The layer name is the same as the filename withouth the extension.
          let kmlLayer = new olLayerVector(
            Object.assign(
              {
                category: 'ProjectData',
                name: kmlName,
                opacity: 1,
                opacityPercentage: 100,
                editable: true,
                preload: Infinity,
                source: new olSourceVector({
                  url: downloadUrl.href,
                  format: new olFormatKml(),
                  crossOrigin: 'anonymous'
                })
              },
              this.mapLayersConfig[kmlName]
            )
          )
          kmlLayer.type = 'kmlLayer'
          kmlLayers.push(kmlLayer)
        }
      })
      return kmlLayers
    },

    async getKmlLayers() {
      let fileNames = []
      let getMediaListResponse = await mediaService.getProjectMediaList(this.PrGuid, 'MapLayers')
      if (getMediaListResponse.status === 200 && getMediaListResponse.data.ResultCode === 'GetMediaList_Success') {
        getMediaListResponse.data.files.forEach((file) => {
          fileNames.push(file.FileName)
        })
      }
      return fileNames
    },

    /**
     * The interaction to allow selecting measurementPoints
     */
    addFeatureSelectInteraction() {
      let self = this
      let selectInteraction = new olInteractionSelect({
        layers: [mapHandlers.getLayerByName('map.layer.projectData.measurementPointLayer', this.map)],
        multi: false,
        condition: olCondition.click,
        hitTolerance: 5, // Add some margin to fill the vector shape
        style: null
      })

      this.map.addInteraction(selectInteraction)

      selectInteraction.on('select', function (event) {
        let feature = event.selected[0]
        if (self.clickToDrawContour) return
        if (feature) {
          self.setFeatureTranslateInteraction(false)
          if (self.$listeners.selectRow) {
            self.$emit('selectRow', feature.getId())
            return
          }
          self.$router.push('/project/' + self.$route.params.projectId + '/measurementPoint/' + feature.getId() + '/map')
        } else {
          this.getFeatures().clear()
          if (this.getSelectedMpId) {
            feature = mapHandlers.getFeature(mapHandlers.getLayerByName('map.layer.projectData.measurementPointLayer', self.map), this.getSelectedMpId)
            if (feature) {
              this.getFeatures().push(feature)
            }
          }
        }
      })
    },

    /**
     * Toggle the feature translate interaction: toggles drag and drop for moving the current measurementPoint.
     */
    toggleFeatureTranslateInteraction() {
      if (this.translateInteraction) {
        this.map.removeInteraction(this.translateInteraction)
        this.translateInteraction = null
      } else {
        var self = this

        this.translateInteraction = new olInteractionTranslate({
          layers: [mapHandlers.getLayerByName('map.layer.projectData.measurementPointLayer', this.map)],
          features: mapHandlers.getInteraction(olInteractionSelect, this.map).getFeatures(),
          hitTolerance: 5 // Add some margin to fill the vector shape
        })

        this.map.addInteraction(this.translateInteraction)

        this.translateInteraction.on('translateend', function (event) {
          self.updateCoordinates(event.coordinate)
          // Disable the feature translation (also since updateCoordinates triggers a redraw which invalidates the feature object)
          self.setFeatureTranslateInteraction(false)
        })
      }

      // Redraw to change color
      this.redrawMeasurementPoints()
      mapHandlers.selectFeature('map.layer.projectData.measurementPointLayer', this.getSelectedMpId, this.map)
    },

    /**
     * Sets the feature translate interaction to be enabled or not.
     * Rather than toggling the current state, this only does something if the current state is not the desired state.
     */
    setFeatureTranslateInteraction(enable) {
      if (enable && !this.translateInteraction) {
        this.toggleFeatureTranslateInteraction()
      } else if (!enable && this.translateInteraction) {
        this.toggleFeatureTranslateInteraction()
      }
    },

    /**
     * The interaction when the cursor moves. Sets several values if hovered over a feature.
     */
    addPointerMoveInteraction() {
      let self = this
      this.map.on('pointermove', function (event) {
        // Disable standard cursor while drawing
        if (self.clickToDrawContour) return

        let feature = this.forEachFeatureAtPixel(
          event.pixel,
          function (feature, layer) {
            return feature
          },
          { hitTolerance: 5 }
        ) // Add some margin to fill the vector shape

        if (feature && feature.getId() !== self.$route.params.measurementPointId) {
          this.getTargetElement().style.cursor = 'pointer'
          self.hoverMarker = true
        } else {
          this.getTargetElement().style.cursor = ''
          self.hoverMarker = false
        }
      })
    },

    /**
     * The function where all clicks on the map should be handled
     */
    addClickInteraction() {
      console.log('click')
      let self = this
      this.map.on('click', function (event) {
        // Disable standard clicks while drawing
        if (self.clickToDrawContour) return

        // Places the measurementPoint on the clicked coordinates
        if (self.clickToPlaceMarker) {
          self.updateCoordinates(event.coordinate)
          self.enableClickToPlaceMarker()
          return
        }

        let klicLayer = mapHandlers.getLayerByName('Klic', self.map)

        // only trigger if klic layer is actually visible
        if (klicLayer && klicLayer.getVisible()) {
          self.handleKlicLayerEvent(event)
        }
      })
    },

    /**
     * Add layers of Klic notification on which the user has clicked.
     */
    handleKlicLayerEvent(event) {
      let klicLayer = mapHandlers.getLayerByName('Klic', this.map)
      if (!klicLayer) return

      // get url
      let url = klicLayer.getSource().getGetFeatureInfoUrl(event.coordinate, this.map.getView().getResolution(), this.map.getView().getProjection(), { INFO_FORMAT: 'application/json' })

      // id of the object in MongoDB with the specific layer information
      klicUtils.getKlicLayerObjID(url).then((layerObjID) => {
        // if a klic object has been found
        if (layerObjID) {
          // get all layers corresponding to klic with id layerObjID
          klicUtils.getKlicLayerInfo(layerObjID).then((sublayers) => {
            // Use the last 10 characters as an identifier (the whole string does not fit in the UI)
            let name = 'Klic_' + layerObjID.substr(-10)

            // if layer is not already added
            if (!mapHandlers.getLayerByName(name, this.map)) {
              sublayers = sublayers.map((id) => {
                return {
                  id: id,
                  title: id.substring(0, 50)
                }
              })

              let layer = mapHandlers.convertToOLLayerObject(
                {
                  name: name,
                  type: 'WMS',
                  country: 'ProjectData',
                  url: config.apis.mapLayerProxy + 'http://klic.terraindex.com:8080/geoserver/wms',
                  sublayers: sublayers
                },
                this.mapLayersConfig[name]
              )

              this.map.addLayer(layer)

              this.applyLayerConfiguration(layer)
            }
          })
        }
      })
    },

    /**
     * Updates the coordinates of the currently selected measurementPoint to the server
     */
    updateCoordinates(coordinate) {
      let self = this
      let geom = new olGeomPoint(coordinate)
      let feature = mapHandlers.getFeature(mapHandlers.getLayerByName('map.layer.projectData.measurementPointLayer', this.map), this.getSelectedMpId)

      if (feature) {
        feature.setGeometry(geom)
      }
      /* else {
         let marker = self.createMarker(geom, self.featureStyle, {id: self.$route.params.measurementPointId})
         self.addFeature('map.layer.projectData.measurementPointLayer', marker)
         } */

      let measurementPoint = this.measurementPoints.filter(function (mp) {
        return mp.MpGuid === self.$route.params.measurementPointId
      })[0]

      measurementPoint.MpPointGeometry = mapHandlers.writeWKTString(geom, mapHandlers.readWKTStringHeight(measurementPoint.MpPointGeometry))
      measurementPoint.MpDateLastChanged = moment().utc().add(3, 'hour').format('YYYY-MM-DD HH:mm:ss')
      measurementPointService.updateMeasurementPoint(measurementPoint)

      self.redrawMeasurementPoints()
    },

    /**
     * Enables or disabales the ability to place a marker
     */
    enableClickToPlaceMarker() {
      this.clickToPlaceMarker = !this.clickToPlaceMarker
    },

    /**
     * Sets cursorXPixel and cursorYPixel based on current mouse position
     */
    moveCursor: function (event) {
      this.cursorXPixel = event.clientX
      this.cursorYPixel = event.clientY
    },

    /**
     * returns the topmost pixel of the map
     */
    rectTop: function () {
      let elem = document.getElementById('ol-map')
      if (elem === 'undefined') return 0
      return elem.getBoundingClientRect().top
    },

    /**
     * returns the leftmost pixel of the map
     */
    rectLeft: function () {
      let elem = document.getElementById('ol-map')
      if (elem === 'undefined') return 0
      return elem.getBoundingClientRect().left
    },

    /**
     * Redraws all measurementPoints when either the measurementPoints change or when the route changes.
     * Do note that this removes and re-adds all map features, meaning any previous references to features are invalidated
     */
    redrawMeasurementPoints: function (highlightSelectedMeasurementPoint = true) {
      let self = this
      if (this.measurementPoints.length === 0) {
        return
      }
      mapHandlers.removeAllFeatures('map.layer.projectData.measurementPointLayer', this.map)

      let filteredMeasurementPoints = this.measurementPoints

      if (this.MpIdFilter) {
        filteredMeasurementPoints = filteredMeasurementPoints.filter((measurementPoint) => measurementPoint.MpID === this.MpIdFilter)
      }

      filteredMeasurementPoints.forEach(function (measurementPoint) {
        if (measurementPoint.MpPointGeometry) {
          let geometry = mapHandlers.readWKTString(measurementPoint.MpPointGeometry)

          // Decide which svg icon to use for the current measurementPoint

          let svgStyle = ''
          switch (measurementPoint.MpTypeCode) {
            case 'V':
            case 'B':
              svgStyle = self.defaultStyleSVG
              break
            case 'P':
              svgStyle = self.gaugingTubeStyleSVG
              break
            case 'A':
              svgStyle = self.holeStyleSVG
              break
            case 'D':
            case 'E':
              svgStyle = self.holeStyleSVG
              break
            case 'G':
            case 'K':
            case 'L':
            case 'R':
            case 'S':
            case 'U':
            case 'W':
              svgStyle = self.otherStyleSVG
              break
            case 'C':
            case 'O':
              svgStyle = self.probingStyleSVG
              break
            case '35':
              svgStyle = self.holeBoreholeStyleSVG
              break
            case '36':
              svgStyle = self.monitoringWellStyleSVG
              break
            case '37':
              svgStyle = self.findingSiteStyleSVG
              break

            default:
              svgStyle = self.defaultStyleSVG
              break
          }

          let featureStyle

          // Create the style for the current measurementPoint. Make sure it is red if its the current one.
          if (highlightSelectedMeasurementPoint && self.getSelectedMpId !== '' && self.getSelectedMpId === measurementPoint.MpGuid) {
            if (self.translateInteraction) {
              // Drag and drop to move the current measurementPoint is enabled
              featureStyle = new olStyleStyle({
                image: new olStyleIcon({
                  src: svgStyle,
                  scale: 0.5,
                  color: '#000000'
                })
              })
            } else {
              // MeasurementPoint is locked in place
              featureStyle = new olStyleStyle({
                image: new olStyleIcon({
                  src: svgStyle,
                  scale: 0.5,
                  color: '#ff0000'
                })
              })
            }
          } else {
            // MeasurementPoint is not currently selected
            featureStyle = new olStyleStyle({
              image: new olStyleIcon({
                src: svgStyle,
                scale: 0.5
              })
            })
          }

          // Add measurementPoint name to the style.
          renderHandlers.addTextToStyle(featureStyle, measurementPoint.MpName, self.invertMapTextColour)

          // Create and add the marker to the layer.
          let marker = mapHandlers.createMarker(geometry, featureStyle, {
            id: measurementPoint.MpGuid
          })
          mapHandlers.addFeature('map.layer.projectData.measurementPointLayer', marker, self.map)
        }
      })
      // Set to true so the watch on the route is activated.
      this.measurementPointsReady = true
    },

    /**
     * Updates the map layer visibility
     */
    setLayerVisible: function (mapLayer) {
      let layer = mapHandlers.getLayerByName(mapLayer.values_.name, this.map)
      let subLayersVisible = !layer.state_.visible

      for (let prop in layer.values_.subLayers) {
        // skip loop if the property is from prototype
        if (!layer.values_.subLayers.hasOwnProperty(prop)) continue
        layer.values_.subLayers[prop]['visible'] = subLayersVisible
      }

      layer.setVisible(mapLayer.values_.visible)

      // Draw layer doesn't need to apply configuration.
      if (this.clickToDrawContour && !layer.values_.visible) {
        this.toggleClickToDrawContour(false, null)
      } else {
        this.applyLayerConfiguration(layer)
      }
      this.map.updateSize()
    },

    applyLayerConfiguration(layer) {
      layer.values_.opacity = layer.values_.opacityPercentage / 100
      if (layer.values_.subLayers) {
        let subLayers = Object.keys(layer.values_.subLayers)
          .filter((subLayer) => layer.values_.subLayers[subLayer]['visible'] === true)
          .join(',')

        let params = {
          LAYERS: subLayers
        }

        if (layer.values_.viewparams) {
          params['viewparams'] = layer.values_.viewparams
        }

        if (layer.values_.CQL_FILTER) {
          params['CQL_FILTER'] = layer.values_.CQL_FILTER
        }

        layer.getSource().updateParams(params)
      }

      this.redrawMeasurementPoints()

      this.storeLayersConfig()
    },

    /**
     * Update the map layer sublayers visibility
     */
    setSubLayerVisible: function (mapLayer) {
      let layer = mapHandlers.getLayerByName(mapLayer.values_.name, this.map)
      mapLayer.values_.visible = true

      layer.setVisible(mapLayer.values_.visible)

      this.applyLayerConfiguration(layer)
    },

    changeVisibilityOfFeatures(incomingObject) {
      incomingObject.featureIDs.forEach((id) => {
        const key = `${incomingObject.mapLayer.ol_uid}-${id}`
        const feature = incomingObject.mapLayer.getSource().getFeatureByUid(id)
        if (incomingObject.isVisible) {
          feature.setStyle(this.rememberedKMLStyles[key])
        } else {
          this.rememberedKMLStyles[key] = feature.getStyle()
          feature.setStyle(new olStyleStyle({}))
        }
      })
    },

    loadLayersConfig: function () {
      // Always return some object, hopefully filled!
      try {
        // parse versions dict in local storage else {}
        let currentVersions = JSON.parse(localStorage.getItem(LOCAL_STORAGE_VERSIONS_KEY) || '{}')

        // if version is current version, then load else do not load settings
        if (currentVersions[MAP_LAYERS_CONFIGS_KEY] && currentVersions[MAP_LAYERS_CONFIGS_KEY] === localStorageVersions[MAP_LAYERS_CONFIGS_KEY]) {
          this.mapLayersConfig = Object.assign({}, JSON.parse(localStorage.getItem(MAP_LAYERS_CONFIGS_KEY)))
        } else {
          this.mapLayersConfig = {}
        }
      } catch (e) {}
    },

    storeLayersConfig: function () {
      let layersConfigs = {}
      this.editableMapLayers.forEach((layer) => {
        layersConfigs[layer.values_.name] = this.pickLayerConfig(layer.values_)
      })

      localStorage.setItem(MAP_LAYERS_CONFIGS_KEY, JSON.stringify(layersConfigs))

      // parse versions dict in local storage or initialize
      let currentVersions = JSON.parse(localStorage.getItem(LOCAL_STORAGE_VERSIONS_KEY) || '{}')
      // update version in object
      let updatedVersions = JSON.stringify(
        Object.assign(currentVersions, {
          [MAP_LAYERS_CONFIGS_KEY]: localStorageVersions[MAP_LAYERS_CONFIGS_KEY]
        })
      )

      // update version in local storage
      localStorage.setItem(LOCAL_STORAGE_VERSIONS_KEY, updatedVersions)
    },

    pickLayerConfig: function (layer) {
      // Define what attributes to store here
      return (({ name, visible, opacity, opacityPercentage, subLayers }) => ({
        name,
        visible,
        opacity,
        opacityPercentage,
        subLayers
      }))(layer)
    },

    /* takes a screenshot of the map, draws things on it and sends it off to the server */
    saveMapImage: function () {
      // we do not want the selected measurement point to have a different styling than the others
      this.redrawMeasurementPoints(false)

      let self = this
      this.map.once('postrender', function (event) {
        // draw north facing arrow
        let ctx
        let targetCanvas

        targetCanvas = document.createElement('canvas') // Create a new canvas
        let size = self.map.getSize() // Get the map pixel size
        targetCanvas.width = size[0] // Set the width and height of the canvas
        targetCanvas.height = size[1]
        ctx = targetCanvas.getContext('2d')

        Array.prototype.forEach.call(document.querySelectorAll('.ol-layer canvas'), function (canvas) {
          if (canvas.width > 0) {
            var opacity = canvas.parentNode.style.opacity
            ctx.globalAlpha = opacity === '' ? 1 : Number(opacity)
            var transform = canvas.style.transform
            // Get the transform parameters from the style's transform matrix
            var matrix = transform
              .match(/^matrix\(([^(]*)\)$/)[1]
              .split(',')
              .map(Number)
            // Apply the transform to the export map context
            CanvasRenderingContext2D.prototype.setTransform.apply(ctx, matrix)
            ctx.drawImage(canvas, 0, 0)
          }
        })

        let imgScale = 0.4
        ctx.setTransform(1, 0, 0, 1, 0, 0)

        // draw north arrow
        ctx.drawImage(
          northArrowImg,
          targetCanvas.width - northArrowImg.width * imgScale - 10, // 10 pixels from right
          10, // 10 pixels from top
          northArrowImg.width * imgScale,
          northArrowImg.height * imgScale
        )
        // draw scale
        let olScale = $('.ol-scale-line-inner')
        renderHandlers.writeScaleToCanvas(targetCanvas, ctx, olScale)

        // after drawing store image
        let blob
        try {
          blob = targetCanvas.toDataURL('image/png')
        } catch (e) {
          console.error(e)
          console.error('CORS! this probably indicates a cors problem, make sure the WMS server returns the right headers or use a proxy.')
          popup.popup(Vue.t('map.MapImageError'))
          self.map.renderSync()
          return false
        } finally {
          // highlight selected measurement point again
          self.redrawMeasurementPoints()
        }
        bus.$emit('saveMapImageEvent', blob)
        // redraw old canvas so there is no more trash on it
        self.map.renderSync()
        // finally show popup and pass it the blob
      })
      this.map.renderSync()
    },

    collapseGroup: function (key) {
      this.expandedGroups[key].isGroupExpanded = !this.expandedGroups[key].isGroupExpanded
    },
    collapseLayers: function (groupKey, key) {
      const isExpanded = !this.expandedGroups[groupKey].expandedLayers[key]
      this.$set(this.expandedGroups[groupKey].expandedLayers, [key], isExpanded)
    },

    openPrintToScaleModal: function () {
      bus.$emit('printToScaleEvent', this.map)
    },

    search: function (query) {
      if (query) {
        mapHandlers.search(query, this.map)
      }
    }
  }
}
</script>

<style lang="less">
.openLayersContainer {
  float: left;
  width: calc(100% - 50px);
  height: 100%;
}

.whileDrawing {
  cursor: none;
}

.ol-map {
  height: 100%;
  background-color: #515151;
}

.glyphicon {
  display: inline-block;
  padding: 6px 8px;
}

.placeMarker {
  position: fixed;
  pointer-events: none;
  span {
    display: inline-block;
    color: #fff;
    font-size: 21px;
  }
}

#ol-map {
  .ol-scale-line {
    right: 8px;
    left: auto;
    background: rgba(131, 131, 131, 1);
  }
}

.search-container {
  position: absolute;
  top: 17px;
  left: 90px;
  z-index: 1;
}
@import './openLayers/ol-custom.css';
</style>

<link rel="stylesheet" href="../node_modules/ol/ol.css">
