import { dxTableCellValidator } from '@/utils/terraIndexEditorValidator'
import store from '@/vuex/store'
import codeListUtils from '@/utils/codeListUtils'
import sortUtils from '@/utils/sortUtils'

export default {
  buildColumnFromTemplate: (control) => {
    let dropdownOptions = []
    let lookupDatasource = {}
    let combinedCodelists = []
    if (control.inputControlOptions.DynamicCodeListMapping) {
      let dynamicCodeListMapping = JSON.parse(control.inputControlOptions.DynamicCodeListMapping)
      for (var filterValue in dynamicCodeListMapping) {
        let categoryId = dynamicCodeListMapping[filterValue]
        let codelist = store.state.dynamicCodeLists.find((dynamicCodeList) => { return dynamicCodeList.categoryId === categoryId })
        if (codelist && codelist.options.length > 0) {
          codelist.options.forEach((option) => {
            option.filterValue = filterValue
            combinedCodelists.push(option)
          })
        }
      }
    }

    if (combinedCodelists.length > 0) {
      dropdownOptions = combinedCodelists.sort((a, b) => sortUtils.naturalSort(a.localizedText, b.localizedText))
      lookupDatasource = (options) => {
        return {
          store: dropdownOptions,
          filter: ['filterValue', '=', options.data[control.inputControlOptions.DynamicCodeListField]]
        }
      }
    } else {
      dropdownOptions = codeListUtils.getVisibleCodesForField(control.fields[0]).sort((a, b) => sortUtils.naturalSort(a.localizedText, b.localizedText))
      lookupDatasource = dropdownOptions
    }

    let column = {
      caption: control.labels[0][control.languageCode3Letter],
      dataField: control.fields[0].key,
      validationRules: [{
        type: 'custom',
        validationCallback: dxTableCellValidator(control),
        message: null
      }],
      calculateDisplayValue: (rowData) => {
        let cellValue = rowData[control.fields[0].key]
        let foundOption = dropdownOptions.find((option) => {
          if (!option.filterValue) {
            return option.value === cellValue
          } else {
            return (option.value === cellValue) && (option.filterValue === rowData[control.inputControlOptions.DynamicCodeListField])
          }
        })
        if (foundOption) {
          return foundOption.localizedText
        }
      },
      calculateCellValue: (rowData) => {
        let cellValue = rowData[control.fields[0].key]
        let foundOption = dropdownOptions.find((option) => {
          if (!option.filterValue) {
            return option.value === cellValue
          } else {
            return (option.value === cellValue) && (option.filterValue === rowData[control.inputControlOptions.DynamicCodeListField])
          }
        })
        if (foundOption) {
          return foundOption.value
        }
      },
      lookup: {
        dataSource: lookupDatasource,
        displayExpr: (option) => { return (option) ? option.localizedText : '' },
        valueExpr: (option) => { return (option) ? option.value : '' },
        allowClearing: true
      }
    }
    // Use natural sorting on the column
    column.sortingMethod = function (a, b) {
      return sortUtils.naturalSort(a, b)
    }

    return column
  }
}
