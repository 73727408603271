<!-- eslint-disable vue/valid-v-model -->
<template>
  <div id="modalStartDraw" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" @click="close"><span aria-hidden="true">&times;</span><span class="sr-only">Close</span></button>
          <h4 class="modal-title">{{ $t("map.DrawContour.popup.title") }}</h4>
        </div>
        <div class="modal-body">
          <h5 style="margin-top: 0px">{{ $t('map.DrawContour.popup.labelSelectType') }}</h5>
          <DxSelectBox
            v-model:value="type"
            :data-source="[
              { name: $t('map.DrawContour.popup.labelSelectProject'), value: 'project' },
              { name: $t('map.DrawContour.popup.labelSelectSublocation'), value: 'subLocation' }
            ]"
            display-expr="name"
            value-expr="value"
          />
          <div v-if="type === 'subLocation'" class="dropdown">
            <h5>{{ $t('map.DrawContour.popup.labelSelectSublocation') }}</h5>
            <DxSelectBox v-model:value="SelectedSubLocationGuid" :data-source="sublocations" display-expr="SlName" value-expr="SlGuid" />
          </div>
        </div>
        <div class="modal-footer">
          <!-- Can only be started with either SubLocation or Project selected -->
          <button id="btnCreateProject" type="button" class="btn" :disabled="SelectedSubLocationGuid.length === 0 && type === 'subLocation'" @click="start">{{ $t('map.DrawContour.popup.buttonStart') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import { bus } from '@/bus.js'
import DxSelectBox from 'devextreme-vue/select-box'

export default {
  name: 'start-draw-modal',
  components: {
    DxSelectBox
  },
  props: {
    drawSource: {
      type: Object
    }
  },

  data() {
    return {
      loading: false,
      type: 'project',
      SelectedSubLocationGuid: ''
    }
  },

  computed: {
    sublocations() {
      return this.$store.state.subLocations || []
    }
  },

  mounted() {
    bus.$on('startDrawEvent', function () {
      $('#modalStartDraw').show()
    })
  },

  methods: {
    setSublocations() {
      this.$store.dispatch('getSubLocations', { projectId: this.$route.params.projectId }).then((result) => {
        this.sublocations = result
      })
    },

    close() {
      $('#modalStartDraw').hide()
    },

    start() {
      $('#modalStartDraw').hide()

      this.$emit('startConfirmedDraw', true, { type: this.type, location: this.SelectedSubLocationGuid })
      this.name = ''
    }
  }
}
</script>

<style scoped lang="less">
</style>
