<template>
  <div v-if="editableMapLayers" id="layerHandler" :class="{ layerBtnCollapsed: isHidden }" @click="collapseHandler">
    <div class="titleBar">
      <div class="titleIcon">
        <span class="glyphicons glyphicons-sort backgroundIcon"></span><span class="glyphicons glyphicons-cadastral-map frontIcon"></span>
      </div>
      {{ $t("map.layer.title") }}
    </div>

    <div class="layerList">
      <ul v-for="(value, groupKey) in groupedEditableMapLayers" :key="groupKey">
        <span class="listTitle" @click="collapseGroup(groupKey)">{{ $t("map.layer.category." + groupKey) }}</span>

        <span
          :class="[expandedGroups[groupKey].isGroupExpanded || false ? 'glyphicon glyphicon-chevron-up' : 'glyphicon glyphicon-chevron-down', 'collapseIcon']"
          @click="collapseGroup(groupKey)"
        ></span>

        <span v-if="getGroupVisibility(groupKey, value)" :class="['glyphicon glyphicon-eye-open', 'eyeIcon']"></span>

        <li
          v-for="(mapLayer, layerKey) in value"
          :key="layerKey"
          :class="[expandedGroups[groupKey].isGroupExpanded ? 'expanded' : 'collapsed']"
        >
          <div class="layerCategoryTitle">
            <input :id="mapLayer.ol_uid" v-model="mapLayer.values_.visible" type="checkbox" @change="setLayerVisible(mapLayer)" />

            <label :for="mapLayer.ol_uid" class="noselect">
              <span class="ckbx"></span>
              <span v-if="mapLayer.values_.name.includes('.')" class="layerName" :title="$t(mapLayer.values_.name.toString())">{{ $t(mapLayer.values_.name.toString()) }}</span>
              <span v-else class="layerName" :title="mapLayer.values_.name">{{ mapLayer.values_.name }}</span>
            </label>

            <span
              v-if="mapLayer.values_.source.params_ || mapLayer.type === 'kmlLayer'"
              :class="[
                expandedGroups[groupKey].expandedLayers[layerKey] ? 'glyphicon glyphicon-chevron-up' : 'glyphicon glyphicon-chevron-down',
                'collapseIcon'
              ]"
              @click="collapseLayers(groupKey, layerKey)"
            ></span>

            <span class="glyphicons glyphicons-adjust opacityIcon"></span>

            <input
              v-model="mapLayer.values_.opacityPercentage"
              class="opacityModifier"
              type="number"
              inputmode="numeric"
              min="0"
              max="100"
              step="10"
              v-on:wheel="scrollOpacity"
              @input="applyLayerConfiguration(mapLayer)"
            />
            <div class="number-spinner">
              <div class="up noselect" @click="changeOpacity(mapLayer, 5, 0, 100)">
                <span class="glyphicons glyphicons-plus"></span>
              </div>
              <div class="down noselect" @click="changeOpacity(mapLayer, -5, 0, 100)">
                <span class="glyphicons glyphicons-minus"></span>
              </div>
            </div>
          </div>

          <ul v-if="mapLayer.values_.source.params_">
            <li
              v-for="(subMapLayer, key) in mapLayer.values_.subLayers"
              :key="key"
              :class="[expandedGroups[groupKey].expandedLayers[layerKey] ? 'expanded' : 'collapsed']"
            >
              <input :id="key + mapLayer.ol_uid" v-model="subMapLayer.visible" type="checkbox" @change="setSubLayerVisible(mapLayer)" />
              <label :for="key + mapLayer.ol_uid" class="noselect">
                <span class="ckbx"></span>
                <span class="layerName" :title="key">{{ subMapLayer["title"] }}</span>
              </label>
            </li>
          </ul>
          <ul v-if="mapLayer.type === 'kmlLayer'">
            <li
              v-for="(subMapLayer, key) in prepareMapLayers(mapLayer)"
              :key="key"
              :class="[expandedGroups[groupKey].expandedLayers[layerKey] ? 'expanded' : 'collapsed']"
            >
              <input :id="key + mapLayer.ol_uid" type="checkbox" :value="subMapLayer['visible']"  :checked="subMapLayer['visible']" @change="setFeaturesVisible(mapLayer, subMapLayer.featureIDS, key + mapLayer.ol_uid)" />
              <label :for="key + mapLayer.ol_uid" class="noselect">
                <span class="ckbx"></span>
                <span class="layerName" :title="key">{{ subMapLayer["title"] }}</span>
              </label>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  name: 'layer-handler',

  props: {
    map: {
      type: Object,
      required: true
    },
    editableMapLayers: {
      type: Boolean,
      required: true
    },
    isHidden: {
      type: Boolean,
      required: true
    },
    groupedEditableMapLayers: {
      type: Object,
      required: true
    },
    expandedGroups: {
      type: Object,
      required: true
    }
  },

  methods: {
    prepareMapLayers(mapLayer) {
      let klicLayer = {}
      const subNameKey = 'TI:SublayerName'
      mapLayer.getSource().getFeatures().forEach(feature => {
        if (!feature.values_.hasOwnProperty(subNameKey)) return
        const groupName = feature.values_[subNameKey].value
        if (!klicLayer[groupName]) klicLayer[groupName] = []

        klicLayer[groupName] = [...klicLayer[groupName], feature.ol_uid]
      })

      const klicLayerArray = []

      Object.keys(klicLayer).forEach((key) => {
        klicLayerArray.push({
          type: 'features',
          featureIDS: klicLayer[key],
          visible: true,
          title: key
        })
      })

      return klicLayerArray
    },
    getGroupVisibility (key, value) {
      for (let layer of value) {
        if (layer.getVisible()) return true
      }
      return false
    },
    collapseGroup (groupKey) {
      this.$emit('clickGroupArrow', groupKey)
    },
    collapseLayers (groupKey, key) {
      this.$emit('clickLayerArrow', groupKey, key)
    },
    collapseHandler () {
      this.$emit('clickHandler')
    },
    setLayerVisible (mapLayer) {
      this.$emit('clickLayerBox', mapLayer)
    },
    scrollOpacity (event) {
      this.$emit('scrollOpacity', event)
    },
    applyLayerConfiguration (mapLayer) {
      this.$emit('clickOpacity', mapLayer)
    },
    changeOpacity (mapLayer, increment, min, max) {
      this.$emit('clickSpinner', mapLayer, increment, min, max)
    },
    setSubLayerVisible (mapLayer) {
      this.$emit('clickSubLayerBox', mapLayer)
    },
    setFeaturesVisible (mapLayer, featureIDs, htmlElementKey) {
      this.$emit('clickKMLVisible', { mapLayer, featureIDs, isVisible: document.getElementById(htmlElementKey.toString()).checked })
    }
  }
}
</script>

<style lang="less">
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                     supported by Chrome and Opera */
}

.layerBtnCollapsed {
  height: 38px !important;
  width: 38px !important;
  overflow: hidden;
  cursor: pointer;
  transition: 0.3s;
}

.layerBtnCollapsed:hover {
  transform: scale(0.9);
}

#layerHandler {
  position: absolute;
  z-index: 1;
  bottom: 10px;
  left: 60px;
  background-color: #eee;
  transition: 0.3s;
  width: 700px;
  height: 50%;

  .titleBar {
    background-color: #67ac45;
    padding: 10px;
    color: #ffffff;
    position: relative;
    padding-left: 40px;

    .backgroundIcon {
      position: absolute;
      left: 0px;
      top: 0px;
      line-height: 28px;
      font-size: 20px;
    }

    .frontIcon {
      position: absolute;
      left: 0px;
      top: 11px;
      color: #67ac45;
      font-size: 13px;
    }
  }
  .layerList {
    padding: 10px;
    padding-right: 0px;
    height: 90%;
    overflow-y: scroll;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      .listTitle {
        font-weight: bold;
        display: inline-block;
      }
      .listTitle:not(:first-child) {
        margin-top: 10px;
      }
      .eyeIcon {
        float: right;
      }
      .collapseIcon {
        cursor: pointer;
      }
      ul {
        margin-left: 10px;
      }
      li {
        .layerCategoryTitle {
          display: flex;
        }
      }
      li.collapsed {
        display: none;
      }
      li.expanded {
        display: block;
      }
      li:hover {
        .number-spinner {
          display: inline-block !important;
        }
      }
    }

    .opacityIcon {
      width: 27px;
      font-size: 15px;
      margin-right: -5px;
    }
    .opacityModifier {
      background-color: transparent;
      border: none;
      width: 35px;
      vertical-align: top;
      font-size: 15px;
      line-height: 25px;
      padding-left: 3px;

      &:focus {
        outline-color: #67ac45;
        outline-style: solid;
        outline-width: 1px;
      }
    }

    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type="number"] {
      -moz-appearance: textfield;
    }

    .number-spinner {
      display: none;
      height: 25px;
      overflow: hidden;

      .up {
        float: left;
        font-weight: bold;
        cursor: pointer;
        margin-left: -5px;
        margin-top: -2px;
      }

      .down {
        float: left;
        font-weight: bold;
        cursor: pointer;
        margin-right: -5px;
        margin-top: -2px;
      }
    }

    input[type="checkbox"] {
      display: none;
      & + label {
        position: relative;
        font-weight: normal;
        cursor: pointer;
        margin-bottom: 0px;
        height: 27px;
        .ckbx {
          display: inline-block;
          width: 12px;
          height: 12px;
          margin-right: 5px;
          border: 1px solid #9d9d9d;
          background-color: #ffffff;
          cursor: pointer;
          margin-bottom: 7px;
        }
        .layerName {
          display: inline-block;
          overflow: hidden;
          line-height: 27px;
        }
      }
      &:checked + label .ckbx {
        background-color: #67ac45;
      }
    }
  }
}

.collapsed {
  display: none;
}
.expanded {
  display: block;
}
</style>
