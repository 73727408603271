<template>
  <div>
    <TerraIndexDynamicTable
      scrollingMode="infinite"
      :tableData="tableData"
      :tableTitle="$t('tableTitles.LocationVisits')"
      :tableDataIdentifier="tableIdentifier"
      :tableCreateAction="createTableAction"
      :tableUpdateAction="updateTableAction"
      :tableDeleteAction="deleteTableAction"
      :tableColumns="tableColumns"
      :search-panel-enabled="searchPanelEnabled"
      :enable-column-chooser="enableColumnChooser"
      :tableName="tableName"
      :template="template"
      :settingName="settingName"
      :language="language"
      :excel-export-settings="exportSettings"
    />
  </div>
</template>

<script>
import stringUtils from '@/utils/stringUtils'
import guidUtils from '@/utils/guidUtils'
import TerraIndexDynamicTable from '@/components/dynamicTables/TerraIndexDynamicTable'
import GuiTemplateParser from '@/components/dynamicTables/GuiTemplateParser'
import cookie from '@/utils/cacheProviders/cookieCacheProvider'
import Popup from '@/utils/popup'
import Config from '@/configurations/config'

const TiPopup = Popup[Config.platform].default

export default {
  name: 'locationVisitsTable',
  data() {
    return {
      tableData: [],
      tableColumns: [],
      tableIdentifier: 'LvGuid',
      tableName: 'tblLocationVisits',
      tableNameForExport: 'tblLocationVisits',
      templateName: 'frmLocationVisitsGrid',
      template: {},
      settingName: 'LocationVisits_Settings',
      projectCode: ''
    }
  },
  async created() {
    await this.setCurrentProject()
  },
  props: {
    projectId: {
      type: String,
      required: true
    },
    enableCreateTableRowAction: {
      required: false,
      default() {
        return true
      }
    },
    enableDeleteTableRowAction: {
      required: false,
      default() {
        return true
      }
    },
    enableUpdateTableRowAction: {
      required: false,
      default() {
        return true
      }
    },
    searchPanelEnabled: {
      required: false,
      default() {
        return true
      }
    },
    enableExportSettings: {
      required: false,
      default() {
        return true
      }
    },
    enableColumnChooser: {
      required: false,
      default() {
        return true
      }
    }
  },
  watch: {
    projectId: {
      deep: true,
      immediate: true,
      handler: function () {
        if (this.projectId) {
          this.getServerData()
          this.getTableColumns()
        }
      }
    }
  },
  components: {
    TerraIndexDynamicTable
  },
  computed: {
    createTableAction() {
      return { active: this.enableCreateTableRowAction, method: this.createLocationVisit }
    },
    updateTableAction() {
      return { active: this.enableUpdateTableRowAction, method: this.updateLocationVisit }
    },
    deleteTableAction() {
      return { active: this.enableDeleteTableRowAction, method: this.deleteLocationVisit }
    },
    language() {
      return cookie.get('language')
    },
    exportSettings() {
      return {
        enabled: this.enableExportSettings,
        fileName: `${this.projectCode}_${this.tableNameForExport}`
      }
    }
  },
  methods: {
    async setCurrentProject() {
      if (stringUtils.isNullOrEmptyOrWhitespace(this.$route.params.projectId)) {
        TiPopup.popup(this.$t('message.projectNotFound'))
        return
      }

      await this.$store.dispatch('fetchProject', {
        PrID: this.$route.params.projectId
      })
      const project = this.$store.state.currentProject

      if (project && project.PrID) {
        this.projectCode = project.PrCode
      } else {
        TiPopup.popup(this.$t('message.projectNotFound'))
      }
    },
    async createLocationVisit(row) {
      const locationVisits = await this.$store.dispatch('createLocationVisit', {
        locationVisit: {
          ...row.data,
          PrID: this.projectId,
          LvID: '-1',
          LvGuid: guidUtils.newGuid()
        }
      })

      this.tableData = locationVisits
    },
    async updateLocationVisit(row) {
      await this.$store.dispatch('updateLocationVisit', {
        locationVisit: row.data
      })
    },
    async deleteLocationVisit(key) {
      await this.$store.dispatch('deleteLocationVisit', {
        PrID: this.projectId,
        LvGuid: key
      })
    },
    async getServerData() {
      this.tableData = await this.$store.dispatch('getLocationVisits', {
        projectId: this.projectId
      })
    },
    async getTableColumns() {
      this.tableColumns = await this.$store
        .dispatch('getDefaultFieldTemplate', {
          templateName: this.templateName
        })
        .then((templateData) => {
          this.template = templateData
          return GuiTemplateParser.parseTemplate(templateData)
        })
    }
  }
}
</script>

<style>
h2 {
  font-size: 20px;
  margin: 0 5px 5px 5px;
}
</style>
