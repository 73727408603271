<template>
  <div
    :key="options.data.length"
    class="media-gallery"
    :class="{ 'single-row': singleRow }" >
    <boxes
      v-if="!mediaDataAvailable"
      :options="options"
      :projectGuid="projectGuid"
      inlineButtons
      @uploaded="onFirstUploaded">
      <span v-if="loading" class="loading glyphicons glyphicons-refresh" />
      <div v-else class="noloading">{{ $t(notFoundTranslationKey) }}</div>
    </boxes>
    <boxes
      v-else
      :options="options"
      :projectGuid="projectGuid"
      inlineButtons
      showItemCount />
  </div>
</template>

<script>
import boxes from '@/components/boxes'
import config from '@/configurations/config'

export default {
  components: {
    boxes
  },
  props: {
    mediaType: {
      type: String,
      required: true,
      validator: function (value) {
        // No access to `this` in props validator because component is not initialized yet.
        // Because of that, the reference to config has to be inlined.
        return Object.values(
          config.constants.MediaClassification || {}
        ).includes(value)
      }
    },
    singleRow: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    },
    files: {
      default() {
        return []
      }
    },
    loading: {
      type: Boolean,
      required: true
    },
    externalLink: {
      type: String,
      default() {
        return ''
      }
    }
  },
  computed: {
    projectGuid() {
      return this.$store.getters.getCurrentProject.PrGuid
    },
    options() {
      return {
        ...this.dataTypeOptions,
        data: this.files,
        prGuid: this.projectGuid,
        projectId: this.$route.params.projectId,
        uploadType: this.mediaType,
        externalLink: this.externalLink,
        modal: 'uploadModal',
        enableBoxLink: true
      }
    },
    notFoundTranslationKey() {
      switch (this.mediaType) {
        case this.mediaClassifications.ProjectPhoto:
          return 'PictureGalleryOverview.NoImagesFound'
        case this.mediaClassifications.MapImages:
          return 'PictureGalleryOverview.NoMapImagesFound'
        case this.mediaClassifications.MapLayers:
          return 'AttachmentGalleryOverview.NoMapLayersFound'
        case this.mediaClassifications.ProjectDocuments:
        case this.mediaClassifications.KLICFiles:
        case this.mediaClassifications.LabCertificates:
          return 'AttachmentGalleryOverview.NoDocumentsFound'
        default:
          throw new Error('Could not find media classification type')
      }
    },
    isMediaTypeWithThumbnail() {
      return [
        this.mediaClassifications.ProjectPhoto,
        this.mediaClassifications.MapImages
      ].includes(this.mediaType)
    },
    mediaDataAvailable() {
      return this.files && this.files.length > 0
    },
    mediaClassifications() {
      return config.constants.MediaClassification || {}
    },
    dataTypeOptions() {
      switch (this.mediaType) {
        case this.mediaClassifications.ProjectPhoto:
          return {
            title: 'label.pictures',
            type: 'pictureBoxes',
            uploadType: 'images',
            businesskey: 'pictures',
            buttons: ['addButton', 'empty', 'empty', 'linkButton']
          }
        case this.mediaClassifications.MapImages:
          return {
            title: 'label.mapScreenshot',
            type: 'mapImagesBox',
            uploadType: this.mediaClassifications.MapImages,
            businesskey: 'mapImages',
            buttons: ['addButton', 'empty', 'empty', 'linkButton']
          }
        case this.mediaClassifications.MapLayers:
          return {
            title: 'label.MapLayers',
            type: 'mapLayerBox',
            uploadType: 'MapLayers',
            businesskey: 'mapLayers',
            buttons: ['addButton', 'empty', 'empty', 'linkButton']
          }
        case this.mediaClassifications.ProjectDocuments:
          return {
            title: 'label.ProjectDocuments',
            type: 'projectDocumentBox',
            businesskey: 'projectDocuments',
            buttons: ['addButton', 'empty', 'empty', 'linkButton']
          }
        case this.mediaClassifications.KLICFiles:
          return {
            title: 'label.KLICFiles',
            type: 'klicFileBox',
            businesskey: 'klicFiles',
            buttons: ['empty', 'empty', 'empty', 'linkButton']
          }
        case this.mediaClassifications.LabCertificates:
          return {
            title: 'label.LabCertificates',
            type: 'labCertificateBox',
            uploadType: this.mediaClassifications.LabCertificates,
            businesskey: 'labCertificates',
            buttons: ['addButton', 'empty', 'empty', 'linkButton']
          }
        default:
          throw new Error('Invalid media type')
      }
    }
  },
  methods: {
    onFirstUploaded() {
      this.$emit('onFirstUploaded')
    }
  }
}
</script>

<style lang="less">
// This component's style cannot be scoped, as it needs to heavily
// override styles in boxes.vue to accomodate horizontal layouts.
// Instead, everything is applied to children of the .media-gallery CSS class for pseudo scoping.
.media-gallery {
  min-width: 100%;
  &:last-child {
    flex-grow: 1;
  }
  @keyframes spinning {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  & .loading {
    display: flex;
    justify-content: center;
    font-size: 50px;
    color: #67ac45;
    animation-name: spinning;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }
  & .noloading {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    text-align: center;
    width: 180px;
    height: 60px;
    background-color: #ddd;
  }
  & .mpboxs {
    width: 100%;
  }
  & .contentbox {
    height: auto !important;
  }
  & .simplebar-content > div,
  .simplebar-content > div > div {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    & > a {
      width: 180px;
      min-width: 180px;
      overflow-x: hidden;
      min-height: 80px;
      & > div {
        width: 100%;
      }
    }
  }
}
</style>
