export default {
  buildColumnFromTemplate: (control) => {
    let column = {
      caption: control.labels[0][control.languageCode3Letter],
      dataField: control.fields[0].key,
      dataType: 'date',
      format: 'yyyy-MM-dd'// same as other date editor, international, should be fixed site wide
    }
    return column
  }
}
