<template>
  <div>
    <TerraIndexDynamicTable
      :tableData="tableData"
      :tableTitle="tableTitle"
      :tableDataIdentifier="tableIdentifier"
      :tableCreateAction="createTableRowAction"
      :tableDeleteAction="deleteTableRowAction"
      :tableMultiUpdateAction="multiUpdateTableRowAction"
      :tableUpdateAction="updateTableRowAction"
      :hasSaveAndRevertButtons="saveAndRevertButtonsEnabled"
      :enableColumnChooser="columnChooserEnabled"
      :enableHeaderFilter="headerFilterEnabled"
      :enableGrouping="headerGroupingEnabled"
      :searchPanelEnabled="searchPanelEnabled"
      :useMultiSelect="multiSelectEnabled"
      :tableColumns="tableColumns"
      :tableName="tableName"
      :template="template"
      :settingName="settingName"
      :language="language"
      :excelExportSettings="exportSettings"
      :state-storing="stateStoring"
      :defaultSelectedRowIndex="defaultSelectedRowIndex"
      :project="project"
      :isValidRequest="isValidRequest"
      :onRowValidating="validateRow"
      :scrollingMode="scrollingMode"
      :onEditorPreparing="onEditorPreparing"
      @updateSelectedRows="updateSelectedRows"
      @refreshTableData="refreshData"
    />
  </div>
</template>

<script>
import BaseProjectTable from '@/components/dynamicTables/tables/baseProjectTable'

export default {
  name: 'SummariesAndConclusionsTable',
  extends: BaseProjectTable,
  data() {
    return {
      tableName: 'tblSummaryConclusions',
      tableNameForExport: 'tblSummaryConclusions',
      tableIdentifier: 'SmGUID',
      tableTitle: this.$t('tableTitles.SummariesAndConclusions'),
      dateLastChangedField: 'SmDateLastChanged',
      templateName: 'frmSummaryConclusionGrid',
      settingName: 'SummariesAndConclusions_Settings',
      createTableRowActionEnabled: true,
      deleteTableRowActionEnabled: true,
      multiUpdateTableRowActionEnabled: false,
      updateTableRowActionEnabled: true,
      saveAndRevertButtonsEnabled: true,
      columnChooserEnabled: true,
      headerFilterEnabled: true,
      headerGroupingEnabled: false,
      multiSelectEnabled: true,
      searchPanelEnabled: true,
      exportEnabled: true,
      scrollingMode: 'infinite',
      defaultSelectedRowIndex: 0,
      stateStoring: {}
    }
  },
  computed: {
    isValidRequest() {
      return this.isProjectIdSet
    }
  },
  methods: {
    async validateRow (row) {
      if (!row.isValid) {
        // return
      }

      if (row.newData.SmType && (!row.oldData || row.newData.SmType.trim() !== row.oldData.SmType)) {
        row.newData.SmType = row.newData.SmType.trim()
        const itemsWithSameName = this.tableData.filter(tuple => tuple.SmType === row.newData.SmType)
        if (itemsWithSameName.length > 0) {
          row.promise = new Promise((resolve) => {
            resolve()
          }).then(() => {
            row.isValid = false
            row.errorText = this.$t('message.typeShouldUnique')
          })
        }
      }
    },
    onEditorPreparing (editor) {
      if (editor.dataField !== 'SmType') {
        return
      }

      // Backend overwrites summaries if new summaries are created with existing
      // summary types, so we filter those from the creation dropdown
      const existingSmTypes = this.tableData.map((item) => item.SmType)
      // This array now also includes the currently selected item.
      // Remove it from this array, so users can reselect the currently selected item
      existingSmTypes.splice(existingSmTypes.indexOf(editor.row.data.SmType), 1)

      const smTypes = editor.editorOptions.dataSource.store._array
      editor.editorOptions.dataSource.store._array = smTypes.filter(
        (item) => !existingSmTypes.includes(item.key)
      )
    },
    async createTableRow(row) {
      try {
        const record = {
          ...this.prepareTableRowDataForCreate(row.data),
          PrID: this.project.PrID
        }
        const response = await this.$store.dispatch(
          'createSummaryAndConclusion',
          record
        )
        if (response) {
          this.handleGetDataResponse(response)
        }

        return !!response
      } catch (error) {
        this.handleErrorResponse(error)
      }
    },
    multiDelete(keys = []) {
      const deleteActions = []
      keys.forEach((key) => {
        deleteActions.push(
          this.$store.dispatch('deleteSummaryAndConclusion', {
            projectId: this.project.PrID,
            SmGUID: key
          })
        )
      })

      return Promise.all(deleteActions).then(() => {
        return this.getData().then((result) => {
          this.tableData = result
        })
      })
    },
    async deleteTableRow(rowKey) {
      try {
        return await this.$store.dispatch('deleteSummaryAndConclusion', { projectId: this.project.PrID, SmGUID: rowKey })
      } catch (error) {
        this.handleErrorResponse(error)
      }
    },
    async getData() {
      try {
        return await this.$store.dispatch('getSummaryAndConclusion', {
          projectId: this.project.PrID
        })
      } catch (error) {
        this.handleErrorResponse(error)
      }
    },
    async updateTableRow(change) {
      try {
        const record = {
          ...this.prepareTableRowDataForUpdate(change)
        }

        const response = await this.$store.dispatch(
          'updateSummaryAndConclusion',
          record
        )
        if (response) {
          this.handleGetDataResponse(response)
        }

        return !!response
      } catch (error) {
        this.handleErrorResponse(error)
      }
    }
  }
}
</script>
