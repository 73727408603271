// disable eslint check for new-cap because every openlayer object is lowercase
/* eslint-disable new-cap */

import olInteractionSelect from 'ol/interaction/Select'
import olFeature from 'ol/Feature'
import olFormatWkt from 'ol/format/WKT'
import olControlScaleLine from 'ol/control/ScaleLine'
import olSourceTilewms from 'ol/source/TileWMS'
import olLayerTile from 'ol/layer/Tile'
import { fromLonLat } from 'ol/proj'
import BingMaps from 'ol/source/BingMaps'
import olSourceXyz from 'ol/source/XYZ'
import * as olExtent from 'ol/extent'
import olStyleStyle from 'ol/style/Style'
import olStyleIcon from 'ol/style/Icon'

import OSM from 'ol/source/OSM'

export default {
  /**
   * Returns the layer object
   */
  getLayerByName (name, map) {
    let layer

    map.getLayers().forEach(function (lyr) {
      let layerName = lyr.get('name')
      if (name === layerName) {
        layer = lyr
      }
    })

    return layer
  },

  /**
   * Get the feature with ID 'featureID' in layer 'layer'.
   * @param {ol/layer} the layer
   * @param {string} the ID of the feature
   */
  getFeature (layer, featureID) {
    if (typeof layer !== 'undefined') {
      return layer.getSource().getFeatureById(featureID)
    }
  },

  /**
   * returns the interaction object
   */
  getInteraction (interactionType, map) {
    let retInteraction
    map.getInteractions().forEach(function (interaction) {
      if (interaction instanceof interactionType) {
        retInteraction = interaction
      }
    })

    return retInteraction
  },

  /**
   * Change the selection to the given feature (or none if not found)
   */
  selectFeature (layerName, featureID, map) {
    let layer = this.getLayerByName(layerName, map)
    let feature = this.getFeature(layer, featureID)

    let selectInteraction = this.getInteraction(olInteractionSelect, map)
    selectInteraction.getFeatures().clear()

    if (feature) {
      selectInteraction.getFeatures().push(feature)
    }

    return feature
  },

  /**
   * Add feature 'feature' to layer with name 'layerName'.
   * @param {string} layerName the name of the layer
   * @param {ol/Feature} feature the feature to be added
   */
  addFeature (layerName, feature, map) {
    let layer = this.getLayerByName(layerName, map)

    if (typeof layer !== 'undefined') {
      layer.getSource().addFeature(feature)
    }
  },

  /*
    add features like scale lines, north arrows, wind socks.. whatever
    */
  addOverlayFeatures (map) {
    // eslint-disable-next-line new-cap
    const scaleLine = new olControlScaleLine()
    map.addControl(scaleLine)
    map.scaleLineRef = scaleLine
  },

  /**
   * Places a marker on the map as an OpenLayers.Feature.Vector .
   * @param {ol.geom.Point} position - The position where the marker has to be placed (has to be in mercator projection)
   * @param {iconStyleEnum} iconStyle - The style of the marker to be placed
   * @param {object} [markerData] - The data that is to attached to the marker
   */
  createMarker (position, iconStyle, markerData) {
    markerData = markerData || {}

    // eslint-disable-next-line new-cap
    let marker = new olFeature({
      geometry: position
    })

    marker.setStyle(iconStyle)

    // cannot set via constructor :(
    if (markerData.id) {
      marker.setId(markerData.id)
    }

    return marker
  },

  /**
   * Remove all features from the select interaction and the layer with name 'layerName'.
   * @param {string} layerName the name of the layer
   */
  removeAllFeatures (layerName, map) {
    let selectInteraction = this.getInteraction(olInteractionSelect, map)

    if (selectInteraction) {
      selectInteraction.getFeatures().clear()
    }

    let layer = this.getLayerByName(layerName, map)

    if (layer) {
      layer.getSource().clear()
    }
  },

  /**
   * Focus (zoom in) on feature with id 'featureID' in layer with name 'layerName'.
   * @param {string} layerName the name of the layer
   * @param {string} featureID the ID of the feature
   */
  focusOnFeature (layerName, featureID, map) {
    // focus on feature
    let layer = this.getLayerByName(layerName, map)

    let feature = this.getFeature(layer, featureID)

    if (feature === null) return

    // keep current zoom level if the user is zoomed in
    let currentZoom = map.getView().getZoom()
    if (currentZoom < 18) {
      map.getView().setZoom(18)
    }

    let extent = feature.getGeometry().getExtent()
    let center = olExtent.getCenter(extent)
    map.getView().animate({ center: center })
  },

  /**
   * Focus on and select a measurementpoint.
   * @param {string} mpID Either the MpID or MpGuid of the measuremenpoint to be focussed on.
   */
  focusOnMP (mpID, measurementPoints, map) {
    this.focusOnFeature('map.layer.projectData.measurementPointLayer', mpID, map)
    this.selectFeature('map.layer.projectData.measurementPointLayer', mpID, map)
  },

  /**
   * Redraws all measurementPoints when either the measurementPoints change or when the route changes.
   * Do note that this removes and re-adds all map features, meaning any previous references to features are invalidated
   */
  redrawMeasurementPoints: function (
    highlightSelectedMeasurementPoint = true,
    measurementPoints,
    MpIdFilter,
    { defaultStyleSVG, gaugingTubeStyleSVG, otherStyleSVG, probingStyleSVG, holeBoreholeStyleSVG, monitoringWellStyleSVG, findingSiteStyleSVG, holeStyleSVG },
    map
  ) {
    if (measurementPoints.length === 0) {
      return
    }
    this.removeAllFeatures('map.layer.projectData.measurementPointLayer', this.map)

    let filteredMeasurementPoints = measurementPoints

    if (MpIdFilter) {
      filteredMeasurementPoints = filteredMeasurementPoints.filter(
        (measurementPoint) => measurementPoint.MpID === MpIdFilter
      )
    }

    filteredMeasurementPoints.forEach(function (measurementPoint) {
      if (measurementPoint.MpPointGeometry) {
        let geometry = this.readWKTString(measurementPoint.MpPointGeometry)

        // Decide which svg icon to use for the current measurementPoint
        let svgStyle = ''
        switch (measurementPoint.MpTypeCode) {
          case 'V':
          case 'B':
            svgStyle = defaultStyleSVG
            break
          case 'P':
            svgStyle = gaugingTubeStyleSVG
            break
          case 'A':
            svgStyle = holeStyleSVG
            break
          case 'D':
          case 'E':
            svgStyle = holeStyleSVG
            break
          case 'G':
          case 'K':
          case 'L':
          case 'R':
          case 'S':
          case 'U':
          case 'W':
            svgStyle = otherStyleSVG
            break
          case 'C':
          case 'O':
            svgStyle = probingStyleSVG
            break
          case '35':
            svgStyle = holeBoreholeStyleSVG
            break
          case '36':
            svgStyle = monitoringWellStyleSVG
            break
          case '37':
            svgStyle = findingSiteStyleSVG
            break

          default:
            svgStyle = defaultStyleSVG
            break
        }

        let featureStyle

        // Create the style for the current measurementPoint. Make sure it is red if its the current one.
        if (
          highlightSelectedMeasurementPoint &&
          typeof self.$route.params.measurementPointId !== 'undefined' &&
          self.$route.params.measurementPointId === measurementPoint.MpID
        ) {
          if (self.translateInteraction) {
            // Drag and drop to move the current measurementPoint is enabled
            // eslint-disable-next-line new-cap
            featureStyle = new olStyleStyle({
              // eslint-disable-next-line new-cap
              image: new olStyleIcon({
                src: svgStyle,
                scale: 0.5,
                color: '#000000'
              })
            })
          } else {
            // MeasurementPoint is locked in place
            // eslint-disable-next-line new-cap
            featureStyle = new olStyleStyle({
              // eslint-disable-next-line new-cap
              image: new olStyleIcon({
                src: svgStyle,
                scale: 0.5,
                color: '#ff0000'
              })
            })
          }
        } else {
          // MeasurementPoint is not currently selected
          // eslint-disable-next-line new-cap
          featureStyle = new olStyleStyle({
            // eslint-disable-next-line new-cap
            image: new olStyleIcon({
              src: svgStyle,
              scale: 0.5
            })
          })
        }

        // Add measurementPoint name to the style.
        // templateManipulation.addTextToStyle(featureStyle, measurementPoint.MpName, self.invertMapTextColour)

        // Create and add the marker to the layer.
        let marker = this.createMarker(geometry, featureStyle, {
          id: measurementPoint.MpID
        })
        this.addFeature('map.layer.projectData.measurementPointLayer', marker, map)
      }
    })
    // Set to true so the watch on the route is activated.
    this.measurementPointsReady = true
  },

  /**
   * Strips the z coordinate from a wktstring (if present) and returns it as a geometry in the correct projection
   * @param {string} wktString - geometry string in EPSG:4326
   * @return {string} - geometry string in EPSG: 3857 without z coordinate
   */
  readWKTString (wktString) {
    // hacky way to remove 3th argument
    // since this is not needed by OL3
    if (wktString.includes('POINT')) {
      let coordinates = wktString.substring(
        wktString.lastIndexOf('(') + 1,
        wktString.lastIndexOf(')')
      )
      let split = coordinates.split(' ')

      if (split.length > 2) {
        wktString = 'POINT (' + split[0] + ' ' + split[1] + ')'
      }
    }

    // parse geometry
    // eslint-disable-next-line new-cap
    let format = new olFormatWkt()
    let geom = format.readGeometry(wktString)

    geom.transform('EPSG:4326', 'EPSG:3857')

    return geom
  },

  /*
   *
   */
  readWKTStringHeight (wktString) {
    if (wktString != null && wktString.includes('POINT')) {
      let coordinates = wktString.substring(
        wktString.lastIndexOf('(') + 1,
        wktString.lastIndexOf(')')
      )
      let split = coordinates.split(' ')

      if (split.length < 2) {
        return split[2]
      } else {
        return null
      }
    } else {
      return null
    }
  },

  /**
   * Reverses readWKTString()
   */
  writeWKTString (geometry, zCoordinate) {
    // eslint-disable-next-line new-cap
    let format = new olFormatWkt()
    // clone the geometry since we don't want to transform the real geometry
    // THIS IS REALLY IMPORTANT
    let geom = geometry.clone()
    geom.transform('EPSG:3857', 'EPSG:4326')
    let wktString = format.writeGeometry(geom)

    // add extra z-coordinate if it's defined and store in wkt-string
    if (zCoordinate && wktString.includes('POINT')) {
      wktString = wktString.substring(0, wktString.length - 1) + ' ' + zCoordinate + ')'
    }

    return wktString
  },

  convertToOLLayerObject (obj, objName) {
    let subLayers = obj.sublayers.reduce((obj, item) => {
      obj[item['id']] = {
        title: item['title'],
        visible: false
      }

      return obj
    }, {})

    let source = {
      url: obj.url,
      projection: obj.EPSG || 'EPSG:3857',
      transition: 0,
      params: {
        CRS: obj.EPSG || 'EPSG:3857'
      },
      crossOrigin: 'anonymous'
    }
    // eslint-disable-next-line new-cap
    return new olLayerTile(
      Object.assign(
        {
          name: obj.name,
          category: obj.country,
          editable: true,
          visible: false,
          invertMapTextColour: obj.invertMapTextColour,
          opacity: 1,
          opacityPercentage: 100,
          subLayers: subLayers,
          viewparams: obj['viewparams'],
          CQL_FILTER: obj['CQL_FILTER'],
          preload: Infinity,
          // eslint-disable-next-line new-cap
          source: new olSourceTilewms(source)
        },
        objName
      )
    )
  },

  convertBaseLayerToOLLayerObject (obj, mapLayersConfig) {
    let source
    let name
    switch (obj.source) {
      case 'BING':
        name = 'map.layer.base.BING.' + obj.settings.imagerySet
        source = new BingMaps(obj.settings)
        break
      case 'OSM':
        name = 'map.layer.base.OSM'
        source = new OSM()
        break
      case 'ESRI':
        name = 'map.layer.base.ESRI.' + obj.name
        // eslint-disable-next-line new-cap
        source = new olSourceXyz(obj.settings)
        break
      default:
        console.error('convertBaseLayerToOLLayerObject(): Invalid type!')
        return
    }

    source.crossOrigin = 'anonymous'

    // eslint-disable-next-line new-cap
    return new olLayerTile(
      Object.assign(
        {
          name: name,
          category: 'Base',
          visible: obj.defaultVisible,
          invertMapTextColour: obj.invertMapTextColour,
          opacity: 1,
          opacityPercentage: 100,
          editable: true,
          preload: Infinity,
          source: source
        },
        mapLayersConfig[name]
      )
    )
  },

  /**
   * Add right click on draw feature interaction. Opens context menu.
   * @param {*} ctx Open Layers map component
   */
  addContextMenuInteraction (ctx) {
    ctx.map.getViewport().addEventListener('contextmenu', async function (e) {
      e.preventDefault()
      const { layerX, layerY } = e

      // Not drawing & on feature - Pop menu
      if (!ctx.clickToDrawContour) {
        const featureArray = await ctx.drawLayer.getFeatures([layerX, layerY])
        if (featureArray.length === 0) return

        const feature = featureArray[0]
        ctx.clickToContextMenu = true
        ctx.position = { x: layerX, y: layerY }
        ctx.selectedDrawFeature = feature
      }

      // Drawing - Cancel
      if (ctx.clickToDrawContour) {
        ctx.drawFeature.abortDrawing()
      }
    })
  },
  search (query, map) {
    fetch(`https://nominatim.openstreetmap.org/search.php?q=` + encodeURIComponent(query) + `&format=jsonv2`)
      .then(response => {
        if (response.status === 200) {
          response.json().then(searchResults => {
            if (searchResults.length > 0) {
              var topResult = searchResults[0]
              var coordinates = fromLonLat([topResult.lon, topResult.lat], 'EPSG:3857')
              map.getView().setCenter(coordinates, map.getSize(), coordinates)
              map.getView().animate({
                zoom: 19,
                duration: 1500
              })
            }
          })
        }
      })
  }
}
