<template>
  <div>
    <TerraIndexDynamicTable
      scrollingMode="infinite"
      :tableData="tableData"
      :tableTitle="$t('tableTitles.ProjectParticipants')"
      :tableDataIdentifier="tableIdentifier"
      :tableCreateAction="createTableRowAction"
      :tableUpdateAction="updateTableRowAction"
      :tableDeleteAction="deleteTableRowAction"
      :tableColumns="tableColumns"
      :tableName="tableName"
      :settingName="settingName"
      :language="language"
      :excelExportSettings="exportSettings"
      :search-panel-enabled="searchPanelEnabled"
      :enableColumnChooser="false"
      :state-storing="{}"
      :defaultSelectedRowIndex="0"
      :isValidRequest="isValidRequest"
      :hasSaveAndRevertButtons="false"
      :useMultiSelect="true"
      @refreshTableData="refreshTableData"
    />
    <UserModal :option="wrappedProjectId" @pinnedUsersUpdated="refreshTableData" />
  </div>
</template>

<script>
import $ from 'jquery'
import TerraIndexDynamicTable from '@/components/dynamicTables/TerraIndexDynamicTable'
import TableColumn from '@/components/dynamicTables/TableColumn'
import Cookie from '@/utils/cacheProviders/cookieCacheProvider'
import stringUtils from '@/utils/stringUtils'
import Popup from '@/utils/popup'
import Config from '@/configurations/config'
import UserModal from '@/components/modal/userModal.vue'

const TiPopup = Popup[Config.platform].default

export default {
  name: 'projectParticipantsTable',
  components: {
    TerraIndexDynamicTable,
    UserModal
  },
  data() {
    return {
      tableData: [],
      tableColumns: [],
      tableName: 'tblProjectParticipants',
      tableNameForExport: 'tblProjectParticipants',
      tableIdentifier: 'UserName',
      template: {},
      settingName: 'ProjectParticipants_Settings',
      wrappedProjectId: { projectId: this.projectId }
    }
  },
  created() {
    this.getTableColumns()
  },
  props: {
    projectId: {
      type: String,
      required: true
    },
    projectCode: {
      type: String,
      required: false,
      default() {
        return ''
      }
    },
    enableCreateTableRowAction: {
      required: false,
      default() {
        return true
      }
    },
    enableDeleteTableRowAction: {
      required: false,
      default() {
        return true
      }
    },
    enableUpdateTableRowAction: {
      required: false,
      default() {
        return true
      }
    },
    searchPanelEnabled: {
      required: false,
      default() {
        return true
      }
    },
    enableExportSettings: {
      required: false,
      default() {
        return true
      }
    }
  },
  watch: {
    projectId: {
      deep: true,
      immediate: true,
      handler: async function () {
        await this.refreshTableData()
      }
    }
  },
  computed: {
    createTableRowAction() {
      return { active: this.enableCreateTableRowAction, method: this.createTableRow, override: true }
    },
    updateTableRowAction() {
      return { active: this.enableUpdateTableRowAction }
    },
    deleteTableRowAction() {
      return { active: this.enableDeleteTableRowAction, method: this.deleteTableRow }
    },
    language() {
      return Cookie.get('language')
    },
    exportSettings() {
      return {
        enabled: this.enableExportSettings,
        fileName: `${this.projectCode}_${this.tableNameForExport}`
      }
    },
    isValidRequest() {
      return this.isProjectIdSet
    },
    isProjectIdSet() {
      return stringUtils.isNullOrEmptyOrWhitespace(this.projectId) === false
    }
  },
  methods: {
    async createTableRow(row) {
      try {
        $('#modalSelectUser').modal('show')
        $('#modalSelectUser').trigger('popupTrigger')
      } catch (error) {
        await this.handleErrorResponse(error)
      }
    },
    async deleteTableRow(key) {
      try {
        await this.$store.dispatch('pinUnpinUser', {
          PrID: this.projectId,
          UserName: key,
          Enabled: false
        })
      } catch (error) {
        await this.handleErrorResponse(error)
      }
    },
    async refreshTableData() {
      if (this.isValidRequest) {
        await this.getTableData()
      } else {
        await this.clearTableData()
      }
    },
    async clearTableData() {
      this.tableData = []
    },
    async getTableData() {
      try {
        await this.$store
          .dispatch('getProjectUsersPinnedForGrid', {
            projectId: this.projectId
          })
          .then(async (response) => {
            this.tableData = response
          })
      } catch (error) {
        await this.handleErrorResponse(error)
      }
    },
    async getTableColumns() {
      try {
        // TODO: check whether this will work with a GUI template instead
        this.tableColumns = []
        const firstNameColumn = new TableColumn('string', this.$t('columnTitles.FirstName'), 'FirstName')
        const lastNameColumn = new TableColumn('string', this.$t('columnTitles.LastName'), 'LastName')
        const emailColumn = new TableColumn('string', this.$t('columnTitles.Email'), 'Email')
        const userNameColumn = new TableColumn('string', this.$t('columnTitles.UserName'), 'UserName')
        this.tableColumns.push(firstNameColumn, lastNameColumn, emailColumn, userNameColumn)
      } catch (error) {
        await this.handleErrorResponse(error)
      }
    },
    async handleErrorResponse(error, errorMessage = 'message.UnknownError') {
      console.log(error)
      TiPopup.popup(this.$t(errorMessage))
    }
  }
}
</script>
