import { dxTableCellValidator } from '@/utils/terraIndexEditorValidator'
import coördinateUtils from '@/utils/coördinateUtils'
import sortUtils from '@/utils/sortUtils'
import store from '@/vuex/store'
import stringUtils from '@/utils/stringUtils'

export default {
  buildColumnFromTemplate: (control) => {
    let column = {
      caption: control.labels[0][control.languageCode3Letter],
      dataField: control.fields[0].key,
      dataType: 'number',
      validationRules: [{
        type: 'custom',
        validationCallback: dxTableCellValidator(control),
        message: null
      }]
    }
    // Use natural sorting on the column
    column.sortingMethod = function (a, b) {
      return sortUtils.naturalSort(a, b)
    }
    if (control && (control.controlId === coördinateUtils.measurementPointCoord.x)) {
      column.calculateDisplayValue = function (rowData) {
        if (store.state.currentProject && store.state.currentProject.PrCoordinateSystem && rowData.MpXCoord) {
          let projectCordSys = store.state.currentProject.PrCoordinateSystem
          return stringUtils.returnDecimalForWgs84(control, projectCordSys, rowData.MpXCoord)
        } else {
          return rowData.MpXCoord
        }
      }
    }
    if (control && (control.controlId === coördinateUtils.measurementPointCoord.y)) {
      column.calculateDisplayValue = function (rowData) {
        if (store.state.currentProject && store.state.currentProject.PrCoordinateSystem && rowData.MpYCoord) {
          let projectCordSys = store.state.currentProject.PrCoordinateSystem
          return stringUtils.returnDecimalForWgs84(control, projectCordSys, rowData.MpYCoord)
        } else {
          return rowData.MpYCoord
        }
      }
    }
    return column
  }
}
