<template>
  <a :class="btnDisabled? 'flatButton dx-widget dx-state-disabled':'flatButton'"  v-bind:style="buttonStyle" :title="title">
    <slot></slot>
  </a>
</template>

<script>
export default {
  name: 'squareButton',
  props: {
    color: String,
    backgroundColor: String,
    width: Number,
    height: Number,
    title: String,
    btnDisabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    buttonStyle: {
      get () {
        return {
          color: this.color || '#fff',
          backgroundColor: this.backgroundColor || '#67ac45',
          width: (this.width || 40) + 'px',
          height: (this.height || 40) + 'px'
        }
      }
    }
  }
}
</script>

<style scoped lang="less">
  .flatButton{
    cursor: pointer;
    display: inline-block;
    margin-left: 5px;
    transition: transform 0.2s ease-in;

    &:first-of-type{
      margin-left: 0px;
    }

    &:hover{
      transform: scale(0.9);
    }
  }
</style>
