<template>
  <transition name="fade">
    <div v-show="show" class="backdrop">
      <div class="terraIndexModal">
        <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" @click="confirmButtonPress()">&times;</button>
            <h4 class="modal-title">{{titleLabel}}</h4>
          </div>
          <div class="modal-body">
            <slot></slot>
          </div>
          <div class="modal-footer">
            <button class="btn" @click="confirmButtonPress()">{{confirmButtonLabel}}</button>
          </div>
        </div>
      </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'alertModal',
  props: {
    show: Boolean,
    titleLabel: String,
    confirmButtonLabel: String
  },
  data () {
    return {
    }
  },
  methods: {
    hideModal () {
      this.$emit('close-modal')
    },
    confirmButtonPress () {
      this.$emit('confirm-button-pressed')
      this.hideModal()
    }
  }
}
</script>

<style lang="less" scoped>
  .terraIndexModal{
    z-index: 1002;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    /*top: 50%;
    transform: translateY(calc(-50% - 60px));*/
  }
  .backdrop{
    background-color: rgba(0,0,0, 0.5);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1001;
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity .2s;
  }
  .fade-enter, .fade-leave-to{
    opacity: 0;
  }
</style>
