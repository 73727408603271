<template>
  <!-- Dynamic XY coords: +50px on 'X' because of lateral left strip of map's container  -->
  <div
    class="contextMenu"
    :class="{ enabled: clickToContextMenu }"
    :style="{ top: position.y + 'px', left: position.x  + 50 + 'px' }"
    @click="clickDelete"
  >
    {{ $t('map.DrawContour.ContourActions.delete', {0: selectedDrawFeature.values_.name }) }}
  </div>
</template>

<script>

export default {
  name: 'context-menu',

  props: {
    clickToContextMenu: {
      type: Boolean,
      required: true
    },
    position: {
      type: Object,
      required: true
    },
    selectedDrawFeature: {
      type: Object,
      required: true
    }
  },

  created () {
    window.addEventListener('click', (e) => {
      if (!this.$el.contains(e.target)) {
        this.$emit('clickOutside')
      }
    })
  },

  methods: {
    clickDelete () {
      this.$emit('clickDelete')
      this.$emit('clickOutside')
    }
  }
}
</script>

<style lang="less">
.contextMenu {
  position: absolute;
  display: none;
  top: 0;
  left: 0;

  border: 1px solid rgba(105, 105, 105, 0.646);
  border-radius: 5px;
  padding: 2px 10px;
  color: black;
  background-color: white;
  cursor: pointer;

  &:hover {
    color: white;
    background-color: rgb(103, 172, 69);
  }
}

.enabled {
  display: initial;
}
</style>
