<template>
  <transition name="fade">
    <div v-show="show" class="backdrop">
      <div class="terraIndexModal">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" @click="hideModal()">&times;</button>
              <h4 class="modal-title">{{$t('cadastralOvam.addCadastralOvamCustomersModalTitle')}}</h4>
            </div>
            <div class="modal-body">
              <p>{{$t('cadastralOvam.addCadastralOvamCustomersModalHelp')}}</p>
              <p>{{$t("cadastralOvam.CuName")}}</p>
              <input v-model="CuName" type="text" class="form-control"/>
              <p class="error">
                <span v-if="CuNameToLong">{{$t('message.valueTooLong')}}</span>
                <span v-if="CuNameSelected">{{$t('message.valueRequired')}}</span>
              </p>
            </div>
            <div class="modal-footer">
              <button class="btn" @click="returnRow()">{{$t('project.CreateButton')}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import guidUtils from '@/utils/guidUtils'

export default {
  name: 'addCadastralOvamCustomerModal',
  components: { },
  props: {
    show: Boolean,
    PrID: Number,
    CaID: String
  },
  data () {
    return {
      CuName: '',
      CuType: '',
      CuNameSelected: false,
      CuNameToLong: false
    }
  },
  created () {
  },
  methods: {
    hideModal () {
      this.$emit('close-modal')
    },
    returnRow () {
      if (this.CuName && this.CuName.trim().length > 0 && this.CuName.trim().length < 100) {
        let cadastralOvamCustomer = {
          CuName: this.CuName,
          PrID: this.PrID.toString(),
          CaID: this.CaID,
          CuGUID: guidUtils.newGuid()
        }
        this.$emit('create-cadastral-ovam-customer', cadastralOvamCustomer)
        this.hideModal()
      } else {
        this.CuNameSelected = !(this.CuName.trim().length > 0)
        this.CuNameToLong = !(this.CuName.trim().length < 100)
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .terraIndexModal{
    z-index: 1002;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    /*top: 50%;
    transform: translateY(calc(-50% - 60px));*/
  }
  .backdrop{
    background-color: rgba(0,0,0, 0.5);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1001;
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity .2s;
  }
  .fade-enter, .fade-leave-to{
    opacity: 0;
  }
</style>
