<template>
  <div>
    <ti-dialog ref="selectOverviewsDialog">
      <overview-select />
    </ti-dialog>

    <ti-dialog ref="selectOverviewsParamDialog">
      <overview-param-edit />
    </ti-dialog>

    <DxDataGrid
      :data-source="dataSource"
      :show-borders="true"
      :column-auto-width="true"
      :allow-column-reordering="true"
      :row-alternation-enabled="true"
      :allow-column-resizing="true"
      @toolbar-preparing="toolbarPreparing"
      @exporting="onExporting"
    >
      <dx-column-chooser :enabled="true" mode="select" />
      <DxSearchPanel :visible="true" :width="160" placeholder="Zoeken..." />
      <DxHeaderFilter :visible="true" />
      <dx-scrolling mode="virtual" row-rendering-mode="standard" />
      <DxGrouping :auto-expand-all="false" />
      <DxGroupPanel :visible="true" />
      <DxExport :enabled="overviewName != ''" />
      <div slot="tableTitleTemplate" slot-scope="{}">
        <selectOverviewTool :overviewName="overviewName" v-on:onRenameOverview="openOverviewSelectDialog" v-on:onEditParameters="openOverviewParamEditDialog" />
      </div>
    </DxDataGrid>
  </div>
</template>

<script>
import { DxDataGrid, DxSearchPanel, DxColumnChooser, DxHeaderFilter, DxScrolling, DxGrouping, DxGroupPanel, DxExport } from 'devextreme-vue/data-grid'
import selectOverviewTool from '@/components/selectOverviewTool'

import TiDialog from '@/components/_shared/tiDialog.vue'
import OverviewSelect from '@/components/modalSelects/selectOverviewFromList.vue'
import OverviewParamEdit from '@/components/modalSelects/insertParametersForOverview'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { Workbook } from 'exceljs'
import saveAs from 'file-saver'

export default {
  name: 'sqlOverview',
  components: {
    DxDataGrid,
    DxSearchPanel,
    DxHeaderFilter,
    DxScrolling,
    DxColumnChooser,
    DxGrouping,
    DxGroupPanel,
    selectOverviewTool,
    TiDialog,
    OverviewSelect,
    DxExport,
    OverviewParamEdit
  },
  data() {
    return {
      overviewName: '',
      firstOpen: false
    }
  },
  mounted() {
    this.openOverviewSelectDialog()
  },
  computed: {
    dataSource() {
      return this.$store.getters.getOverviewQueryResponse
    }
    /**
     * Computed property to set the Excel export parameters
     */
  },
  methods: {
    onExporting(e) {
      const workbook = new Workbook()
      const worksheet = workbook.addWorksheet('Sheet')
      const fileName = this.overviewName + '.xlsx'

      exportDataGrid({
        component: e.component,
        worksheet: worksheet
      }).then(function () {
        workbook.xlsx.writeBuffer().then(function (buffer) {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName)
        })
      })
    },
    toolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: 'before',
        template: 'tableTitleTemplate'
      })
    },
    async openOverviewSelectDialog() {
      // clear the current list before opening the modal
      this.$store.dispatch('clearOverviewQueryResponse')

      let overview = await this.$refs.selectOverviewsDialog.open({
        title: this.$t('label.selectCustomOverview'),
        buttonLabel: this.$t('label.choose')
      })

      if (overview) {
        this.overviewName = overview.Name
        this.$store.dispatch('setCurrentOverview', overview)
        this.openOverviewParamEditDialog()
      } else {
        this.overviewName = ''
        this.$store.dispatch('setCurrentOverviewParameters', null)
      }
    },
    async openOverviewParamEditDialog() {
      // selectOverviewsParamDialog
      let overviewParams = await this.$refs.selectOverviewsParamDialog.open({
        title: this.$t('label.overviewParameters'),
        buttonLabel: this.$t('label.retrieve')
      })

      if (overviewParams) {
        this.$store.dispatch('setCurrentOverviewParameters', overviewParams)
      }

      this.$store.dispatch('getOverviewQueryResponse', {
        params: {
          Param: JSON.stringify(overviewParams),
          ViewName: this.overviewName
        }
      })
    }
  }
}
</script>

<style scoped>
/deep/ .dx-datagrid {
  height: 100%;
}

/deep/ .dx-toolbar {
  background-color: #eee;
}

/deep/ .dx-datagrid-rowsview .dx-row-removed > td {
  background-color: #fc8f8c !important;
  border-top: 1px solid #fc8f8c;
  border-bottom: 1px solid #fc8f8c;
}

/deep/ .dx-datagrid-rowsview .dx-row-inserted > td {
  background-color: #83e183;
  border-top: 1px solid #83e183;
  border-bottom: 1px solid #83e183;
}

/deep/ .dx-datagrid-rowsview .dx-cell-modified {
  background-color: #5cb85c !important;
}

/deep/ .dx-datagrid-rowsview .dx-datagrid-invalid {
  background-color: #d9534f !important;
}

/deep/ .dx-datagrid-header-panel .dx-button-mode-contained {
  border-radius: 0px;
  border: none;
  background-color: #67ac45;
  transition: 0.2s;
}

/deep/ .dx-button-mode-contained.dx-state-hover {
  background-color: #67ac45;
  transform: scale(0.92);
}

/deep/ .dx-toolbar-text-auto-hide .dx-button .dx-icon {
  color: #fff;
}

/deep/ .dx-button-mode-contained .dx-icon {
  color: #fff;
}

/deep/ .tableTitle {
  color: #333;
  margin: 0px;
  padding: 0px;
  font-size: 1.2rem;
}
</style>
