import olStyleText from 'ol/style/Text'
import olStyleFill from 'ol/style/Fill'
import olStyleStroke from 'ol/style/Stroke'

export default {
  /**
  * Adds a label with readable text 20 pixels above the provided style
  */
  addTextToStyle: function (style, text, invertMapTextColour) {
    let mapTextStroke = '#000000'
    let mapTextColour = '#ffffff'

    if (invertMapTextColour) {
      mapTextStroke = '#ffffff'
      mapTextColour = '#000000'
    }

    // eslint-disable-next-line new-cap
    let newText = new olStyleText({
      text: text,
      // eslint-disable-next-line new-cap
      fill: new olStyleFill({
        color: mapTextColour
      }),
      offsetY: -20,
      // eslint-disable-next-line new-cap
      stroke: new olStyleStroke({
        color: mapTextStroke,
        width: 1
      }),
      font: '14px sans-serif'
    })
    style.setText(newText)
  },

  /**
  * Changes scale presented on the corner of the map
  */
  writeScaleToCanvas: function (canvas, ctx, olScale) {
    let line1 = 10
    // Offset from the left
    let xOffset = 10
    // offset from the bottom
    let yOffset = 30
    let fontsize1 = 20
    let font1 = fontsize1 + 'px Arial'
    let multiplier = 2

    let scalewidth = parseInt(olScale.css('width'), 10) * multiplier
    let scale = olScale.text()
    let scalenumber = parseInt(scale, 10) * multiplier
    let scaleunit = scale.match(/[Aa-zZ]{1,}/g)

    // Scale Text
    ctx.beginPath()
    ctx.textAlign = 'center'
    ctx.strokeStyle = '#ffffff'
    ctx.fillStyle = '#000000'
    ctx.lineWidth = 1
    ctx.font = font1
    ctx.strokeText(
      [scalenumber + ' ' + scaleunit],
      xOffset + fontsize1 / 2 + scalewidth / 2,
      canvas.height - yOffset - fontsize1 / 2
    )
    ctx.fillText(
      [scalenumber + ' ' + scaleunit],
      xOffset + fontsize1 / 2 + scalewidth / 2,
      canvas.height - yOffset - fontsize1 / 2
    )

    // Scale Dimensions
    let xzero = scalewidth + xOffset
    let yzero = canvas.height - yOffset
    let xfirst = xOffset + scalewidth / 4
    let xsecond = xfirst + scalewidth / 4
    let xthird = xsecond + scalewidth / 4
    let xfourth = xthird + scalewidth / 4

    // Stroke
    ctx.beginPath()
    ctx.lineWidth = line1 + 2
    ctx.strokeStyle = '#838383'
    ctx.fillStyle = '#ffffff'
    ctx.moveTo(xOffset, yzero)
    ctx.lineTo(xzero + 1, yzero)
    ctx.stroke()

    // sections black/white
    ctx.beginPath()
    ctx.lineWidth = line1
    ctx.strokeStyle = '#838383'
    ctx.moveTo(xOffset, yzero)
    ctx.lineTo(xfirst, yzero)
    ctx.stroke()

    ctx.beginPath()
    ctx.lineWidth = line1
    ctx.strokeStyle = '#FFFFFF'
    ctx.moveTo(xfirst, yzero)
    ctx.lineTo(xsecond, yzero)
    ctx.stroke()

    ctx.beginPath()
    ctx.lineWidth = line1
    ctx.strokeStyle = '#838383'
    ctx.moveTo(xsecond, yzero)
    ctx.lineTo(xthird, yzero)
    ctx.stroke()

    ctx.beginPath()
    ctx.lineWidth = line1
    ctx.strokeStyle = '#FFFFFF'
    ctx.moveTo(xthird, yzero)
    ctx.lineTo(xfourth, yzero)
    ctx.stroke()
  }
}
