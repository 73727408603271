<template>
  <div class="sotool dx-datagrid-header-panel">
    <div class="sotool-title">{{$t(title)}}:</div>
    <div v-if="overviewName !== ''" class="sotool-name">{{overviewName}}</div>
    <div
      class="dx-datagrid-toolbar-button dx-datagrid-export-button dx-button dx-button-normal dx-button-mode-contained dx-widget dx-button-has-icon"
      role="button"
      aria-label=""
      :title="$t('label.selectOverviewTitle')"
      tabindex="0"
      v-on:click="$emit('onRenameOverview')"
    >
      <div class="dx-button-content">
        <i class="dx-icon dx-icon-edit"></i>
      </div>
    </div>
    <div
      v-if="overviewName !== ''"
      style="margin-left: 5px; margin-right: 20px"
      class="dx-datagrid-toolbar-button dx-datagrid-export-button dx-button dx-button-normal dx-button-mode-contained dx-widget dx-button-has-icon"
      role="button"
      :title="$t('label.selectOverviewParameters')"
      tabindex="0"
      v-on:click="$emit('onEditParameters')"
    >
      <div class="dx-button-content">
        <i class="dx-icon dx-icon-preferences"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'selectOverviewTool',
  props: ['overviewName'],
  components: {},
  data () {
    return {}
  },
  computed: {
    title: () => {
      return 'pages.overview'
    }
  }
}
</script>

<style scoped>
.sotool.dx-datagrid-header-panel {
    border: 0px;
}

.sotool {
  display: inline-flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  height: 34px;
}
.sotool-title {
  padding: 0 8px 0 8px;
  line-height: 32px;
  color: #959595;
}

.sotool-name {
  color: #000;
  padding: 0 25px 0 5px;
  flex: 1;
  line-height: 32px;
}
</style>
