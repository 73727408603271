<template>
  <div>
    <TerraIndexDynamicTable
      scrollingMode="infinite"
      :tableData="tableData"
      :tableTitle="$t('label.finishings')"
      :tableDataIdentifier="tableIdentifier"
      :tableCreateAction="createTableRowAction"
      :tableUpdateAction="updateTableRowAction"
      :tableDeleteAction="deleteTableRowAction"
      :tableColumns="tableColumns"
      :tableName="tableName"
      :template="template"
      :settingName="settingName"
      :language="language"
      :excelExportSettings="exportSettings"
      :enableColumnChooser="enableColumnChooser"
      :search-panel-enabled="searchPanelEnabled"
      :on-row-validating="customRowValidation"
      :state-storing="{}"
      :defaultSelectedRowIndex="0"
      :tableMultiUpdateAction="multiUpdateTableRowAction"
      :useMultiSelect="true"
      :projectId="projectId"
      :isValidRequest="isValidRequest"
      @refreshTableData="refreshTableData"
    />
  </div>
</template>

<script>
import TerraIndexDynamicTable from '@/components/dynamicTables/TerraIndexDynamicTable'
import GuiTemplateParser from '@/components/dynamicTables/GuiTemplateParser'
import Cookie from '@/utils/cacheProviders/cookieCacheProvider'
import stringUtils from '@/utils/stringUtils'
import Popup from '@/utils/popup'
import Config from '@/configurations/config'
import DataTableUtils from '@/utils/dataTableUtils'

const TiPopup = Popup[Config.platform].default

export default {
  name: 'finishingsTable',
  components: {
    TerraIndexDynamicTable
  },
  data() {
    return {
      tableData: [],
      tableColumns: [],
      tableName: 'tblFinishings',
      tableNameForExport: 'tblFinishings',
      tableIdentifier: 'FiGuid',
      dateLastChangedField: 'FiDateLastChanged',
      templateName: 'frmFinishingGrid',
      template: {},
      settingName: 'Finishing_Settings'
    }
  },
  created() {
    this.getTableColumns()
  },
  props: {
    projectId: {
      type: String,
      required: true
    },
    projectCode: {
      type: String,
      required: true
    },
    selectedMeasurementObject: {
      type: Object,
      required: true
    },
    enableCreateTableRowAction: {
      required: false,
      default() {
        return true
      }
    },
    enableDeleteTableRowAction: {
      required: false,
      default() {
        return true
      }
    },
    enableUpdateTableRowAction: {
      required: false,
      default() {
        return true
      }
    },
    enableColumnChooser: {
      required: false,
      default() {
        return true
      }
    },
    searchPanelEnabled: {
      required: false,
      default() {
        return true
      }
    },
    enableExportSettings: {
      required: false,
      default() {
        return true
      }
    }
  },
  watch: {
    projectId: {
      deep: true,
      immediate: true,
      handler: async function () {
        await this.refreshTableData()
      }
    },
    selectedMeasurementObject: {
      deep: true,
      immediate: true,
      handler: async function () {
        await this.refreshTableData()
      }
    }
  },
  computed: {
    createTableRowAction() {
      return { active: this.enableCreateTableRowAction, method: this.createTableRow }
    },
    updateTableRowAction() {
      return { active: this.enableUpdateTableRowAction, method: this.updateTableRow }
    },
    deleteTableRowAction() {
      return { active: this.enableDeleteTableRowAction, method: this.deleteTableRow }
    },
    language() {
      return Cookie.get('language')
    },
    exportSettings() {
      return {
        enabled: this.enableExportSettings,
        fileName: `${this.projectCode}_${this.tableNameForExport}`
      }
    },
    isValidRequest() {
      return this.isProjectIdSet && this.isMeasurementObjectGuidSet
    },
    selectedMeasurementObjectGuid() {
      return this.selectedMeasurementObject && this.selectedMeasurementObject.MpGuid ? this.selectedMeasurementObject.MpGuid : ''
    },
    isProjectIdSet() {
      return stringUtils.isNullOrEmptyOrWhitespace(this.projectId) === false
    },
    isMeasurementObjectGuidSet() {
      return stringUtils.isNullOrEmptyOrWhitespace(this.selectedMeasurementObjectGuid) === false
    },
    multiUpdateTableRowAction() {
      return {
        active: true,
        projectId: this.projectId,
        tableName: this.tableName,
        dateLastChangedField: this.dateLastChangedField,
        tblMeasurementPoints: [this.selectedMeasurementObject]
      }
    }
  },
  methods: {
    async createTableRow(row) {
      try {
        const record = {
          ...row.data
        }

        const finishingsTable = await this.$store.dispatch('createFinishing', {
          finishing: record,
          projectId: this.projectId,
          measurementPointGuid: this.selectedMeasurementObjectGuid
        })

        this.tableData = finishingsTable
      } catch (error) {
        await this.handleErrorResponse(error)
      }
    },
    async updateTableRow(row) {
      try {
        const record = {
          ...row.data,
          [this.dateLastChangedField]: await DataTableUtils.GenerateLastUpdatedAt()
        }

        await this.$store.dispatch('updateFinishing', {
          finishing: record,
          projectId: this.projectId,
          measurementPointGuid: this.selectedMeasurementObjectGuid
        })
      } catch (error) {
        await this.handleErrorResponse(error)
      }
    },
    async deleteTableRow(key) {
      try {
        await this.$store.dispatch('deleteFinishing', {
          projectId: this.projectId,
          measurementPointGuid: this.selectedMeasurementObjectGuid,
          finishingGuid: key
        })
      } catch (error) {
        await this.handleErrorResponse(error)
      }
    },
    async refreshTableData() {
      if (this.isValidRequest) {
        const response = await this.getTableData()
        const handleGetTableDataResponseResult = await this.handleGetTableDataResponse(response)
        this.tableData = handleGetTableDataResponseResult.tableData
      } else {
        await this.clearTableData()
      }
    },
    async handleGetTableDataResponse(response) {
      const result = {
        tableData: []
      }

      if (!response || response.length < 1) {
        return result
      }
      result.tableData = response
      return result
    },
    async clearTableData() {
      this.tableData = []
    },
    async getTableData() {
      try {
        const request = {
          projectId: this.projectId,
          measurementPointGuid: this.selectedMeasurementObjectGuid
        }
        return await this.$store.dispatch('getFinishings', request)
      } catch (error) {
        await this.handleErrorResponse(error)
      }
    },
    async getTableColumns() {
      try {
        this.tableColumns = await this.$store
          .dispatch('getDefaultFieldTemplate', {
            templateName: this.templateName
          })
          .then((templateData) => {
            this.template = templateData
            return GuiTemplateParser.parseTemplate(templateData)
          })
      } catch (error) {
        await this.handleErrorResponse(error)
      }
    },
    async handleErrorResponse(error, errorMessage = 'message.UnknownError') {
      console.log(error)
      TiPopup.popup(this.$t(errorMessage))
    },
    customRowValidation(event) {
      if (event.isValid === false) return
      event.errorText = ''

      const FiFromValidationValue = parseInt(event.newData.FiFrom || event.oldData.FiFrom, 10) || 0
      const FiToValidationValue = parseInt(event.newData.FiTo || event.oldData.FiTo, 10) || Number.MAX_SAFE_INTEGER

      if (event.newData.FiFrom && event.newData.FiFrom !== 0 && event.newData.FiFrom >= FiToValidationValue) {
        event.isValid = false
        event.errorText = this.$t('message.toShouldHigherThanFrom')
      }

      if (event.newData.FiTo && event.newData.FiTo <= FiFromValidationValue) {
        event.isValid = false
        event.errorText = this.$t('message.toShouldHigherThanFrom')
      }
    }
  }
}
</script>
