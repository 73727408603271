<template>
  <div>
    <dx-data-grid-wrapper
      v-if="!loading && cadastralOvam"
      ref="tableBase"
      :tableTitle="$t('tableTitles.OVAM')"
      :template-name="templateName"
      :controls="controls"
      :data="cadastralOvam"
      :selected="selected"
      :projectId="projectId"
      :row-key="rowKeyName"
      :child-tables="childTables"
      @ti-table-row-removed="rowRemoved"
      @ti-table-row-updated="rowUpdated"
      @ti-table-row-inserted="rowInserted"
      @savingToDataSourceFinished="saveEditedRowsUsingDataService"
      @selectionChanged="onSelectionChanged"
    >
      <div slot="customButtonsRightStart">
        <square-button :width="34" :height="34" :title="$t('button.create')" @click.native="showAddModal">
          <span class="addIcon glyphicons glyphicons-plus"></span>
        </square-button>
      </div>
      <div slot="customButtonsRightEnd">
        <square-button :width="34" :height="34" :title="$t('button.delete')" @click.native="showDeleteModal">
          <span class="deleteIcon glyphicons glyphicons-bin"></span>
        </square-button>
      </div>
    </dx-data-grid-wrapper>
    <nv-loading :show="loading"></nv-loading>
    <add-cadastral-ovam-modal :show="addModalShown" :PrID="projectId" @create-cadastral-ovam="addRowToTable" @close-modal="addModalShown = false"></add-cadastral-ovam-modal>
    <confirm-delete-modal :subHeader="deleteModalSubHeader" :show="deleteModalShown" @delete-confirmed="deleteTableRow" @close-modal="deleteModalShown = false">
      <p>{{ selectedRows > 1? $t("project.deleteCadastralOvamsHelp") : $t("project.deleteCadastralOvamHelp")}}</p>
    </confirm-delete-modal>
    <table-base />
  </div>
</template>

<script>
import nvLoading from '@/components/_shared/loading'
import squareButton from '@/components/_shared/squareButton'
import addCadastralOvamModal from '@/components/modal/addCadastralOvamModal'
import confirmDeleteModal from '@/components/modal/confirmDeleteModal'
import DxDataGridWrapper from '@/components/tables/dxDataGridWrapper'
import tableBase from '@/components/tables/tableBase'

import { CadastralOvamService } from '@/services/DataWS/cadastralOvamService'

const cadastralOvamService = new CadastralOvamService()
export default {
  name: 'tableCadastralOvam',
  extends: tableBase,
  components: {
    DxDataGridWrapper, nvLoading, squareButton, addCadastralOvamModal, confirmDeleteModal, tableBase
  },
  data () {
    return {
      templateName: 'frmCadastralOvam',
      rowKeyName: 'CaGUID',
      deleteKey: 'CaDeleted',
      dateLastChangedKey: 'CaDateLastChanged',
      tableName: 'cadastralOvam',
      idKey: 'CaID',
      selectedRows: 0
    }
  },
  computed: {
    cadastralOvam: {
      get () {
        return this.$store.state.cadastralOvam
      },
      set (value) {
        this.$store.commit('setCadastralOvams', value)
      }
    }
  },
  created () {
    this.$store.dispatch('fetchCadastralOvam', { PrID: this.projectId })
      .then(
        () => { this.loading = false },
        (e) => {
          this.loading = false
          console.error('Error saving [' + this.templateName + ']', e)
        }
      )
  },
  watch: {
    'cadastralOvam': {
      handler: function (to, from) {
        if (!this.loading && this.cadastralOvam) {
          this.reUpdateSelected()
        }
      }
    }
  },
  methods: {
    onSelectionChanged (e) {
      this.selectedRows = e.selectedRowKeys.length
    },
    saveRow (row, action) {
      switch (action) {
        case 'INSERT':
          return cadastralOvamService.createCadastralOvam(row, this.projectId)
        case 'DELETE':
          return cadastralOvamService.deleteCadastralOvam(row, this.projectId)
        default:
          return cadastralOvamService.updateCadastralOvam(row, this.projectId)
      }
    },
    reFetchData  () {
      this.cadastralOvam = []
      this.$store.dispatch('fetchCadastralOvam', { PrID: this.projectId })
        .then(
          () => { this.loading = false },
          (e) => {
            this.loading = false
            console.error('Error saving [' + this.templateName + ']', e)
          }
        )
    }
  }
}
</script>

<style lang="less" scoped>
.addIcon, .deleteIcon, .saveIcon{
  font-size: 1.2rem;
  margin: 2px 1px;
}
</style>
