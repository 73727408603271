export default {
  checkForLabStatusCell(cellFieldType) {
    return (cellFieldType === 'LbLabStatusCode') ? 'lab-status-codes-cell' : ''
  },

  getClassNameForLabStatusCode(labAssignmentData, labAssignmentStatusCodeList) {
    const yellowLabCodes = ['3', '2']
    const orangeLabCodes = ['10', '4', '1']
    const redLabCodes = ['11', '12', '7']
    const greenLabCodes = ['5', '6', '9', '13', '14', '8']
    let LabStatusObject = labAssignmentStatusCodeList.find(StatusCode => StatusCode.GcDescription === labAssignmentData.value)
    if (!labAssignmentData.data.LbIsSent || labAssignmentData.data.LbIsSent === 'false') {
      return 'red-lab-code-cell-status'
    }
    if (typeof LabStatusObject !== 'object' && labAssignmentData.data.LbIsSent) {
      return 'light-yellow-lab-code-cell-status'
    }
    if (typeof LabStatusObject === 'object') {
      if (yellowLabCodes.includes(LabStatusObject.GcCode)) {
        return 'yellow-lab-code-cell-status'
      }
      if (orangeLabCodes.includes(LabStatusObject.GcCode)) {
        return 'orange-lab-code-cell-status'
      }
      if (redLabCodes.includes(LabStatusObject.GcCode)) {
        return 'red-lab-code-cell-status'
      }
      if (greenLabCodes.includes(LabStatusObject.GcCode)) {
        return 'green-lab-code-cell-status'
      }
    }
  }
}
