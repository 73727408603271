<template>
  <transition name="fade">
    <div v-show="show" class="backdrop">
      <div class="terraIndexModal">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" @click="hideModal()">&times;</button>
              <h4 class="modal-title">{{$t('cadastralOvam.addCadastralOvamVlaremActModalTitle')}}</h4>
            </div>
            <div class="modal-body">
              <p>{{$t('cadastralOvam.addCadastralOvamVlaremActModalHelp')}}</p>
              <p>{{$t("cadastralOvam.VLID")}}</p>
              <dx-drop-down-box v-if="gridDataSource" ref="customDropDownBoxRoot"
                                :value.sync="VlID"
                                :display-expr="displayExpression"
                                :show-clear-button="true"
                                :data-source="gridDataSource"
              >
                <div slot="content" slot-scope="{}">
                  <dx-data-grid
                    :data-source="gridDataSource"
                    :columns="gridColumns"
                    :hover-state-enabled="true"
                    :selected-row-keys.sync="gridSelectedRowKeys"
                    width="100%"
                    height="300px"
                  >
                    <dx-header-filter :visible="true"/>
                    <dx-selection mode="single"/>
                    <dx-scrolling mode="infinite"/>
                  </dx-data-grid>
                </div>
              </dx-drop-down-box>
              <p v-if="selectedVlID" class="error">{{$t('message.valueRequired')}}</p>
              <p>{{$t("cadastralOvam.VlDateStart")}}</p>
              <dx-date-box
                :value.sync="VlDateStart"
                type="date"
                display-format="yyyy-MM-dd"
              />
              <p v-if="selectedVlDateStart" class="error">{{$t('message.valueRequired')}}</p>
            </div>
            <div class="modal-footer">
              <button class="btn" @click="returnRow()">{{$t('project.CreateButton')}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { DxDateBox } from 'devextreme-vue/date-box'
import { DxDataGrid, DxSelection, DxScrolling, DxHeaderFilter } from 'devextreme-vue/data-grid'
import { DxDropDownBox } from 'devextreme-vue/drop-down-box'
import translation from '@/configurations/app/config-locales'
import guidUtils from '@/utils/guidUtils'

export default {
  name: 'addCadastralOvamVlaremActModal',
  components: {
    DxDateBox,
    DxDataGrid,
    DxSelection,
    DxScrolling,
    DxDropDownBox,
    DxHeaderFilter
  },
  props: {
    show: Boolean,
    controls: Array,
    PrID: Number,
    CaID: String
  },
  data () {
    return {
      control: null,
      VlID: null,
      VlDateStart: new Date(),
      gridDataSource: null,
      gridColumns: null,
      grid_SelectedRowKeys: [],
      selectedVlID: false,
      selectedVlDateStart: false
    }
  },
  computed: {
    gridSelectedRowKeys: {
      get: function () {
        return this.grid_SelectedRowKeys
      },
      set: function (value) {
        if (value[0] && value[0].value) {
          this.VlID = value[0].value
        }
        this.grid_SelectedRowKeys = value
        this.$refs.customDropDownBoxRoot.instance.close()
      }
    }
  },
  created () {
    this.control = this.controls.find((control) => control.controlId === '1800') // Vlarem
  },
  mounted () {
    this.buildAndSetColumnsFromControl(this.control)
    this.buildAndSetDataSourceFromControl(this.control)
  },
  methods: {
    hideModal () {
      this.$emit('close-modal')
    },
    returnRow () {
      if (this.VlID && this.VlDateStart) {
        let cadastralOvamVlaremAct = {
          VlID: this.VlID,
          VlDateStart: this.VlDateStart,
          PrID: this.PrID.toString(),
          CaID: this.CaID,
          VlGUID: guidUtils.newGuid()
        }
        this.selectedVlID = false
        this.selectedVlDateStart = false
        this.$emit('create-cadastral-ovam-vlarem-act', cadastralOvamVlaremAct)
        this.hideModal()
      } else {
        this.selectedVlID = (this.VlID === null)
        this.selectedVlDateStart = (this.VlDateStart === null)
      }
    },
    displayExpression () {
      return this.control.displayValueLookup[this.VlID]
    },
    buildAndSetColumnsFromControl (control) { // look at control and textformatkeys to build columns
      let columns = []
      let firstField = control.fields[0]
      firstField.editorOptions.textFormatKeys.forEach((textFormatOption) => {
        let column = {
          dataField: textFormatOption,
          caption: translation.t('gridColumn.' + textFormatOption)
        }
        switch (textFormatOption) {
          case 'localizedCode':
            column.width = '75px'
            break
          case 'localizedText2':
            column.width = '75px'
            break
        }
        columns.push(column)
      })
      this.gridColumns = columns
    },
    buildAndSetDataSourceFromControl (control) {
      let dataSource = []
      let firstField = control.fields[0]
      // because the templates have an annoying nested array + language field + mapping we have to walk this and correct it
      firstField.options.forEach((option) => {
        option.localizedCode = (option.codeInterface[0] ? option.codeInterface[0][control.languageCode3Letter] : null)
        option.localizedText = (option.text[0] ? option.text[0][control.languageCode3Letter] : null)
        option.localizedText2 = (option.text2[0] ? option.text2[0][control.languageCode3Letter] : null)
        dataSource.push(option)
      })
      this.gridDataSource = dataSource
    }
  }
}
</script>

<style lang="less" scoped>
  .terraIndexModal{
    z-index: 1002;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    /*top: 50%;
    transform: translateY(calc(-50% - 60px));*/
  }
  .backdrop{
    background-color: rgba(0,0,0, 0.5);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1001;
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity .2s;
  }
  .fade-enter, .fade-leave-to{
    opacity: 0;
  }
</style>
