<template>
  <div class="attachment-gallery-overview">
    <MediaGallery :mediaType="mapLayersMediaType" :singleRow="true" :files="mapLayers" :loading="mapLayersLoading" :externalLink="mapLayersExternalLink" @onFirstUploaded="loadMediaLocationLinkFunction(mapLayersMediaType, 'mapLayersExternalLink')"/>
    <MediaGallery :mediaType="labCertificatesMediaType" :files="labCertificates" :loading="labCertificatesLoading" :externalLink="labCertificatesExternalLink" @onFirstUploaded="loadMediaLocationLinkFunction(labCertificatesMediaType, 'labCertificatesExternalLink')"/>
    <MediaGallery :mediaType="projectDocumentsMediaType" :files="projectDocuments" :loading="projectDocumentsLoading" :externalLink="projectDocumentExternalLink" @onFirstUploaded="loadMediaLocationLinkFunction(projectDocumentsMediaType, 'projectDocumentExternalLink')"/>
    <MediaGallery :mediaType="klicFilesMediaType" :files="klicFiles" :loading="klicFilesLoading" :externalLink="klicFilesExternalLink" @onFirstUploaded="loadMediaLocationLinkFunction(klicFilesMediaType, 'klicFilesExternalLink')"/>

    <div id="projectContextMenuPopup" class="right-click-menu">
      <li
        @click="openMediaDeleteModal" >
        {{ $t('attachment.deleteModalTitle') }}
      </li>
    </div>
    <delete-modal/>
  </div>
</template>

<script>
import $ from 'jquery'

import MediaGallery from '@/components/dynamicTables/misc/mediaGallery'
import deleteModal from '@/components/modal/deleteModal.vue'
import stringUtils from '@/utils/stringUtils'

import config from '@/configurations/config'
import Popup from '@/utils/popup'

let popup = Popup[config.platform].default

export default {
  components: {
    MediaGallery,
    deleteModal
  },
  async mounted() {
    await this.setCurrentProject()
    await this.loadMediaTypeData()
  },
  data() {
    return {
      mapLayersLoading: false,
      projectDocumentsLoading: false,
      klicFilesLoading: false,
      labCertificatesLoading: false,
      mapLayersExternalLink: '',
      projectDocumentExternalLink: '',
      klicFilesExternalLink: '',
      labCertificatesExternalLink: '',
      mapLayersMediaType: config.constants.MediaClassification.MapLayers,
      projectDocumentsMediaType: config.constants.MediaClassification.ProjectDocuments,
      klicFilesMediaType: config.constants.MediaClassification.KLICFiles,
      labCertificatesMediaType: config.constants.MediaClassification.LabCertificates
    }
  },
  computed: {
    projectDocuments: {
      get() {
        return this.$store.state.projectDocuments
      },
      set(value) {
        this.$store.commit('setProjectDocuments', value)
      }
    },
    klicFiles: {
      get() {
        return this.$store.state.klicFiles
      },
      set(value) {
        this.$store.commit('setKlicFiles', value)
      }
    },
    mapLayers: {
      get() {
        return this.$store.state.mapLayers
      },
      set(value) {
        this.$store.commit('setMapLayers', value)
      }
    },
    labCertificates: {
      get() {
        return this.$store.state.labCertificates
      },
      set(value) {
        this.$store.commit('setLabCertificates', value)
      }
    }
  },
  methods: {
    openMediaDeleteModal() {
      let modal = $('#modalDelete')
      modal.on('shown.bs.modal', function (e) {
        $('#txtActionCode').focus()
      })
      modal.modal('toggle')
    },
    async setCurrentProject() {
      if (stringUtils.isNullOrEmptyOrWhitespace(this.$route.params.projectId)) {
        popup.popup(this.$t('message.projectNotFound'))
        return
      }

      await this.$store.dispatch('fetchProject', {
        PrID: this.$route.params.projectId
      })
      const project = this.$store.state.currentProject

      if (project && project.PrID) {
        this.projectCode = project.PrCode
      } else {
        popup.popup(this.$t('message.projectNotFound'))
      }
    },
    async loadMediaLocationLinkFunction(mediaClassification, externalLinkMediaType) {
      const response = await this.$store.dispatch('loadMediaLocationLink', {
        mediaType: mediaClassification
      })

      this[externalLinkMediaType] = response.data.Link
    },
    async getProjectMediaListFunction(mediaClassification, filesMediaTypeName) {
      const response = await this.$store.dispatch('getProjectMediaList', {
        mediaType: mediaClassification
      })

      this.handleMediaResponse(response, mediaClassification, filesMediaTypeName)
    },
    async setProjectDocumentsFiles() {
      this.projectDocumentsLoading = true
      await Promise.all([
        this.loadMediaLocationLinkFunction(this.projectDocumentsMediaType, 'projectDocumentExternalLink'),
        this.getProjectMediaListFunction(this.projectDocumentsMediaType, 'projectDocuments')])
      this.projectDocumentsLoading = false
    },
    async setMapLayersFiles() {
      this.mapLayersLoading = true
      await Promise.all([
        this.loadMediaLocationLinkFunction(this.mapLayersMediaType, 'mapLayersExternalLink'),
        this.getProjectMediaListFunction(this.mapLayersMediaType, 'mapLayers')])
      this.mapLayersLoading = false
    },
    async setKlicFiles() {
      this.klicFilesLoading = true
      await Promise.all([
        this.loadMediaLocationLinkFunction(this.klicFilesMediaType, 'klicFilesExternalLink'),
        this.getProjectMediaListFunction(this.klicFilesMediaType, 'klicFiles')])
      this.klicFilesLoading = false
    },
    async setLabCertificates() {
      this.labCertificatesLoading = true
      await Promise.all([
        this.loadMediaLocationLinkFunction(this.labCertificatesMediaType, 'labCertificatesExternalLink'),
        this.getProjectMediaListFunction(this.labCertificatesMediaType, 'labCertificates')])
      this.labCertificatesLoading = false
    },
    async loadMediaTypeData() {
      await Promise.all([this.setProjectDocumentsFiles(), this.setMapLayersFiles(), this.setKlicFiles(), this.setLabCertificates()])
    },
    handleMediaResponse(mediaListRes, mediaClassification, filesMediaTypeName) {
      if (!mediaListRes.data || mediaListRes.data.ResultCode !== 'GetMediaList_Success') {
        this[filesMediaTypeName] = []
        return
      }
      if (typeof mediaListRes.data.files === 'undefined') {
        this[filesMediaTypeName] = []
        return
      }
      if (mediaClassification !== this.mapLayersMediaType && mediaClassification !== this.projectDocumentsMediaType && mediaClassification !== this.klicFilesMediaType && mediaClassification !== this.labCertificatesMediaType) {
        this.handlerError(new Error('ERROR: Unknown mediaClassification: ' + mediaClassification))
        this[filesMediaTypeName] = []
        return
      }
      this[filesMediaTypeName] = mediaListRes.data.files
    },
    handlerError(err) {
      if (err && err.ResultCode && err.ResultCode === 'MeasurementPoint_UNKNOWN_ERROR') {
        popup.popup(err.ResultMessage)
        return
      }
      popup.popup(err.body)
    }
  }
}
</script>

<style scoped lang="less">
.attachment-gallery-overview {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}
</style>
