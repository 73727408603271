<template>
  <div>
    <TerraIndexDynamicTable
      :table-data="tableData"
      :table-title="this.$t('tableTitles.ConcernedContacts')"
      :table-data-identifier="tableIdentifier"
      :table-create-action="createTableRowAction"
      :table-update-action="updateTableRowAction"
      :table-delete-action="deleteTableRowAction"
      :table-columns="tableColumns"
      :table-name="tableName"
      :setting-name="settingName"
      :language="language"
      :excel-export-settings="exportSettings"
      :enable-column-chooser="false"
      :search-panel-enabled="searchPanelEnabled"
      :state-storing="{}"
      :default-selected-row-index="0"
      :is-valid-request="isValidRequest"
      scrolling-mode="infinite"
      :use-multi-select="true"
      @refresh-table-data="refreshTableData"
    />
    <ConcernedContactModal :functions="{ add: newConcernedContactCallback }" />
  </div>
</template>

<script>
import $ from 'jquery'
import TerraIndexDynamicTable from '@/components/dynamicTables/TerraIndexDynamicTable'
import GuiTemplateParser from '@/components/dynamicTables/GuiTemplateParser'
import Cookie from '@/utils/cacheProviders/cookieCacheProvider'
import stringUtils from '@/utils/stringUtils'
import Popup from '@/utils/popup'
import Config from '@/configurations/config'
import ConcernedContactModal from '@/components/modal/concernedContactModal.vue'
import DataTableUtils from '@/utils/dataTableUtils'

const TiPopup = Popup[Config.platform].default

export default {
  name: 'concernedContactsTable',
  components: {
    TerraIndexDynamicTable,
    ConcernedContactModal
  },
  data() {
    return {
      tableData: [],
      tableColumns: [],
      tableName: 'tblConcernedContacts',
      tableNameForExport: 'tblConcernedContacts',
      tableIdentifier: 'CcGUID',
      dateLastChangedField: 'CcDateLastChanged',
      templateName: 'frmConcernedContactDetails', // TODO: replace frmConcernedContactDetails with frmConcernedContactGrid
      template: {},
      settingName: 'ConcernedContacts_Settings'
    }
  },
  created() {
    this.getTableColumns()
  },
  props: {
    projectId: {
      type: String,
      required: true
    },
    projectCode: {
      type: String,
      required: false,
      default() {
        return ''
      }
    },
    enableCreateTableRowAction: {
      required: false,
      default() {
        return true
      }
    },
    enableDeleteTableRowAction: {
      required: false,
      default() {
        return true
      }
    },
    enableUpdateTableRowAction: {
      required: false,
      default() {
        return true
      }
    },
    searchPanelEnabled: {
      required: false,
      default() {
        return true
      }
    },
    enableExportSettings: {
      required: false,
      default() {
        return true
      }
    }
  },
  watch: {
    projectId: {
      deep: true,
      immediate: true,
      handler: async function () {
        await this.refreshTableData()
      }
    }
  },
  computed: {
    createTableRowAction() {
      return {
        active: this.enableCreateTableRowAction,
        method: this.showConcernedContactModal,
        override: true
      }
    },
    updateTableRowAction() {
      return { active: this.enableUpdateTableRowAction, method: this.updateTableRow }
    },
    deleteTableRowAction() {
      return { active: this.enableDeleteTableRowAction, method: this.deleteTableRow }
    },
    language() {
      return Cookie.get('language')
    },
    exportSettings() {
      return {
        enabled: this.enableExportSettings,
        fileName: `${this.projectCode}_${this.tableNameForExport}`
      }
    },
    isValidRequest() {
      return this.isProjectIdSet
    },
    isProjectIdSet() {
      return stringUtils.isNullOrEmptyOrWhitespace(this.projectId) === false
    }
  },
  methods: {
    async showConcernedContactModal() {
      try {
        $('#modalAddConcernedContact').modal('show')
        $('#modalAddConcernedContact').trigger('popupTrigger')
      } catch (error) {
        await this.handleErrorResponse(error)
      }
    },
    async newConcernedContactCallback(concernedContact) {
      $('#modalAddConcernedContact').modal('hide')
      try {
        concernedContact.PrID = this.projectId
        concernedContact[this.dateLastChangedField] = await DataTableUtils.GenerateLastUpdatedAt()
        const concernedContacts = await this.$store.dispatch('createConcernedContact', {
          concernedContact: concernedContact
        })
        this.tableData = concernedContacts
      } catch (error) {
        await this.handleErrorResponse(error)
      }
    },
    async deleteTableRow(key) {
      try {
        const context = {
          CcGUID: key,
          PrID: this.projectId
        }
        await this.$store.dispatch('deleteConcernedContact', context)
      } catch (error) {
        await this.handleErrorResponse(error)
      }
    },
    async updateTableRow(row) {
      try {
        const record = {
          ...row.data,
          [this.dateLastChangedField]: await DataTableUtils.GenerateLastUpdatedAt(),
          PrID: this.projectId
        }

        await this.$store.dispatch('updateConcernedContact', {
          concernedContact: record
        })
      } catch (error) {
        await this.handleErrorResponse(error)
      }
    },
    async refreshTableData() {
      if (this.isValidRequest) {
        await this.getTableData()
      } else {
        await this.clearTableData()
      }
    },
    async clearTableData() {
      this.tableData = []
    },
    async getTableData() {
      try {
        const response = await this.$store.dispatch('getConcernedContacts', {
          projectId: this.projectId
        })
        this.tableData = response.tblConcernedCompanies
      } catch (error) {
        await this.handleErrorResponse(error)
      }
    },
    async getTableColumns() {
      try {
        this.tableColumns = await this.$store
          .dispatch('getDefaultFieldTemplate', {
            templateName: this.templateName
          })
          .then((templateData) => {
            this.template = templateData
            return GuiTemplateParser.parseTemplate(templateData)
          })
      } catch (error) {
        await this.handleErrorResponse(error)
      }
    },
    async handleErrorResponse(error, errorMessage = 'message.UnknownError') {
      console.log(error)
      TiPopup.popup(this.$t(errorMessage))
    }
  }
}
</script>
