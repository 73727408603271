<template>
  <div class="analysis-results-data-table-overview">
    <div class="overview-table-row">
      <div class="overview-table">
        <BannerTable
          :tableTitle="title"
          :dataSet="dataset"
          :columns="columns"
          :banneredColumnHeaders="banneredColumnHeaders"
          :banneredColumns="banneredColumns"
          :userMasterDetail="userMasterDetail"
          :masterDetailTemplates="masterDetailTemplates"
          :dataKey="'SampleGUID'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BannerTable from '@/components/tables/ChildTableContainers/DataGridBannerMasterDetail'
import { tableDataSetParser } from '@/utils/DataGridTableUtils'
export default {
  name: 'tableAllSamplesWithResults',
  props: {
    projectId: String
  },
  data() {
    return {
      dataset: null,
      columns: null,
      banneredColumnHeaders: null,
      banneredColumns: null,
      userMasterDetail: true,
      masterDetailTemplates: [
        {
          componentName: 'tableComponentResults',
          titleTranslationKey: 'AnalysesResults.buttons.results'
        },
        {
          componentName: 'tableFieldSamples',
          titleTranslationKey: 'AnalysesResults.buttons.subSamples',
          conditions: [
            '["GR", "SL", "OV"].includes(rowData.data["SampleMatrixCode"])'
          ]
        }
      ]
    }
  },
  components: {
    BannerTable
  },
  async created() {
    // These parameters should be moved to properies
    let bannerIdentifier = 'TestSubframeworkID'
    let connectionCharacter = '_'
    let bannerTitleIdentifier = 'TestSubframework'
    let translationsGroup = 'AnalysesResults'
    this.dataset = await this.$store.dispatch('getAllSamplesWithResults', {
      projectId: this.projectId
    })
    if (this.dataset) {
      let parsedData = tableDataSetParser.Bannered(
        bannerIdentifier,
        connectionCharacter,
        bannerTitleIdentifier,
        translationsGroup,
        this.dataset
      )
      this.columns = parsedData.columns
      this.banneredColumnHeaders = parsedData.groups
      this.banneredColumns = parsedData.groupColumns
    }
  },
  computed: {
    title() {
      return this.$t('tableTitles.AllAnalysisSamples')
    }
  }
}
</script>

<style scoped>
.analysis-results-data-table-overview h2 {
  margin-top: 5px;
  font-size: 20px;
}
.analysis-results-data-table-overview {
  height: 100%;
}
.analysis-results-data-table-overview .overview-table-row {
  height: 100%;
}
.analysis-results-data-table-overview .overview-table-row:first-child {
  display: flex;
}
.analysis-results-data-table-overview .overview-table {
  overflow: hidden;
}
.analysis-results-data-table-overview
  .overview-table-row
  .overview-table
  > div {
  margin-left: 10px;
}
.analysis-results-data-table-overview .overview-table > div,
.analysis-results-data-table-overview .overview-table > div > div {
  height: 100%;
}
</style>
