<template>
  <div>
    <TerraIndexDynamicTable
      scrollingMode="infinite"
      :tableData="tableData"
      :tableTitle="$t('label.WaterSamples')"
      :tableDataIdentifier="tableIdentifier"
      :tableCreateAction="createTableRowAction"
      :tableUpdateAction="updateTableRowAction"
      :tableDeleteAction="deleteTableRowAction"
      :tableColumns="tableColumns"
      :tableName="tableName"
      :template="template"
      :settingName="settingName"
      :language="language"
      :excelExportSettings="exportSettings"
      :enableColumnChooser="enableColumnChooser"
      :search-panel-enabled="searchPanelEnabled"
      :onRowValidating="customRowValidation"
      :state-storing="{}"
      :defaultSelectedRowIndex="0"
      :isValidRequest="isValidRequest"
      :tableMultiUpdateAction="multiUpdateTableRowAction"
      :useMultiSelect="true"
      :projectId="projectId"
      @refreshTableData="refreshTableData"
      @updateSelectedRows="updateSelectedRows"
    />
  </div>
</template>

<script>
import TerraIndexDynamicTable from '@/components/dynamicTables/TerraIndexDynamicTable'
import GuiTemplateParser from '@/components/dynamicTables/GuiTemplateParser'
import Cookie from '@/utils/cacheProviders/cookieCacheProvider'
import stringUtils from '@/utils/stringUtils'
import Popup from '@/utils/popup'
import Config from '@/configurations/config'
import DataTableUtils from '@/utils/dataTableUtils'

const TiPopup = Popup[Config.platform].default

export default {
  name: 'waterSamplesTable',
  components: {
    TerraIndexDynamicTable
  },
  data() {
    return {
      tableData: [],
      tableDataUnfiltered: [],
      tableColumns: [],
      tableIdentifier: 'WsGuid',
      tableName: 'tblWaterSamples',
      tableNameForExport: 'tblWaterSamples',
      dateLastChangedField: 'WsDateLastChanged',
      templateName: 'frmWatersampleGrid',
      template: {},
      settingName: 'Watersample_Settings'
    }
  },
  async created() {
    await this.getTableColumns()
  },
  props: {
    projectId: {
      type: String,
      required: true
    },
    projectCode: {
      type: String,
      required: true
    },
    selectedMeasurementObject: {
      type: Object,
      required: true
    },
    selectedGaugingTube: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    },
    updateSelectedRows: {
      type: Function,
      required: false,
      default() {
        return () => {}
      }
    },
    sampleType: {
      type: String,
      required: false,
      default: 'All'
    },
    enableCreateTableRowAction: {
      required: false,
      default() {
        return true
      }
    },
    enableDeleteTableRowAction: {
      required: false,
      default() {
        return true
      }
    },
    enableUpdateTableRowAction: {
      required: false,
      default() {
        return true
      }
    },
    enableColumnChooser: {
      required: false,
      default() {
        return true
      }
    },
    searchPanelEnabled: {
      required: false,
      default() {
        return true
      }
    },
    enableExportSettings: {
      required: false,
      default() {
        return true
      }
    }
  },
  watch: {
    projectId: {
      deep: true,
      immediate: true,
      handler: async function () {
        await this.refreshTableData()
      }
    },
    selectedMeasurementObject: {
      deep: true,
      immediate: true,
      handler: async function () {
        await this.refreshTableData()
      }
    },
    selectedGaugingTube: {
      deep: true,
      immediate: true,
      handler: async function (value) {
        await this.refreshTableData()
      }
    }
  },
  computed: {
    createTableRowAction() {
      return { active: this.enableCreateTableRowAction, method: this.createTableRow }
    },
    updateTableRowAction() {
      return { active: this.enableUpdateTableRowAction, method: this.updateTableRow }
    },
    deleteTableRowAction() {
      return { active: this.enableDeleteTableRowAction, method: this.deleteTableRow }
    },
    language() {
      return Cookie.get('language')
    },
    exportSettings() {
      return {
        enabled: this.enableExportSettings,
        fileName: `${this.projectCode}_${this.tableNameForExport}`
      }
    },
    isValidRequest() {
      if (
        this.isProjectIdSet === false ||
        this.isMeasurementObjectGuidSet === false
      ) {
        return false
      }

      if (
        this.isGaugingTubeGuidRequired &&
        this.isGaugingTubeGuidSet === false
      ) {
        return false
      }

      return true
    },
    isGaugingTubeGuidRequired() {
      return this.sampleType === 'Ground'
    },
    selectedMeasurementObjectGuid() {
      return this.selectedMeasurementObject &&
        this.selectedMeasurementObject.MpGuid
        ? this.selectedMeasurementObject.MpGuid
        : ''
    },
    selectedGaugingTubeGuid() {
      return this.selectedGaugingTube && this.selectedGaugingTube.FtGuid
        ? this.selectedGaugingTube.FtGuid
        : ''
    },
    isProjectIdSet() {
      return stringUtils.isNullOrEmptyOrWhitespace(this.projectId) === false
    },
    isMeasurementObjectGuidSet() {
      return (
        stringUtils.isNullOrEmptyOrWhitespace(this.selectedMeasurementObjectGuid) ===
        false
      )
    },
    isGaugingTubeGuidSet() {
      return (
        stringUtils.isNullOrEmptyOrWhitespace(this.selectedGaugingTubeGuid) === false
      )
    },
    multiUpdateTableRowAction() {
      const request = {
        active: true,
        projectId: this.projectId,
        tableName: this.tableName,
        dateLastChangedField: this.dateLastChangedField,
        tblMeasurementPoints: [this.selectedMeasurementObject]
      }

      if (this.isGaugingTubeGuidSet) {
        request.tblGaugingTubes = [this.selectedGaugingTube]
      }

      return request
    }
  },
  methods: {
    async customRowValidation(event) {
      if (event.isValid === false) {
        return
      }

      if (event.newData.WsName) {
        event.isValid = this.validateNameIsUniqueInDataTable(event)

        if (event.isValid === false) {
          event.errorText = this.$t('message.nameShouldUnique')
          return
        }

        event.promise = new Promise(async (resolve) => {
          await this.validateNameIsUniqueOnServer(event, resolve)
          resolve({ isValid: true, errorText: '' })
        }).then((result) => {
          event.errorText = result.errorText
          event.isValid = result.isValid
        })
      }
    },
    validateNameIsUniqueInDataTable(event) {
      const validationContext = {
        dataTable: this.tableDataUnfiltered,
        propertyToValidate: 'WsName',
        guidToValidate: this.tableIdentifier,
        event: event
      }

      return DataTableUtils.ValidatePropertyIsUniqueInDataTable(
        validationContext
      )
    },
    async validateNameIsUniqueOnServer(event, resolve) {
      await this.$store
        .dispatch('validateWaterSampleName', {
          waterSampleName: event.newData.WsName,
          projectId: this.projectId,
          WSGuid:
            event.oldData && event.oldData.WsGuid ? event.oldData.WsGuid : null
        })
        .then((result) => {
          if (!result) {
            resolve({
              isValid: false,
              errorText: this.$t('message.nameShouldUnique')
            })
          }
        })
    },
    async createTableRow(row) {
      try {
        const record = {
          ...row.data
        }

        await this.$store
          .dispatch('createWaterSample', {
            waterSampleRecord: record,
            projectId: this.projectId,
            measurementPointGuid: this.selectedMeasurementObjectGuid,
            gaugingTubeGuid: this.selectedGaugingTubeGuid
          })
          .then(async (response) => {
            // observations need a WaGuid, so update the water sample when server has responded with a WaGuid after insert
            const recordCreated = response.tblWaterSamples.find(
              (sample) => sample.WsName === row.data.WsName
            )
            if (!recordCreated) {
              console.log(
                'Newly created sample could not be found in data table by sample name; skipping update for observations'
              )
              await this.refreshTableData()
              return
            }
            row.data = {
              ...row.data,
              ...recordCreated
            }

            await this.updateTableRow(row)
          })
      } catch (error) {
        await this.handleErrorResponse(error)
      }
    },
    async updateTableRow(row) {
      try {
        const record = {
          ...row.data,
          [this.dateLastChangedField]:
            await DataTableUtils.GenerateLastUpdatedAt()
        }
        // remove the id because it causes constraint errors in DataWS
        if (record.WsID) {
          delete record.WsID
        }

        await this.$store
          .dispatch('updateWaterSample', {
            waterSample: record,
            projectId: this.projectId,
            measurementPointGuid: this.selectedMeasurementObjectGuid,
            gaugingTubeGuid: this.selectedGaugingTubeGuid
          })
          .then(async (response) => {
            await this.refreshTableData()
          })
      } catch (error) {
        await this.handleErrorResponse(error)
      }
    },
    async deleteTableRow(key) {
      try {
        await this.$store.dispatch('deleteWaterSample', {
          projectId: this.projectId,
          measurementPointGuid: this.selectedMeasurementObjectGuid,
          gaugingTubeGuid: this.selectedGaugingTubeGuid,
          waterSampleGuid: key
        })
        await this.refreshTableData()
      } catch (error) {
        await this.handleErrorResponse(error)
      }
    },
    async refreshTableData() {
      if (this.isValidRequest) {
        await this.getTableData().then(async (response) => {
          this.tableDataUnfiltered = response.tableDataUnfiltered
          this.tableData = response.tableData
        })
      } else {
        await this.clearTableData()
      }
    },
    async clearTableData() {
      this.tableData = []
    },
    async getTableData() {
      try {
        return await this.$store
          .dispatch('getSamples', {
            projectId: this.projectId,
            gaugingTubeGuid: this.selectedGaugingTubeGuid,
            measurementPointGuid: this.selectedMeasurementObjectGuid
          })
          .then(async (response) => {
            return this.handleGetTableDataResponse(response)
          })
      } catch (error) {
        await this.handleErrorResponse(error)
      }
    },
    async handleGetTableDataResponse(response) {
      const result = {
        tableDataUnfiltered: response.tblWaterSamples,
        tableData: []
      }

      if (
        !response ||
        !response.tblWaterSamples ||
        response.tblWaterSamples.length < 1
      ) {
        return result
      }

      let samples = await DataTableUtils.FilterWaterSamplesFromSamples(
        response.tblWaterSamples
      )

      switch (this.sampleType) {
        case 'Surface':
          samples = await DataTableUtils.FilterSurfaceSamplesFromSamples(
            samples
          )
          break

        case 'Ground':
          if (this.isGaugingTubeGuidSet === false) {
            return result
          }

          samples = await DataTableUtils.FilterGroundSamplesFromSamples(samples)
          break
      }

      result.tableData = await DataTableUtils.MapObservationsToDataTable(
        samples,
        response.tblObservation
      )
      return result
    },
    async getTableColumns() {
      try {
        this.tableColumns = await this.$store
          .dispatch('getDefaultFieldTemplate', {
            templateName: this.templateName
          })
          .then(async (templateData) => {
            this.template = await this.alterTemplate(templateData)
            return GuiTemplateParser.parseTemplate(this.template)
          })
      } catch (error) {
        await this.handleErrorResponse(error)
      }
    },
    async alterTemplate(template) {
      template = await this.alterMatrixCodeFieldInTemplate(template)

      return template
    },
    async alterMatrixCodeFieldInTemplate(template) {
      const matrixControl = template.controls.find((control) =>
        control.fields.find((field) => field.key === 'WsMatrixCode')
      )

      if (matrixControl) {
        const matrixField = matrixControl.fields.find(
          (field) => field.key === 'WsMatrixCode'
        )

        if (matrixField) {
          switch (this.sampleType) {
            case 'Surface':
              matrixField.options =
                await DataTableUtils.FilterSurfaceWaterSampleMatrices(
                  matrixField.options
                )
              break

            case 'Ground':
              matrixField.options =
                await DataTableUtils.FilterGroundWaterSampleMatrices(
                  matrixField.options
                )
              break
          }
        }
      }

      return template
    },
    async handleErrorResponse(error, errorMessage = 'message.UnknownError') {
      console.log(error)
      TiPopup.popup(this.$t(errorMessage))
    }
  }
}
</script>
