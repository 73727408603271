<template>
  <nv-loading />
</template>
<script>
import cookie from '@/utils/cacheProviders/cookieCacheProvider'
import stringUtils from '@/utils/stringUtils'
import dateFormatter from '@/utils/dateFormatter'
import config from '@/configurations/config.js'
import typefields from '@/configurations/app/config-typefield'
import { bus } from '@/bus'
import nvLoading from '@/components/_shared/loading.vue'
import { CodeListService } from '@/services/DataWS/codeListService'
import { TemplateService } from '@/services/DataWS/templateService'
const codeListService = new CodeListService()

const templateService = new TemplateService()
let getEditor = config.configEditors

export default {
  name: 'tableBase',
  props: {
    projectId: Number,
    childTables: Array,
    parentRow: Object,
    useMasterDetail: Boolean,
    isChildTable: Boolean
  },
  components: {
    nvLoading
  },
  async mounted () {
    this.language = cookie.get('language')
    await this.loadScreenTemplate()
    this.$on('savingToDataSourceFinished', (oldDataSourceDeepCopy) => {
      this.saveEditedRowsUsingDataService(oldDataSourceDeepCopy)
    })
  },
  data () {
    return {
      controls: null,
      template: {},
      loading: false,
      language: null,
      changedRows: [],
      insertedCount: 0,
      addModalShown: false,
      deleteModalShown: false
    }
  },
  computed: {
    deleteModalSubHeader: {
      get () {
        if (this.selectedRows > 1) {
          return this.selectedRows + ' ' + this.$t('label.deleteSelectedRecords')
        }
        return this.selectedRows + ' ' + this.$t('label.deleteSelectedRecord')
      }
    },
    dynamicCodeLists: {
      get () {
        return this.$store.state.dynamicCodeLists
      },
      set (value) {
        this.$store.commit('setDynamicCodeLists', value)
      }
    },
    selected: {
      get () {
        return this.$store.state.selected
      },
      set (value) {
        this.$store.commit('setSelected', value)
      }
    }
  },
  methods: {
    showAddModal () {
      this.addModalShown = true
    },
    showDeleteModal () {
      this.deleteModalShown = true
    },
    addRowToTable (newRowData) {
      this.$refs.tableBase.addRow(newRowData)
    },
    deleteTableRow () {
      this.$refs.tableBase.deleteFocusedRow()
    },

    getFocusedRowKey () {
      return this.$refs.tableBase ? this.$refs.tableBase.focusedRow : null
    },

    rowUpdated (rowData) {
      let row = rowData.data
      row[this.dateLastChangedKey] = dateFormatter.generateDateLastChangedValue()
      let changedRow = { rowData: row, action: 'UPDATE' }
      this.changedRows.push(changedRow)
    },
    rowInserted (rowData) {
      rowData[this.dateLastChangedKey] = dateFormatter.generateDateLastChangedValue()
      let changedRow = { rowData: rowData.data, action: 'INSERT' }
      this.changedRows.push(changedRow)
      this.insertedCount++
    },
    rowRemoved (rowData) {
      let row = rowData.data
      row[this.deleteKey] = true
      row[this.dateLastChangedKey] = dateFormatter.generateDateLastChangedValue()
      let changedRow = { rowData: row, action: 'DELETE' }
      this.changedRows.push(changedRow)
      if (this.selected[this.templateName]) { // Happens once every bulk delete, why? Doesn't seem to affect the functionality.
        if (this.selected[this.templateName][this.rowKeyName] === row[this.rowKeyName]) {
          bus.$emit('removeSelected', this.templateName)
        }
      }
    },

    /*
    * Saves all changed rows which are tracked in the rowUpdated, rowInserted and rowRemoved methods.
    * TODO: When a row fails saving to the database use oldDataSourceCopy and failedRows to re-insert the user made changes back into the grid. This highlights the changes back in the grid.
    */
    async saveEditedRowsUsingDataService (oldDataSourceCopy) {
      if (this.changedRows.length > 0) {
        this.loading = true
        let result
        // Every call need to be awaited because currently we do another call in the save rows that breaks when spreading the promises
        if (this.batchUpdate) {
          result = await this.saveRows(this.changedRows[0].action)
          this.changedRows[0].action = ''
        } else {
          for (let i = 0; i < this.changedRows.length; i++) {
            result = await this.saveRow(this.changedRows[i].rowData, this.changedRows[i].action)
          }
        }
        // let validResults = results.filter(result => !(result instanceof Error))
        this[this.tableName] = result
        await this.reFetchData() // Can't be sure all inserted rows are retrieved. Retrieve them again.
        this.insertedCount = 0
        this.changedRows = []

        // Set this in a try catch block because sometimes the find correct row can't be found
        try {
          this.focusedRow = {}
          this.reUpdateSelected()
        } catch (e) {
          console.error(e)
        }
        this.loading = false
      }
    },

    reUpdateSelected () {
      if (this.getFocusedRowKey()) {
        let updatedSelectedObject = this[this.tableName].find((table) => { return table[this.rowKeyName] === this.getFocusedRowKey() })
        if (updatedSelectedObject) {
          bus.$emit('updateSelected', {
            key: this.templateName,
            data: updatedSelectedObject
          })
        }
      }
    },

    loadScreenTemplate () {
      if (this.templateName === null) {
        console.error('tableBase templateName is null, not properly extended')
      }
      if (!this.isChildTable) {
        this.loading = true
      }

      return templateService.getDefaultFieldTemplate(this.templateName).then((result) => {
        if (!this.isChildTable) {
          this.loading = false
        }
        this.template = result
        this.initTemplate()
        this.retrieveDynamicCodeLists()
      }).catch(() => {
        if (!this.isChildTable) {
          this.loading = false
        }
      })
    },

    initTemplate () {
      let template = this.template
      this.controls = template.controls.map(control => {
        // leave one field
        if (control.fields.length > 1) {
          let tempfield = control.fields[0]
          let typeFields = typefields[tempfield.type]

          if (typeFields) {
            let generatedTypeField = typeFields.find(v => {
              let check = tempfield.key.indexOf(v.name) !== -1
              if (tempfield.title) { check = check && tempfield.title.indexOf(v.title) !== -1 }
              return check
            })
            if (generatedTypeField) {
              let raws = []
              control.fields.forEach(field => {
                let raw = {}
                raw.key = field.key
                raw.maxLength = field.maxLength
                raw.requiredBySystem = field.requiredBySystem
                raw.requiredByUser = field.requiredByUser
                raw.options = field.options
                raws.push(raw)
              })
              tempfield.raws = raws
              control.fields = [tempfield]
            }
          }
        }

        control.languageCode3Letter = stringUtils.convert2To3LetterLanguageCode(this.language)
        control.fields.forEach(field => {
          delete field.component
          let editor = getEditor(field.type || '*')
          field.component = editor.name
          field.tableEditorComponent = editor.tableEditorComponent || undefined
          field.editorOptions = editor.options
          field.inputControlOptions = control.inputControlOptions || {}
        })

        control.displayValueLookup = this.buildDisplayValueLookup(control)

        return control
      }).sort(this.orderControls)
    },

    async retrieveDynamicCodeLists () {
      // Get a complete list of all categories which need to be retrieved
      let categoriesToRetrieve = []
      this.controls.forEach((control) => {
        if (control.inputControlOptions.DynamicCodeListField) {
          let dynamicCodeListMapping = JSON.parse(control.inputControlOptions.DynamicCodeListMapping)
          Object.keys(dynamicCodeListMapping).forEach((key) => {
            categoriesToRetrieve.push(dynamicCodeListMapping[key])
          })
        }
      })
      categoriesToRetrieve = [...new Set(categoriesToRetrieve)]

      let promises = []
      // Create a promise to retrieve all codelists
      categoriesToRetrieve.forEach((categoryToRetrieve) => {
        let promise = codeListService.getCodeListByCategoryIDOrCode(categoryToRetrieve)
        promises.push(promise)
      })

      let promisesResponses = await Promise.all(promises)
      let allCodeLists = []
      promisesResponses.forEach((category) => {
        let dynamicCategory = {
          categoryId: category[0].GcGroup,
          options: []
        }
        category.forEach((option) => {
          let dynamicOption = {
            localizedCode: option.GcShortCode,
            localizedText: option.GcDescription,
            localizedText2: option.GcDescription2,
            value: option.GcCode
          }
          dynamicCategory.options.push(dynamicOption)
        })
        allCodeLists.push(dynamicCategory)
      })
      this.dynamicCodeLists = allCodeLists
    },

    buildDisplayValueLookup (control) {
      let firstField = control.fields[0]
      let editorOptions = firstField.editorOptions
      if ((!firstField.options) || (!editorOptions) || (!editorOptions.textFormat)) return null
      let result = {}
      firstField.options.forEach(function (option) {
        option.localizedCode = ''
        if (option.codeInterface && option.codeInterface[0]) {
          option.localizedCode = option.codeInterface[0][control.languageCode3Letter]
        }
        if (option.text && option.text[0]) {
          option.localizedText = option.text[0][control.languageCode3Letter]
        }
        if (editorOptions.textFormatKeys.includes('localizedText2') && option.text2 && option.text2[0]) {
          option.localizedText += ' - ' + option.text2[0][control.languageCode3Letter]
        }
        result[option.value] = editorOptions.textFormat(option)
      })
      return result
    }
  }
}
</script>
