<template>
  <div class="baseTable">
    <dx-data-grid
      v-if="controls"
      id="gridContainer"
      ref="tableGrid"
      :data-source="dataSource"
      :show-borders="true"
      :columns="calculatedColumns"
      :two-way-binding-enabled="false"
      :name="templateName"
      :column-auto-width="true"
      :allow-column-reordering="true"
      :allow-column-resizing="true"
      column-resizing-mode="widget"
      width="100%"
      height="100%"
      :focusedRowEnabled="true"
      :focused-row-key.sync="focusedRow"
      @exporting="onExporting"
      @toolbar-preparing="toolbarPreparing"
      @row-updated="rowUpdatedBase"
      @row-removed="rowRemovedBase"
      @row-inserted="rowInsertedBase"
      @init-new-row="onInitNewRow"
      @editor-preparing="editorPreparing"
      @focused-row-changing="onFocusedRowChanging"
      @focused-row-changed="onFocusedRowChanged"
      @selection-changed="onSelectionChanged"
      @row-dbl-click="onRowDoubleClick"
    >
      <DxMasterDetail v-if="useMasterDetail"
                      :enabled="useMasterDetail"
                      template="master-detail"
      />
      <template v-if="useMasterDetail" #master-detail="row">
        <slot :rowData="row"
              name="masterDetailTemplate"></slot>
      </template>
    <DxSelection mode="multiple"
      :show-check-boxes-mode="'none'"
    />
      <dx-state-storing
        :enabled="true"
        type="localStorage"
        :storage-key="templateName"
      />
      <dx-export :enabled="true" />
      <dx-column-chooser
        :enabled="true"
        mode="select"
      />
      <dx-scrolling
        mode="virtual"
        row-rendering-mode="standard"
      />
      <dx-paging
        :page-size="30"
        :enabled="false"
        />
      <dx-header-filter
        :visible="true"
      />
      <dx-editing
        ref="Editing"
        :allow-updating="true"
        start-edit-action="dblClick"
        mode="batch"
       />
      <DxGrouping :auto-expand-all="false"/>
      <DxGroupPanel :visible="true"/>
      <div
        slot="tableTitleTemplate"
        slot-scope="{}"
      ><h2>{{ tableTitle }}</h2>
      </div>
      <div slot="customButtonsRightStartTemplate" slot-scope="{}">
        <slot name="customButtonsRightStart"></slot>
      </div>
      <div slot="customButtonsRightEndTemplate" slot-scope="{}">
        <slot name="customButtonsRightEnd"></slot>
      </div>
      <DxSearchPanel :visible="true" :width="160" placeholder="Zoeken..."/>
    </dx-data-grid>
    <confirm-modal :show="showChildrenHaveChangesWarning" :title-label="$t('label.AreYouSure')" :confirm-button-label="$t('button.confirm')" :cancel-button-label="$t('button.cancel')" @close-modal="showChildrenHaveChangesWarning = false" @confirm-button-pressed="focusNextRow(true, true)" @cancel-button-pressed="focusPrevRow">{{$t('message.unsavedChanges')}}</confirm-modal>
    <confirm-modal :show="showUnsavedChangesWarning" :title-label="$t('label.AreYouSure')" :confirm-button-label="$t('button.confirm')" :cancel-button-label="$t('button.cancel')" @close-modal="showUnsavedChangesWarning = false" @confirm-button-pressed="focusNextRow()" @cancel-button-pressed="focusPrevRow">{{$t('message.invalidValues')}}</confirm-modal>
    <alert-modal :show="showValidationAlert" :title-label="$t('label.warn')" :confirm-button-label="$t('button.confirm')" @close-modal="showValidationAlert = false">{{$t('message.saveFailedInvalidValues')}}</alert-modal>
  </div>
</template>

<script>
import DataSource from 'devextreme/data/data_source'
import ArrayStore from 'devextreme/data/array_store'
import {
  DxDataGrid,
  DxEditing,
  DxStateStoring,
  DxExport,
  DxColumnChooser,
  DxHeaderFilter,
  DxScrolling,
  DxPaging,
  DxGrouping,
  DxGroupPanel,
  DxSearchPanel,
  DxSelection,
  DxMasterDetail
} from 'devextreme-vue/data-grid'

import deDevExtremeLocale from '@/locales/devExtreme/de'
import esDevExtremeLocale from '@/locales/devExtreme/es'
import frDevExtremeLocale from '@/locales/devExtreme/fr'
import itDevExtremeLocale from '@/locales/devExtreme/it'
import nlDevExtremeLocale from '@/locales/devExtreme/nl'
import { locale, loadMessages } from 'devextreme/localization'
import cookie from '@/utils/cacheProviders/cookieCacheProvider'
import tableInputControls from '@/components/table-input-controls'
import { bus } from '@/bus'
import ConfirmModal from '@/components/modal/confirmModal'
import AlertModal from '@/components/modal/alertModal'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { Workbook } from 'exceljs'
import saveAs from 'file-saver'
export default {
  name: 'dxDataGridWrapper',
  components: {
    DxDataGrid,
    DxEditing,
    DxStateStoring,
    DxExport,
    DxColumnChooser,
    DxHeaderFilter,
    DxScrolling,
    DxPaging,
    ConfirmModal,
    AlertModal,
    DxGrouping,
    DxGroupPanel,
    DxSearchPanel,
    DxSelection,
    DxMasterDetail
  },
  props: {
    data: Array,
    rowKey: String,
    selected: Object,
    readOnly: Boolean,
    projectId: Number,
    controls: Array,
    templateName: String,
    childTables: Array,
    rowKeyName: String,
    useMasterDetail: Boolean,
    masterDetailTemplate: String,
    tableTitle: String
  },
  created () {
    this.language = cookie.get('language')
    if (this.language !== 'en') {
      loadMessages(deDevExtremeLocale)
      loadMessages(esDevExtremeLocale)
      loadMessages(frDevExtremeLocale)
      loadMessages(itDevExtremeLocale)
      loadMessages(nlDevExtremeLocale)
      locale(this.language)
    }
  },
  mounted () {
    bus.$on('tableEditorClose', () => {
      if (this.$refs.tableGrid && this.$refs.tableGrid.instance) {
        this.$refs.tableGrid.instance.closeEditCell()
      }
    })
  },
  data () {
    return {
      language: null,
      focusedRow: null,
      showChildrenHaveChangesWarning: false,
      showUnsavedChangesWarning: false,
      showValidationAlert: false,
      nextRowToFocus: null,
      prevRowToFocus: null,
      triggerWatch: true,
      rowToBeAdded: null,
      showabc: false,
      showPopUp: false,
      editModus: 'batch',
      multiEditDataObject: {},
      ColumnsToUse: []
    }
  },
  computed: {
    dataSource () {
      return new DataSource({
        store: new ArrayStore({
          data: this.data,
          key: this.rowKey
        })
      })
    },
    calculatedColumns () {
      var columns = []
      this.controls.forEach((control) => {
        let canBuild = true
        let fullInputName = control.fields[0].tableEditorComponent || 'table' + this.firstLetterUpperCase(control.fields[0].component)
        let tableControl = tableInputControls[fullInputName]
        if (typeof tableControl === 'undefined') {
          canBuild = false
          console.warn('table-input-controls has no component for [' + fullInputName + ']')
        }
        if (canBuild && (typeof tableControl.buildColumnFromTemplate === 'undefined')) {
          canBuild = false
          console.error('table-input-controls component [' + fullInputName + '] has no public buildColumnFromTemplate function')
        }
        if (canBuild) {
          let column = tableControl.buildColumnFromTemplate(control)

          // Check if the field should be visible at the start or should be added to the Column picker
          if (control.inputControlOptions && (typeof (control.inputControlOptions.StartInColumnPicker) !== 'undefined')) {
            column.visible = !(control.inputControlOptions.StartInColumnPicker === 'true')
          }
          if (control.inputControlOptions && (typeof (control.inputControlOptions.SystemField) !== 'undefined')) {
            column.showInColumnChooser = !(control.inputControlOptions.SystemField === 'true')
          }
          // Check if the column should be read only
          if (control.inputControlOptions && (typeof (control.inputControlOptions.ReadOnly) !== 'undefined')) {
            column.allowEditing = !(control.inputControlOptions.ReadOnly === 'true')
          }
          columns.push(column) // this calls the buildColumnFromTemplate function for the correct control in the table-input-controls folder as specified by the template
        }
      })
      return columns
    }
  },
  methods: {
    onRowDoubleClick (e) {
      this.$emit('row-dbl-click', e)
    },
    onFocusedRowChanging (e) {
      if (e.prevRowIndex !== e.newRowIndex) {
        if (!this.childrenHaveChanges()) { // If there are no unsaved changes in the child tables update the selected object so the child tables retrieve new data.
          if (this.$parent.$refs.tableBase.hasChanges()) {
            if (this.allRowsValid()) {
              this.saveGridToDataservice()
            } else {
              this.showUnsavedChangesWarning = true
              this.prevRowToFocus = e.prevRowIndex
              this.nextRowToFocus = e.newRowIndex
              e.cancel = true
            }
          }
        } else {
          // If any child tables have changes don't updated the selected instantly because the data will be lost. Instead store both the old and new rows and let the user decide where to go in a modal
          this.showChildrenHaveChangesWarning = true
          this.prevRowToFocus = e.prevRowIndex
          this.nextRowToFocus = e.newRowIndex
          e.cancel = true
        }
      }
    },
    onSelectionChanged: function (e) {
      this.$emit('selectionChanged', e)
    },
    onFocusedRowChanged: function (e) {
      var data = e.row && e.row.data
      this.focusedRowKey = e.component.option('focusedRowKey')
      bus.$emit('updateSelected', {
        key: this.templateName,
        data
      })
    },
    firstLetterUpperCase (s) {
      return s.charAt(0).toUpperCase() + s.slice(1)
    },
    /* Checks if the table has changes */
    hasChanges () {
      return this.$refs.tableGrid.instance.hasEditData()
    },
    /* Checks if any of the child tables have changes */
    childrenHaveChanges () {
      let childHasChanges = false
      if (this.childTables && this.childTables.length > 0) {
        this.childTables.forEach((childTable) => {
          if (this.$parent.$parent.$refs[childTable][0].$refs.tableBase) {
            if (this.$parent.$parent.$refs[childTable][0].$refs.tableBase.hasChanges()) {
              childHasChanges = true
            }
          }
        })
      }
      return childHasChanges
    },
    /* Focuses the next row discarding any unsaved changes */
    focusNextRow (discardSelf, discardChildren) {
      if (discardSelf === true) {
        this.$refs.tableGrid.instance.cancelEditData()
      }
      if (discardChildren) {
        if (this.childTables && this.childTables.length > 0) {
          let self = this
          this.childTables.forEach((childTable) => {
            if (self.$parent.$parent.$refs[childTable][0].$refs.tableBase.$refs.tableGrid.instance) {
              self.$parent.$parent.$refs[childTable][0].$refs.tableBase.$refs.tableGrid.instance.cancelEditData()
            }
          })
        }
      }
      this.focusedRow = this.$refs.tableGrid.instance.getKeyByRowIndex(this.nextRowToFocus)
    },
    /* Focuses the previous row, the selected object never updated thus the unsaved changes are still available */
    focusPrevRow () {
      // Go focussing next row, this will activate rowChanging eevent, but wont do anything.
      this.focusedRow = this.$refs.tableGrid.instance.getKeyByRowIndex(this.prevRowToFocus)
    },

    editorPreparing (e) {
      e.editorOptions.valueChangeEvent = 'keyup'
    },

    /* Customize the toolbar with custom buttons and actions */
    toolbarPreparing (e) {
      e.toolbarOptions.items.forEach((item) => {
        if (item.name === 'saveButton') {
          item.options.onClick = () => {
            this.saveGridToDataservice()
          }
        }
      })

      e.toolbarOptions.items.unshift({
        location: 'before',
        template: 'tableTitleTemplate'
      })
      e.toolbarOptions.items.unshift({
        location: 'after',
        template: 'customButtonsRightStartTemplate'
      })
      e.toolbarOptions.items.push({
        location: 'after',
        template: 'customButtonsRightEndTemplate'
      })

      // hacky way to move the searchbar to the last
      const seachPanel = e.toolbarOptions.items.filter(item => item.name === 'searchPanel')[0]
      e.toolbarOptions.items = e.toolbarOptions.items.filter(item => item.name !== 'searchPanel')
      e.toolbarOptions.items.push(seachPanel)
    },

    rowRemovedBase (e) {
      this.$emit('ti-table-row-removed', e)
    },

    rowUpdatedBase (e) {
      this.$emit('ti-table-row-updated', e)
    },

    rowInsertedBase (e) {
      this.$emit('ti-table-row-inserted', e)
    },

    onInitNewRow (e) {
      e.data = { ...this.rowToBeAdded }
      this.rowToBeAdded = null
    },

    saveGridToDataservice () {
      if (this.allRowsValid()) {
        this.$emit('savingToDataSourceStarted')
        // Get our datasource and make a deep copy so that it can be merged when save fails
        let oldDataSourceDeepCopy = Object.assign({}, this.dataSource)
        this.$refs.tableGrid.instance.saveEditData()
          .then((res) => {
            this.$emit('savingToDataSourceFinished', oldDataSourceDeepCopy)
          })
          .catch(err => {
            console.error(err)
          })
      } else {
        this.showValidationAlert = true
      }
    },

    allRowsValid () {
      let elems = this.$el.querySelector('.dx-datagrid-invalid')
      if (elems === null) {
        return true
      } else {
        return false
      }
    },

    addRow (data) {
      this.rowToBeAdded = { ...data }
      this.$refs.tableGrid.instance.addRow()
      this.saveGridToDataservice()
      // Go focussing next row, this will activate rowChanging even to save current.
      this.focusedRow = data[this.rowKey]
    },

    deleteFocusedRow () {
      let tableGridInstance = this.$refs.tableGrid.instance
      tableGridInstance.getSelectedRowKeys().forEach(guid => {
        let rowIndex = tableGridInstance.getRowIndexByKey(guid)
        tableGridInstance.deleteRow(rowIndex)
      })
      this.saveGridToDataservice()
    },

    onExporting(event) {
      const workbook = new Workbook()
      const worksheet = workbook.addWorksheet('Sheet')
      exportDataGrid({
        component: event.component,
        worksheet: worksheet
      }).then(function() {
        workbook.xlsx.writeBuffer()
          .then(function(buffer) {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'OVAMOverview.xlsx')
          })
      })
    }
  }
}
</script>

<style lang="less">
#grid {
  height: 100%;
}

.dx-datagrid-header-panel{
  background-color: #eee;
}

.baseTable{
  height: 100%;
}

.baseTable .dx-toolbar{
  background-color: #eee;
}

.baseTable .dx-datagrid-rowsview .dx-row-removed > td {
  background-color: #fc8f8c !important;
  border-top: 1px solid #fc8f8c;
  border-bottom: 1px solid #fc8f8c;
}

.baseTable .dx-datagrid-rowsview .dx-row-inserted > td {
  background-color: #83e183;
  border-top: 1px solid #83e183;
  border-bottom: 1px solid #83e183;
}

.baseTable .dx-datagrid-rowsview .dx-cell-modified{
  background-color: #5cb85c !important;
}

.baseTable .dx-datagrid-rowsview .dx-datagrid-invalid{
  background-color: #d9534f !important;
}

.baseTable .dx-datagrid-header-panel .dx-button-mode-contained{
  border-radius: 0px;
  border: none;
  background-color: #67ac45;
  transition: 0.2s;
}

.baseTable .dx-button-mode-contained.dx-state-hover{
  background-color: #67ac45;
  transform: scale(0.92);
}

.dx-toolbar-text-auto-hide .dx-button .dx-icon{
  color: #fff;
}

.dx-button-mode-contained .dx-icon {
  color: #fff;
}

.tableTitle{
  color: #333;
  margin: 0px;
  padding: 0px;
  font-size: 1.2rem;
}

.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > td, .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > tr > td, .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover > td, .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover > tr > td {
    background-color: rgb(151, 214, 120); ;
    color: black;
}

.dx-datagrid-rowsview .dx-row-focused.dx-data-row:not(.dx-edit-row):not(.dx-row-lines) > td, .dx-datagrid-rowsview .dx-row-focused.dx-data-row:not(.dx-edit-row):not(.dx-row-lines) > tr:first-child > td {
    border-top: 1px solid rgb(103, 172, 69);
}

.dx-datagrid-rowsview .dx-row-focused.dx-data-row:not(.dx-edit-row) > td:not(.dx-focused), .dx-datagrid-rowsview .dx-row-focused.dx-data-row:not(.dx-edit-row) > tr > td:not(.dx-focused), .dx-datagrid-rowsview .dx-row-focused.dx-data-row:not(.dx-edit-row) .dx-command-edit .dx-link {
    background-color: rgb(103, 172, 69);
    color: black;
}

.dx-button-content > .dx-icon-close {
    color: black !important;
}

.duplicate.alert {
    position: absolute;
    width: auto;
    z-index: 99;
    transform: translate(-50%, 47px);
    left: 50%;
    background-image: none;
    background-color: #a3dcab;
    border-radius: 0;
    color: rgba(0,0,0,87);
    min-width: 400px;
    text-align: center
}

</style>
