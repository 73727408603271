<template>
  <div class="project-participants-data-table-overview">
    <div class="overview-table-row">
      <div class="overview-table">
        <ProjectParticipantsTable
          :projectId="$route.params.projectId"
          :projectCode="projectCode"
        />
      </div>
    </div>
    <div class="overview-table-row">
      <div class="overview-table">
        <ConcernedContactsTable
          :projectId="$route.params.projectId"
          :projectCode="projectCode"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  ProjectParticipantsTable,
  ConcernedContactsTable
} from '@/components/dynamicTables/tables'
import Popup from '@/utils/popup'
import Config from '@/configurations/config'
import stringUtils from '@/utils/stringUtils'

const TiPopup = Popup[Config.platform].default

export default {
  name: 'projectParticipantsOverview',
  components: {
    ProjectParticipantsTable,
    ConcernedContactsTable
  },
  data() {
    return {
      selectedMeasurementObject: {},
      projectCode: ''
    }
  },
  async created() {
    await this.setCurrentProject()
  },
  methods: {
    async setCurrentProject() {
      if (stringUtils.isNullOrEmptyOrWhitespace(this.$route.params.projectId)) {
        TiPopup.popup(this.$t('message.projectNotFound'))
        return
      }

      await this.$store.dispatch('fetchProject', {
        PrID: this.$route.params.projectId
      })
      const project = this.$store.state.currentProject

      if (project && project.PrID) {
        this.projectCode = project.PrCode
      } else {
        TiPopup.popup(this.$t('message.projectNotFound'))
      }
    }
  }
}
</script>

<style>
.project-participants-data-table-overview h2 {
  margin-top: 5px;
  font-size: 20px;
}
.project-participants-data-table-overview {
  height: 100%;
}
.project-participants-data-table-overview .overview-table-row {
  height: 50%;
  display: flex;
}
.project-participants-data-table-overview .overview-table-row:nth-child(2) {
  padding-top: 10px;
}
.project-participants-data-table-overview
  .overview-table-row
  .overview-table:first-child {
  width: 100%;
}
.project-participants-data-table-overview .overview-table {
  overflow: hidden;
}
.project-participants-data-table-overview
  .overview-table-row
  .overview-table
  > div {
  margin-left: 10px;
}
.project-participants-data-table-overview .overview-table > div,
.project-participants-data-table-overview .overview-table > div > div {
  height: 100%;
}
.project-participants-data-table-overview .overview-table-row > div,
.project-participants-data-table-overview .overview-table-row > div > div {
  height: 100%;
}
</style>
