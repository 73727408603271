<template>
  <div>
    <TerraIndexDynamicTable
      scrolling-mode="infinite"
      :default-selected-row-index="0"
      :enable-column-chooser="enableColumnChooser"
      :excel-export-settings="exportSettings"
      :search-panel-enabled="searchPanelEnabled"
      :is-valid-request="isValidRequest"
      :language="language"
      :on-row-validating="customRowValidation"
      :project-id="projectId"
      :setting-name="settingName"
      :state-storing="{}"
      :table-data="tableData"
      :table-title="$t('label.bottles')"
      :table-data-identifier="tableIdentifier"
      :table-create-action="createTableRowAction"
      :table-update-action="updateTableRowAction"
      :table-delete-action="deleteTableRowAction"
      :table-multi-update-action="multiUpdateTableRowAction"
      :table-columns="tableColumns"
      :table-name="tableName"
      :template="template"
      :use-multi-select="true"
      @refresh-table-data="refreshTableData"
    />
  </div>
</template>

<script>
import TerraIndexDynamicTable from '@/components/dynamicTables/TerraIndexDynamicTable'
import GuiTemplateParser from '@/components/dynamicTables/GuiTemplateParser'
import Cookie from '@/utils/cacheProviders/cookieCacheProvider'
import stringUtils from '@/utils/stringUtils'
import Popup from '@/utils/popup'
import Config from '@/configurations/config'
import DataTableUtils from '@/utils/dataTableUtils'

const TiPopup = Popup[Config.platform].default

export default {
  name: 'bottlesTable',
  components: {
    TerraIndexDynamicTable
  },
  data() {
    return {
      tableData: [],
      tableColumns: [],
      tableName: 'tblBottles',
      tableNameForExport: 'tblBottles',
      tableIdentifier: 'BoGuid',
      dateLastChangedField: 'BoDateLastChanged',
      templateName: 'frmBottleGrid',
      template: {},
      settingName: 'Bottle_Settings'
    }
  },
  async created() {
    await this.getTableColumns()
  },
  props: {
    projectId: {
      type: String,
      required: true
    },
    projectCode: {
      type: String,
      required: true
    },
    selectedMeasurementObject: {
      type: Object,
      required: true
    },
    selectedGaugingTube: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    },
    selectedWaterSample: {
      type: Object,
      required: true
    },
    enableCreateTableRowAction: {
      required: false,
      default() {
        return true
      }
    },
    enableDeleteTableRowAction: {
      required: false,
      default() {
        return true
      }
    },
    enableUpdateTableRowAction: {
      required: false,
      default() {
        return true
      }
    },
    enableColumnChooser: {
      required: false,
      default() {
        return true
      }
    },
    searchPanelEnabled: {
      required: false,
      default() {
        return true
      }
    },
    enableExportSettings: {
      required: false,
      default() {
        return true
      }
    }
  },
  watch: {
    projectId: {
      deep: true,
      immediate: true,
      handler: async function () {
        await this.refreshTableData()
      }
    },
    selectedMeasurementObject: {
      deep: true,
      immediate: true,
      handler: async function () {
        await this.refreshTableData()
      }
    },
    selectedGaugingTube: {
      deep: true,
      immediate: true,
      handler: async function () {
        await this.refreshTableData()
      }
    },
    selectedWaterSample: {
      deep: true,
      immediate: true,
      handler: async function () {
        await this.refreshTableData()
      }
    }
  },
  computed: {
    createTableRowAction() {
      return { active: this.enableCreateTableRowAction, method: this.createTableRow }
    },
    updateTableRowAction() {
      return { active: this.enableUpdateTableRowAction, method: this.updateTableRow }
    },
    deleteTableRowAction() {
      return { active: this.enableDeleteTableRowAction, method: this.deleteTableRow }
    },
    language() {
      return Cookie.get('language')
    },
    exportSettings() {
      return {
        enabled: this.enableExportSettings,
        fileName: `${this.projectCode}_${this.tableNameForExport}`
      }
    },
    isValidRequest() {
      return this.isProjectIdSet && this.isMeasurementObjectGuidSet && this.isWaterSampleGuidSet
    },
    selectedMeasurementObjectGuid() {
      return this.selectedMeasurementObject && this.selectedMeasurementObject.MpGuid ? this.selectedMeasurementObject.MpGuid : ''
    },
    selectedGaugingTubeGuid() {
      return this.selectedGaugingTube && this.selectedGaugingTube.FtGuid ? this.selectedGaugingTube.FtGuid : ''
    },
    selectedWaterSampleGuid() {
      return this.selectedWaterSample ? this.selectedWaterSample.WsGuid : ''
    },
    isProjectIdSet() {
      return stringUtils.isNullOrEmptyOrWhitespace(this.projectId) === false
    },
    isMeasurementObjectGuidSet() {
      return stringUtils.isNullOrEmptyOrWhitespace(this.selectedMeasurementObjectGuid) === false
    },
    isGaugingTubeGuidSet() {
      return stringUtils.isNullOrEmptyOrWhitespace(this.selectedGaugingTubeGuid) === false
    },
    isWaterSampleGuidSet() {
      return stringUtils.isNullOrEmptyOrWhitespace(this.selectedWaterSampleGuid) === false
    },
    multiUpdateTableRowAction() {
      const request = {
        active: true,
        projectId: this.projectId,
        tableName: this.tableName,
        dateLastChangedField: this.dateLastChangedField,
        tblMeasurementPoints: [this.selectedMeasurementObject],
        tblWaterSamples: [this.selectedWaterSample]
      }

      if (this.isGaugingTubeGuidSet) {
        request.tblGaugingTubes = [this.selectedGaugingTube]
      }

      return request
    }
  },
  methods: {
    customRowValidation(event) {
      if (event.isValid === false) {
        return
      }

      if (event.newData.BoName) {
        event.isValid = this.validateNameIsUnique(event)

        if (event.isValid === false) {
          event.errorText = this.$t('message.nameShouldUnique')
        }
      }
    },
    validateNameIsUnique(event) {
      const validationContext = {
        dataTable: this.tableData,
        propertyToValidate: 'BoName',
        guidToValidate: this.tableIdentifier,
        event: event
      }
      return DataTableUtils.ValidatePropertyIsUniqueInDataTable(validationContext)
    },
    async createTableRow(row) {
      try {
        const record = {
          ...row.data
        }

        const bottlesTable = await this.$store.dispatch('createBottle', {
          bottles: [record],
          projectId: this.projectId,
          measurementPointGuid: this.selectedMeasurementObjectGuid,
          waterSampleGuid: this.selectedWaterSampleGuid,
          gaugingTubeGuid: this.selectedGaugingTubeGuid
        })

        this.tableData = bottlesTable.tblBottles
      } catch (error) {
        await this.handleErrorResponse(error)
      }
    },
    async updateTableRow(row) {
      try {
        const record = {
          ...row.data,
          [this.dateLastChangedField]: await DataTableUtils.GenerateLastUpdatedAt()
        }

        const request = {
          bottles: [record],
          projectId: this.projectId,
          measurementPointGuid: this.selectedMeasurementObjectGuid,
          waterSampleGuid: this.selectedWaterSampleGuid,
          filterTubeGuid: this.selectedGaugingTubeGuid
        }

        await this.$store.dispatch('updateBottle', request)
      } catch (error) {
        await this.handleErrorResponse(error)
      }
    },
    async deleteTableRow(key) {
      try {
        await this.$store.dispatch('deleteBottle', {
          bottleGuid: key,
          projectId: this.projectId,
          measurementPointGuid: this.selectedMeasurementObjectGuid,
          gaugingTubeGuid: this.selectedGaugingTubeGuid,
          waterSampleGuid: this.selectedWaterSampleGuid
        })
      } catch (error) {
        await this.handleErrorResponse(error)
      }
    },
    async refreshTableData() {
      if (this.isValidRequest) {
        const response = await this.getTableData()
        const handleTableDataResponseResult = await this.handleGetTableDataResponse(response)
        this.tableData = handleTableDataResponseResult.tableData
      } else {
        await this.clearTableData()
      }
    },
    async clearTableData() {
      this.tableData = []
    },
    async getTableData() {
      try {
        const request = {
          projectId: this.projectId,
          measurementPointGuid: this.selectedMeasurementObjectGuid,
          waterSampleGuid: this.selectedWaterSampleGuid,
          gaugingTubeGuid: this.selectedGaugingTubeGuid
        }

        return await this.$store.dispatch('getBottles', request)
      } catch (error) {
        await this.handleErrorResponse(error)
      }
    },
    async handleGetTableDataResponse(response) {
      const result = {
        tableData: []
      }

      if (!response || response.length < 1) {
        return result
      }
      result.tableData = response
      return result
    },
    async getTableColumns() {
      try {
        this.tableColumns = await this.$store
          .dispatch('getDefaultFieldTemplate', {
            templateName: this.templateName
          })
          .then((templateData) => {
            this.template = templateData
            return GuiTemplateParser.parseTemplate(templateData)
          })
      } catch (error) {
        await this.handleErrorResponse(error)
      }
    },
    async handleErrorResponse(error, errorMessage = 'message.UnknownError') {
      console.log(error)
      TiPopup.popup(this.$t(errorMessage))
    }
  }
}
</script>
