<template>
  <TerraIndexDynamicTable
    :tableData="tableData"
    :tableTitle="tableTitle"
    :tableDataIdentifier="tableIdentifier"
    :tableCreateAction="createTableRowAction"
    :tableDeleteAction="deleteTableRowAction"
    :tableMultiUpdateAction="multiUpdateTableRowAction"
    :tableUpdateAction="updateTableRowAction"
    :hasSaveAndRevertButtons="saveAndRevertButtonsEnabled"
    :enableColumnChooser="columnChooserEnabled"
    :enableHeaderFilter="headerFilterEnabled"
    :enableGrouping="headerGroupingEnabled"
    :searchPanelEnabled="searchPanelEnabled"
    :useMultiSelect="multiSelectEnabled"
    :tableColumns="tableColumns"
    :tableName="tableName"
    :template="template"
    :settingName="settingName"
    :language="language"
    :excelExportSettings="exportSettings"
    :state-storing="stateStoring"
    :defaultSelectedRowIndex="defaultSelectedRowIndex"
    :project="project"
    :isValidRequest="isValidRequest"
    :onRowValidating="customTableRowDataValidation"
    :scrollingMode="scrollingMode"
    :additionalHeaderButtons="additionalHeaderButtons"
    :getClassNameForCell="getClassNameForLabStatusCode"
    :checkForFieldDataType="checkForLabStatusCell"
    forceShowTable
    @row-dbl-click="onRowDoubleClick"
    @updateSelectedRows="updateSelectedRows"
    @settings-loaded="onSettingsLoaded"
    @refreshTableData="refreshData"
  />
</template>

<script>
import BaseProjectTable from '@/components/dynamicTables/tables/baseProjectTable'
import TerraIndexDynamicTable from '@/components/dynamicTables/TerraIndexDynamicTable'
import config from '@/configurations/config-environment'
import GuiTemplateParser from '@/components/dynamicTables/GuiTemplateParser'
import translation from '@/configurations/app/config-locales'
import labAssignmentUtils from '@/business/libs/labassignments'
import labStatusCodeUtils from '@/utils/labStatusCodeUtils'
export default {
  name: 'labAssignmentsTable',
  extends: BaseProjectTable,
  components: {
    TerraIndexDynamicTable
  },
  data() {
    return {
      tableName: 'tblLabAssignments',
      tableNameForExport: 'tblLabAssignments',
      tableIdentifier: 'LbID',
      tableTitle: this.$t('tableTitles.LabAssignments'),
      dateLastChangedField: 'LbDateLastChanged',
      templateName: '<template-not-available>',
      settingName: 'LabAssignments_Settings',
      tableData: [],
      createTableRowActionEnabled: false,
      deleteTableRowActionEnabled: false,
      multiUpdateTableRowActionEnabled: true,
      updateTableRowActionEnabled: false,
      saveAndRevertButtonsEnabled: false,
      columnChooserEnabled: true,
      headerFilterEnabled: true,
      headerGroupingEnabled: false,
      multiSelectEnabled: false,
      searchPanelEnabled: true,
      exportEnabled: true,
      scrollingMode: 'infinite',
      defaultSelectedRowIndex: 0,
      stateStoring: {},
      additionalHeaderButtons: [
        {
          locateInMenu: 'auto',
          location: 'after',
          name: 'addRowButton',
          options: {
            disabled: false,
            icon: 'edit-button-addrow',
            onClick: () => window.open(this.getLabAssignmentLink, '_blank'),
            onInitialized: this.onDuplicateButtonInitialized,
            hint: this.$t('button.create'),
            text: this.$t('button.create')
          },
          showText: 'inMenu',
          sortIndex: 22,
          widget: 'dxButton'
        }
      ],
      labAssignemtStatusCodeList: []
    }
  },
  computed: {
    isValidRequest() {
      return this.isProjectIdSet
    },
    getLabAssignmentLink() {
      return config.apps.labassignment + '1?PrID=' + this.$route.params.projectId + '&create=true'
    },

    /**
     * @returns {Array<CodeObject>}
     */
    matrixCodeMap() {
      return [
        { id: 1, code: 'GR', description: 'ground' },
        { id: 2, code: 'GW', description: 'groundwater' },
        { id: 3, code: 'AW', description: 'waste water' },
        { id: 4, code: 'OW', description: 'oppervlaktewater' },
        { id: 5, code: 'SL', description: 'water-floor' },
        { id: 6, code: 'OV', description: 'other' }
      ]
    }
  },
  methods: {
    getClassNameForLabStatusCode(labAssignmentData) {
      return labStatusCodeUtils.getClassNameForLabStatusCode(labAssignmentData, this.labAssignemtStatusCodeList)
    },
    checkForLabStatusCell(cellFieldType) {
      return labStatusCodeUtils.checkForLabStatusCell(cellFieldType)
    },
    onRowDoubleClick(e) {
      const id = e.data.LbID
      if (!id) {
        return
      }
      const url = `${config.apps.labassignment}6?PrID=${e.data.PrID}&LbID=${id}&clear=true&saved=true`
      window.open(url, '_blank')
    },
    /**
     * @param {number} codeId
     * @returns {CodeObject}
     */
    getCodeObjectFromCodeId(codeMap, codeId) {
      const codeObject = codeMap.find((element) => element.id === codeId)
      return codeObject || { id: 0, code: '', description: '' }
    },
    /** @override This table in particular shouldn't load template data without the settings being available
     * to prevent race conditions, which cause the settings to be reset if the table data is loaded _after_
     * the column settings are loaded.
     */
    onCreated() {},
    onSettingsLoaded(settings) {
      this.setTemplate(settings)
    },
    async setTemplate(settings) {
      // template not supported for lab assignments
      await this.setTableColumns(settings)
    },
    async setTableColumns(settings) {
      const columnDefinitions = this.getColumnDefinitions()
      const columnOperations = []
      const columns = []
      columnDefinitions.forEach((columnDefinition) => {
        const columnOperation = new Promise((resolve, reject) => {
          const column = {
            caption: this.$t(`columnTitles.labAssignments.${columnDefinition.key}`),
            dataFieldKey: `Lb${columnDefinition.key}`,
            dataType: columnDefinition.dataType,
            allowEditing: false,
            validations: {}
          }

          switch (column.dataFieldKey) {
            case 'LbIsSent':
              column.calculateCellValue = this.calculateCellValueForLbIsSent
              break

            case 'LbSikbLabCode':
              column.calculateCellValue = this.calculateCellValueForLbSikbLabCode
              break

            case 'LbSikbLabMatrixCode':
              column.calculateCellValue = this.calculateCellValueForLbSikbMatrixLabCode
              break
          }

          if (columnDefinition.categoryId) {
            this.$store
              .dispatch('getCodeListByCategoryIDOrCode', {
                categoryId: columnDefinition.categoryId
              })
              .then((response) => {
                if (response && Array.isArray(response) && response.length > 0) {
                  column.validations.dxLookup = true
                  column.validations.dxLookupValidationProperties = {
                    options: GuiTemplateParser.convertGcOptionsToDxOptions(response),
                    displayProperty: 'description',
                    valueProperty: 'key'
                  }

                  columns.push(column)
                }
                resolve()
              })
          } else {
            columns.push(column)
            resolve()
          }
        })

        columnOperations.push(columnOperation)
      })

      // Should only sort these columns if the current user has settings for this table.
      // If there are no settings available, a default order will be used to seed the settings table.
      if (settings.columns && settings.columns.length > 0) {
        columns.sort((a, b) => {
          const foundColumnA = settings.columns.find((column) => column.dataField === a.dataFieldKey)
          const foundColumnB = settings.columns.find((column) => column.dataField === b.dataFieldKey)

          if (!foundColumnB) {
            return 1 // A comes first
          }

          if (!foundColumnA) {
            return -1 // B comes first
          }

          return foundColumnA.visibleIndex - foundColumnB.visibleIndex
        })

        columns[0].sortIndex = 0
        columns[0].sortOrder = 'desc'
      }

      await Promise.all(columnOperations)

      this.tableColumns = columns
    },
    getColumnDefinitions() {
      return [
        // These fields exist in lab assignment data but should not be shown to users:
        // 'PrID',
        // 'LbGuid'
        { key: 'ID', dataType: 'number' },
        { key: 'SikbLabCode', dataType: 'string' },
        { key: 'Date', dataType: 'date' },
        { key: 'IsSent', dataType: 'string' },
        { key: 'CertificateCode', dataType: 'string' },
        // { key: 'CertificateAddition', dataType: 'string' },
        { key: 'FieldMatrixCode', dataType: 'string', categoryId: 253 },
        { key: 'CustomerCode', dataType: 'string' },
        // { key: 'PriceAgreementCode', dataType: 'string' },
        { key: 'UrgencyDescription', dataType: 'string' },
        { key: 'ProjectLeader', dataType: 'string' },
        { key: 'ProjectLeaderEmail', dataType: 'string' },
        { key: 'OrderNr', dataType: 'string' },
        { key: 'ReservationNumber', dataType: 'number' },
        { key: 'LabCertificateNumber', dataType: 'string' },
        { key: 'Remarks', dataType: 'string' },
        // pdf link (?): starting points: LbLabCertificateNumber, LbCertificateCode, LbCertificateAddition Lab repo: getLabCertificate() // n2h
        // { key: 'CustomerDescription', dataType: 'string' }, // n2h
        { key: 'LabStatusCode', dataType: 'string' }
        // { key: 'UrgencyCode', dataType: 'string' },
        // { key: 'SampleDeliveryDate', dataType: 'date' },
        // { key: 'SampleTransportationNr', dataType: 'number' }
      ]
    },
    calculateCellValueForLbIsSent(row) {
      return row && row.LbIsSent && (row.LbIsSent === 'true' || row.LbIsSent === true) ? this.$t('toggle.yes') : this.$t('toggle.no')
    },
    calculateCellValueForLbLabStatusCode(row) {
      if (!row || !row.LbLabStatusCode) return ''
      return row.LbLabStatusCode ? row.LbLabStatusCode : ''
    },
    calculateCellValueForLbSikbLabCode(row) {
      if (!row || !row.LbSikbLabCode) return ''
      return this.getCodeObjectFromCodeId(labAssignmentUtils.labCodeMap(), parseInt(row.LbSikbLabCode, 10)).description
    },
    calculateCellValueForLbSikbMatrixLabCode(row) {
      if (!row || !row.LbSikbLabCode) return ''
      return this.getCodeObjectFromCodeId(this.matrixCodeMap, parseInt(row.LbSikbLabMatrixCode, 10)).description
    },
    async refreshData() {
      if (this.isValidRequest) {
        const response = await this.getData()
        if (response) {
          this.tableData = response
        } else {
          this.clearData()
        }
      } else {
        this.clearData()
      }
    },
    async getData() {
      try {
        if (this.labAssignemtStatusCodeList.length <= 0) {
          this.labAssignemtStatusCodeList = await this.$store.dispatch('getCodeListByCategoryIDOrCode', { categoryId: 62 })
        }

        await this.$store.dispatch('fetchProjectWithAllLabData', {
          projectId: this.$route.params.projectId
        })

        const labAssignments = this.$store.getters.getLabAssignments
        labAssignments.sort((a, b) => b.LbID - a.LbID)
        labAssignments.forEach(labassignment => {
          labassignment = this.setLabasignmentState(labassignment)
        })
        if (labAssignments && Array.isArray(labAssignments)) {
          return labAssignments
        }
        return false
      } catch (error) {
        await this.handleErrorResponse(error)
      }
    },
    setLabasignmentState(labassignment) {
      let state = translation.t('project.LabAssignmentNotSent')
      if (labassignment.LbIsSent === 'true') {
        state = translation.t('project.LabAssignmentSent')
      }
      if (labassignment.LbLabStatusCode) {
        const foundCode = this.labAssignemtStatusCodeList.find((labAssignemtStatusCode) => {
          return labAssignemtStatusCode.GcCode === labassignment.LbLabStatusCode.toString()
        })
        if (foundCode) {
          state = foundCode.GcDescription
        }
      }
      labassignment.LbLabStatusCode = state
      return labassignment
    }
  }
}
</script>
