<script>
import Vue from 'vue'
import Cookie from '@/utils/cacheProviders/cookieCacheProvider'
import Popup from '@/utils/popup'
import Config from '@/configurations/config'
import GuiTemplateParser from '@/components/dynamicTables/GuiTemplateParser'
import TerraIndexDynamicTable from '@/components/dynamicTables/TerraIndexDynamicTable'
import stringUtils from '@/utils/stringUtils'
import DataTableUtils from '@/utils/dataTableUtils'

const TiPopup = Popup[Config.platform].default

export default {
  name: 'baseProjectTable',
  extends: Vue,
  components: {
    TerraIndexDynamicTable,
    TiPopup
  },
  data() {
    return {
      tableData: [],
      tableName: 'baseProjectTable',
      tableColumns: [],
      tableIdentifier: '',
      tableTitle: '',
      dateLastChangedField: '',
      template: {},
      templateName: '',
      createTableRowActionEnabled: false,
      deleteTableRowActionEnabled: false,
      multiUpdateTableRowActionEnabled: false,
      updateTableRowActionEnabled: false,
      hasSaveAndRevertButtons: false,
      enableColumnChooser: false,
      enableHeaderFilter: false,
      searchPanelEnabled: false,
      exportEnabled: false
    }
  },
  props: {
    project: {
      type: Object,
      required: true
    },
    updateSelectedRows: {
      type: Function,
      required: false,
      default() {
        return () => {}
      }
    }
  },
  // cannot be overridden; override onCreated instead
  async created() {
    if (!this.tableName) {
      throw new Error('NotImplementedException for this.tableName')
    }
    if (!this.tableTitle) {
      throw new Error('NotImplementedException for this.tableTitle')
    }
    if (!this.templateName) {
      throw new Error('NotImplementedException for this.templateName')
    }
    if (!this.tableIdentifier) {
      throw new Error('NotImplementedException for this.tableIdentifier')
    }
    if (this.hasSaveAndRevertButtons && !this.updateTableRowActionEnabled) {
      throw new Error(
        'this.hasSaveAndRevertButtons is enabled while this.updateTableRowActionEnabled is disabled'
      )
    }
    if (
      (this.updateTableRowActionEnabled ||
        this.multiUpdateTableRowActionEnabled) &&
      !this.dateLastChangedField
    ) {
      throw new Error(
        'this.updateTableRowActionEnabled or this.multiUpdateTableRowActionEnabled is enabled while this.dateLastChangedField is disabled'
      )
    }

    await this.onCreated()
  },
  watch: {
    project: {
      deep: true,
      immediate: true,
      handler: async function (value) {
        if (value && value.PrID) {
          await this.refreshData()
        }
      }
    }
  },
  computed: {
    isValidRequest() {
      return false
    },
    createTableRowAction() {
      return {
        active: this.createTableRowActionEnabled,
        method: this.createTableRow
      }
    },
    deleteTableRowAction() {
      return {
        active: this.deleteTableRowActionEnabled,
        method: this.deleteTableRow
      }
    },
    multiUpdateTableRowAction() {
      return { active: this.multiUpdateTableRowActionEnabled }
    },
    updateTableRowAction() {
      return {
        active: this.updateTableRowActionEnabled,
        method: this.updateTableRow
      }
    },
    exportSettings() {
      const projectCode =
        this.project && this.project.PrCode ? this.project.PrCode : 'unknown'

      return {
        enabled: this.exportEnabled,
        fileName: `${projectCode}_${this.tableName}`
      }
    },
    language() {
      return Cookie.get('language')
    },
    isProjectIdSet() {
      return (
        this.project &&
        stringUtils.isNullOrEmptyOrWhitespace(this.project.PrID) === false
      )
    }
  },
  methods: {
    async onCreated() {
      await this.setTemplate()
    },
    async createTableRow(row) {
      throw new Error('NotImplementedException for createTableRow')
    },
    async updateTableRow(changes) {
      throw new Error('NotImplementedException for updateTableRow')
    },
    async deleteTableRow(row) {
      throw new Error('NotImplementedException for deleteTableRow')
    },
    async getData() {
      throw new Error('NotImplementedException for getData')
    },
    async refreshData() {
      if (this.isValidRequest === false) {
        this.clearData()
        return
      }

      const response = await this.getData()
      if (response) {
        this.handleGetDataResponse(response)
      } else {
        this.clearData()
      }
    },
    handleGetDataResponse(response) {
      this.tableData = response
    },
    clearData() {
      this.tableData = []
    },
    async setTemplate() {
      const response = await this.getTemplate()
      if (response) {
        this.template = response.template
        this.tableColumns = response.tableColumns
      }
    },
    getTemplateRequest() {
      return {
        templateName: this.templateName
      }
    },
    async getTemplate() {
      try {
        const response = await this.$store.dispatch(
          'getDefaultFieldTemplate',
          this.getTemplateRequest()
        )

        if (!response) {
          throw new Error(
            `Template '${this.templateName}' not found for table '${this.tableName}'`
          )
        }

        const template = await this.alterTemplate(response)
        let tableColumns = GuiTemplateParser.parseTemplate(template)
        tableColumns = await this.alterTableColumns(tableColumns)

        return {
          tableColumns: tableColumns,
          template: template
        }
      } catch (error) {
        this.handleErrorResponse(error)
      }
    },
    async alterTemplate(template) {
      return template
    },
    async alterTableColumns(tableColumns) {
      return tableColumns
    },
    async validatePropertyValueIsUnique(propertyName, event, resolve) {
      const response = await this.getData()
      if (!response) {
        return
      }

      const foundItems = response.filter((row) => {
        return (
          row[propertyName].toLowerCase().trim() ===
          event.newData[propertyName].toLowerCase().trim()
        )
      })

      if (foundItems.length > 1) {
        resolve({
          isValid: false,
          errorText: this.$t('message.nameShouldUnique')
        })
      }

      if (
        foundItems.length === 1 &&
        (!event.oldData ||
          JSON.stringify(event.oldData) !== JSON.stringify(foundItems[0]))
      ) {
        resolve({
          isValid: false,
          errorText: this.$t('message.nameShouldUnique')
        })
      }
    },
    handleErrorResponse(error, errorMessage = 'message.UnknownError') {
      console.error(error)
      TiPopup.popup(this.$t(errorMessage))
    },
    prepareTableRowDataForCreate(data) {
      const record = {
        ...data,
        [this.dateLastChangedField]: DataTableUtils.GenerateLastUpdatedAt()
      }

      return record
    },
    prepareTableRowDataForDelete(data) {
      const record = {
        projectId: this.project.PrID,
        [this.tableIdentifier]: data.id
      }

      return record
    },
    prepareTableRowDataForUpdate(change) {
      const record = {
        ...this.getTableRowDataByRowKey(this.tableData, change.key),
        ...change.data,
        [this.dateLastChangedField]: DataTableUtils.GenerateLastUpdatedAt()
      }

      return record
    },
    getTableRowDataByRowKey(tableData, key) {
      return tableData.find((row) => {
        return row[this.tableIdentifier] === key
      })
    },
    async customTableRowDataValidation(event) {}
  }
}
</script>

<style scoped>
.dx-datagrid-search-panel {
  margin-left: 0px;
}
</style>
