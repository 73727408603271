<template>
  <div id="dxwrapper-width">
    <DxDataGrid
      id="grid"
      :data-source="dataSet"
      :column-auto-width="true"
      :allow-column-reordering="true"
      :show-borders="true"
      :height="height"
      @exporting="onExporting"
      @toolbar-preparing="onToolbarPreparing"
    >
      <template #tableTitleTemplate>
        <h2>{{ tableTitle }} ({{ amountOfRecords }})</h2>
      </template>
      <DxExport :enabled="true" />
      <DxSelection mode="single" :show-check-boxes-mode="'none'" />
      <DxSorting mode="multiple" />
      <DxHeaderFilter :visible="true" />
      <DxColumnChooser mode="select" :enabled="true" />
      <DxSearchPanel :visible="true" :width="240" />
      <dx-scrolling mode="virtual" row-rendering-mode="standard" />
      <dx-paging :page-size="30" :enabled="false" />
      <DxColumn
        v-for="col in visibleColumns()"
        :key="'static-column-' + col.dataField"
        :caption="translations.t(col.title)"
        :data-field="col.dataField"
        :data-type="getDataType(col.dataField)"
        :style="getDataType(col.dataField) === 'hide' ? 'display: none;' : ''"
      />
      <DxGrouping :auto-expand-all="false" />
      <DxGroupPanel :visible="true" />
      <DxColumn
        v-for="banner in banneredColumnHeaders"
        :key="banner.bannerIdentification"
        :caption="banner.title"
      >
        <DxColumn
          v-for="col in visibleBannerColumns(banner.bannerIdentification)"
          :key="col.dataField"
          :caption="translations.t(col.title)"
          :data-field="col.dataField"
          :data-type="getDataType(col.dataField)"
        />
      </DxColumn>
      <DxMasterDetail
        v-if="userMasterDetail"
        :enabled="true"
        template="masterDetailTemplate"
      />
      <template v-if="userMasterDetail" #masterDetailTemplate="{ data: rd }">
        <div v-if="masterDetailTemplates.length > 1">
          <ul class="nav nav-tabs">
            <template v-for="(template, index) in masterDetailTemplates">
              <li
                v-if="shouldShow(template.conditions, rd)"
                :key="rd.data[dataKey] + '-' + template.componentName + '-tab'"
                :class="index === 0 ? 'active' : ''"
              >
                <a
                  data-toggle="tab"
                  :href="'#' + rd.data[dataKey] + '-' + template.componentName"
                  >{{ $t(template.titleTranslationKey) }}</a
                >
              </li>
            </template>
          </ul>

          <div class="tab-content">
            <template v-for="(template, index) in masterDetailTemplates">
              <div
                v-if="shouldShow(template.conditions, rd)"
                :id="rd.data[dataKey] + '-' + template.componentName"
                :key="
                  rd.data[dataKey] + '-' + template.componentName + '-content'
                "
                :class="
                  index === 0 ? 'tab-pane fade in active' : 'tab-pane fade'
                "
              >
                <div
                  :is="template.componentName"
                  :key="template.componentName"
                  :rowData="rd"
                ></div>
              </div>
            </template>
          </div>
        </div>
        <div v-else>
          <template v-for="template in masterDetailTemplates">
            <div
              :is="template.componentName"
              :key="template.componentName"
              :rowData="rd"
            ></div>
          </template>
        </div>
      </template>
    </DxDataGrid>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxSearchPanel,
  DxColumnChooser,
  DxMasterDetail,
  DxHeaderFilter,
  DxSelection,
  DxGrouping,
  DxGroupPanel,
  DxScrolling,
  DxPaging,
  DxSorting,
  DxExport
} from 'devextreme-vue/data-grid'

import { fieldTypeIdentifiers } from '@/utils/DataGridTableUtils'
// Imports used for the language localisation of the datagrid
import cookie from '../../../utils/cacheProviders/cookieCacheProvider'
import deDevExtremeLocale from '../../../locales/devExtreme/de'
import esDevExtremeLocale from '../../../locales/devExtreme/es'
import frDevExtremeLocale from '../../../locales/devExtreme/fr'
import itDevExtremeLocale from '../../../locales/devExtreme/it'
import nlDevExtremeLocale from '../../../locales/devExtreme/nl'
import { locale, loadMessages } from 'devextreme/localization'

import { exportDataGrid } from 'devextreme/excel_exporter'
import { Workbook } from 'exceljs'
import saveAs from 'file-saver'

import translations from '@/configurations/app/config-locales'

// List of all the tables that can be used as a child table
const tableComponentResults = () =>
  import(
    /* webpackChunkName: "BannerChildTables" */ '@/components/tables/AnalysisResults/tableComponentResults'
  )

const tableFieldSamples = () =>
  import(
    /* webpackChunkName: "BannerChildTables" */ '@/components/tables/AnalysisResults/tableFieldSamples'
  )

export default {
  name: 'DataGridBannerMasterDetail',
  props: {
    // The translated title that should be shown above the table
    tableTitle: String,

    // A Array of objects from which the values should be shown
    dataSet: Array,

    /* A ordered array of objects with all the columns without a banner
     * The objects in the array should look like this: { title: '', dataField: '' }
     * - title is the translation key for the Header of the column
     * - dataField contains the attribute key of the value that should be shown here
     */
    columns: Array,

    /* A ordered array of objects for all the banners that should be shown
     * The objects in the array should look like this: { title: '', bannerIdentification: '' }
     * - title is the translation for the Header of the banner group
     * - bannerIdentification is the field that contains a unique identifier that is used in banneredColumns as a property key for the columns
     */
    banneredColumnHeaders: Array,

    /* A object with for each banner a property. The property key should be the bannerIdentifications of the banner.
     * Each property should contain a array of objects(columns) that should be shown in the banner
     * The objects in the array should look like this: { title: '', subFrameworkId: '' }
     * - title is the translation key for the header of the column
     * - dataField contains the attribute key of the value that should be shown here
     */
    banneredColumns: Object,

    // A boolean if the master detail functionality should be used
    userMasterDetail: Boolean,

    // The name of the component that should be shown in the master detail.
    // Make sure this component is also imported in this component
    masterDetailTemplates: Array,

    // The name of the identifier for the dataset that is loaded.
    dataKey: String,
    // The height of the table if left empty the table will be as long as the data set is.
    height: String
  },
  components: {
    DxDataGrid,
    DxColumn,
    DxHeaderFilter,
    DxColumnChooser,
    DxMasterDetail,
    DxSearchPanel,
    DxSelection,
    DxGrouping,
    DxGroupPanel,
    DxScrolling,
    DxPaging,
    DxSorting,
    DxExport,
    tableComponentResults,
    tableFieldSamples,
    exportDataGrid
  },
  created() {
    this.language = cookie.get('language')
    if (this.language !== 'en') {
      loadMessages(deDevExtremeLocale)
      loadMessages(esDevExtremeLocale)
      loadMessages(frDevExtremeLocale)
      loadMessages(itDevExtremeLocale)
      loadMessages(nlDevExtremeLocale)
      locale(this.language)
    }
  },
  computed: {
    translations: {
      get() {
        return translations
      }
    },
    amountOfRecords() {
      if (this.dataSet && Array.isArray(this.dataSet)) return this.dataSet.length

      return 0
    }
  },
  methods: {
    async onToolbarPreparing(event) {
      event.toolbarOptions.items.unshift({
        location: 'before',
        template: 'tableTitleTemplate',
        sortIndex: 0
      })
      event.toolbarOptions.items.sort((a, b) =>
        a.sortIndex > b.sortIndex ? 1 : -1
      )
    },
    getDataType(dataFieldName) {
      let type = 'string'
      Object.keys(fieldTypeIdentifiers).forEach((key) => {
        if (dataFieldName.includes(key)) {
          type = fieldTypeIdentifiers[key]
        }
      })
      return type
    },
    visibleColumns() {
      let columns = []
      if (this.columns) {
        columns = this.columns.filter((col) => {
          return this.getDataType(col.dataField) !== 'hide'
        })
      }
      return columns
    },
    visibleBannerColumns(bannerIdentification) {
      let columns = []
      if (
        this.banneredColumns &&
        this.banneredColumns[bannerIdentification.toString()]
      ) {
        columns = this.banneredColumns[bannerIdentification.toString()].filter(
          (col) => {
            return this.getDataType(col.dataField) !== 'hide'
          }
        )
      }
      return columns
    },
    // Row data will be used in the condition
    shouldShow(conditions, rowData) {
      let visible = true
      if (conditions) {
        conditions.forEach((condition) => {
          if (visible) {
            // eslint-disable-next-line no-eval
            visible = eval(condition)
          }
        })
      }
      return visible
    },
    onExporting(event) {
      let fileName = 'Report'
      const workbook = new Workbook()
      const worksheet = workbook.addWorksheet('Sheet')

      if (this.tableTitle) {
        fileName = this.tableTitle.replace(/ /g, '_')
      }

      exportDataGrid({
        component: event.component,
        worksheet: worksheet
      }).then(function() {
        workbook.xlsx.writeBuffer()
          .then(function(buffer) {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName + '.xlsx')
          })
      })
    }
  }
}
</script>

<style lang="less">
.dx-datagrid-header-panel {
  background-color: #eeeeee;
}

.baseTable {
  height: 100%;
}

.dx-toolbar {
  background-color: transparent;
}

.dx-datagrid-header-panel .dx-button-mode-contained {
  border-radius: 0px;
  border: none;
  background-color: #67ac45;
  transition: 0.2s;
}

.dx-button-mode-contained.dx-state-hover {
  background-color: #67ac45;
  transform: scale(0.92);
}

.dx-toolbar-text-auto-hide .dx-button .dx-icon {
  color: #fff;
}

.dx-button-mode-contained .dx-icon {
  color: #fff;
}

.tableTitle {
  color: #333;
  margin: 0px;
  padding: 0px;
  font-size: 1.2rem;
}

.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > tr > td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover > td,
.dx-datagrid-rowsview
  .dx-selection.dx-row:not(.dx-row-focused):hover
  > tr
  > td {
  background-color: rgb(151, 214, 120);
  color: black;
}

.dx-datagrid-rowsview
  .dx-row-focused.dx-data-row:not(.dx-edit-row):not(.dx-row-lines)
  > td,
.dx-datagrid-rowsview
  .dx-row-focused.dx-data-row:not(.dx-edit-row):not(.dx-row-lines)
  > tr:first-child
  > td {
  border-top: 1px solid rgb(103, 172, 69);
}

.dx-datagrid-rowsview
  .dx-row-focused.dx-data-row:not(.dx-edit-row)
  > td:not(.dx-focused),
.dx-datagrid-rowsview
  .dx-row-focused.dx-data-row:not(.dx-edit-row)
  > tr
  > td:not(.dx-focused),
.dx-datagrid-rowsview
  .dx-row-focused.dx-data-row:not(.dx-edit-row)
  .dx-command-edit
  .dx-link {
  background-color: rgb(103, 172, 69);
  color: black;
}

.dx-button-content > .dx-icon-close {
  color: black !important;
}

.dx-toolbar {
  background-color: transparent;
}

.dx-datagrid-column-chooser.dx-datagrid-column-chooser-mode-select
  .dx-datagrid-column-chooser-plain
  .dx-treeview-node {
  padding-left: 10px;
}

.dx-datagrid-column-chooser.dx-datagrid-column-chooser-mode-select
  .dx-datagrid-column-chooser-plain
  .dx-treeview-node
  .dx-checkbox {
  left: 15px;
}
#dxwrapper-width .dx-template-wrapper {
  width: calc(100vw - 20rem) !important;
}
</style>
