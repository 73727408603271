import Vue from 'vue'
import customGridDropdown from '@/components/table-input-controls/customGridDropdown'
import sortUtils from '@/utils/sortUtils'
import store from '@/vuex/store'

// List of all the control key that have an editor of value 3
let editor3values = ['MpDrillingMasterCode', 'PrDrillingMasterCode', 'WsSampleTakerCode', 'MpSampleTakerCode', 'PrLeaderCode', 'PrPhaseCode']

let buildColumnFromTemplate = (control) => {
  /* Editor 3 saves the full text instead of the code
   * When getting values from this the value has to be set as the property to make sure the dropdown works and the text value is saved
   */
  if ((editor3values.indexOf(control.fields[0].key) > -1)) {
    let newDisplayValue = {}
    for (var prop in control.displayValueLookup) {
      newDisplayValue[control.displayValueLookup[prop]] = control.displayValueLookup[prop]
    }
    control.displayValueLookup = newDisplayValue
  }
  let column = {
    caption: control.labels[0][control.languageCode3Letter],
    dataField: control.fields[0].key,
    customLinkedControl: control
  }

  // Use natural sorting on the column
  column.sortingMethod = function (a, b) {
    return sortUtils.naturalSort(a, b)
  }

  if (!control.displayValueLookup) {
    console.error('ListEditor has no displayValueLookup [' + control.fields[0].key + ']')
  } else {
    // If column is used to display a list, order the rows by display text instead of value
    column.calculateSortValue = function (rowData) {
      let column = this
      if (rowData[column.dataField] && control.displayValueLookup[rowData[column.dataField]]) {
        return control.displayValueLookup[rowData[column.dataField]].replace(rowData[column.dataField], '')
      } else {
        return rowData[column.dataField]
      }
    }
  }

  // simple lookup is possible too, we have to decide what to use when
  column.editCellTemplate = editCellTemplateCustomGridDropdown
  column.calculateDisplayValue = (rowData) => {
    if (control.inputControlOptions.DynamicCodeListField && control.inputControlOptions.DynamicCodeListMapping) {
      let dynamicCodeListMapping = JSON.parse(control.inputControlOptions.DynamicCodeListMapping)
      let codeListToFind = dynamicCodeListMapping[rowData[control.inputControlOptions.DynamicCodeListField]]
      if (codeListToFind) {
        let codeList = store.state.dynamicCodeLists.find((dynamicCodeList) => { return dynamicCodeList.categoryId === codeListToFind })
        if (codeList) {
          let codeListOption = codeList.options.find((option) => { return option.value === rowData[column.dataField] })
          if (codeListOption) {
            return codeListOption.localizedText
          }
        }
      }
    } else {
      if (control.displayValueLookup) {
        return control.displayValueLookup[rowData[column.dataField]]
      } else {
        console.error('displayExpr in tableListEditor expects displayValueLookup, not set')
        return '-'
      }
    }
  }
  return column
}

// custom grid dropdown is chosen,
let editCellTemplateCustomGridDropdown = (cellElement, cellInfo) => {
  var DxDropDownBoxClass = Vue.extend(customGridDropdown)
  var dxDropDownBox = new DxDropDownBoxClass({ propsData: { cellInfo: cellInfo } })
  dxDropDownBox.$mount()
  cellElement.appendChild(dxDropDownBox.$el)
}

export default {
  buildColumnFromTemplate
}
