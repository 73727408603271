<template>
  <!-- eslint-disable vue/valid-v-model -->
  <div>
    <p>
      {{ actionDescription }}
      <br /><br />
      <DxSelectBox
        v-model:value="changedData"
        :search-enabled="true"
        :data-source="dataSet"
        display-expr="SampleName"
        value-expr="SampleGUID"
      />
    </p>
    <p>
      <br />
      <small>{{ actionExplanation }}</small>
    </p>
  </div>
</template>
<script>
import { DxSelectBox } from 'devextreme-vue/select-box'

export default {
  name: 'selectSingleItemFromArray',
  inject: ['validate'],
  components: {
    DxSelectBox
  },
  props: {
    dataSet: {
      required: true
    },
    displayExpresion: {
      // SampleName
      type: String,
      Required: true
    },
    valueExpresion: {
      type: String,
      Required: true
    },
    actionDescription: {
      type: String,
      required: false,
      default: ''
    },
    actionExplanation: {
      type: String,
      required: false,
      default: ''
    },
    defaultValue: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      changedData: this.defaultValue
    }
  },
  mounted() {
    if (this.changedData) {
      this.validate(this.changedData)
    }
  },
  watch: {
    changedData: {
      handler: function (val) {
        if (val) {
          this.validate(val)
        }
      }
    },

    screen: {
      handler: function () {
        this.loadScreenTemplate()
      }
    }
  }
}
</script>
