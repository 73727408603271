import Vue from 'vue'
import config from '@/configurations/config-environment'
import { BaseService } from '../baseService'
const dataws = config.apis.dataws

export class SpecialSettingsTableService extends BaseService {
  getCadastreList() {
    const authorisation = this.getAuthorizationObject()
    const languageCode = this.getLangCodeLongFromShort(authorisation.Language)
    const data = {
      Authorisation: authorisation,
      LanguageCode: languageCode,
      WebserviceVersion: '1.0',
      TypeOfTableExport: '4' // IBD_CADASTRALS_JSON
    }
    return Vue.http
      .post(`${dataws}ITWDataRestService_V1_0/GetSpecialSettingsTable`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((resp) => {
        if (resp.status !== 200 || resp.data.ResultCode !== 'Export_Succes') {
          throw new Error(`Unable to get cadastre list. ${resp.data.ResultMessage}`)
        }

        return JSON.parse(resp.data.Data).IBDCadastrals.IBDCadastral
      })
  }
}
