<template>
  <div class="ground-air-table-overview">
    <div class="overview-table-row">
      <div class="overview-table">
        <MeasurementObjectsSelectTable
          :projectId="$route.params.projectId"
          :updateSelectedRows="() => {}"
          :enableColumnChooser="enableColumnChooser"
          :searchPanelEnabled="searchPanelEnabled"
          @update:selected="onUpdateMeasurementObject"
        />
      </div>
      <div class="overview-table">
        <GaugingTubesTable
          :projectId="$route.params.projectId"
          :projectCode="projectCode"
          :selectedMeasurementObject="measurementObject"
          :updateSelectedRows="onUpdateGaugingTube"
          :enableCreateTableRowAction="enableCreateTableRowAction"
          :enableDeleteTableRowAction="enableDeleteTableRowAction"
          :enableUpdateTableRowAction="enableUpdateTableRowAction"
          :enableColumnChooser="enableColumnChooser"
          :searchPanelEnabled="searchPanelEnabled"
          :enableExportSettings="enableExportSettings"
        />
      </div>
      <div class="overview-table">
        <FinishingsTable
          :projectId="$route.params.projectId"
          :projectCode="projectCode"
          :selectedMeasurementObject="measurementObject"
          :selectedAirSample="airSample"
          :enableCreateTableRowAction="enableCreateTableRowAction"
          :enableDeleteTableRowAction="enableDeleteTableRowAction"
          :enableUpdateTableRowAction="enableUpdateTableRowAction"
          :enableColumnChooser="enableColumnChooser"
          :searchPanelEnabled="searchPanelEnabled"
          :enableExportSettings="enableExportSettings"
        />
      </div>
    </div>
    <div class="overview-table-row">
      <div class="overview-table">
        <AirSamplesTable
          :projectId="$route.params.projectId"
          :projectCode="projectCode"
          :selectedMeasurementObject="measurementObject"
          :selectedGaugingTube="gaugingTube"
          :updateSelectedRows="onUpdateAirSample"
          :enableCreateTableRowAction="enableCreateTableRowAction"
          :enableDeleteTableRowAction="enableDeleteTableRowAction"
          :enableUpdateTableRowAction="enableUpdateTableRowAction"
          :enableColumnChooser="enableColumnChooser"
          :searchPanelEnabled="searchPanelEnabled"
          :enableExportSettings="enableExportSettings"
          sampleType="Ground"
        />
      </div>
      <div class="overview-table">
        <PackagingsTable
          :projectId="$route.params.projectId"
          :projectCode="projectCode"
          :selectedMeasurementObject="measurementObject"
          :selectedGaugingTube="gaugingTube"
          :selectedAirSample="airSample"
          :enableCreateTableRowAction="enableCreateTableRowAction"
          :enableDeleteTableRowAction="enableDeleteTableRowAction"
          :enableUpdateTableRowAction="enableUpdateTableRowAction"
          :enableColumnChooser="enableColumnChooser"
          :searchPanelEnabled="searchPanelEnabled"
          :enableExportSettings="enableExportSettings"
        />
      </div>
    </div>
  </div>
</template>
<script>
import {
  MeasurementObjectsSelectTable,
  GaugingTubesTable,
  FinishingsTable,
  AirSamplesTable,
  PackagingsTable
} from '@/components/dynamicTables/tables'
import Popup from '@/utils/popup'
import Config from '@/configurations/config'
import stringUtils from '@/utils/stringUtils'

const TiPopup = Popup[Config.platform].default

export default {
  name: 'SurfaceSamplesTableOverview',
  components: {
    MeasurementObjectsSelectTable,
    GaugingTubesTable,
    FinishingsTable,
    AirSamplesTable,
    PackagingsTable
  },
  props: {
    enableCreateTableRowAction: {
      required: false,
      default() {
        return true
      }
    },
    enableDeleteTableRowAction: {
      required: false,
      default() {
        return true
      }
    },
    enableUpdateTableRowAction: {
      required: false,
      default() {
        return true
      }
    },
    enableColumnChooser: {
      required: false,
      default() {
        return true
      }
    },
    searchPanelEnabled: {
      required: false,
      default() {
        return true
      }
    },
    enableExportSettings: {
      required: false,
      default() {
        return true
      }
    }
  },
  data() {
    return {
      measurementObject: {},
      gaugingTube: {},
      airSample: {},
      projectCode: ''
    }
  },
  async created() {
    await this.setCurrentProject()
  },
  methods: {
    async onUpdateMeasurementObject(data) {
      // if (data && Array.isArray(data) && data.length === 1) {
      //   this.measurementObject = data[0]
      // } else {
      //   this.measurementObject = {}
      // }
      this.measurementObject = data
    },
    async onUpdateAirSample(data) {
      if (data && Array.isArray(data) && data.length === 1) {
        this.airSample = data[0]
      } else {
        this.airSample = {}
      }
    },
    async onUpdateGaugingTube(data) {
      if (data && Array.isArray(data) && data.length === 1) {
        this.gaugingTube = data[0]
      } else {
        this.gaugingTube = {}
      }
    },
    async setCurrentProject() {
      if (stringUtils.isNullOrEmptyOrWhitespace(this.$route.params.projectId)) {
        TiPopup.popup(this.$t('message.projectNotFound'))
        return
      }

      await this.$store.dispatch('fetchProject', {
        PrID: this.$route.params.projectId
      })
      const project = this.$store.state.currentProject

      if (project && project.PrID) {
        this.projectCode = project.PrCode
      } else {
        TiPopup.popup(this.$t('message.projectNotFound'))
      }
    },
    async handleErrorResponse(error, errorMessage = 'message.UnknownError') {
      console.log(error)
      TiPopup.popup(this.$t(errorMessage))
    }
  }
}
</script>

<style>
.ground-air-table-overview h2 {
  margin-top: 5px;
  font-size: 20px;
}
.ground-air-table-overview {
  height: 100%;
}
.ground-air-table-overview .overview-table-row {
  height: 50%;
}
.ground-air-table-overview .overview-table-row {
  display: flex;
}
.ground-air-table-overview
  .overview-table-row:first-child
  .overview-table:first-child {
  flex: 2;
}
.ground-air-table-overview
  .overview-table-row:first-child
  .overview-table:nth-child(2) {
  flex: 4;
}
.ground-air-table-overview
  .overview-table-row:first-child
  .overview-table:nth-child(3) {
  flex: 3;
}
.ground-air-table-overview
  .overview-table-row:nth-child(2)
  .overview-table:first-child {
  flex: 6;
}
.ground-air-table-overview
  .overview-table-row:nth-child(2)
  .overview-table:nth-child(2) {
  flex: 3;
}
.ground-air-table-overview .overview-table-row .overview-table > div {
  margin-left: 10px;
}
.ground-air-table-overview .overview-table-row:nth-child(2) {
  padding-top: 10px;
}
.ground-air-table-overview .overview-table {
  overflow: hidden;
}
.ground-air-table-overview .overview-table > div,
.ground-air-table-overview .overview-table > div > div {
  height: 100%;
}
.ground-air-table-overview .overview-table-row .overview-table > div,
.ground-air-table-overview .overview-table-row .overview-table > div > div {
  height: 100%;
}
.dx-datagrid-search-panel {
  margin-left: 0px;
}
</style>
