import Vue from 'vue'
import configEnv from '../configurations/config-environment'

// Refactors: getKlicLayerInfo function isn't all too great therefore a refactor might be required later.

export default {
  getKlicLayerObjID: function (url) {
    return Vue.http.get(configEnv.apis.mapLayerProxy + url)
      .then(resp => {
        if (resp.data.features.length > 0) {
          return resp.data.features[0].properties['properties.layerobj']
        } else {
          return null
        }
      })
  },

  getKlicLayerInfo: function (id) {
  // Use the features' identifier to get the actual layer information for this rectangle
    return Vue.http.post('https://klic.terraindex.com/getlayers', { id: id }, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(resp => {
        if (!resp.data) throw new Error(`getKlicLayerInfo error: No response data found. ${resp.message}`)
        if (resp.data.message) throw new Error(`getKlicLayerInfo error: Message found in response data. Message is: ${resp.data.message}`)
        return resp.data.layers
      })
      .catch(error => {
        console.error(error)
        return Promise.reject(error.message)
      })
  }
}
