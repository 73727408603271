<template>
  <div>
    <TerraIndexDynamicTable
      :tableData="tableData"
      :tableTitle="tableTitle"
      :tableDataIdentifier="tableIdentifier"
      :tableCreateAction="createTableRowAction"
      :tableDeleteAction="deleteTableRowAction"
      :tableMultiUpdateAction="multiUpdateTableRowAction"
      :tableUpdateAction="updateTableRowAction"
      :hasSaveAndRevertButtons="saveAndRevertButtonsEnabled"
      :enableColumnChooser="columnChooserEnabled"
      :enableHeaderFilter="headerFilterEnabled"
      :enableGrouping="headerGroupingEnabled"
      :searchPanelEnabled="searchPanelEnabled"
      :useMultiSelect="multiSelectEnabled"
      :tableColumns="tableColumns"
      :tableName="tableName"
      :template="template"
      :settingName="settingName"
      :language="language"
      :excelExportSettings="exportSettings"
      :state-storing="stateStoring"
      :defaultSelectedRowIndex="defaultSelectedRowIndex"
      :project="project"
      :isValidRequest="isValidRequest"
      :onRowValidating="validateRow"
      :scrollingMode="scrollingMode"
      @updateSelectedRows="updateSelectedRows"
      @refreshTableData="refreshData"
    />
  </div>
</template>

<script>
import BaseProjectTable from '@/components/dynamicTables/tables/baseProjectTable'

export default {
  name: 'subLocationsTable',
  extends: BaseProjectTable,
  data() {
    return {
      tableName: 'tblSublocations',
      tableNameForExport: 'tblSublocations',
      tableIdentifier: 'SlGuid',
      tableTitle: this.$t('tableTitles.Sublocations'),
      dateLastChangedField: 'SlDateLastChanged',
      templateName: 'frmSublocationGrid',
      settingName: 'Sublocations_Settings',
      createTableRowActionEnabled: true,
      deleteTableRowActionEnabled: true,
      multiUpdateTableRowActionEnabled: false,
      updateTableRowActionEnabled: true,
      saveAndRevertButtonsEnabled: true,
      columnChooserEnabled: true,
      headerFilterEnabled: true,
      headerGroupingEnabled: false,
      multiSelectEnabled: true,
      searchPanelEnabled: true,
      exportEnabled: true,
      scrollingMode: 'infinite',
      defaultSelectedRowIndex: 0,
      stateStoring: {}
    }
  },
  computed: {
    isValidRequest() {
      return this.isProjectIdSet
    },
    multiUpdateTableRowAction() {
      return {
        active: true,
        projectId: this.project.PrID,
        tableName: this.tableName,
        dateLastChangedField: this.dateLastChangedField
      }
    }
  },
  methods: {
    async validateRow (row) {
      if (!row.isValid) {
        return
      }

      if (row.newData.SlName && (!row.oldData || row.newData.SlName.trim() !== row.oldData.SlName)) {
        row.newData.SlName = row.newData.SlName.trim()
        const itemsWithSameName = this.tableData.filter(tuple => tuple.SlName === row.newData.SlName)
        if (itemsWithSameName.length > 0) {
          row.promise = new Promise((resolve) => {
            resolve()
          }).then(() => {
            console.log('wrong')
            row.isValid = false
            row.errorText = this.$t('message.nameShouldUnique')
          })
        }
      }
    },
    async createTableRow (row) {
      try {
        const record = {
          ...this.prepareTableRowDataForCreate(row.data),
          PrID: this.project.PrID
        }

        const response = await this.$store.dispatch('createSubLocation', record)
        if (response) {
          this.handleGetDataResponse(response)
        }

        return !!response
      } catch (error) {
        this.handleErrorResponse(error)
      }
    },
    async deleteTableRow(rowKey) {
      try {
        return await this.$store.dispatch('deleteSubLocation', { projectId: this.project.PrID, SlGuid: rowKey })
      } catch (error) {
        this.handleErrorResponse(error)
      }
    },
    multiDelete(keys = []) {
      const deleteActions = []
      keys.forEach((key) => {
        deleteActions.push(
          this.$store.dispatch('deleteSubLocation', {
            projectId: this.project.PrID,
            SlGuid: key
          })
        )
      })

      return Promise.all(deleteActions).then(() => {
        return this.getData().then((result) => {
          this.tableData = result
        })
      })
    },
    async getData() {
      try {
        return await this.$store.dispatch('getSubLocations', {
          projectId: this.project.PrID
        })
      } catch (error) {
        this.handleErrorResponse(error)
      }
    },
    async updateTableRow(change) {
      try {
        const record = {
          ...this.prepareTableRowDataForUpdate(change)
        }

        const response = await this.$store.dispatch('updateSubLocation', record)
        if (response) {
          this.handleGetDataResponse(response)
        }

        return !!response
      } catch (error) {
        this.handleErrorResponse(error)
      }
    },
    getTemplateRequest() {
      return {
        templateName: this.templateName,
        templateId: '92'
      }
    }
  }
}
</script>
