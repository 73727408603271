<template>
  <div id="project-details">
    <TerraIndexDynamicTable
      :tableData="tableData"
      :tableTitle="tableTitle"
      :tableDataIdentifier="tableIdentifier"
      :tableCreateAction="createTableRowAction"
      :tableDeleteAction="deleteTableRowAction"
      :tableMultiUpdateAction="multiUpdateTableRowAction"
      :tableUpdateAction="updateTableRowAction"
      :hasSaveAndRevertButtons="saveAndRevertButtonsEnabled"
      :enableColumnChooser="columnChooserEnabled"
      :enableHeaderFilter="headerFilterEnabled"
      :enableGrouping="headerGroupingEnabled"
      :searchPanelEnabled="searchPanelEnabled"
      :useMultiSelect="multiSelectEnabled"
      :tableColumns="tableColumns"
      :tableName="tableName"
      :template="template"
      :settingName="settingName"
      :language="language"
      :excelExportSettings="exportSettings"
      :state-storing="stateStoring"
      :defaultSelectedRowIndex="defaultSelectedRowIndex"
      :project="project"
      :isValidRequest="isValidRequest"
      :onRowValidating="validateRow"
      :scrollingMode="scrollingMode"
      @refreshTableData="refreshData"
      @updateSelectedRows="updateSelectedRows"
    />
  </div>
</template>

<script>
import BaseProjectTable from '@/components/dynamicTables/tables/baseProjectTable'
import moment from 'moment'

export default {
  name: 'projectDetailsTable',
  extends: BaseProjectTable,
  data() {
    return {
      tableName: 'tblProjects',
      tableNameForExport: 'tblProjects',
      tableIdentifier: 'PrID',
      tableTitle: this.$t('tableTitles.ProjectDetails'),
      dateLastChangedField: 'PrDateLastChanged',
      templateName: 'frmProjectGrid',
      settingName: 'projectDetails_Settings',
      createTableRowActionEnabled: false,
      deleteTableRowActionEnabled: false,
      multiUpdateTableRowActionEnabled: false,
      updateTableRowActionEnabled: true,
      saveAndRevertButtonsEnabled: true,
      columnChooserEnabled: true,
      headerFilterEnabled: false,
      headerGroupingEnabled: false,
      multiSelectEnabled: false,
      searchPanelEnabled: true,
      exportEnabled: true,
      scrollingMode: 'infinite',
      defaultSelectedRowIndex: 0,
      stateStoring: {}
    }
  },
  computed: {
    isValidRequest() {
      return this.isProjectIdSet
    }
  },
  methods: {
    // todo: custom row validation https://test.terraindex.com/DataWS/ITWDataRestService_V1_0/ValidateProjectCode
    async refreshData() {
      if (this.isValidRequest) {
        this.tableData = [this.project]
      } else {
        this.clearData()
      }
    },
    setTimeToMidday(record) {
      this.tableColumns.forEach((tableColumn) => {
        if (tableColumn.dataType !== 'date') return
        if (record[tableColumn.dataFieldKey] === undefined) return
        record[tableColumn.dataFieldKey] = moment(record[tableColumn.dataFieldKey]).set('hour', 12).format('YYYY-MM-DDTHH:mm:ssZ')
      })
      return record
    },
    async updateTableRow(change) {
      try {
        let record = {
          ...this.prepareTableRowDataForUpdate(change)
        }

        record = this.setTimeToMidday({ ...record })

        const response = await this.$store.dispatch('setProject', {
          projectData: record
        })

        if (!response) {
          return false
        }

        const responseTableData = Array.isArray(response)
          ? response
          : [{ ...response }]

        this.handleGetDataResponse(responseTableData)

        return true
      } catch (error) {
        this.handleErrorResponse(error)
      }
    },
    async validateRow(row) {
      if (!row.isValid) {
        return
      }

      if (row.newData.PrCode) {
        row.newData.PrCode = row.newData.PrCode.trim()

        if (row.newData.PrCode === row.oldData.PrCode) {
          return
        }

        row.promise = this.$store
          .dispatch('validateProject', { PrCode: row.newData.PrCode })
          .then((result) => {
            row.isValid = result
            row.errorText = result ? '' : this.$t('project.InvalidProjectCode')
          })
      }
    }
  }
}
</script>

<style>
#project-details .dx-datagrid-rowsview {
  min-height: 4em;
}
</style>
