<template>
  <div class="ti-dynamic-table">
    <DxDataGrid
      v-if="showTable"
      ref="tableGrid"
      name="devextremetable"
      columnRenderingMode="virtual"
      :data-source="tableData"
      :row-alternation-enabled="true"
      :show-borders="true"
      :key-expr="tableDataIdentifier"
      :onCellDblClick="onCellDblClick"
      :columnAutoWidth="true"
      :height="height"
      :allow-column-reordering="true"
      :allow-column-resizing="true"
      :focusedRowEnabled="enableKeyboardNavigation"
      :onFocusedRowChanged="onFocusedRowChanged"
      @key-down="onKeyDown"
      @row-click="onRowClick"
      @toolbar-preparing="onToolbarPreparing"
      @saving="onSavingData"
      @selection-changed="onSelectedRowsChanged"
      @row-validating="onRowValidating"
      @content-ready="onContentReady"
      @editing-start="() => (editModusEnabled = true)"
      @edit-canceling="() => (editModusEnabled = false)"
      @row-updating="() => (editModusEnabled = false)"
      @editor-preparing="editorPreparing"
      @exporting="onExporting"
      @row-dbl-click="onRowDoubleClick"
    >
      <DxScrolling
        :mode="scrollingMode"
        row-rendering-mode="standard"
      />
      <DxStateStoring
        :enabled="shouldPersistState"
        type="custom"
        :storage-key="settingName"
        :custom-load="loadSettings"
        :custom-save="saveSettings"
        :saving-timeout="100"
      />
      <DxExport :enabled="excelExportSettings.enabled" />
      <DxColumnChooser
        :enabled="enableColumnChooser"
        mode="select"
      >
        <DxColumnChooserSearch :enabled="true" />
      </DxColumnChooser>
      <DxColumn
        v-for="tableColumn in tableColumns"
        :key="tableColumn.dataFieldKey"
        :allow-editing="tableUpdateAction.active && !tableColumn.disabledForEditing"
        :caption="tableColumn.caption"
        :calculate-display-value="tableColumn.calculateDisplayValue"
        :calculate-cell-value="tableColumn.calculateCellValue"
        :calculate-group-value="(...row) => tableGroupingAction.method(...row, tableColumn)"
        :customize-text="customizeColumnText"
        :data-field="tableColumn.dataFieldKey"
        :data-type="tableColumn.dataType"
        :format="(...value) => formatCellValue(tableColumn, ...value)"
        :set-cell-value="tableColumn.setCellValue"
        :sorting-method="(a, b) => naturalSort(a, b, tableColumn)"
        :visible="tableColumn.visible"
        :cell-template="checkForFieldDataType(tableColumn.dataFieldKey)"
        group-cell-template="groupCellTemplate"
      >
        <DxStringLengthRule
          v-if="tableColumn.validations.dxStringLengthRule"
          :min="tableColumn.validations.minStringLength"
          :max="tableColumn.validations.maxStringLength"
        />
        <DxRangeRule
          v-if="tableColumn.validations.dxNumberRangeRule"
          :min="tableColumn.validations.minNumberValue"
          :max="tableColumn.validations.maxNumberValue"
        />
        <DxRequiredRule v-if="tableColumn.validations.dxRequireRule" />
        <DxLookup
          v-if="tableColumn.validations.dxLookup && tableColumn.validations.dxLookupValidationProperties.displayProperty && tableColumn.validations.dxLookupValidationProperties.valueProperty"
          :display-expr="tableColumn.validations.dxLookupValidationProperties.displayProperty"
          :value-expr="tableColumn.validations.dxLookupValidationProperties.valueProperty"
          :data-source="tableColumn.validations.dxLookupValidationProperties.options"
        />
      </DxColumn>
      <DxColumn
        :visible="false"
        :showInColumnChooser="false"
        type="buttons"
      >
        <!-- DevExtreme includes an action column by default. This removes it. -->
      </DxColumn>
      <DxEditing
        :allow-updating="tableUpdateAction.active"
        :allow-deleting="tableDeleteAction.active"
        :allow-adding="tableCreateAction.active"
        :mode="editMode"
        :confirm-delete="false"
      />
      <DxSelection
        show-check-boxes-mode="none"
        :mode="rowSelectionType"
        select-all-mode="page"
      />
      <DxHeaderFilter :visible="enableHeaderFilter" />
      <DxSearchPanel
        :visible="searchPanelEnabled"
        :width="searchPanelWidth"
      />
      <DxGrouping
        :auto-expand-all="true"
        expand-mode="buttonClick"
      />
      <DxGroupPanel :visible="tableGroupingAction.active" />

      <template #tableTitleTemplate>
        <h2>{{ tableTitle }} {{ tableAmount }}</h2>
      </template>
      <template #groupCellTemplate="{ data }">
        {{ getColumnGroupHeaderTitle(data) }}
      </template>
      <template #lab-status-codes-cell="{ data }">
        <td :class="getClassNameForCell(data)">{{ data.value }}</td>
      </template>
      <DxPaging :enabled="false" />
    </DxDataGrid>
    <NvLoading :show="isLoading"></NvLoading>
    <TiDialog ref="multiEditDialog">
      <MultiEditDialog
        v-if="tableMultiUpdateAction.active"
        :template="template"
        :projectId="tableMultiUpdateAction.projectId ? parseInt(tableMultiUpdateAction.projectId) : -1"
      />
    </TiDialog>
    <ConfirmMultiDeleteDialog
      :subHeader="confirmMultiDeleteHeader"
      :show="showConfirmMultiDeleteDialog"
      @delete-confirmed="onConfirmMultiDelete"
      @close-modal="showConfirmMultiDeleteDialog = false"
    >
      {{ $t('project.DeleteRowsConfirmation') }}
    </ConfirmMultiDeleteDialog>
    <div
      class="duplicate mp-alert alert alert-success"
      role="alert"
      style="display: none"
    ></div>
  </div>
</template>

<script>
import {
  DxColumn,
  DxColumnChooser,
  DxDataGrid,
  DxEditing,
  DxGrouping,
  DxGroupPanel,
  DxHeaderFilter,
  DxLookup,
  DxPaging,
  DxRangeRule,
  DxRequiredRule,
  DxScrolling,
  DxSearchPanel,
  DxSelection,
  DxStateStoring,
  DxStringLengthRule,
  DxExport,
  DxColumnChooserSearch
} from 'devextreme-vue/data-grid'

import deDevExtremeLocale from '@/locales/devExtreme/de'
import esDevExtremeLocale from '@/locales/devExtreme/es'
import frDevExtremeLocale from '@/locales/devExtreme/fr'
import itDevExtremeLocale from '@/locales/devExtreme/it'
import nlDevExtremeLocale from '@/locales/devExtreme/nl'
import { formatDate, loadMessages, locale } from 'devextreme/localization'

import ConfirmMultiDeleteDialog from '@/components/modal/confirmDeleteModal'
import MultiEditDialog from '@/components/modalSelects/MultiEditDialog'
import NvLoading from '@/components/_shared/loading'
import TiDialog from '@/components/_shared/tiDialog'
import Config from '@/configurations/config'
import Popup from '@/utils/popup'
import stringUtils from '@/utils/stringUtils'
import sortUtils from '@/utils/sortUtils'
import _ from 'lodash'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { Workbook } from 'exceljs'
import saveAs from 'file-saver'

import '@/assets/css/table-cell.css'

const TiPopup = Popup[Config.platform].default

export default {
  name: 'TerraIndexDynamicTable',
  props: {
    getClassNameForCell: {
      type: Function,
      required: false,
      default() {
        return () => { }
      }
    },
    checkForFieldDataType: {
      type: Function,
      required: false,
      default() {
        return ''
      }
    },
    tableTitle: {
      type: String,
      required: false,
      default() {
        return ''
      }
    },
    onRowClick: {
      type: Function,
      required: false,
      default() {
        return () => { }
      }
    },
    tableColumns: {
      type: Array,
      require: true
    },
    tableData: {
      type: Array,
      required: true,
      default() {
        return []
      }
    },
    tableDataIdentifier: {
      type: String,
      required: true
    },
    tableCreateAction: {
      type: Object,
      required: false,
      default() {
        return { active: false }
      }
    },
    tableUpdateAction: {
      type: Object,
      required: false,
      default() {
        return { active: false }
      }
    },
    tableMultiUpdateAction: {
      type: Object,
      required: false,
      default() {
        return { active: false }
      }
    },
    tableDeleteAction: {
      type: Object,
      required: false,
      default() {
        return { active: false }
      }
    },
    tableGroupingAction: {
      type: Object,
      required: false,
      default() {
        return { active: false }
      }
    },
    editMode: {
      type: String,
      default() {
        return 'row'
      }
    },
    hasSaveAndRevertButtons: {
      type: Boolean,
      default() {
        return true
      }
    },
    hasMapButton: {
      type: Boolean
    },
    useMultiSelect: {
      type: Boolean,
      default() {
        return false
      }
    },
    height: {
      type: String,
      required: false,
      default() {
        return '100%'
      }
    },
    tableName: {
      type: String,
      required: true
    },
    enableColumnChooser: {
      type: Boolean,
      required: false,
      default() {
        return true
      }
    },
    enableHeaderFilter: {
      type: Boolean,
      required: false,
      default() {
        return true
      }
    },
    language: {
      type: String,
      required: false,
      default() {
        return 'en'
      }
    },
    excelExportSettings: {
      type: Object,
      required: false,
      default() {
        return { enabled: false, fileName: '' }
      }
    },
    defaultSelectedRowIndex: {
      type: Number,
      required: false,
      default() {
        return -1
      }
    },
    enableKeyboardNavigation: {
      type: Boolean,
      required: false,
      default() {
        return true
      }
    },
    onRowValidating: {
      type: Function,
      required: false,
      default() {
        return () => { }
      }
    },
    additionalHeaderButtons: {
      type: Array,
      required: false,
      default() {
        return []
      }
    },
    scrollingMode: {
      type: String,
      required: false,
      default() {
        return 'virtual'
      }
    },
    focusedRowEnabled: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    },
    isLoading: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    },
    showWarning: {
      type: String,
      required: false,
      default() {
        return ''
      }
    },
    projectId: {
      type: String,
      required: false,
      default() {
        return ''
      }
    },
    template: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    },
    shouldPersistState: {
      type: Boolean,
      required: false,
      default() {
        return true
      }
    },
    isValidRequest: {
      type: Boolean,
      required: false,
      default() {
        return true
      }
    },
    searchPanelWidth: {
      type: Number,
      required: false,
      default() {
        return 160
      }
    },
    settingName: {
      type: String,
      default() {
        return ''
      }
    },
    onEditorPreparing: {
      type: Function,
      required: false
    },
    searchPanelEnabled: {
      type: Boolean,
      required: false,
      default() {
        return true
      }
    },
    forceShowTable: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    },
    shouldUpdateStateOnDelete: {
      type: Boolean,
      required: false,
      default() {
        return true
      }
    },
    selectedRowKeyFromParent: {
      type: String,
      required: false,
      default() {
        return null
      }
    }
  },
  data() {
    return {
      selectedRowKeys: [],
      showTable: this.forceShowTable,
      rowIndex: null,
      isDoubleClick: false,
      showConfirmMultiDeleteDialog: false,
      onShowWarning: '',
      editButtonInstance: {},
      createButtonInstance: {},
      deleteButtonInstance: {},
      columnPickerButtonInstance: {},
      exportButtonInstance: {},
      editModusEnabled: false,
      hiddenColumnsThroughColumnPicker: [],
      settings: {},
      settingsLoaded: false,
      tabKeyAtLastColumnPressed: false,
      unselectRowOnContentReady: -1
    }
  },
  computed: {
    tableAmount() {
      return `(${this.tableData.length})`
    },
    rowSelectionType() {
      return this.useMultiSelect ? 'multiple' : 'single'
    },
    instance() {
      return this && this.$refs.tableGrid && this.$refs.tableGrid.$_instance ? this.$refs.tableGrid.$_instance : false
    },
    confirmMultiDeleteHeader() {
      return this.showConfirmMultiDeleteDialog && this.instance ? this.$t('label.numberOfSelectedRows') + ': ' + this.instance.getSelectedRowKeys().length.toString() : ''
    },
    multiEditDialog() {
      return this && this.$refs.multiEditDialog ? this.$refs.multiEditDialog : false
    }
  },
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxRequiredRule,
    DxRangeRule,
    DxStringLengthRule,
    DxLookup,
    DxSelection,
    DxStateStoring,
    DxColumnChooser,
    DxHeaderFilter,
    DxScrolling,
    DxGrouping,
    DxGroupPanel,
    DxSearchPanel,
    NvLoading,
    MultiEditDialog,
    TiDialog,
    ConfirmMultiDeleteDialog,
    DxPaging,
    DxExport,
    DxColumnChooserSearch
  },
  watch: {
    tableColumns: {
      immediate: true,
      deep: true,
      handler: function (value) {
        this.onTableColumnsChanged(value)
      }
    }
  },
  async created() {
    if (this.language && this.language !== 'en') {
      switch (this.language) {
        case 'nl':
          loadMessages(nlDevExtremeLocale)
          break
        case 'fr':
          loadMessages(frDevExtremeLocale)
          break
        case 'de':
          loadMessages(deDevExtremeLocale)
          break
        case 'es':
          loadMessages(esDevExtremeLocale)
          break
        case 'it':
          loadMessages(itDevExtremeLocale)
          break
      }
      locale(this.language)
    }
  },
  methods: {
    selectRowWithId(id) {
      const selectNewRow = [this.$refs.tableGrid.instance.getRowIndexByKey(id)]
      this.$refs.tableGrid.instance.selectRowsByIndexes(selectNewRow)
    },
    editorPreparing(event) {
      // Ensure parent method is not null and correctly passed down
      if (typeof this.onEditorPreparing === 'function') {
        this.onEditorPreparing(event)
      }
      const visibleColumns = this.instance.getVisibleColumns()
      // If the last column goes out of focus will check if tab has been pressed
      if (event.parentType === 'dataRow' && visibleColumns[visibleColumns.length - 1].visibleIndex === event.visibleIndex) {
        event.editorOptions.onFocusOut = () => {
          if (this.tabKeyAtLastColumnPressed) {
            this.instance.saveEditData()
            this.unselectRowOnContentReady = event.row.rowIndex
            event.component.clearSelection()
            this.tabKeyAtLastColumnPressed = false
          }
          event.editorOptions.disabled = true
        }
      }
    },
    onFocusedRowChanged(e) {
      this.onRowClick(e.row)
    },
    onRowDoubleClick(e) {
      this.$emit('row-dbl-click', e)
    },
    onTableColumnsChanged(columns) {
      this.showTable = this.forceShowTable
      if (columns && columns.length > 0) {
        this.showTable = true
      }
    },
    onEditButtonInitialized(event) {
      this.editButtonInstance = event.component
    },
    onCreateButtonInitialized(event) {
      this.createButtonInstance = event.component
    },
    onDeleteButtonInitialized(event) {
      this.deleteButtonInstance = event.component
    },
    onColumnPickerButtonInitialized(event) {
      this.columnPickerButtonInstance = event.component
    },
    onExportButtonInitialized(event) {
      this.exportButtonInstance = event.component
    },
    evaluateToolBarButtonAvailability(amountSelected) {
      this.evaluateEditButtonAvailability(amountSelected)
      this.evaluateCreateButtonAvailability()
      this.evaluateDeleteButtonAvailability(amountSelected)
      this.evaluateColumnPickerButtonAvailability()
      this.evaluateExportButtonAvailability()
    },
    evaluateEditButtonAvailability(amountSelected) {
      if (this.editButtonInstance && this.editButtonInstance.option) {
        this.editButtonInstance.option('disabled', amountSelected < 2)
      }
    },
    evaluateCreateButtonAvailability() {
      if (this.createButtonInstance && this.createButtonInstance.option) {
        this.createButtonInstance.option('disabled', this.isValidRequest === false || this.editModusEnabled)
      }
    },
    evaluateDeleteButtonAvailability(amountSelected) {
      if (this.deleteButtonInstance && this.deleteButtonInstance.option) {
        this.deleteButtonInstance.option('disabled', this.isValidRequest === false || amountSelected < 1)
      }
    },
    evaluateColumnPickerButtonAvailability() {
      if (this.columnPickerButtonInstance && this.columnPickerButtonInstance.option) {
        this.columnPickerButtonInstance.option('disabled', this.isValidRequest === false || this.editModusEnabled)
      }
    },
    evaluateExportButtonAvailability() {
      if (!this.exportButtonInstance || !this.exportButtonInstance.option) {
        return
      }

      const disabled = this.excelExportSettings.enabled === false || this.tableData.length < 1

      this.exportButtonInstance.option('disabled', disabled)
    },
    onContentReady(event) {
      if (!this.showConfirmMultiDeleteDialog) {
        this.evaluateDefaultRowSelection(event.component)
      }

      this.evaluateToolBarButtonAvailability(event.component.getSelectedRowKeys().length)
    },
    evaluateDefaultRowSelection(component) {
      if (this.shouldSelectDefaultRow(component) && !this.selectedRowKeyFromParent) {
        component.selectRowsByIndexes(this.defaultSelectedRowIndex)
      }

      if (this.selectedRowKeyFromParent && !this.showConfirmMultiDeleteDialog) {
        component.selectRows([this.selectedRowKeyFromParent], false)
      }
    },
    shouldSelectDefaultRow(component) {
      if (this.defaultSelectedRowIndex < 0 || this.hasActiveGrouping(component) || this.editModusEnabled) {
        return false
      }
      return true
    },
    hasActiveGrouping(component) {
      const groupingRows = component.getVisibleRows().filter((row) => row.rowType === 'group')
      if (groupingRows.length > 0) {
        return true
      }
      return false
    },
    async onKeyDown(event) {
      if (this.enableKeyboardNavigation && (event.event.key === 'ArrowUp' || event.event.key === 'ArrowDown')) {
        const selectedRowKey = event.component.getSelectedRowKeys()[0]
        const index = event.component.getRowIndexByKey(selectedRowKey)
        if (event.event.key === 'ArrowUp' && index > 0) {
          event.component.selectRowsByIndexes(index - 1)
        }
        if (event.event.key === 'ArrowDown' && index + 1 < event.component.totalCount()) {
          event.component.selectRowsByIndexes(index + 1)
        }
      }
      if (this.tableUpdateAction.active && event.event.key === 'Tab') {
        // Checks if the user pushed tab on the last column in row
        const { rowIndex, columnIndex } = this.instance._controllers.keyboardNavigation._focusedCellPosition
        const visibleColumns = this.instance.getVisibleColumns()
        if (visibleColumns.length === columnIndex + 1) {
          this.tabKeyAtLastColumnPressed = true
          this.instance.getRowElement(rowIndex)[0].classList.remove('dx-selection')
        }
      }
    },
    async onCellDblClick(event) {
      if (this.tableUpdateAction.active) {
        event.component.editRow(event.rowIndex, event.columnIndex)
      }
    },
    onExporting(e) {
      const workbook = new Workbook()
      const worksheet = workbook.addWorksheet('Sheet')
      const fileName = this.excelExportSettings.fileName + '.xlsx'

      exportDataGrid({
        component: e.component,
        worksheet: worksheet
      }).then(function () {
        workbook.xlsx.writeBuffer()
          .then(function (buffer) {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName)
          })
      })
    },
    async onSelectedRowsChanged(event) {
      this.evaluateToolBarButtonAvailability(event.component.getSelectedRowKeys().length)

      if (event.component.hasEditData()) {
        event.component.saveEditData()
      } else if (event.currentDeselectedRowKeys && event.currentDeselectedRowKeys.length > 0) {
        const rowIndex = event.component.getRowIndexByKey(event.currentDeselectedRowKeys[0])
        const rowElements = event.component.getRowElement(rowIndex)
        if (rowElements && rowElements.length > 0 && rowElements[0].className.includes('dx-edit-row')) {
          event.component.cancelEditData()
        }
      }

      const data = await this.getTableDataRowsByRowKeys(event.selectedRowKeys, this.tableDataIdentifier)
      this.$emit('updateSelectedRows', data)
    },
    async onToolbarPreparing(event) {
      if (event.toolbarOptions && event.toolbarOptions.items && event.toolbarOptions.items.length > 0) {
        if (this.hasSaveAndRevertButtons) {
          const saveButton = {
            locateInMenu: 'auto',
            location: 'after',
            name: 'saveButton',
            options: {
              icon: 'save',
              onClick: this.onSaveButtonClick,
              hint: this.$t('label.save'),
              text: this.$t('label.save'),
              onInitialized: () => { }
            },
            showText: 'inMenu',
            sortIndex: 22,
            widget: 'dxButton'
          }
          event.toolbarOptions.items.push(saveButton)

          const revertButton = {
            locateInMenu: 'auto',
            location: 'after',
            name: 'revertButton',
            options: {
              icon: 'undo',
              onClick: this.onRevertButtonClick,
              hint: this.$t('label.revert'),
              text: this.$t('label.revert'),
              onInitialized: () => { }
            },
            showText: 'inMenu',
            sortIndex: 23,
            widget: 'dxButton'
          }
          event.toolbarOptions.items.push(revertButton)
        }
        if (this.tableCreateAction.active) {
          const item = event.toolbarOptions.items.find((toolbarItem) => toolbarItem.name === 'addRowButton')

          if (item) {
            item.options.onInitialized = this.onCreateButtonInitialized
            if (this.tableCreateAction.override) {
              item.options.onClick = this.tableCreateAction.method
            }
            const cachedFunction = item.options.onClick

            item.options.onClick = () => {
              this.$refs.tableGrid.instance.saveEditData()
              cachedFunction.apply()
            }
          }
        }
        if (this.tableMultiUpdateAction.active) {
          const updateButton = {
            locateInMenu: 'auto',
            location: 'after',
            name: 'updateRowButton',
            options: {
              disabled: false,
              icon: 'rename',
              onClick: this.onUpdateButtonClick,
              hint: this.$t('label.multiEdit'),
              text: this.$t('label.multiEdit'),
              onInitialized: this.onEditButtonInitialized
            },
            showText: 'inMenu',
            sortIndex: 21,
            widget: 'dxButton'
          }
          event.toolbarOptions.items.push(updateButton)
        }
        if (this.tableDeleteAction.active) {
          const deleteButton = {
            locateInMenu: 'auto',
            location: 'after',
            name: 'deleteRowButton',
            options: {
              disabled: false,
              icon: 'trash',
              onClick: this.onDeleteButtonClick,
              hint: this.$t('label.delete'),
              text: this.$t('label.delete'),
              onInitialized: this.onDeleteButtonInitialized
            },
            showText: 'inMenu',
            sortIndex: 25,
            widget: 'dxButton'
          }
          event.toolbarOptions.items.push(deleteButton)
        }

        if (this.excelExportSettings.enabled) {
          const item = event.toolbarOptions.items.find((toolbarItem) => toolbarItem.name === 'exportButton')

          if (item) {
            item.options.onInitialized = this.onExportButtonInitialized
            item.options.disabled = true
            item.locateInMenu = 'never'
          }
        }

        if (this.additionalHeaderButtons && this.additionalHeaderButtons.length > 0) {
          this.additionalHeaderButtons.forEach((item) => {
            event.toolbarOptions.items.push(item)
          })
        }
      }
      if (this.hasMapButton) {
        const mapButton = {
          locateInMenu: 'auto',
          location: 'after',
          name: 'mapButton',
          options: {
            icon: 'map',
            onClick: this.openMap,
            hint: 'map',
            text: 'map'
          },
          showText: 'inMenu',
          sortIndex: 40,
          widget: 'dxButton'
        }
        event.toolbarOptions.items.push(mapButton)
      }
      let columnPicker = event.toolbarOptions.items.find((item) => item.name === 'columnChooserButton')
      if (columnPicker) {
        columnPicker.sortIndex = 39
        columnPicker.options.onInitialized = this.onColumnPickerButtonInitialized
      }
      event.toolbarOptions.items.unshift({
        location: 'before',
        template: 'tableTitleTemplate',
        sortIndex: 0
      })
      event.toolbarOptions.items.sort((a, b) => (a.sortIndex > b.sortIndex ? 1 : -1))
    },
    async onDeleteButtonClick() {
      this.showConfirmMultiDeleteDialog = true
    },
    openMap() {
      this.$emit('openMap')
    },
    async onConfirmMultiDelete() {
      const selectedRowKeys = this.instance.getSelectedRowKeys()
      if (selectedRowKeys.length < 1) {
        this.onInvalidSelection()
        return
      }
      this.deleteRowsWithKeys(selectedRowKeys)
    },
    async deleteRowsWithKeys(selectedRowKeys = []) {
      if (selectedRowKeys.length >= 1) {
        this.instance.cancelEditData()

        const deleteActions = []

        selectedRowKeys.forEach((selectedKey) => {
          deleteActions.push(async () => {
            const rowIndex = this.instance.getRowIndexByKey(selectedKey)
            this.instance.deleteRow(rowIndex)
            await this.tableDeleteAction.method(selectedKey)
            this.instance.refresh()
          })
        })

        // using await in a for loop is not recommended or shouldn't even work properly in JS,
        // but it was the only way we could get multidelete to work for all tables including layers
        for (var deleteAction in deleteActions) {
          await deleteActions[deleteAction]()
        }
      }
    },
    async onInvalidSelection() {
      TiPopup.popup(this.$t('message.Selection_is_required'))
    },
    async onConfirmMultiEdit(multiEditDialogData) {
      this.$store.state.loading = true
      try {
        await this.$store
          .dispatch('multiEdit', {
            projectId: this.tableMultiUpdateAction.projectId,
            tableName: this.tableMultiUpdateAction.tableName,
            selectionIDs: this.instance.getSelectedRowKeys(),
            selectionObjects: (await this.shouldSendFullDataTableOnMultiEdit(this.tableMultiUpdateAction.tableName))
              ? this.tableData
              : await this.getTableDataRowsByRowKeys(this.instance.getSelectedRowKeys(), this.tableDataIdentifier),
            guidColumn: this.tableDataIdentifier,
            dateColumn: this.tableMultiUpdateAction.dateLastChangedField,
            tblMeasurementPoints: this.tableMultiUpdateAction.tblMeasurementPoints,
            tblWaterSamples: this.tableMultiUpdateAction.tblWaterSamples,
            tblGaugingTubes: this.tableMultiUpdateAction.tblGaugingTubes,
            changedObject: multiEditDialogData,
            includeAll: await this.shouldSendFullDataTableOnMultiEdit(this.tableMultiUpdateAction.tableName)
          })
          .then(async (result) => {
            this.$emit('refreshTableData')
            this.$store.state.loading = false
            await TiPopup.alertAsync(this.$t('message.multiple_edit_alert'), null, 'mp-alert')
          })
      } catch (exception) {
        console.log(JSON.stringify(exception))
        this.$store.state.loading = false
        TiPopup.popup(this.$t('message.UnknownError'))
      }
    },
    async shouldSendFullDataTableOnMultiEdit(tableName) {
      if (tableName === 'tblLayers') {
        return true
      }

      return false
    },
    async onUpdateButtonClick(event) {
      const selectedRowKeys = this.instance.getSelectedRowKeys()
      if (selectedRowKeys.length < 1) {
        this.onInvalidSelection()
        return
      }

      if (this.useMultiSelect) {
        if (stringUtils.isNullOrEmptyOrWhitespace(this.tableMultiUpdateAction.projectId)) {
          console.log('Multi edit support requires prop project id to have a value')
          return
        }

        this.onEditRows(selectedRowKeys)
      } else {
        this.onEditRow(selectedRowKeys[0])
      }
    },
    async onEditRow(key) {
      const index = this.instance.getRowIndexByKey(key)
      this.instance.editRow(index, 0)
      if (this.unselectRowOnContentReady >= 0) {
        this.unselectRowOnContentReady = 1
      }
    },
    async onSaveButtonClick(event) {
      if (this.instance.hasEditData()) {
        this.instance.saveEditData()
        return
      }
      this.instance.cancelEditData()
    },
    async onRevertButtonClick(event) {
      this.instance.cancelEditData()
    },
    async onEditRows(selectedRowKeys) {
      if (this.tableMultiUpdateAction.active === false) {
        return
      }

      const multiEditDialogData = await this.multiEditDialog.open({
        title: this.$t('label.multiEdit'),
        buttonLabel: this.$t('button.confirm'),
        subHeader: this.$t('label.numberOfSelectedRows') + ' ' + selectedRowKeys.length
      })

      if (multiEditDialogData) {
        this.onConfirmMultiEdit(multiEditDialogData)
      }
    },
    async onSavingData(action) {
      const editDataObject = { id: null, data: null }
      if (!this.instance.hasEditData()) {
        this.instance.cancelEditData()
        return
      }

      if (action.changes.length < 1) {
        return
      }

      if (action.changes[0].type === 'insert') {
        if (this.tableCreateAction.active) {
          action.cancel = true
          action.component.cancelEditData()
          editDataObject.data = action.changes[0].data
          await this.tableCreateAction.method(editDataObject)
        }
      }
      if (action.changes[0].type === 'update') {
        if (this.tableUpdateAction.active) {
          editDataObject.id = action.changes[0].key
          await action.component.getDataByKeys([editDataObject.id]).then((result) => {
            editDataObject.data = result[0]

            if (editDataObject.data.PrCode) {
              editDataObject.data.PrCode = editDataObject.data.PrCode.replace(/\s+/g, '_')
            }
            return this.tableUpdateAction.method(editDataObject)
          })
        }
      }
      if (action.changes[0].type === 'remove') {
        if (this.tableDeleteAction.active) {
          // When deleting layers, we have to send the full state to the server.
          // If we would allow the DxGrid to update its internal state, we wouldn't be able to do that.
          if (!this.shouldUpdateStateOnDelete) {
            action.cancel = true
          }
        }
      }
    },
    async loadSettings() {
      var response = await this.$store.dispatch('getSettingByName', {
        settingName: this.settingName
      })

      const settingsString = JSON.parse(response.body).SettingValue
      if (settingsString.length > 0) {
        const settings = JSON.parse(settingsString)
        this.$emit('settings-loaded', settings)
        this.settings = settings
        this.settingsLoaded = true
        return settings
      }

      const emptySettings = {}
      this.$emit('settings-loaded', emptySettings)
      this.settingsLoaded = true
      return emptySettings
    },
    async saveSettings(changedSettings) {
      if (!this.settingsLoaded) return

      this.stripDxGridSettings(changedSettings)

      if (_.isEqual(changedSettings, this.settings)) return

      await this.$store.dispatch('updateSetting', {
        settingName: this.settingName,
        settings: changedSettings
      })

      this.settings = changedSettings
    },
    stripDxGridSettings(settings) {
      if (settings.columns) {
        settings.columns.forEach((column) => {
          column.filterValues = []
        })
      }

      if (settings.focusedRowKey) {
        settings.focusedRowKey = null
      }

      if (settings.selectedRowKeys) {
        settings.selectedRowKeys = null
      }

      if (settings.pageIndex || settings.pageIndex === 0) {
        settings.pageIndex = null
      }

      if (settings.allowedPageSizes) {
        settings.allowedPageSizes = null
      }

      if (settings.pageSize || settings.pageSize === 0) {
        settings.pageSize = null
      }

      if (settings.searchText) {
        settings.searchText = null
      }
    },
    async getTableDataRowsByRowKeys(selectedRowKeys, keyName) {
      return this.tableData.filter((row) => {
        return selectedRowKeys.indexOf(row[keyName]) > -1
      })
    },
    formatCellValue(column, value) {
      try {
        switch (column.dataType) {
          case 'date':
            return formatDate(value, 'yyyy-MM-dd')
          case 'datetime':
            // format date time as date when grouping
            if (this.instance.columnOption(column.dataFieldKey).groupIndex > -1) {
              return formatDate(value, 'yyyy-MM-dd')
            }
            return formatDate(value, 'yyyy-MM-dd HH:mm:ss')
          default:
            return null
        }
      } catch (error) {
        this.handleErrorResponse(error)
      }
    },
    naturalSort(a, b, tableColumn) {
      switch (tableColumn.dataType) {
        case 'datetime':
        case 'date':
          if (typeof a === 'undefined') {
            return -1
          }
          if (typeof b === 'undefined') {
            return 1
          }

          // normal sorting
          if (typeof a === 'object' && typeof b === 'object') {
            return a - b
          }
          // sorting while grouped on a column
          if (typeof a === 'string' && typeof b === 'string') {
            return new Date(a) - new Date(b)
          }
      }
      return sortUtils.anotherNaturalSort(a, b)
    },
    customizeColumnText(cellInfo) {
      if (cellInfo && cellInfo.value === 0) {
        return '0'
      }

      return cellInfo.valueText
    },
    async handleErrorResponse(error, errorMessage = 'message.UnknownError') {
      console.log(error)
      TiPopup.popup(this.$t(errorMessage))
    },
    getColumnGroupHeaderTitle(data) {
      const groupTitle = data.column.caption
      let valueTitle = ''

      if (data.column.lookup && data.column.lookup.dataSource) {
        const lookupItem = data.column.lookup.dataSource.find((item) => item.key === data.value)
        if (lookupItem) valueTitle = lookupItem.description
      }

      if (valueTitle === '') {
        valueTitle = data.value
      }

      if (typeof valueTitle === 'undefined' || valueTitle === 'undefined') {
        valueTitle = this.$t('label.Empty')
      }
      return `${groupTitle} - ${valueTitle}`
    }
  }
}
</script>

<style lang="less">
.dx-widget.dx-visibility-change-handler {
  width: 100% !important;
}

.dx-datagrid-header-panel {
  background-color: #eee;
}

.dx-toolbar {
  background-color: #eee;
}

.dx-datagrid-rowsview .dx-row-removed>td {
  background-color: #fc8f8c !important;
  border-top: 1px solid #fc8f8c;
  border-bottom: 1px solid #fc8f8c;
}

.dx-datagrid-rowsview .dx-row-inserted>td {
  border-top: 1px solid #83e183;
  border-bottom: 1px solid #83e183;
}

.dx-datagrid-rowsview .dx-data-row .dx-cell-modified.dx-cell-modified::after,
.dx-datagrid-rowsview .dx-data-row .dx-cell-modified.dx-datagrid-invalid::after {
  border: 0;
}

.dx-datagrid-rowsview .dx-datagrid-invalid {
  background-color: #d9534f !important;
}

.dx-datagrid-header-panel .dx-button-mode-contained {
  border-radius: 0px;
  border: none;
  background-color: #67ac45;
  transition: 0.2s;
}

.dx-button-mode-contained.dx-state-hover {
  background-color: #67ac45;
  transform: scale(0.92);
}

.dx-toolbar-text-auto-hide .dx-button .dx-icon {
  color: #fff;
}

.dx-button-mode-contained .dx-icon {
  color: #fff;
}

.tableTitle {
  color: #333;
  margin: 0px;
  padding: 0px;
  font-size: 1.2rem;
}

.dx-toolbar-item>h2 {
  white-space: nowrap;
}

.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused)>td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused)>tr>td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover>td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover>tr>td {
  background-color: rgb(151, 214, 120);
  color: black;
}

.dx-datagrid-rowsview .dx-row-focused.dx-data-row:not(.dx-edit-row):not(.dx-row-lines)>td,
.dx-datagrid-rowsview .dx-row-focused.dx-data-row:not(.dx-edit-row):not(.dx-row-lines)>tr:first-child>td {
  border-top: 1px solid rgb(103, 172, 69);
}

.dx-datagrid-rowsview .dx-row-focused.dx-data-row:not(.dx-edit-row)>td:not(.dx-focused),
.dx-datagrid-rowsview .dx-row-focused.dx-data-row:not(.dx-edit-row)>tr>td:not(.dx-focused),
.dx-datagrid-rowsview .dx-row-focused.dx-data-row:not(.dx-edit-row) .dx-command-edit .dx-link {
  background-color: rgb(103, 172, 69);
  color: black;
}

.dx-button-content>.dx-icon-close {
  color: black !important;
}

.duplicate.alert {
  position: absolute;
  width: auto;
  z-index: 99;
  transform: translate(-50%, 47px);
  left: 50%;
  background-image: none;
  background-color: #a3dcab;
  border-radius: 0;
  color: rgba(0, 0, 0, 87);
  min-width: 400px;
  text-align: center;
  bottom: 50px;
}

.addIcon,
.deleteIcon,
.duplicateIcon,
.saveIcon {
  font-size: 1.2rem;
  margin: 2px 1px;
}

.dx-checkbox.dx-state-readonly .dx-checkbox-icon {
  border-color: #67ac45;
  background-color: #fff;
}

.terraIndexModal .modal-header {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.terraIndexModal .modal-content {
  border: 0;
}

.dx-datagrid-addrow-button .dx-icon-edit-button-addrow {
  font: 18px/18px DXIcons;
  font-style: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-weight: normal;
  font-stretch: normal;
  font-family: DXIcons;
}

.dx-icon-edit-button-addrow {
  font: 18px/18px DXIcons;
  font-style: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-weight: normal;
  font-stretch: normal;
}

.dx-icon-edit-button-addrow::before {
  content: '\f00b';
}

.dx-header-filter:not(.dx-header-filter-empty) {
  color: #942020;
}

.dx-datagrid.dx-datagrid-borders>.dx-datagrid-pager {
  border: 1px solid #ddd;
}
</style>
