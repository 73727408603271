<template>
 <dx-drop-down-box v-if="gridDataSource" ref="customDropDownBoxRoot"
                   :value.sync="gridBoxValue"
                   :display-expr="displayExpression"
                   :show-clear-button="true"
                   :data-source="gridDataSource"
 >
   <div
     slot="content"
     slot-scope="{}"
   >
     <dx-data-grid
      :data-source="gridDataSource"
      :columns="gridColumns"
      :hover-state-enabled="true"
      :selected-row-keys.sync="gridSelectedRowKeys"
      :allow-column-resizing="true"
      width="100%"
      height="300px"
      @contentReady="refreshDropdown"
     >
    <dx-header-filter
      :visible="true"
    />
     <dx-selection mode="single"/>
      <dx-scrolling mode="infinite"/>
     </dx-data-grid>
   </div>
 </dx-drop-down-box>
</template>

<script>
import { DxDataGrid, DxSelection, DxScrolling, DxHeaderFilter } from 'devextreme-vue/data-grid'
import { DxDropDownBox } from 'devextreme-vue/drop-down-box'
import { bus } from '@/bus'
import translation from '@/configurations/app/config-locales'
import { CodeListService } from '@/services/DataWS/codeListService'
const codeListService = new CodeListService()

export default {
  name: 'customGridDropdown',
  components: {
    DxDataGrid,
    DxSelection,
    DxScrolling,
    DxDropDownBox,
    DxHeaderFilter
  },
  props: {
    cellInfo: Object
  },
  data () {
    return {
      control: null,
      saveAsText: false,
      gridColumns: null,
      gridDataSource: null,
      gridSelectedRowKeys: [],
      gridBoxValue: null
    }
  },
  created () {
    this.control = this.cellInfo.column.customLinkedControl
  },
  mounted () {
    this.gridBoxValue = this.cellInfo.value
    this.buildAndSetColumnsFromControl(this.control)
    this.buildAndSetDataSourceFromControl(this.control)
  },
  watch: {
    gridSelectedRowKeys (value) {
      if (value[0]) {
        this.gridBoxValue = (this.isSavedAsString) ? value[0].localizedText : value[0].value
        bus.$emit('tableEditorClose')
      }
    },
    gridBoxValue (val) {
      this.cellInfo.setValue(val)
    }
  },
  computed: {
    isSavedAsString: {
      get () {
        let firstField = this.control.fields[0]
        let saveAsString = firstField.editorOptions.saveFullTextInsteadOfID !== undefined
        return saveAsString && firstField.editorOptions.saveFullTextInsteadOfID.indexOf(firstField.key) >= 0
      }
    },
    dynamicCodeLists: {
      get () {
        return this.$store.state.dynamicCodeLists
      },
      set (value) {
        this.$store.commit('setDynamicCodeLists', value)
      }
    }
  },
  methods: {
    getSelectedObject (value) { // builds an object that lets listeners understand not only what was selected but for what field
      return {
        'screen': this.control.screen,
        'columnDataField': this.cellInfo.column.dataField,
        'value': value
      }
    },
    displayExpression (item) {
      if (this.cellInfo) {
        return this.cellInfo.text
      }
    },
    refreshDropdown () {
      // might not be needed
    },
    buildAndSetColumnsFromControl (control) { // look at control and textformatkeys to build columns
      let columns = []
      let firstField = control.fields[0]
      firstField.editorOptions.textFormatKeys.forEach((textFormatOption) => {
        let column = {
          dataField: textFormatOption,
          caption: translation.t('gridColumn.' + textFormatOption)
        }
        switch (textFormatOption) {
          case 'localizedCode':
            column.width = '75px'
            break
          case 'localizedText2':
            column.width = '75px'
            break
        }
        columns.push(column)
      })
      this.gridColumns = columns
    },
    buildAndSetDataSourceFromControl (control) {
      let dataSource = []
      // Check if the input control contains a DynamicCodeList
      if (control.inputControlOptions.DynamicCodeListField && control.inputControlOptions.DynamicCodeListMapping) {
        let dynamicCodeListMapping = JSON.parse(control.inputControlOptions.DynamicCodeListMapping)
        if (this.cellInfo.data[control.inputControlOptions.DynamicCodeListField]) {
          let codeListToRetrieve = dynamicCodeListMapping[this.cellInfo.data[control.inputControlOptions.DynamicCodeListField]]
          if (codeListToRetrieve) {
            // Retrieve the needed codelist for the dropdown from the server and set is as the data source
            codeListService.getCodeListByCategoryIDOrCode(codeListToRetrieve)
              .then((response) => {
                response.forEach((GcOption) => {
                  let option = {
                    localizedCode: GcOption.GcShortCode,
                    localizedText: GcOption.GcDescription,
                    localizedText2: GcOption.GcDescription2,
                    value: GcOption.GcCode
                  }
                  dataSource.push(option)
                })
              })
          }
        }
      } else {
        let firstField = control.fields[0]
        // because the templates have an annoying nested array + language field + mapping we have to walk this and correct it
        firstField.options.forEach((option) => {
          option.localizedCode = (option.codeInterface[0] ? option.codeInterface[0][control.languageCode3Letter] : null)
          option.localizedText = (option.text[0] ? option.text[0][control.languageCode3Letter] : null)
          option.localizedText2 = (option.text2[0] ? option.text2[0][control.languageCode3Letter] : null)
          dataSource.push(option)
        })
      }
      this.gridDataSource = dataSource
    }
  }
}
</script>

<style lang="less">

.dx-dropdowneditor-overlay .dx-overlay-content {
  min-width: 300px;
}

</style>
