<template>
  <div>
    <dx-data-grid-wrapper
      v-if="!loading && cadastralOvamVlaremAct && selected.frmCadastralOvam && cadastralOvam.length > 0"
      ref="tableBase"
      :template-name="templateName"
      :controls="controls"
      :data="cadastralOvamVlaremAct"
      :selected="selected"
      :projectId="projectId"
      :row-key="rowKeyName"
      @ti-table-row-removed="rowRemoved"
      @ti-table-row-updated="rowUpdated"
      @ti-table-row-inserted="rowInserted"
      @savingToDataSourceFinished="saveEditedRowsUsingDataService"
      @selectionChanged="onSelectionChanged"
    >
      <div slot="customButtonsRightStart">
        <square-button :width="34" :height="34" :title="$t('button.create')" @click.native="showAddModal">
          <span class="addIcon glyphicons glyphicons-plus"></span>
        </square-button>
      </div>
      <div slot="customButtonsRightEnd">
        <square-button :width="34" :height="34" :title="$t('button.delete')" @click.native="showDeleteModal">
          <span class="addIcon glyphicons glyphicons-bin"></span>
        </square-button>
      </div>
    </dx-data-grid-wrapper>
    <nv-loading :show="loading"></nv-loading>
    <add-cadastral-ovam-vlarem-act-modal v-if="controls" :show="addModalShown" :controls="controls" :PrID="projectId" :CaID="CaID" @create-cadastral-ovam-vlarem-act="addRowToTable" @close-modal="addModalShown = false"></add-cadastral-ovam-vlarem-act-modal>
    <confirm-delete-modal :subHeader="deleteModalSubHeader" :show="deleteModalShown" @delete-confirmed="deleteTableRow" @close-modal="deleteModalShown = false">
      <p>{{ selectedRows > 1? $t("project.deleteCadastralOvamVlaremsActHelp") : $t("project.deleteCadastralOvamVlaremActHelp")}}</p>
    </confirm-delete-modal>
    <table-base />
  </div>
</template>

<script>
import tableBase from '@/components/tables/tableBase'
import nvLoading from '@/components/_shared/loading'
import squareButton from '@/components/_shared/squareButton'
import confirmDeleteModal from '@/components/modal/confirmDeleteModal'
import addCadastralOvamVlaremActModal from '@/components/modal/addCadastralOvamVlaremActModal'
import DxDataGridWrapper from '@/components/tables/dxDataGridWrapper'

import { CadastralOvamService } from '@/services/DataWS/cadastralOvamService'

const cadastralOvamService = new CadastralOvamService()
export default {
  name: 'tableCadastralOvamVlaremAct',
  extends: tableBase,
  components: {
    DxDataGridWrapper, nvLoading, confirmDeleteModal, squareButton, addCadastralOvamVlaremActModal, tableBase
  },
  data () {
    return {
      templateName: 'frmCadastralOvamVlaremAct',
      rowKeyName: 'VlGUID',
      deleteKey: 'VlDeleted',
      dateLastChangedKey: 'VlDateLastChanged',
      tableName: 'cadastralOvamVlaremAct',
      idKey: 'VlID',
      selectedRows: 0
    }
  },
  computed: {
    cadastralOvam: {
      get () {
        return this.$store.state.cadastralOvam
      },
      set (value) {
        this.$store.commit('setCadastralOvams', value)
      }
    },
    cadastralOvamVlaremAct: {
      get () {
        return this.$store.state.cadastralOvamVlaremAct
      },
      set (value) {
        this.$store.commit('setCadastralOvamVlaremActs', value)
      }
    },
    CaID: function () {
      if (this.selected.frmCadastralOvam) {
        return this.selected.frmCadastralOvam.CaID
      } else {
        return null
      }
    },
    CaGUID: function () {
      if (this.selected.frmCadastralOvam) {
        return this.selected.frmCadastralOvam.CaGUID
      } else {
        return null
      }
    }
  },
  watch: {
    'selected.frmCadastralOvam': {
      handler: function (to, from) {
        if (to && to.CaGUID) {
          this.$store.dispatch('fetchCadastralOvamVlaremActs', { PrID: this.projectId, CaGUID: to.CaGUID, CaID: to.CaID })
        }
      },
      deep: true
    },
    'cadastralOvamVlaremAct': {
      handler: function (to, from) {
        if (!this.loading && this.cadastralOvamVlaremAct && this.selected.frmCadastralOvam && this.cadastralOvam.length > 0) {
          this.reUpdateSelected()
        }
      }
    }
  },
  methods: {
    onSelectionChanged (e) {
      this.selectedRows = e.selectedRowKeys.length
    },
    saveRow (row, action) {
      switch (action) {
        case 'INSERT':
          return cadastralOvamService.createCadastralOvamVlaremAct(row, this.projectId, this.CaID)
        case 'DELETE':
          return cadastralOvamService.deleteCadastralOvamVlaremAct(row, this.projectId, this.CaID)
        default:
          return cadastralOvamService.updateCadastralOvamVlaremAct(row, this.projectId, this.CaID)
      }
    },
    reFetchData  () {
      this.cadastralOvamVlaremAct = []
      this.$store.dispatch('fetchCadastralOvamVlaremActs', { PrID: this.projectId, CaGUID: this.CaGUID, CaID: this.CaID })
    }
  }
}
</script>

<style lang="less" scoped>
.addIcon, .deleteIcon{
  font-size: 1.2rem;
  margin: 2px 1px;
}
</style>
