import tableListEditor from '@/components/table-input-controls/tableListEditor.js'
import tableTextEditor from '@/components/table-input-controls/tableTextEditor.js'
import tableNumberEditor from '@/components/table-input-controls/tableNumberEditor.js'
import tableDateEditor from '@/components/table-input-controls/tableDateEditor.js'
import tableTextAreaEditor from '@/components/table-input-controls/tableTextAreaEditor.js'
import tableDropdownEditor from '@/components/table-input-controls/tableDropdownEditor.js'
import tableBooleanEditor from '@/components/table-input-controls/tableBooleanEditor.js'

export default {
  tableListEditor, tableTextEditor, tableNumberEditor, tableDateEditor, tableTextAreaEditor, tableDropdownEditor, tableBooleanEditor
}
