import stringUtils from '@/utils/stringUtils'
import $store from '@/vuex/store'

export default {
  async GetMappings (lookupKey) {
    return [
      {
        id: 2,
        [lookupKey]: 'Z'
      },
      {
        id: 3,
        [lookupKey]: 'G'
      }
    ]
  },
  async getMedianCodesAsDxOptions (lookupKey) {
    let options = []
    const mappings = await this.GetMappings(lookupKey)

    return Promise.all(mappings.map(async (mapEntry) => {
      await $store.dispatch('getCodeListByCategoryIDOrCode', {
        categoryId: mapEntry.id
      }).then(async codeListOptions => {
        if (mapEntry.filterById) {
          codeListOptions = codeListOptions.filter(item => mapEntry.filterById.includes(item.GcCode))
        }
        const optionsForMapEntry = this.createOptionsWithLookupKey(codeListOptions, lookupKey, mapEntry[lookupKey])
        options = options.concat(optionsForMapEntry)
      })
    })).then(() => {
      return options
    })
  },

  createOptionsWithLookupKey (fromOptions, lookupKey, lookupValue) {
    const toOptions = []

    Promise.all(fromOptions.map((fromOption) => {
      const toOption = {
        key: this.createComposedValue(lookupValue, fromOption.GcCode),
        description: fromOption.GcDescription,
        [lookupKey]: lookupValue
      }

      toOptions.push(toOption)
    }))

    return toOptions
  },

  setComposedValueForMedianCodeTableColumn (key, lookupKey, rowData) {
    rowData[key] = this.createComposedValue(rowData[lookupKey], rowData[key])
    return rowData
  },

  createComposedValue (lookupValue, value) {
    if (stringUtils.isNullOrEmptyOrWhitespace(value)) {
      return ''
    }

    return `${lookupValue}.${value}`
  },

  async unsetComposedValueForMedianCodeTableColumn (key, rowData) {
    rowData[key] = await this.getValueFromComposedValue(rowData[key])

    return rowData
  },

  async getValueFromComposedValue (value) {
    if (stringUtils.isNullOrEmptyOrWhitespace(value)) {
      return ''
    }
    return value.includes('.') ? value.split('.')[1] : value
  }
}
