<template>
  <div v-show="show" class="backdrop" @click="hideModal()">
    <div class="terraIndexModal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" @click="hideModal()">&times;</button>
            <h4 class="modal-title">{{$t("label.confirmDeleteModalTitle")}}</h4>
            <small v-if="subHeader" class="h6">{{subHeader}}</small>
          </div>
          <div class="modal-body">
            <slot></slot>
          </div>
          <div class="modal-footer">
            <button class="btn" @click="confirmDelete()">{{$t("project.DeleteButton")}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'confirmDeleteModal',
  props: {
    show: Boolean,
    subHeader: {
      type: String,
      required: false,
      default: null
    }
  },
  methods: {
    hideModal () {
      this.$emit('close-modal')
    },
    confirmDelete () {
      this.$emit('delete-confirmed')
      this.hideModal()
    }
  }
}
</script>

<style lang="less" scoped>
  .terraIndexModal{
    z-index: 1002;
    margin-left: auto;
    margin-right: auto;
    position: relative;
  }
  .backdrop{
    background-color: rgba(0,0,0, 0.5);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1001;
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity .2s;
  }
  .fade-enter, .fade-leave-to{
    opacity: 0;
  }
</style>
