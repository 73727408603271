import editableFields from '@/configurations/app/config-boolean-editables'

export default {
  buildColumnFromTemplate: (control) => {
    /**
     * Checks if a given field is editable.
     * @param {*} fieldName
     */
    const isEditable = (fieldName) => {
      return editableFields.indexOf(fieldName) !== -1
    }

    /**
     * Gets the display state or the value state
     * based on editable state end the control value as truthy falsy
     * @param {*} rowData
     * @param {*} dataField
     */
    const getState = (rowData, dataField) => {
      if (isEditable(dataField)) {
        return rowData[dataField] && rowData[dataField].toString() !== 'false'
      } else {
        return rowData[dataField]
      }
    }

    let column = {
      caption: control.labels[0][control.languageCode3Letter],
      dataField: control.fields[0].key,
      dataType: 'boolean',
      calculateCellValue: (rowData) => {
        return getState(rowData, control.fields[0].key)
      }
    }
    return column
  }
}
