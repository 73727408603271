<template>
  <transition name="fade">
    <div v-show="show" class="backdrop">
      <div class="terraIndexModal">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" @click="hideModal()">&times;</button>
              <h4 class="modal-title">{{$t('cadastralOvam.addCadastralOvamModalTitle')}}</h4>
            </div>
            <div class="modal-body">
              <p>{{$t('cadastralOvam.addCadastralOvamModalHelp')}}</p>
              <dx-tree-list
                :data-source="cadasters"
                key-expr="Key"
                parent-id-expr="RealParentID"
                height="300px"
                :focused-row-enabled="true"
                :focused-row-key.sync="focusedRow"
              >
                <dx-search-panel
                  :visible="true"
                  :width="250"
                />
                <dx-scrolling
                  mode="standard"
                />
                <dx-column data-field="Naam" :caption="$t('cadastralOvam.CadastreName')"></dx-column>
                <dx-column data-field="ParentID" :caption="$t('cadastralOvam.CadastreNIS')"></dx-column>
                <dx-column data-field="Key" :caption="$t('cadastralOvam.CadastreDepartment')"></dx-column>
              </dx-tree-list>
              <p v-if="selected" class="error">{{$t('message.valueRequired')}}</p>
            </div>
            <div class="modal-footer">
              <button class="btn" :disabled="!focusedRow" @click="returnRow()">{{$t('project.CreateButton')}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { DxTreeList, DxSearchPanel, DxColumn, DxScrolling } from 'devextreme-vue/tree-list'
import guidUtils from '@/utils/guidUtils'
import { SpecialSettingsTableService } from '@/services/DataWS/specialSettingsTableService'
const specialSettingsTableService = new SpecialSettingsTableService()

export default {
  name: 'addCadastralOvamModal',
  components: { DxTreeList, DxSearchPanel, DxColumn, DxScrolling },
  props: {
    show: Boolean,
    PrID: Number
  },
  data () {
    return {
      cadasters: [],
      focusedRow: null,
      selected: false
    }
  },
  created () {
    specialSettingsTableService.getCadastreList().then((resp) => {
      this.cadasters = resp
      this.cadasters.forEach((c) => {
        c.RealParentID = c.ParentID
        if (c.Key === c.ParentID) {
          c.RealParentID = null
        }
        if (!c.RealParentID || (!this.cadasters.find(cc => cc.Key === c.ParentID))) {
          c.RealParentID = null
        }
      })
    })
  },
  methods: {
    hideModal () {
      this.$emit('close-modal')
    },
    returnRow () {
      if (this.focusedRow) {
        this.selected = false
        let focusedCadaster = this.cadasters.find((cadaster) => cadaster.Key === this.focusedRow)
        let newCadastralOvam = {
          PrID: this.PrID.toString(),
          CaMunicipality: focusedCadaster.Gemeente,
          CaNISID: focusedCadaster.ParentID,
          CaDepartment: focusedCadaster.Key,
          CaGUID: guidUtils.newGuid(),
          CaID: '-1'
        }
        this.$emit('create-cadastral-ovam', newCadastralOvam)
        this.hideModal()
      } else {
        this.selected = true
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .terraIndexModal{
    z-index: 1002;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    /*top: 50%;
    transform: translateY(calc(-50% - 60px));*/
  }
  .backdrop{
    background-color: rgba(0,0,0, 0.5);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1001;
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity .2s;
  }
  .fade-enter, .fade-leave-to{
    opacity: 0;
  }
</style>
