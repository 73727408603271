<script>
import Vue from 'vue'
import Popup from '@/utils/popup'
import Config from '@/configurations/config'
import stringUtils from '@/utils/stringUtils'

const TiPopup = Popup[Config.platform].default

export default Vue.extend({
  name: 'baseTableOverview',
  data() {
    return {
      project: {}
    }
  },
  // cannot be overridden; override onCreated instead
  async created() {
    await this.onCreated()
  },
  methods: {
    async onCreated() {
      await this.setProject()
    },
    async setProject() {
      if (stringUtils.isNullOrEmptyOrWhitespace(this.$route.params.projectId)) {
        TiPopup.popup(this.$t('message.projectNotFound'))
        return
      }

      const response = await this.getProject()
      if (!response) {
        TiPopup.popup(this.$t('message.projectNotFound'))
        return
      }

      this.project = response
    },
    async getProject() {
      try {
        await this.$store.dispatch('fetchProject', {
          PrID: this.$route.params.projectId
        })

        return this.$store.getters.getCurrentProject
      } catch (error) {
        this.handleErrorResponse(error)
      }
    },
    handleErrorResponse(error, errorMessage = 'message.UnknownError') {
      console.error(error)
      TiPopup.popup(this.$t(errorMessage))
    }
  }
})
</script>

<style scoped>
.data-table-overview h2 {
  margin-top: 5px;
  font-size: 20px;
}
.data-table-overview {
  height: 100%;
}
.data-table-overview-row {
  display: flex;
}
.data-table-overview-row:not(:first-child) {
  padding-top: 10px;
}
.data-table-overview-row .data-table,
.data-table-overview-row .data-map {
  margin-left: 10px;
  overflow: hidden;
}
.data-table-overview-row .data-map {
  flex: 1;
}
</style>
