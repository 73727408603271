<template>
  <div>
    <TerraIndexDynamicTable
      :key="selectedMeasurementObject.MpGuid"
      :tableData="tableData"
      :tableTitle="$t('label.gaugingTubes')"
      :tableDataIdentifier="tableIdentifier"
      :tableCreateAction="createTableRowAction"
      :tableUpdateAction="updateTableRowAction"
      :tableDeleteAction="deleteTableRowAction"
      :tableColumns="tableColumns"
      :tableName="tableName"
      :template="template"
      :settingName="settingName"
      :language="language"
      :excelExportSettings="exportSettings"
      :enableColumnChooser="enableColumnChooser"
      :search-panel-enabled="searchPanelEnabled"
      :state-storing="{}"
      :defaultSelectedRowIndex="0"
      :tableMultiUpdateAction="multiUpdateTableRowAction"
      :useMultiSelect="true"
      :projectId="projectId"
      :isValidRequest="isValidRequest"
      :onRowValidating="customRowValidation"
      scrollingMode="infinite"
      @refreshTableData="refreshTableData"
      @updateSelectedRows="updateSelectedRows"
    />
  </div>
</template>

<script>
import GuiTemplateParser from '@/components/dynamicTables/GuiTemplateParser'
import TerraIndexDynamicTable from '@/components/dynamicTables/TerraIndexDynamicTable'
import Config from '@/configurations/config'
import Cookie from '@/utils/cacheProviders/cookieCacheProvider'
import DataTableUtils from '@/utils/dataTableUtils'
import Popup from '@/utils/popup'
import stringUtils from '@/utils/stringUtils'

const TiPopup = Popup[Config.platform].default

export default {
  name: 'gaugingTubesTable',
  components: {
    TerraIndexDynamicTable
  },
  data() {
    return {
      tableData: [],
      tableColumns: [],
      tableName: 'tblGaugingTubes',
      tableNameForExport: 'tblGaugingTubes',
      tableIdentifier: 'FtGuid',
      dateLastChangedField: 'FtDateLastChanged',
      templateName: 'frmFiltertubeGrid',
      template: {},
      settingName: 'Filtertube_Settings'
    }
  },
  async created() {
    await this.getTableColumns()
  },
  props: {
    projectId: {
      type: String,
      required: true
    },
    projectCode: {
      type: String,
      required: true
    },
    selectedMeasurementObject: {
      type: Object,
      required: true
    },
    updateSelectedRows: {
      type: Function,
      required: false,
      default() {
        return () => {}
      }
    },
    enableCreateTableRowAction: {
      required: false,
      default() {
        return true
      }
    },
    enableDeleteTableRowAction: {
      required: false,
      default() {
        return true
      }
    },
    enableUpdateTableRowAction: {
      required: false,
      default() {
        return true
      }
    },
    enableColumnChooser: {
      required: false,
      default() {
        return true
      }
    },
    searchPanelEnabled: {
      required: false,
      default() {
        return true
      }
    },
    enableExportSettings: {
      required: false,
      default() {
        return true
      }
    }
  },
  watch: {
    projectId: {
      deep: true,
      immediate: true,
      handler: async function () {
        await this.refreshTableData()
      }
    },
    selectedMeasurementObject: {
      deep: true,
      immediate: true,
      handler: async function () {
        await this.refreshTableData()
      }
    }
  },
  computed: {
    createTableRowAction() {
      return { active: this.enableCreateTableRowAction, method: this.createTableRow }
    },
    updateTableRowAction() {
      return { active: this.enableUpdateTableRowAction, method: this.updateTableRow }
    },
    deleteTableRowAction() {
      return { active: this.enableDeleteTableRowAction, method: this.deleteTableRow }
    },
    language() {
      return Cookie.get('language')
    },
    exportSettings() {
      return {
        enabled: this.enableExportSettings,
        fileName: `${this.projectCode}_${this.tableNameForExport}`
      }
    },
    isValidRequest() {
      return this.isProjectIdSet && this.isMeasurementObjectGuidSet
    },
    selectedMeasurementObjectGuid() {
      return this.selectedMeasurementObject && this.selectedMeasurementObject.MpGuid ? this.selectedMeasurementObject.MpGuid : ''
    },
    isProjectIdSet() {
      return stringUtils.isNullOrEmptyOrWhitespace(this.projectId) === false
    },
    isMeasurementObjectGuidSet() {
      return stringUtils.isNullOrEmptyOrWhitespace(this.selectedMeasurementObjectGuid) === false
    },
    multiUpdateTableRowAction() {
      return {
        active: true,
        projectId: this.projectId,
        tableName: this.tableName,
        dateLastChangedField: this.dateLastChangedField,
        tblMeasurementPoints: [this.selectedMeasurementObject]
      }
    }
  },
  methods: {
    customRowValidation(event) {
      if (event.isValid === false) {
        return
      }

      if (event.newData.FtName) {
        event.isValid = this.validateNameIsUnique(event)

        if (event.isValid === false) {
          event.errorText = this.$t('message.nameShouldUnique')
          return
        }
      }

      if (event.newData.FtFilterTo || event.newData.FtFilterFrom) {
        this.validateDepths(event)

        if (event.isValid === false) {
          event.errorText = this.$t('message.toShouldHigherThanFrom')
        }
      }
    },
    validateDepths(event) {
      const fromToValidate = event.oldData ? event.newData.FtFilterFrom || event.oldData.FtFilterFrom : event.newData.FtFilterFrom
      const toToValidate = event.oldData ? event.newData.FtFilterTo || event.oldData.FtFilterTo : event.newData.FtFilterTo

      if (Number(fromToValidate) >= Number(toToValidate)) {
        event.isValid = false
      }
    },
    validateNameIsUnique(event) {
      const validationContext = {
        dataTable: this.tableData,
        propertyToValidate: 'FtName',
        guidToValidate: this.tableIdentifier,
        event: event
      }
      return DataTableUtils.ValidatePropertyIsUniqueInDataTable(validationContext)
    },
    async createTableRow(row) {
      try {
        const record = {
          ...row.data
        }

        const gaugingTubesTable = await this.$store.dispatch('createGaugingTube', {
          gaugingTubes: [record],
          projectId: this.projectId,
          measurementPointGuid: this.selectedMeasurementObjectGuid
        })

        this.tableData = gaugingTubesTable
      } catch (error) {
        await this.handleErrorResponse(error)
      }
    },
    async updateTableRow(row) {
      try {
        const record = {
          ...row.data,
          [this.dateLastChangedField]: await DataTableUtils.GenerateLastUpdatedAt()
        }

        await this.$store.dispatch('updateGaugingTube', {
          gaugingTube: record,
          projectId: this.projectId,
          measurementPointGuid: this.selectedMeasurementObjectGuid
        })
      } catch (error) {
        await this.handleErrorResponse(error)
      }
    },
    async deleteTableRow(key) {
      try {
        await this.$store.dispatch('deleteGaugingTube', {
          projectId: this.projectId,
          measurementPointGuid: this.selectedMeasurementObjectGuid,
          gaugingTubeId: key
        })
      } catch (error) {
        await this.handleErrorResponse(error)
      }
    },
    async refreshTableData() {
      if (this.isValidRequest) {
        const response = await this.getTableData()
        this.tableData = response
      } else {
        await this.clearTableData()
      }
    },
    async clearTableData() {
      this.tableData = []
    },
    async getTableData() {
      try {
        return await this.$store.dispatch('getGaugingTubes', {
          projectId: this.projectId,
          measurementPointGuid: this.selectedMeasurementObjectGuid
        })
      } catch (error) {
        await this.handleErrorResponse(error)
      }
    },
    async getTableColumns() {
      try {
        this.tableColumns = await this.$store
          .dispatch('getDefaultFieldTemplate', {
            templateName: this.templateName
          })
          .then((templateData) => {
            this.template = templateData
            return GuiTemplateParser.parseTemplate(templateData)
          })
      } catch (error) {
        await this.handleErrorResponse(error)
      }
    },
    async handleErrorResponse(error, errorMessage = 'message.UnknownError') {
      console.log(error)
      TiPopup.popup(this.$t(errorMessage))
    }
  }
}
</script>
