<template>
  <div>
    <TerraIndexDynamicTable
      :tableData="tableData"
      :tableTitle="$t('tableTitles.FieldSample')"
      :tableDataIdentifier="tableIdentifier"
      :tableCreateAction="createTableRowAction"
      :tableUpdateAction="updateTableRowAction"
      :tableDeleteAction="deleteTableRowAction"
      :tableColumns="tableColumns"
      :tableName="tableName"
      :template="template"
      :settingName="settingName"
      :language="language"
      :excelExportSettings="exportSettings"
      :enableColumnChooser="enableColumnChooser"
      :state-storing="{}"
      :defaultSelectedRowIndex="0"
      :tableMultiUpdateAction="multiUpdateTableRowAction"
      :useMultiSelect="true"
      :projectId="projectId"
      :isValidRequest="isValidRequest"
      :onRowValidating="customRowValidation"
      scrollingMode="infinite"
      :searchPanelEnabled="searchPanelEnabled"
      @refreshTableData="refreshTableData"
    />
  </div>
</template>

<script>
import GuiTemplateParser from '@/components/dynamicTables/GuiTemplateParser'
import TerraIndexDynamicTable from '@/components/dynamicTables/TerraIndexDynamicTable'
import Config from '@/configurations/config'
import Cookie from '@/utils/cacheProviders/cookieCacheProvider'
import DataTableUtils from '@/utils/dataTableUtils'
import Popup from '@/utils/popup'
import stringUtils from '@/utils/stringUtils'

const TiPopup = Popup[Config.platform].default

export default {
  name: 'fieldSamplesTable',
  components: {
    TerraIndexDynamicTable
  },
  data() {
    return {
      tableData: [],
      tableColumns: [],
      tableName: 'tblJars',
      tableNameForExport: 'tblJars',
      tableIdentifier: 'JrGuid',
      dateLastChangedField: 'JrDateLastChanged',
      templateName: 'frmJarsGrid',
      template: {},
      settingName: 'Jars_Settings'
    }
  },
  async created() {
    this.template = await this.getTemplateData(this.templateName)
    this.tableColumns = this.getTableColumns(this.template)
  },
  props: {
    projectId: {
      type: String,
      required: true
    },
    projectCode: {
      type: String,
      required: true
    },
    selectedMeasurementObject: {
      type: Object,
      required: true
    },
    enableCreateTableRowAction: {
      required: false,
      default() {
        return true
      }
    },
    enableDeleteTableRowAction: {
      required: false,
      default() {
        return true
      }
    },
    enableUpdateTableRowAction: {
      required: false,
      default() {
        return true
      }
    },
    enableColumnChooser: {
      required: false,
      default() {
        return true
      }
    },
    searchPanelEnabled: {
      required: false,
      default() {
        return true
      }
    },
    enableExportSettings: {
      required: false,
      default() {
        return true
      }
    }
  },
  watch: {
    projectId: {
      deep: true,
      immediate: true,
      handler: async function () {
        await this.refreshTableData()
      }
    },
    selectedMeasurementObject: {
      deep: true,
      immediate: true,
      handler: async function () {
        await this.refreshTableData()
      }
    }
  },
  computed: {
    createTableRowAction() {
      return { active: this.enableCreateTableRowAction, method: this.createTableRow }
    },
    updateTableRowAction() {
      return { active: this.enableUpdateTableRowAction, method: this.updateTableRow }
    },
    deleteTableRowAction() {
      return { active: this.enableDeleteTableRowAction, method: this.deleteTableRow }
    },
    language() {
      return Cookie.get('language')
    },
    exportSettings() {
      return {
        enabled: this.enableExportSettings,
        fileName: `${this.projectCode}_${this.tableNameForExport}`
      }
    },
    isValidRequest() {
      return this.isProjectIdSet && this.isMeasurementObjectGuidSet
    },
    selectedMeasurementObjectGuid() {
      return this.selectedMeasurementObject && this.selectedMeasurementObject.MpGuid ? this.selectedMeasurementObject.MpGuid : ''
    },
    isProjectIdSet() {
      return stringUtils.isNullOrEmptyOrWhitespace(this.projectId) === false
    },
    isMeasurementObjectGuidSet() {
      return stringUtils.isNullOrEmptyOrWhitespace(this.selectedMeasurementObjectGuid) === false
    },
    multiUpdateTableRowAction() {
      return {
        active: true,
        projectId: this.projectId,
        tableName: this.tableName,
        dateLastChangedField: this.dateLastChangedField,
        tblMeasurementPoints: [this.selectedMeasurementObject]
      }
    }
  },
  methods: {
    customRowValidation(event) {
      if (event.isValid === false) {
        return
      }

      if (event.newData.JrName) {
        event.isValid = this.validateNameIsUnique(event)

        if (event.isValid === false) {
          event.errorText = this.$t('message.nameShouldUnique')
          return
        }
      }

      if (event.newData.JrTo || event.newData.JrFrom) {
        this.validateDepths(event)

        if (event.isValid === false) {
          event.errorText = this.$t('message.toShouldHigherThanFrom')
        }
      }
    },
    validateNameIsUnique(event) {
      const validationContext = {
        dataTable: this.tableData,
        propertyToValidate: 'JrName',
        guidToValidate: this.tableIdentifier,
        event: event
      }
      return DataTableUtils.ValidatePropertyIsUniqueInDataTable(validationContext)
    },
    validateDepths(event) {
      const fromToValidate = event.oldData ? event.newData.JrFrom || event.oldData.JrFrom : event.newData.JrFrom
      const toToValidate = event.oldData ? event.newData.JrTo || event.oldData.JrTo : event.newData.JrTo

      if (Number(fromToValidate) >= Number(toToValidate)) {
        event.isValid = false
      }
    },
    async createTableRow(row) {
      try {
        const record = {
          ...row.data
        }

        const soilSamplesTable = await this.$store.dispatch('createSoilSample', {
          soilSample: record,
          projectId: this.projectId,
          measurementPointGuid: this.selectedMeasurementObjectGuid
        })

        this.tableData = soilSamplesTable
      } catch (error) {
        await this.handleErrorResponse(error)
      }
    },
    async updateTableRow(row) {
      try {
        const record = {
          ...row.data,
          [this.dateLastChangedField]: await DataTableUtils.GenerateLastUpdatedAt()
        }

        await this.$store.dispatch('updateSoilSample', {
          soilSample: record,
          projectId: this.projectId,
          measurementPointGuid: this.selectedMeasurementObjectGuid
        })
      } catch (error) {
        await this.handleErrorResponse(error)
      }
    },
    async deleteTableRow(key) {
      try {
        await this.$store.dispatch('deleteSoilSample', {
          soilSampleGuid: key,
          projectId: this.projectId,
          measurementPointGuid: this.selectedMeasurementObjectGuid
        })
      } catch (error) {
        await this.handleErrorResponse(error)
      }
    },
    async refreshTableData() {
      if (this.isValidRequest) {
        try {
          this.tableData = await this.$store.dispatch('getSoilSamples', {
            projectId: this.projectId,
            measurementPointGuid: this.selectedMeasurementObjectGuid
          })
        } catch (error) {
          await this.handleErrorResponse(error)
        }
      } else {
        this.clearTableData()
      }
    },
    clearTableData() {
      this.tableData = []
    },
    async getTemplateData(templateName) {
      try {
        const templateData = await this.$store.dispatch('getDefaultFieldTemplate', {
          templateName: templateName
        })
        return templateData
      } catch (error) {
        await this.handleErrorResponse(error)
      }
    },
    getTableColumns(templateData) {
      let tableColumns = GuiTemplateParser.parseTemplate(templateData)
      return tableColumns
    },
    async handleErrorResponse(error, errorMessage = 'message.UnknownError') {
      console.log(error)
      TiPopup.popup(this.$t(errorMessage))
    }
  }
}
</script>
