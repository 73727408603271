<template>
  <div>
    <TerraIndexDynamicTable
      scrollingMode="infinite"
      :tableData="tableData"
      :tableTitle="$t('tableTitles.Layers')"
      :tableDataIdentifier="tableIdentifier"
      :tableCreateAction="createTableRowAction"
      :tableUpdateAction="updateTableRowAction"
      :tableDeleteAction="deleteTableRowAction"
      :tableColumns="tableColumns"
      :tableName="tableName"
      :template="template"
      :settingName="settingName"
      :language="language"
      :excelExportSettings="exportSettings"
      :enableColumnChooser="enableColumnChooser"
      :state-storing="{}"
      :defaultSelectedRowIndex="0"
      :tableMultiUpdateAction="multiUpdateTableRowAction"
      :useMultiSelect="true"
      :projectId="projectId"
      :isValidRequest="isValidRequest"
      :onEditorPreparing="onEditorPreparing"
      :onRowValidating="customRowValidation"
      :shouldUpdateStateOnDelete="false"
      :searchPanelEnabled="searchPanelEnabled"
      @refreshTableData="refreshTableData"
    />
  </div>
</template>

<script>
import GuiTemplateParser from '@/components/dynamicTables/GuiTemplateParser'
import TerraIndexDynamicTable from '@/components/dynamicTables/TerraIndexDynamicTable'
import Config from '@/configurations/config'
import Cookie from '@/utils/cacheProviders/cookieCacheProvider'
import DataTableUtils from '@/utils/dataTableUtils'
import MedianUtils from '@/utils/medianUtils'
import Popup from '@/utils/popup'
import stringUtils from '@/utils/stringUtils'
import dateFormatter from '@/utils/dateFormatter'
import { Promise } from 'bluebird'

const TiPopup = Popup[Config.platform].default

export default {
  name: 'layersTable',
  components: {
    TerraIndexDynamicTable
  },
  data() {
    return {
      tableData: [],
      tableColumns: [],
      tableName: 'tblLayers',
      tableNameForExport: 'tblLayers',
      tableIdentifier: 'LaGuid',
      dateLastChangedField: 'LaDateLastChanged',
      templateName: 'frmLayersGrid',
      template: {},
      settingName: 'Layers_Settings',
      medianCodePropertyKey: 'LaMedianCode',
      soilTypeCodePropertyKey: 'LaSoilTypeCode',
      fromPropertyKey: 'LaFrom',
      toPropertyKey: 'LaTo',
      medianCodes: [],
      optionsForPropertyFrom: [],
      relationLang: {
        nl: 'nld',
        en: 'eng',
        fr: 'fra',
        es: 'spa',
        de: 'deu',
        it: 'ita'
      }
    }
  },
  async created() {
    this.template = await this.getTemplateData(this.templateName)
    this.tableColumns = this.getTableColumns(this.template)
    this.medianCodes = await this.getMedianCodes()
  },
  props: {
    projectId: {
      type: String,
      required: true
    },
    projectCode: {
      type: String,
      required: true
    },
    selectedMeasurementObject: {
      type: Object,
      required: true
    },
    enableCreateTableRowAction: {
      required: false,
      default() {
        return true
      }
    },
    enableDeleteTableRowAction: {
      required: false,
      default() {
        return true
      }
    },
    enableUpdateTableRowAction: {
      required: false,
      default() {
        return true
      }
    },
    enableColumnChooser: {
      required: false,
      default() {
        return true
      }
    },
    searchPanelEnabled: {
      required: false,
      default() {
        return true
      }
    },
    enableExportSettings: {
      required: false,
      default() {
        return true
      }
    }
  },
  watch: {
    projectId: {
      deep: true,
      immediate: true,
      handler: async function () {
        await this.refreshTableData()
      }
    },
    selectedMeasurementObject: {
      deep: true,
      immediate: true,
      handler: async function () {
        await this.refreshTableData()
      }
    }
  },
  computed: {
    createTableRowAction() {
      return { active: this.enableCreateTableRowAction, method: this.createTableRow }
    },
    updateTableRowAction() {
      return { active: this.enableUpdateTableRowAction, method: this.updateTableRow }
    },
    deleteTableRowAction() {
      return { active: this.enableDeleteTableRowAction, method: this.deleteTableRow }
    },
    language() {
      return Cookie.get('language')
    },
    exportSettings() {
      return {
        enabled: this.enableExportSettings,
        fileName: `${this.projectCode}_${this.tableNameForExport}`
      }
    },
    isValidRequest() {
      return this.isProjectIdSet && this.isMeasurementObjectGuidSet
    },
    selectedMeasurementObjectGuid() {
      return this.selectedMeasurementObject && this.selectedMeasurementObject.MpGuid ? this.selectedMeasurementObject.MpGuid : ''
    },
    isProjectIdSet() {
      return stringUtils.isNullOrEmptyOrWhitespace(this.projectId) === false
    },
    isMeasurementObjectGuidSet() {
      return stringUtils.isNullOrEmptyOrWhitespace(this.selectedMeasurementObjectGuid) === false
    },
    multiUpdateTableRowAction() {
      return {
        active: true,
        projectId: this.projectId,
        tableName: this.tableName,
        dateLastChangedField: this.dateLastChangedField,
        tblMeasurementPoints: [this.selectedMeasurementObject]
      }
    }
  },
  methods: {
    onEditorPreparing(event) {
      if (event.parentType === 'dataRow' && event.dataField === this.medianCodePropertyKey) {
        event.editorOptions.disabled = stringUtils.isNullOrEmptyOrWhitespace(event.row.data[this.soilTypeCodePropertyKey])
      }

      if (event.parentType === 'dataRow' && event.dataField === this.fromPropertyKey) {
        if (event.value || event.value === 0) {
          return
        }
        const tableMaxToValue = this.getMaxPropertyToValue(this.toPropertyKey, this.tableData)
        event.editorOptions.value = tableMaxToValue
        event.setValue(tableMaxToValue)
      }
    },
    async customRowValidation(event) {
      if (event.isValid === false) {
        return
      }

      if (event.newData.LaTo || event.newData.LaFrom) {
        this.validateDepths(event)

        if (event.isValid === false) {
          event.errorText = this.$t('message.toShouldHigherThanFrom')
        }
      }
    },
    async validateDepths(event) {
      const fromToValidate = event.oldData ? event.newData.LaFrom || event.oldData.LaFrom : event.newData.LaFrom
      const toToValidate = event.oldData ? event.newData.LaTo || event.oldData.LaTo : event.newData.LaTo

      if (Number(fromToValidate) >= Number(toToValidate)) {
        event.isValid = false
      }
    },
    async createTableRow(row) {
      try {
        const record = {
          ...row.data
        }

        const isLowestLayerDepthCreated = this.tableData.length === 0 ? false : record.LaTo > this.tableData.reduce(this.reduceToLowestLayer).LaTo

        const updatedLayersTable = await this.$store.dispatch('createLayer', {
          layer: record,
          projectId: this.projectId,
          measurementPointGuid: this.selectedMeasurementObjectGuid
        })
        if (isLowestLayerDepthCreated) {
          this.setMeasurementPointDepth(updatedLayersTable)
        }
        await this.refreshTableData(updatedLayersTable)
      } catch (error) {
        await this.handleErrorResponse(error)
      }
    },
    async updateTableRow(row) {
      try {
        const record = await this.alterTableRowForServer(this.medianCodePropertyKey, row.data)
        record[this.dateLastChangedField] = await DataTableUtils.GenerateLastUpdatedAt()

        const isLowestLayerDepthUpdated = this.tableData.length === 0 ? false : record.LaID === this.tableData.reduce(this.reduceToLowestLayer).LaID

        const updatedLayersTable = await this.$store.dispatch('updateLayer', {
          layer: record,
          projectId: this.projectId,
          measurementPointGuid: this.selectedMeasurementObjectGuid
        })
        if (isLowestLayerDepthUpdated) {
          this.setMeasurementPointDepth(updatedLayersTable)
        }
        await this.refreshTableData(updatedLayersTable)
      } catch (error) {
        await this.handleErrorResponse(error)
      }
    },
    async deleteTableRow(key) {
      try {
        await this.$store.dispatch('deleteLayer', {
          projectId: this.projectId,
          measurementPointGuid: this.selectedMeasurementObjectGuid,
          layerGuid: key,
          layers: this.tableData // deleteLayer requests expects the full data table of layers
        })
        await this.refreshTableData()
      } catch (error) {
        await this.handleErrorResponse(error)
      }
    },
    async refreshTableData(updatedTable = null) {
      if (updatedTable) {
        this.tableData = updatedTable
        this.optionsForPropertyFrom = this.getOptionsForPropertyFrom(updatedTable)
      } else if (this.isValidRequest) {
        const response = await this.getTableData()
        this.tableData = response
        this.optionsForPropertyFrom = this.getOptionsForPropertyFrom(response)
      } else {
        this.clearTableData()
      }
    },
    clearTableData() {
      this.tableData = []
      this.optionsForPropertyFrom = []
    },
    async getTableData() {
      try {
        return await this.$store
          .dispatch('getLayers', {
            projectId: this.projectId,
            measurementpointGuid: this.selectedMeasurementObjectGuid
          })
          .then(async (response) => {
            return this.alterTableRowsForDisplay(this.medianCodePropertyKey, this.soilTypeCodePropertyKey, response)
          })
      } catch (error) {
        await this.handleErrorResponse(error)
      }
    },
    async getTemplateData(templateName) {
      try {
        const templateData = await this.$store.dispatch('getDefaultFieldTemplate', {
          templateName: templateName
        })
        return templateData
      } catch (error) {
        await this.handleErrorResponse(error)
      }
    },
    getTableColumns(templateData) {
      let tableColumns = GuiTemplateParser.parseTemplate(templateData)
      tableColumns = this.alterTableColumns(tableColumns)
      return tableColumns
    },
    alterTableColumns(tableColumns) {
      let alteredTableColumns = this.alterMedianCodeTableColumn(tableColumns)
      alteredTableColumns = this.alterFromTableColumn(tableColumns)
      alteredTableColumns = this.setMedianCodeValueOnSoilTypeCodeValueChange(alteredTableColumns)
      return alteredTableColumns
    },
    setMedianCodeValueOnSoilTypeCodeValueChange(tableColumns) {
      const soilTypeCodeTableColumn = tableColumns.find((item) => item.dataFieldKey === this.soilTypeCodePropertyKey)
      if (!soilTypeCodeTableColumn) {
        return
      }

      soilTypeCodeTableColumn.setCellValue = (rowData, value) => {
        rowData[this.soilTypeCodePropertyKey] = value
        rowData[this.medianCodePropertyKey] = null
      }
      return tableColumns
    },
    alterMedianCodeTableColumn(tableColumns) {
      const alterTableColumn = tableColumns.find((item) => item.dataFieldKey === this.medianCodePropertyKey)
      if (!alterTableColumn) {
        return tableColumns
      }

      if (!alterTableColumn.validations || !alterTableColumn.validations.dxLookupValidationProperties) {
        return tableColumns
      }

      alterTableColumn.validations.dxLookupValidationProperties.options = (options) => {
        const key = this.soilTypeCodePropertyKey
        return {
          store: this.medianCodes,
          filter: options.data && options.data[key] ? [key, '=', options.data[key]] : null
        }
      }
      return tableColumns
    },
    alterFromTableColumn(tableColumns) {
      const alterTableColumn = tableColumns.find((item) => item.dataFieldKey === this.fromPropertyKey)
      if (!alterTableColumn) {
        return tableColumns
      }

      if (!alterTableColumn.validations || !alterTableColumn.validations.dxLookupValidationProperties) {
        return tableColumns
      }

      alterTableColumn.validations.dxLookup = true
      alterTableColumn.validations.dxLookupValidationProperties.displayProperty = 'description'
      alterTableColumn.validations.dxLookupValidationProperties.valueProperty = 'key'
      alterTableColumn.validations.dxLookupValidationProperties.options = (options) => {
        return {
          store: this.optionsForPropertyFrom,
          filter: options && options.data && options.data[this.toPropertyKey] ? ['key', '<', Number(options.data[this.toPropertyKey])] : null
        }
      }
      return tableColumns
    },
    getOptionsForPropertyFrom(tableData) {
      const options = tableData.map((row) => parseInt(row[this.fromPropertyKey]))
      if (!options.includes(0)) {
        options.splice(0, 0, 0)
      }
      const maxValue = this.getMaxPropertyToValue(this.toPropertyKey, tableData)
      if (maxValue > 0) {
        options.push(maxValue)
      }

      return options.map((item) => {
        return {
          description: item,
          key: item
        }
      })
    },
    async alterTableRowsForDisplay(medianCodePropertyKey, soilTypeCodePropertyKey, tableData) {
      return Promise.all(
        tableData.map(async (rowData) => {
          rowData = await MedianUtils.setComposedValueForMedianCodeTableColumn(medianCodePropertyKey, soilTypeCodePropertyKey, rowData)
        })
      ).then(() => tableData)
    },
    async alterTableRowForServer(medianCodePropertyKey, rowData) {
      rowData = await MedianUtils.unsetComposedValueForMedianCodeTableColumn(medianCodePropertyKey, rowData)
      return rowData
    },
    async getMedianCodes() {
      return MedianUtils.getMedianCodesAsDxOptions(this.soilTypeCodePropertyKey, this.relationLang[this.language])
    },
    async handleErrorResponse(error, errorMessage = 'message.UnknownError') {
      console.log(error)
      TiPopup.popup(this.$t(errorMessage))
    },
    async setMeasurementPointDepth(layers) {
      this.selectedMeasurementObject.MpDepth = layers.reduce(this.reduceToLowestLayer).LaTo
      this.selectedMeasurementObject.MpDateLastChanged = dateFormatter.generateDateLastChangedValue()
      await this.$store.dispatch('updateMeasurementPoint', {
        measurementPoint: this.selectedMeasurementObject
      })
    },
    reduceToLowestLayer(a, b) {
      return Number(a.LaTo) > Number(b.LaTo) ? a : b
    },
    getMaxPropertyToValue(fieldKey, tableData) {
      if (tableData.length === 0) {
        return 0
      }
      return Math.max(...tableData.map((row) => parseInt(row[fieldKey])))
    }
  }
}
</script>
