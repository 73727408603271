<template>
  <div>
    <TerraIndexDynamicTable
      scrollingMode="infinite"
      :tableData="tableData"
      :tableTitle="$t('label.Packaging')"
      :tableDataIdentifier="tableIdentifier"
      :tableCreateAction="createTableRowAction"
      :tableUpdateAction="updateTableRowAction"
      :tableDeleteAction="deleteTableRowAction"
      :tableColumns="tableColumns"
      :tableName="tableName"
      :template="template"
      :settingName="settingName"
      :language="language"
      :excelExportSettings="exportSettings"
      :enableColumnChooser="enableColumnChooser"
      :search-panel-enabled="searchPanelEnabled"
      :onRowValidating="customRowValidation"
      :state-storing="{}"
      :defaultSelectedRowIndex="0"
      :tableMultiUpdateAction="multiUpdateTableRowAction"
      :useMultiSelect="true"
      :projectId="projectId"
      :isValidRequest="isValidRequest"
      @refreshTableData="refreshTableData"
    />
  </div>
</template>

<script>
import TerraIndexDynamicTable from '@/components/dynamicTables/TerraIndexDynamicTable'
import GuiTemplateParser from '@/components/dynamicTables/GuiTemplateParser'
import Cookie from '@/utils/cacheProviders/cookieCacheProvider'
import stringUtils from '@/utils/stringUtils'
import Popup from '@/utils/popup'
import Config from '@/configurations/config'
import DataTableUtils from '@/utils/dataTableUtils'

const TiPopup = Popup[Config.platform].default

export default {
  name: 'packagingsTable',
  components: {
    TerraIndexDynamicTable
  },
  data() {
    /*
     * Air samples are treated nearly identically to water samples. The analogue
     * of bottles here is packagings, thus we use the methods used for bottles to connect
     * packagings to air samples.
     */
    return {
      tableData: [],
      tableColumns: [],
      tableName: 'tblBottles',
      tableNameForExport: 'tblBottles',
      tableIdentifier: 'BoGuid',
      dateLastChangedField: 'BoDateLastChanged',
      templateName: 'frmBottleGrid',
      template: {},
      settingName: 'Packagings_Settings'
    }
  },
  async created() {
    await this.getTableColumns()
  },
  props: {
    projectId: {
      type: String,
      required: true
    },
    projectCode: {
      type: String,
      required: true
    },
    selectedMeasurementObject: {
      type: Object,
      required: true
    },
    selectedAirSample: {
      type: Object,
      required: true
    },
    selectedGaugingTube: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    },
    enableCreateTableRowAction: {
      required: false,
      default() {
        return true
      }
    },
    enableDeleteTableRowAction: {
      required: false,
      default() {
        return true
      }
    },
    enableUpdateTableRowAction: {
      required: false,
      default() {
        return true
      }
    },
    enableColumnChooser: {
      required: false,
      default() {
        return true
      }
    },
    searchPanelEnabled: {
      required: false,
      default() {
        return true
      }
    },
    enableExportSettings: {
      required: false,
      default() {
        return true
      }
    }
  },
  watch: {
    projectId: {
      deep: true,
      immediate: true,
      handler: async function () {
        await this.refreshTableData()
      }
    },
    selectedMeasurementObject: {
      deep: true,
      immediate: true,
      handler: async function () {
        await this.refreshTableData()
      }
    },
    selectedGaugingTube: {
      deep: true,
      immediate: true,
      handler: async function () {
        await this.refreshTableData()
      }
    },
    selectedAirSample: {
      deep: true,
      immediate: true,
      handler: async function () {
        await this.refreshTableData()
      }
    }
  },
  computed: {
    createTableRowAction() {
      return { active: this.enableCreateTableRowAction, method: this.createTableRow }
    },
    updateTableRowAction() {
      return { active: this.enableDeleteTableRowAction, method: this.updateTableRow }
    },
    deleteTableRowAction() {
      return { active: this.enableDeleteTableRowAction, method: this.deleteTableRow }
    },
    language() {
      return Cookie.get('language')
    },
    exportSettings() {
      return {
        enabled: this.enableColumnChooser,
        fileName: `${this.projectCode}_${this.tableNameForExport}`
      }
    },
    isValidRequest() {
      return (
        this.isProjectIdSet &&
        this.isMeasurementObjectGuidSet &&
        this.isAirSampleGuidSet
      )
    },
    selectedMeasurementObjectGuid() {
      return this.selectedMeasurementObject &&
        this.selectedMeasurementObject.MpGuid
        ? this.selectedMeasurementObject.MpGuid
        : ''
    },
    selectedAirSampleGuid() {
      return this.selectedAirSample ? this.selectedAirSample.WsGuid : ''
    },
    selectedGaugingTubeGuid() {
      return this.selectedGaugingTube && this.selectedGaugingTube.FtGuid
        ? this.selectedGaugingTube.FtGuid
        : ''
    },
    isProjectIdSet() {
      return stringUtils.isNullOrEmptyOrWhitespace(this.projectId) === false
    },
    isMeasurementObjectGuidSet() {
      return (
        stringUtils.isNullOrEmptyOrWhitespace(this.selectedMeasurementObjectGuid) ===
        false
      )
    },
    isAirSampleGuidSet() {
      return (
        stringUtils.isNullOrEmptyOrWhitespace(this.selectedAirSampleGuid) === false
      )
    },
    isGaugingTubeGuidSet() {
      return (
        stringUtils.isNullOrEmptyOrWhitespace(this.selectedGaugingTubeGuid) === false
      )
    },
    multiUpdateTableRowAction() {
      const request = {
        active: true,
        projectId: this.projectId,
        tableName: this.tableName,
        dateLastChangedField: this.dateLastChangedField,
        tblMeasurementPoints: [this.selectedMeasurementObject],
        tblWaterSamples: [this.selectedAirSample] // note: not kidding; air samples are stored in water samples
      }

      if (this.isGaugingTubeGuidSet) {
        request.tblGaugingTubes = [this.selectedGaugingTube]
      }

      return request
    }
  },
  methods: {
    customRowValidation(event) {
      if (event.isValid === false) {
        return
      }

      if (event.newData.BoName) {
        event.isValid = this.validateNameIsUnique(event)

        if (event.isValid === false) {
          event.errorText = this.$t('message.nameShouldUnique')
        }
      }
    },
    validateNameIsUnique(event) {
      const validationContext = {
        dataTable: this.tableData,
        propertyToValidate: 'BoName',
        guidToValidate: this.tableIdentifier,
        event: event
      }

      return DataTableUtils.ValidatePropertyIsUniqueInDataTable(
        validationContext
      )
    },
    async createTableRow(row) {
      try {
        const record = {
          ...row.data
        }

        const packagingsTable = await this.$store.dispatch('createBottle', {
          bottles: [record],
          projectId: this.projectId,
          measurementPointGuid: this.selectedMeasurementObjectGuid,
          waterSampleGuid: this.selectedAirSampleGuid,
          gaugingTubeGuid: this.selectedGaugingTubeGuid
        })

        this.tableData = packagingsTable.tblBottles
      } catch (error) {
        await this.handleErrorResponse(error)
      }
    },
    async updateTableRow(row) {
      try {
        const record = {
          ...row.data,
          [this.dateLastChangedField]:
            await DataTableUtils.GenerateLastUpdatedAt()
        }

        await this.$store.dispatch('updateBottle', {
          bottles: [record],
          projectId: this.projectId,
          measurementPointGuid: this.selectedMeasurementObjectGuid,
          waterSampleGuid: this.selectedAirSampleGuid,
          filterTubeGuid: this.selectedGaugingTubeGuid
        })
      } catch (error) {
        await this.handleErrorResponse(error)
      }
    },
    async deleteTableRow(key) {
      try {
        await this.$store.dispatch('deleteBottle', {
          bottleGuid: key,
          projectId: this.projectId,
          measurementPointGuid: this.selectedMeasurementObjectGuid,
          waterSampleGuid: this.selectedAirSampleGuid,
          gaugingTubeGuid: this.selectedGaugingTubeGuid
        })
      } catch (error) {
        await this.handleErrorResponse(error)
      }
    },
    async refreshTableData() {
      if (this.isValidRequest) {
        await this.getTableData().then(async (response) => {
          this.tableData = response
        })
      } else {
        await this.clearTableData()
      }
    },
    async clearTableData() {
      this.tableData = []
    },
    async getTableData() {
      try {
        return await this.$store.dispatch('getBottles', {
          projectId: this.projectId,
          measurementPointGuid: this.selectedMeasurementObjectGuid,
          waterSampleGuid: this.selectedAirSampleGuid,
          gaugingTubeGuid: this.selectedGaugingTubeGuid
        })
      } catch (error) {
        await this.handleErrorResponse(error)
      }
    },
    async getTableColumns() {
      try {
        this.tableColumns = await this.$store
          .dispatch('getDefaultFieldTemplate', {
            templateName: this.templateName
          })
          .then((templateData) => {
            this.template = templateData
            return GuiTemplateParser.parseTemplate(templateData)
          })
      } catch (error) {
        await this.handleErrorResponse(error)
      }
    },
    async handleErrorResponse(error, errorMessage = 'message.UnknownError') {
      console.log(error)
      TiPopup.popup(this.$t(errorMessage))
    }
  }
}
</script>
