<template>
  <div class="all-lab-assignments-data-table-overview">
    <div class="overview-table-row">
      <div class="overview-table">
        <DxDataGrid
          key-expr="LbID"
          :data-source="tableData"
          :focusedRowEnabled="true"
          :row-alternation-enabled="true"
          columnRenderingMode="virtual"
          @exporting="onExporting"
          @toolbar-preparing="onToolbarPreparing"
          @row-dbl-click="onRowDoubleClick"
        >
          <template #tableTitleTemplate>
            <h2>{{ tableTitle }}</h2>
          </template>
          <template #dateRangePickerTemplate>
            <div style="display: flex; min-width: 250px; padding-right: 50px">
              <div class="dx-field-label"> {{ $t('label.from') }}</div>
              <!-- eslint-disable-next-line vue/valid-v-model -->
              <DxDateBox v-model:value="fromDate" display-format="dd-MM-yyyy" :width="100" />
              <div class="dx-field-label"> {{ $t('label.to') }}</div>
              <!-- eslint-disable-next-line vue/valid-v-model -->
              <DxDateBox v-model:value="toDate" display-format="dd-MM-yyyy" :width="100" />
            </div>
          </template>
          <template #lab-status-codes-cell="{data}">
            <td :class="getClassNameForLabStatusCode(data)">{{ data.value }}</td>
          </template>
          <DxScrolling mode="virtual" row-rendering-mode="standard" />
          <DxColumn :caption="this.$t('columnTitles.ProjectCode')" data-type="string" data-field="PrCode" />
          <DxColumn :caption="this.$t('columnTitles.labAssignments.ID')" data-type="string" data-field="LbID" />
          <DxColumn :caption="this.$t('columnTitles.labAssignments.SikbLabCode')" data-type="string" :calculate-cell-value="calculateCellValueForLbSikbLabCode" data-field="LbSikbLabCode" />
          <DxColumn :caption="this.$t('columnTitles.labAssignments.Date')" display-format="dd-MM-yyyy" data-type="date" data-field="LbDate" />
          <DxColumn :caption="this.$t('columnTitles.labAssignments.SikbLabMatrixCode')" data-type="string" data-field="AnMatrix" />
          <DxColumn :caption="this.$t('SuggestedSamples.NumberOfSamples')" data-type="string" data-field="AMAmount" />
          <DxColumn :caption="this.$t('columnTitles.labAssignments.LabStatusCode')" data-type="string" data-field="LbLabStatusCode" cell-template="lab-status-codes-cell"/>
          <DxColumn :caption="this.$t('columnTitles.labAssignments.CustomerCode')" data-type="string" data-field="LbCustomerCode" />
          <DxColumn :caption="this.$t('columnTitles.labAssignments.ProjectLeader')" data-type="string" data-field="LbProjectLeader" />
          <DxColumn :caption="this.$t('columnTitles.labAssignments.ProjectLeaderEmail')" data-type="string" data-field="LbProjectLeaderEmail" />
          <DxColumn :caption="this.$t('columnTitles.labAssignments.OrderNr')" data-type="string" data-field="LbOrderNr" />
          <DxColumn :caption="this.$t('columnTitles.labAssignments.Remarks')" data-type="string" data-field="LbRemarkt" />
          <DxColumn :caption="this.$t('columnTitles.labAssignments.LabCertificateNumber')" data-type="string" data-field="LbLabCertificateNumber" />
          <DxSearchPanel :visible="true" :width="160" />
          <DxGrouping :auto-expand-all="true" expand-mode="buttonClick" />
          <DxGroupPanel :visible="true" />
          <DxHeaderFilter :visible="true" />
          <DxExport :enabled="true" />
        </DxDataGrid>
      </div>
    </div>
  </div>
</template>

<script>
import { DxDataGrid, DxScrolling, DxColumn, DxGrouping, DxGroupPanel, DxSearchPanel, DxHeaderFilter, DxExport } from 'devextreme-vue/data-grid'
import DxDateBox from 'devextreme-vue/date-box'
import '@/assets/css/table-cell.css'

import labAssignmentUtils from '@/business/libs/labassignments'

import deDevExtremeLocale from '@/locales/devExtreme/de'
import esDevExtremeLocale from '@/locales/devExtreme/es'
import frDevExtremeLocale from '@/locales/devExtreme/fr'
import itDevExtremeLocale from '@/locales/devExtreme/it'
import nlDevExtremeLocale from '@/locales/devExtreme/nl'
import { locale, loadMessages } from 'devextreme/localization'
import cookie from 'vue-cookie'
import config from '@/configurations/config-environment'
import labStatusCodeUtils from '@/utils/labStatusCodeUtils'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { Workbook } from 'exceljs'
import saveAs from 'file-saver'

export default {
  name: 'allLabAssignmentsOverview',
  components: {
    DxDataGrid,
    DxScrolling,
    DxColumn,
    DxGrouping,
    DxGroupPanel,
    DxSearchPanel,
    DxHeaderFilter,
    DxExport,
    DxDateBox
  },
  data() {
    return {
      projectCode: '',
      tableData: [],
      labAssignemtStatusCodeList: [],
      fieldMatrixCodes: [],
      fromDate: '',
      toDate: ''
    }
  },
  computed: {
    tableTitle() {
      return `${this.$t('tableTitles.LabAssignments')} (${this.tableData.length})`
    },
    fileName() {
      return `${this.$t('tableTitles.LabAssignments')}(${this.fromDate}-${this.toDate})`
    },
    dateRange() {
      return { fromDate: this.fromDate, toDate: this.toDate }
    }
  },
  created() {
    this.toDate = new Date()
    this.fromDate = new Date(new Date().setMonth(this.toDate.getMonth() - 1))

    const language = cookie.get('lang')

    if (language && language !== 'en') {
      switch (language) {
        case 'nl':
          loadMessages(nlDevExtremeLocale)
          break
        case 'fr':
          loadMessages(frDevExtremeLocale)
          break
        case 'de':
          loadMessages(deDevExtremeLocale)
          break
        case 'es':
          loadMessages(esDevExtremeLocale)
          break
        case 'it':
          loadMessages(itDevExtremeLocale)
          break
      }
      locale(language)
    }
  },
  watch: {
    'dateRange': {
      handler: function (val, oldVal) {
        this.getData()
      },
      immediate: false,
      deep: true
    }
  },
  methods: {
    getClassNameForLabStatusCode(labAssignmentData) {
      return labStatusCodeUtils.getClassNameForLabStatusCode(labAssignmentData, this.labAssignemtStatusCodeList)
    },
    onRowDoubleClick(e) {
      const id = e.data.LbID
      if (!id) {
        return
      }
      const url = `${config.apps.labassignment}6?PrID=${e.data.PrID}&LbID=${id}&clear=true&saved=true`
      window.open(url, '_blank')
    },
    calculateCellValueForLbSikbLabCode(row) {
      if (!row || !row.LbSikbLabCode) return ''
      return labAssignmentUtils.getCodeObjectFromCode(labAssignmentUtils.labCodeMap(), row.LbSikbLabCode).description
    },
    async getData() {
      this.$store.state.loading = true
      if (this.labAssignemtStatusCodeList.length <= 0) {
        this.labAssignemtStatusCodeList = await this.$store.dispatch('getCodeListByCategoryIDOrCode', { categoryId: 62 })
      }
      if (this.fieldMatrixCodes.length <= 0) {
        this.fieldMatrixCodes = await this.$store.dispatch('getCodeListByCategoryIDOrCode', { categoryId: 253 })
      }
      this.tableData = await this.$store.dispatch('getAllLabAssignmentsBetweenTwoDates', {
        FromDate: this.fromDate.toISOString().split('T')[0],
        ToDate: this.toDate.toISOString().split('T')[0]
      })

      this.tableData.forEach((record) => {
        record = this.setLabasignmentState(record)
        record = this.setFieldMatrix(record)
      })
      this.$store.state.loading = false
    },
    async onToolbarPreparing(event) {
      event.toolbarOptions.items.unshift({
        location: 'before',
        template: 'tableTitleTemplate',
        sortIndex: 0
      })
      event.toolbarOptions.items.unshift({
        location: 'after',
        template: 'dateRangePickerTemplate',
        sortIndex: 0
      })
    },
    setLabasignmentState(labassignment) {
      let state = this.$t('project.LabAssignmentNotSent')
      if (labassignment.LbIsSent === 'true') {
        state = this.$t('project.LabAssignmentSent')
      }
      if (labassignment.LbLabStatusCode) {
        const foundCode = this.labAssignemtStatusCodeList.find((labAssignemtStatusCode) => {
          return labAssignemtStatusCode.GcCode === labassignment.LbLabStatusCode.toString()
        })
        if (foundCode) {
          state = foundCode.GcDescription
        }
      }
      labassignment.LbLabStatusCode = state
      return labassignment
    },
    setFieldMatrix(labassignment) {
      const matrix = labassignment.AnMatrix
      switch (matrix) {
        case 'GR':
        case 'OV':
        case 'SL':
          labassignment.AnMatrix = this.fieldMatrixCodes[0].GcDescription // Ground
          break
        case 'GW':
        case 'AW':
        case 'OW':
          labassignment.AnMatrix = this.fieldMatrixCodes[1].GcDescription // Water
          break
        case 'BL':
        case 'OL':
          labassignment.AnMatrix = this.fieldMatrixCodes[2].GcDescription // Air
          break
        default: // Ground
          labassignment.AnMatrix = this.fieldMatrixCodes[0].GcDescription
          break
      }
      return labassignment
    },
    onExporting(e) {
      const workbook = new Workbook()
      const worksheet = workbook.addWorksheet('Sheet')
      const fileName = this.fileName + '.xlsx'

      exportDataGrid({
        component: e.component,
        worksheet: worksheet
      }).then(function () {
        workbook.xlsx.writeBuffer()
          .then(function (buffer) {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName)
          })
      })
    }
  }
}
</script>

<style scoped>
.dx-field-label {
  width: auto;
  padding: 8px 15px 9px 15px;
}
.all-lab-assignments-data-table-overview h2 {
  margin-top: 5px;
  font-size: 20px;
}
.all-lab-assignments-data-table-overview {
  height: 100%;
}
.all-lab-assignments-data-table-overview .overview-table-row {
  height: 100%;
}
.all-lab-assignments-data-table-overview .overview-table-row:first-child {
  display: flex;
}
.all-lab-assignments-data-table-overview .overview-table {
  margin-right: 0.7rem;
}
.all-lab-assignments-data-table-overview .overview-table-row .overview-table > div {
  margin-left: 10px;
}
.all-lab-assignments-data-table-overview .overview-table > div,
.all-lab-assignments-data-table-overview .overview-table > div > div {
  height: 100%;
}
</style>

<style>
.dx-dropdowneditor-icon {
  color: white
}
.dx-widget.dx-visibility-change-handler {
  width: inherit !important;
}
</style>
