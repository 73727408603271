export const fieldTypeIdentifiers = {
  'ID': 'hide',
  'GUID': 'hide',
  'Code': 'hide',
  'Date': 'date',
  'Is': 'boolean'
}

export let tableDataSetParser = {
  processGroupBanner(foundColumns, ctx, key, connectionCharacter, bannerIdentifier, bannerTitleIdentifier) {
    const id = ctx[key]
    const groupName = ctx[bannerTitleIdentifier + connectionCharacter + id]
    foundColumns.push((bannerTitleIdentifier + connectionCharacter + id).toString(), key)

    return { bannerIdentification: key, title: groupName }
  },

  processGroupItem(foundColumns, ctx, key, connectionCharacter, bannerIdentifier, translationsGroup) {
    const splitKey = key.split(connectionCharacter)
    const groupKey = bannerIdentifier + connectionCharacter + splitKey[1]
    const translationKey = translationsGroup ? translationsGroup + '.' + splitKey[0] : splitKey[0]

    foundColumns.push(key)

    return { groupKey, title: translationKey, dataField: key }
  },

  processNormalColumn(foundColumns, key, translationsGroup) {
    foundColumns.push(key)
    const translationKey = translationsGroup ? translationsGroup + '.' + key : key

    return { title: translationKey, dataField: key }
  },

  Bannered(bannerIdentifier, connectionCharacter, bannerTitleIdentifier, translationsGroup, dataset) {
    const foundColumns = []
    const columnsObject = []
    const foundGroups = []
    const groupColumns = {}

    dataset.forEach(datasetRecord => {
      Object.keys(datasetRecord).forEach(key => {
        if (foundColumns.includes(key)) return

        if (key.includes(connectionCharacter)) {
          if (key.includes(bannerIdentifier) && datasetRecord[key]) {
            const groupBanner = this.processGroupBanner(foundColumns, datasetRecord, key, connectionCharacter, bannerIdentifier, bannerTitleIdentifier)
            foundGroups.push(groupBanner)
          } else if (!key.includes(bannerTitleIdentifier)) {
            const groupItem = this.processGroupItem(foundColumns, datasetRecord, key, connectionCharacter, bannerIdentifier, translationsGroup)

            if (!groupColumns[groupItem.groupKey]) {
              groupColumns[groupItem.groupKey] = []
            }
            groupColumns[groupItem.groupKey].push({ title: groupItem.title, dataField: groupItem.dataField })
          }
        } else {
          const normalColumn = this.processNormalColumn(foundColumns, key, translationsGroup)
          columnsObject.push(normalColumn)
        }
      })
    })

    return { columns: columnsObject, groups: foundGroups, groupColumns: groupColumns }
  }
}
