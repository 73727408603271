// disable eslint check for new-cap because every openlayer object is lowercase
/* eslint-disable new-cap */

import Draw from 'ol/interaction/Draw'
import store from '@/vuex/store'
import olWKT from 'ol/format/WKT'
import olFeature from 'ol/Feature'
import olPolygon from 'ol/geom/Polygon'
import olLayerVector from 'ol/layer/Vector'
import olSourceVector from 'ol/source/Vector'
import olGeoJSON from 'ol/format/GeoJSON'

import olStyleStroke from 'ol/style/Stroke'
import olStyleFill from 'ol/style/Fill'
import olStyleText from 'ol/style/Text'
import olStyleStyle from 'ol/style/Style'
import olStyleIcon from 'ol/style/Icon'

import pencilSvg from './pencil.svg'
import proj4 from 'proj4'
import convert from 'color-convert'

export default {
  convertGeometryWKT(geometryWKT) {
    const formatWKT = new olWKT()
    const getGeoJSON = new olGeoJSON()
    const featureGeometry = formatWKT.readGeometry(geometryWKT)
    const geoJSONFormat = getGeoJSON.writeGeometryObject(featureGeometry)

    return this.setGeometryData(geoJSONFormat)
  },

  setGeometryData(geoJSONFormat) {
    const geometryLonLatData = []
    if (geoJSONFormat.type === 'Point') {
      return geometryLonLatData
    }

    if (geoJSONFormat.type === 'LineString') {
      geoJSONFormat.coordinates.forEach((coordinates) => {
        geometryLonLatData.push(this.geometryConversion(coordinates))
      })
      return geometryLonLatData
    }

    geoJSONFormat.coordinates[0].forEach((coordinates) => {
      geometryLonLatData.push(this.geometryConversion(coordinates))
    })
    return geometryLonLatData
  },

  geometryConversion(coordinates) {
    const conversionResult = proj4('EPSG:4326', 'EPSG:3857', [coordinates[0], coordinates[1]])
    const dataToSave = [conversionResult[0], conversionResult[1]]
    return dataToSave
  },

  async refreshSubLocations() {
    const SlDataEntry = await store.dispatch('getSubLocations', { projectId: store.state.route.params.projectId })
    const subLocationsWithPolygonData = []
    if (SlDataEntry) {
      const filledPolygons = SlDataEntry.filter(location => location.SlPolygonGeometry && location.SlPolygonGeometry !== 'POLYGON EMPTY')

      filledPolygons.forEach(item => {
        const geometryLonLatData = this.convertGeometryWKT(item.SlPolygonGeometry)
        subLocationsWithPolygonData.push({
          type: 'subLocation',
          name: item.SlName,
          location: item.SlGuid,
          featureCount: Number(item.SlID),
          coords: [geometryLonLatData]
        })
      })
    }

    return subLocationsWithPolygonData
  },

  async refreshProjectContour() {
    const prDataEntry = store.getters.getCurrentProject
    if (prDataEntry && prDataEntry.PrPolygonGeometry && prDataEntry.PrPolygonGeometry !== 'POLYGON EMPTY') {
      const geometryLonLatData = this.convertGeometryWKT(prDataEntry.PrPolygonGeometry)
      return {
        type: 'project',
        name: prDataEntry.PrName,
        location: prDataEntry.PrGuid,
        coords: [geometryLonLatData],
        featureCount: 0
      }
    }
    return null
  },

  async refreshDrawFeatures() {
    const drawFeatures = await this.refreshSubLocations()
    const projectContour = await this.refreshProjectContour()

    if (projectContour) {
      drawFeatures.push(projectContour)
    }

    if (drawFeatures.length > 0) {
      localStorage.setItem('drawFeatures', JSON.stringify(drawFeatures))
    }

    return drawFeatures
  },

  /**
   * Checks if the user is using the application in online- or offline-modus
   * @returns Boolean
   */
  get hasInternetConnection() {
    return window.navigator.onLine
  },
  /**
   * Set vector layer ('drawLayer', 'drawSource') from localStorage
   * @param {*} ctx Open Layers map component
  */
  async setupDrawLayer (ctx) {
    // Draw the data on the map
    ctx.drawSource = new olSourceVector({
      format: new olGeoJSON(),
      projection: 'EPSG:4326'
    })

    const storedDrawFeatures = localStorage.getItem('drawFeatures')
    const drawFeatures = this.hasInternetConnection ? await this.refreshDrawFeatures() : JSON.parse(storedDrawFeatures) || []

    drawFeatures.forEach(({ coords, type, location, name, featureCount }, index) => {
      const featureObject = this.createDrawFeature({ type, location, name, geometry: new olPolygon(coords), featureCount }, index)
      ctx.drawSource.addFeature(featureObject)
    })

    ctx.drawLayer = new olLayerVector(
      Object.assign({
        category: 'ProjectData',
        name: 'Contour',
        opacity: 1,
        opacityPercentage: 100,
        editable: true,
        preload: Infinity,
        source: ctx.drawSource,
        isDraw: true
      }, ctx.mapLayersConfig['Contour']))
  },

  /**
   * Add draw interaction. Handles leaving draw mode and storing shape layer data in local storage.
   * @param {Object} ctx Open Layers map component
   */
  addDrawInteraction (ctx) {
    ctx.drawFeature = new Draw({
      source: ctx.drawSource,
      type: 'Polygon',
      style: new olStyleStyle({
        image: new olStyleIcon({
          src: pencilSvg,
          scale: 1.5,
          anchor: [0, 0],
          anchorOrigin: 'bottom-left'
        }),
        stroke: new olStyleStroke({
          color: 'rgba(59, 157, 206, 0.8)',
          width: 2
        }),
        fill: new olStyleFill({
          color: 'rgba(255,255,255,0.2)'
        })
      })
    })

    const drawHandlerCtx = this
    ctx.drawFeature.on('drawend', function (evt) {
      const feature = evt.feature
      const { type, location, name } = ctx.drawMode

      feature.values_.type = type
      feature.values_.location = location
      feature.values_.name = name

      // Time margin to store contour in layer before saving it to local storage
      setTimeout(() => {
        ctx.toggleClickToDrawContour(false, null)
        drawHandlerCtx.addContourToDraw(feature, ctx.drawSource)
      }, 200)
    })
  },

  /**
   * Create new feature, with id and applied style
   * @param {*} param0 feature values: { type, location, name, geometry }
   * @param {*} id unique feature id
   * @returns vector feature with applied style
   */
  createDrawFeature ({ type, location, name, geometry: polygon, featureCount }, id) {
    const featureObject = new olFeature({ type, location, name, geometry: polygon, featureCount })
    featureObject.setId(`poly-${id}`)
    this.applyDrawStyle(featureObject, type, name, featureCount)

    return featureObject
  },

  /**
   * Generate color RGB based in input RGB to determine contrast for the text outline
   * to increase readability for polygon text labels so users will be able to see
   * the labels better on the map.
   * Algorytm from https://www.w3.org/TR/AERT/#color-contrast
   * @param {*} red
   * @param {*} green
   * @param {*} blue
   */
  colorContrast(red, green, blue) {
    const brightnessMaxDifference = 130
    const maxContrastRed = 299
    const maxContrastGreen = 587
    const maxContrastBlue = 114
    const calculation = parseInt((Math.round(red) * maxContrastRed + Math.round(green) * maxContrastGreen + Math.round(blue) * maxContrastBlue) / 1000)

    return calculation > brightnessMaxDifference ? [0, 0, 0, 0.94].join(',') : [255, 255, 255, 0.94].join(',')
  },

  /**
   * Apply, by reference, style to a feature and a displayed label
   * Styling is partly random and partly different between 'location' and 'sublocation' features
   * @param {*} featureObject
   * @param {*} type 'project' | 'location' | 'sublocation'. Used to apply different styling.
   * @param {*} name string to identify features
   */
  applyDrawStyle (featureObject, type, name, featureCount) {
    const hslColor = this.getFeatureHSLColor(featureCount)
    const rgbColor = convert.hsl.rgb(hslColor[0], hslColor[1], hslColor[2])

    const style = new olStyleStyle({
      fill: new olStyleFill({
        color: `rgba(${rgbColor.join(',')}, 0.20)`,
        weight: 1
      }),
      stroke: new olStyleStroke({
        color: `rgba(${rgbColor.join(',')}, 0.8)`,
        width: 2.2
      }),
      text: new olStyleText({
        textAlign: 'center',
        textBaseline: 'middle',
        font: 'bold 16px/1 Arial',
        text: name,
        fill: new olStyleFill({ color: `rgba(${rgbColor.join(',')}, 0.90)` }),
        stroke: new olStyleStroke({ color: `rgba(${this.colorContrast(rgbColor[0], rgbColor[1], rgbColor[2])})`, width: 2.5 }),
        offsetX: 0,
        offsetY: 0,
        placement: 'point',
        maxAngle: 0.7,
        overflow: true,
        rotation: 0
      })
    })

    featureObject.setStyle(style)
  },

  getFeatureHSLColor(featureCount) {
    if (featureCount >= 20) {
      const randomHue = Math.random() * 360
      return [randomHue, 100, 40]
    }

    const standardHueColors = [255, 190, 70, 0, 213, 128, 43]
    const hueColorIndex = featureCount % standardHueColors.length
    const hueColor = standardHueColors[hueColorIndex]

    let saturationDecreasePercentage = Math.floor(featureCount / standardHueColors.length) * 20
    let saturationPercentage = 80 - saturationDecreasePercentage

    let lightnessPercentage = 50
    if (featureCount >= 14) {
      lightnessPercentage = 40
    } else if (featureCount >= 7) {
      lightnessPercentage = 70
    }

    return [hueColor, saturationPercentage, lightnessPercentage]
  },

  /**
   * Adds style to newly created contour and updates local storage
   * @param {*} feature newly created feature
   * @param {*} drawSource vector features layer source
   */

  addContourToDraw: function(feature, drawSource) {
    const type = feature.values_.type

    switch (type) {
      case 'subLocation':
        this.addSubLocationContourToDraw(feature, drawSource)
        break
      case 'project':
        this.addProjectContourToDraw(feature, drawSource)
        break
      default:
        console.log('Invalid contour type: ' + type)
    }
  },

  featureGeometryAsPolygonWKT(feature) {
    const geometryLonLatCoordinates = []
    const geometryCoordinates = feature.getGeometry().getCoordinates()
    geometryCoordinates[0].forEach((v) => {
      const conversionResult = proj4('EPSG:3857', 'EPSG:4326', [v[0], v[1]])
      const dataToSave = [conversionResult[0], conversionResult[1]]
      geometryLonLatCoordinates.push(dataToSave)
    })

    const geometryLonLatCoordinate = geometryLonLatCoordinates.join('_').split(',').join(' ').trim().replaceAll('_', ',')
    const reformattedWellKnownToken = `POLYGON ((${geometryLonLatCoordinate}))`
    return reformattedWellKnownToken
  },

  /**
   * Adds style to newly created sublocation contour and updates local storage
   * @param {*} feature newly created feature
   * @param {*} drawSource vector features layer source
   */
  addSubLocationContourToDraw: function(feature, drawSource) {
    const { subLocations } = store.state
    const { type, location } = feature.values_
    const subLocation = subLocations.find(sl => sl.SlGuid === location)
    if (!subLocation) return

    // there's only one contour per sublocation, so if the user draws multiple, remove the previous ones
    this.removeMatchingFeatures(drawSource, oldFeature =>
      oldFeature !== feature &&
      oldFeature.values_.type === 'subLocation' &&
      oldFeature.values_.name === subLocation.SlName)

    feature.values_.name = subLocation.SlName
    this.applyDrawStyle(feature, type, subLocation.SlName, Number(subLocation.SlID))

    const ReformattedWellKnownToken = this.featureGeometryAsPolygonWKT(feature)
    const PolygonData = {
      PrID: store.state.route.params.projectId,
      SlID: subLocation.SlID,
      SlGuid: subLocation.SlGuid,
      SlPolygonGeometry: ReformattedWellKnownToken
    }

    if (this.hasInternetConnection === true) {
      store.dispatch('setContourOfItem', PolygonData)
    }

    this.updateDrawFeaturesInLocalStorage(drawSource)
  },

  updateDrawFeaturesInLocalStorage: function(drawSource) {
    const features = drawSource.getFeatures().map(({ values_ }) => ({
      coords: values_.geometry.getCoordinates(),
      type: values_.type,
      location: values_.location,
      name: values_.name
    }))

    localStorage.setItem('drawFeatures', JSON.stringify(features))
  },

  /**
   * Adds style to newly created project feature and updates local storage
   * @param {*} feature newly created feature
   * @param {*} drawSource vector features layer source
   */
  addProjectContourToDraw: function(feature, drawSource) {
    if (!store.state.projects || !store.state.projects[0]) {
      return
    }

    const { type } = feature.values_
    const project = store.state.projects[0]

    // there's only one project contour, so if the user draws multiple, remove the old ones
    this.removeMatchingFeatures(drawSource,
      oldFeature => oldFeature !== feature && oldFeature.values_.type === 'project')

    feature.values_.name = project.PrName
    // project should always have the feature count of 0
    this.applyDrawStyle(feature, type, project.PrName, 0)

    const reformattedWellKnownToken = this.featureGeometryAsPolygonWKT(feature)
    project.PrPolygonGeometry = reformattedWellKnownToken

    store.dispatch('setProject', { projectData: project })
  },

  removeMatchingFeatures: function(drawSource, predicate) {
    drawSource.getFeatures().forEach(feature => {
      if (predicate(feature)) {
        drawSource.removeFeature(feature)
      }
    })
  },

  /**
   * Delete feature from draw layer and update localstorage
   * @param {*} context Open Layers map component
   */
  deleteDrawFeature (context) {
    const type = context.selectedDrawFeature.values_.type

    switch (type) {
      case 'subLocation':
        this.deleteSubLocationDrawFeature(context)
        break
      case 'project':
        this.deleteProjectDrawFeature(context)
        break
      default:
        console.log('Invalid contour type: ' + type)
    }
  },

  deleteSubLocationDrawFeature(context) {
    const { subLocations } = store.state
    const subLocation = subLocations.find(location => location.SlGuid === context.selectedDrawFeature.values_.location)

    if (subLocation) {
      context.drawSource.removeFeature(context.selectedDrawFeature)
      context.selectedDrawFeature = null

      const SublocationPolygonData = {
        PrID: store.state.route.params.projectId,
        SlID: subLocation.SlID,
        SlGuid: subLocation.SlGuid,
        SlPolygonGeometry: null
      }

      store.dispatch('deleteContourOfItem', SublocationPolygonData)

      this.updateDrawFeaturesInLocalStorage(context.drawSource)
    }
  },

  deleteProjectDrawFeature(context) {
    if (!store.state.projects || !store.state.projects[0]) {
      return
    }

    const project = store.state.projects[0]
    project.PrPolygonGeometry = ''
    store.dispatch('setProject', { projectData: project })

    context.drawSource.removeFeature(context.selectedDrawFeature)
    context.selectedDrawFeature = null

    this.updateDrawFeaturesInLocalStorage(context.drawSource)
  }
}
